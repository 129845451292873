import React from 'react'

/**
* @author
* @function ToggleSidebarHeading
**/

const ToggleSidebarHeading = ({heading}) => {
  return(
    <h6 className="ToggleSidebarHeading">{heading}</h6>
   )
  }


export default ToggleSidebarHeading