import React from "react";
// import { Form, InputGroup } from "react-bootstrap";
import "./InputField.css";
export default function InputFieldDDR({
  name,
  type,
  placeholder,
  handleInputchange,
  value,
  classname,
  parentclassname,
  readonly,
  min,
  max,
  parentComponet,
}) {
  const _placeholder = placeholder || "Enter Value";
  const _type = type || "text";
  const _classname = classname || "";
  const _parentclassname =
    parentComponet !== "dates"
      ? parentclassname || "inputFieldOrgDetail"
      : parentclassname || "inputFieldOrgDetail2";

  const _readonly = readonly || false;

  return (
    <>
      <div className={_parentclassname}>
        <div className="inputFieldOrgDetailLabel">{_placeholder}</div>

        <input
          type={_type}
          name={name}
          className={_classname}
          // placeholder={_placeholder}
          value={value}
          min={min}
          max={max}
          onChange={(e) => handleInputchange(e.target.value, name)}
          readOnly={_readonly}
        />
      </div>
    </>
  );
}
