import React, { useState } from "react";
import { Button } from "react-bootstrap";
import Radio from "../../atoms/Radio/Radio";
import _ from "lodash";
import FileUpload from "../../atoms/FileUpload/FileUpload";
import {
  uploadPrintElectronicResourcesToLibraryUrl,
  downloadPrintElectronicUploadFormatExcelUrl,
} from "../../utilities/constants";
import { AddSubscriptionInfoDialog } from "../../atoms/Modals/AddSubscriptionInfoDialog";
import { useEffect } from "react";
import ReactTooltip from "react-tooltip";
import { UploadFileInfoDialog } from "../../atoms/Modals/UploadFileInfoDialog";


const AddSubscriptionModel = ({
  customerID,
  access_type,
  setIsAddSubscription,
}) => {
  const [data, setData] = useState({
    file: [],
    action_type: "1",
  });

  const [isAlertOn, setIsAlertOn] = useState(false);
  const [isAlertOnUpload, setIsAlertOnUpload] = useState(false);
  const [isAlertOnRejected, setIsAlertOnRejected] = useState(false);
  const [successData, setSuccessData] = useState("");
  const [isFileUpload, setIsFileUpload] = useState("");
  const [isEmptyFileUploaded, setIsEmptyFileUploaded] = useState(false);

  const isFileUploadData = isFileUpload.name;

  const handleInputchange = (value, name) => {
    const dynamic = _.cloneDeep(data);
    dynamic[name] = value;
    setData(dynamic);
  };

  const handleExcelDownload = async () => {
    window.open(downloadPrintElectronicUploadFormatExcelUrl(customerID));
  };

  const streamToCsv = (result) => {
    const url = window.URL.createObjectURL(new Blob([result]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `${Date.now()}.xlsx`);
    document.body.appendChild(link);
    link.click();
  };

  const handleUpload = async () => {
    const formData = new FormData();

    formData.append("Upload_Package", data["file"]);
    formData.append("customer_id", customerID);
    formData.append("action_type", data["action_type"]);
    formData.append("access_type", access_type);

    await fetch(uploadPrintElectronicResourcesToLibraryUrl, {
      method: "POST",
      body: formData,
    })
      .then((response) => response)
      .then((result) => {
        const contentType = result.headers.get("content-type");

        if (result.status === 200) {
          if (contentType === "application/json") {
            result.json().then((json) => {
              setSuccessData(json);
              if (json.total_count >= 1) {
                setIsAlertOnUpload(true);
              }
              if (json.total_count === "") {
                setIsEmptyFileUploaded(true);
              }
            });
          } else {
            result.blob().then((blob) => {
              streamToCsv(blob);
              setIsAlertOnRejected(true);
            });
          }
        } else {
          setIsAlertOn(true);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const sucessMessage = () => {
    return (
      <div>
        {successData.total_count} Titles Uploaded Successfully
      </div>
    );
  };

  const overrideUploadLabel =
    "Note : For Overwrite, all existing configurations will be as per newly uploaded details";
  const appandUploadLabel = "Note : please add only new content";

  const handleDisableUploadButton = () => {
    return !isFileUploadData;
  };

  useEffect(() => {
    handleDisableUploadButton();

  }, [isFileUpload]);

  return (
    <div>
      <AddSubscriptionInfoDialog
        show={isAlertOnUpload}
        modalClose={setIsAlertOnUpload}
        content={sucessMessage()}
        successCallback={handleUpload}
        heading="Info"
        setIsAddSubscription={setIsAddSubscription}
      />
      <AddSubscriptionInfoDialog
        show={isAlertOnRejected}
        modalClose={setIsAlertOnRejected}
        content={
          "Profile user list uploaded partially please check the downloaded excel for details"
        }
        successCallback={handleUpload}
        heading="Info"
        setIsAddSubscription={setIsAddSubscription}
      />
      <AddSubscriptionInfoDialog
        show={isAlertOn}
        modalClose={setIsAlertOn}
        content={
          "File format does not match, read the Instructions before you Upload"
        }
        successCallback={handleUpload}
        heading="Info"
        setIsAddSubscription={setIsAddSubscription}
      />
      <UploadFileInfoDialog
        show={isEmptyFileUploaded}
        modalClose={setIsEmptyFileUploaded}
        content={"Uploaded file is empty, Please check it once"}
        successCallback={handleUpload}
        heading="Info"
      />
      <div className="AddSubscriptionModelRow">
        <Radio
          label="New / Overwrite "
          value={"1"}
          name="action_type"
          checkedValue={data["action_type"]}
          handleInputchange={handleInputchange}
        />

        <Radio
          label="Append"
          value={"2"}
          checkedValue={data["action_type"]}
          handleInputchange={handleInputchange}
          name="action_type"
        />
      </div>

      <div className="AddSubscriptionModelRow">
        <FileUpload
          name="file"
          handleInputchange={handleInputchange}
          setIsFileUpload={setIsFileUpload}
        />
      </div>

      <div className="AddSubscriptionModelRow">
        {data["action_type"] === "1" ? overrideUploadLabel : appandUploadLabel}
      </div>

      <div className="ViewConfigureLibraryHeadingRow">
        <Button
          secondary
          onClick={handleExcelDownload}
          data-tip="Format/Subscriptions"
        > Download</Button>
        <ReactTooltip />

        <Button
          className="ms-2"
          onClick={handleUpload}
          disabled={handleDisableUploadButton()}
        >Upload</Button>
      </div>
    </div>
  );
};

export default AddSubscriptionModel;
