import React from "react";
import { useFetch } from "../../utilities/apiFunctions/useFetch";
import { getConsortiaMemberListUrl } from "../../utilities/constants";
import MemberViewTable from "./MemberViewTable";
import Loading from "../../components/Loading/Loading";
import CommonHeading from "../../atoms/Heading/CommonHeading";
/**
 * @author
 * @function MemberLibraries
 **/

const MemberLibraries = ({ user }) => {
  const { customerID } = user;

  const getConsortiaMemberListData = useFetch(
    getConsortiaMemberListUrl(customerID)
  );

  return (
    <div className="OrgDetails org_mrg_frm_top org_mrg_frm_btm">
      <CommonHeading heading={"View Member Libraries"} />
      {getConsortiaMemberListData.isLoading && <Loading />}
      {!getConsortiaMemberListData.isLoading &&
        getConsortiaMemberListData.response &&
        getConsortiaMemberListData.response.data &&
        !!getConsortiaMemberListData.response.data[0] && (
          <div>
            <MemberViewTable
              memberTableData={
                getConsortiaMemberListData &&
                getConsortiaMemberListData?.response &&
                getConsortiaMemberListData.response?.data &&
                getConsortiaMemberListData.response?.data[0][0]
              }
              customerID={customerID}
            />
          </div>
        )}
    </div>
  );
};

export default MemberLibraries;
