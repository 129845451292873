import React from "react";

/**
 * @author
 * @function Error
 **/

const ValidatinMessage = ({ text }) => {
  return (
    <span className="error text-danger w-350 contactPersondata">{text}</span>
  );
};

export default ValidatinMessage;

export const ErrorMsg = ({ text }) => {
  return (
    <span className="error text-danger w-350 contactPersondata">{text}</span>
  );
};
