import React from "react";
import "./Reports.css";
import { getLocalStorage } from "../../utilities/storage";
import ConsortiaAdminReports from "./ConsortiaAdminReports";
import LibraryAdminReports from "./LibraryAdminReports";



const Reports = () => {
  const { customerID, admin_type } = getLocalStorage("user");
  const { consortiamaster_id } = getLocalStorage("customer");
  return (
    <>
      {admin_type === "library_admin" && (
        <LibraryAdminReports
          customerID={customerID}
          consortiamaster_id={consortiamaster_id}
          admin_type={admin_type}
        />
      )}

      {admin_type !== "library_admin" && (
        <ConsortiaAdminReports
          customerID={customerID}
          consortiamaster_id={consortiamaster_id}
          admin_type={admin_type}
        />
      )}
    </>
  );
};

export default Reports;
