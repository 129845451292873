import React from "react";
import BarChartDDR from "../../components/Charts/BarChartDDR";
import BarChartRegion from "../../components/Charts/BarChartRegion";
import PiCharts from "../../components/Charts/PiCharts";
import UserCount from "../../components/DashboardComponents/UserCount";
import UsageSummary from "../../components/DashboardComponents/UsageSummary";
import WidgetResources from "../../components/DashboardComponents/WidgetResources";
import "./Dashboard.css";
import { useFetch } from "../../utilities/apiFunctions/useFetch";
import Loading from "../../components/Loading/Loading";
import NoData from "../../atoms/NoData/NoData";

import {
  libraryResourcesUrl,
  openResourcesUrlConsortia,
  forTopDDRFullfilledMembersListUrl,
  forTopConsortiaMembersByFulltextUsageUrl,
  dashboardForConsortiaSubscribedResourceCountUrl,
  forConsortiaUserCountUrl,
  forTopUsedPublisherByConsortiaUrl,
  forTopConsortiaMembersByUsageUrl,
  dashboard_for_consortia_user_region_wise,
  forDashboard_for_consortia_ddr_summary,
  usageSummaryDataUrlForConsortia,
} from "../../utilities/constants";
import WidgetHeading from "../../atoms/Heading/WidgetHeading";
import PiChartComponent from "../../components/Charts/PiChartComponent";
/**
 * @author vimalkovath
 * @function ConsortiaAdmin
 **/

const ConsortiaAdmin = ({
  customerID,
  consortiamaster_id,
  admin_type,
  finalSubject_Ids,
  dataType_Ids,
}) => {
  const forConsortiaSRCountData = useFetch(
    dashboardForConsortiaSubscribedResourceCountUrl(consortiamaster_id),
    {}
  );

  const libraryResourceData = useFetch(libraryResourcesUrl(customerID), {});
  const forConsortiaUserCountData = useFetch(
    forConsortiaUserCountUrl(consortiamaster_id),
    {}
  );
  const forTopConsortiaMembersByFulltextUsageData = useFetch(
    forTopConsortiaMembersByFulltextUsageUrl(consortiamaster_id),
    {}
  );
  const forTopUsedPublisherByConsortiaData = useFetch(
    forTopUsedPublisherByConsortiaUrl(consortiamaster_id),
    {}
  );

  const forTopConsortiaMembersByUsageData = useFetch(
    forTopConsortiaMembersByUsageUrl(consortiamaster_id),
    {}
  );

  const forTopDDRFullfilledMembersListData = useFetch(
    forTopDDRFullfilledMembersListUrl(consortiamaster_id),
    {}
  );

  const forDDRUsageByConsortiaMemberData = useFetch(
    forDashboard_for_consortia_ddr_summary(consortiamaster_id),
    {}
  );

  const dashboard_for_consortia_user_region_wiseData = useFetch(
    dashboard_for_consortia_user_region_wise(customerID),
    {}
  );

  const usageSummaryData = useFetch(
    usageSummaryDataUrlForConsortia(customerID),
    {}
  );

  const openResourcesUrlConsortiaData = useFetch(
    openResourcesUrlConsortia(dataType_Ids, finalSubject_Ids),
    {}
  );

  const pieKeysFultext = {
    name: "member_name",
    label: "member_name",
    count: "fulltext_usage_count",
  };

  const pieKeysConsortiaMember = {
    name: "member_name",
    label: "member_name",
    count: "usage_count",
  };

  const pieKeysDDRFulfillMember = {
    name: "customer_name",
    label: "customer_name",
    count: "fulfill_count",
  };

  return (
    <div className="content-wrapper Admin_wrapper dashbrd_mrg_frm_top dashbrd_mrg_frm_btm">
      <div className="row">
        <div className="col-md-12">
          <h2> Dashboard</h2>
          <WidgetHeading
            heading={"Available Resources"}
            customerID={customerID}
            consortiamaster_id={consortiamaster_id}
            admin_type={admin_type}
          />
        </div>
      </div>

      <div className="row">
        <div className="col-md-6 d-flex gap-1">
          <div className="col-md-6">
            {forConsortiaSRCountData.isLoading && <Loading />}
            {!forConsortiaSRCountData.isLoading &&
              forConsortiaSRCountData.response && (
                <WidgetResources
                  resourceData={forConsortiaSRCountData.response}
                  openAccess={false}
                  heading="Consortia Subscribed Resources"
                  customerID={customerID}
                  consortiamaster_id={consortiamaster_id}
                  admin_type={admin_type}
                />
              )}
          </div>
          <div className="col-md-6">
            {libraryResourceData.isLoading && <Loading />}
            {!libraryResourceData.isLoading &&
              libraryResourceData.response &&
              libraryResourceData.response.data &&
              !!libraryResourceData.response.data[0][0] && (
                <WidgetResources
                  resourceData={libraryResourceData.response}
                  openAccess={false}
                  heading="Library Subscribed Resources"
                  customerID={customerID}
                  consortiamaster_id={consortiamaster_id}
                  admin_type={admin_type}
                />
              )}
          </div>
        </div>

        <div className="col-md-6 ">
          {openResourcesUrlConsortiaData.isLoading && <Loading />}
          {!openResourcesUrlConsortiaData.isLoading &&
            openResourcesUrlConsortiaData.response &&
            openResourcesUrlConsortiaData.response.data && (
              <WidgetResources
                resourceData={
                  openResourcesUrlConsortiaData.response.data.jsonfacets
                }
                openAccess={true}
                heading="Open Access Resources"
                customerID={customerID}
                consortiamaster_id={consortiamaster_id}
                admin_type={admin_type}
              />
            )}
        </div>

        {/* <div className="col-md-6 ">
          {libraryResourceData.isLoading && <Loading />}
          {!libraryResourceData.isLoading &&
            libraryResourceData.response &&
            libraryResourceData.response.data &&
            !!libraryResourceData.response.data[0][0] && (
              <WidgetResources
                resourceData={libraryResourceData.response}
                openAccess={false}
                heading="Library Subscribed Resources"
                customerID={customerID}
                consortiamaster_id={consortiamaster_id}
                admin_type={admin_type}
              />
            )}
        </div> */}
      </div>

      <div className="chartRow">
        <div className="row ">
          <div className="col-md-2">
            {forConsortiaUserCountData.isLoading && <Loading />}
            {!forConsortiaUserCountData.isLoading &&
              forConsortiaUserCountData.response && (
                <UserCount
                  userCountData={forConsortiaUserCountData.response}
                  customerID={customerID}
                  consortiamaster_id={consortiamaster_id}
                  admin_type={admin_type}
                />
              )}
          </div>

          <div className="col-md-10">
            <div className="row">
              <div className="col-md-6">
                <WidgetHeading heading="Top 10 Consortia Publishers by FullText Download" />
                <div className="row">
                  <div className="chart1Widget">
                    {forTopUsedPublisherByConsortiaData.isLoading && (
                      <Loading />
                    )}
                    {!forTopUsedPublisherByConsortiaData.isLoading &&
                      forTopUsedPublisherByConsortiaData.response && (
                        <PiCharts
                          topUsedPublisherData={
                            forTopUsedPublisherByConsortiaData.response
                          }
                          customerID={customerID}
                          consortiamaster_id={consortiamaster_id}
                          admin_type={admin_type}
                        />
                      )}
                  </div>
                </div>
              </div>

              <div className="col-md-6">
                <WidgetHeading heading="Top 10 Consortia Members by FullText Download" />
                <div className="chart1Widget">
                  {forTopConsortiaMembersByFulltextUsageData.isLoading && (
                    <Loading />
                  )}
                  {!forTopConsortiaMembersByFulltextUsageData.isLoading &&
                    forTopConsortiaMembersByFulltextUsageData.response && (
                      <PiChartComponent
                        componentData={
                          forTopConsortiaMembersByFulltextUsageData.response
                        }
                        customerID={customerID}
                        consortiamaster_id={consortiamaster_id}
                        admin_type={admin_type}
                        keys={pieKeysFultext}
                      />
                    )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="chartRow">
        <div className="row">
          <div className="col-md-10">
            <div className="row">
              <div className="col-md-6">
                <WidgetHeading heading="Top 10 members by Total Usage" />
                <div className="chart1Widget">
                  {forTopConsortiaMembersByUsageData.isLoading && <Loading />}
                  {!forTopConsortiaMembersByUsageData.isLoading &&
                    forTopConsortiaMembersByUsageData.response && (
                      <PiChartComponent
                        componentData={
                          forTopConsortiaMembersByUsageData.response
                        }
                        customerID={customerID}
                        consortiamaster_id={consortiamaster_id}
                        admin_type={admin_type}
                        keys={pieKeysConsortiaMember}
                      />
                    )}
                </div>
              </div>
              <div className="col-md-6">
                <WidgetHeading heading="Top 10 members DDR Fullfilled" />
                <div className="chart1Widget">
                  {forTopConsortiaMembersByUsageData.isLoading && <Loading />}
                  {!forTopDDRFullfilledMembersListData.isLoading &&
                  forTopDDRFullfilledMembersListData &&
                  forTopDDRFullfilledMembersListData.response &&
                  forTopDDRFullfilledMembersListData.response.data &&
                  !!forTopDDRFullfilledMembersListData.response.data[0][0] ? (
                    <PiChartComponent
                      componentData={
                        forTopDDRFullfilledMembersListData.response
                      }
                      customerID={customerID}
                      consortiamaster_id={consortiamaster_id}
                      admin_type={admin_type}
                      keys={pieKeysDDRFulfillMember}
                    />
                  ) : (
                    <NoData />
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-2">
            {usageSummaryData.isLoading && <Loading />}
            {!usageSummaryData.isLoading && usageSummaryData.response && (
              <UsageSummary
                usageSummaryData={usageSummaryData.response}
                customerID={customerID}
                consortiamaster_id={consortiamaster_id}
                admin_type={admin_type}
              />
            )}
          </div>
        </div>
      </div>
      <div className="chartRow">
        <div className="row ">
          <div className="col-md-6">
            <WidgetHeading heading="DDR Summary" />
            <div className="chart2Widget">
              {forDDRUsageByConsortiaMemberData.isLoading && <Loading />}
              {!forDDRUsageByConsortiaMemberData.isLoading &&
                forDDRUsageByConsortiaMemberData.response && (
                  <BarChartDDR
                    Bardata={forDDRUsageByConsortiaMemberData.response}
                    customerID={customerID}
                    consortiamaster_id={consortiamaster_id}
                    admin_type={admin_type}
                  />
                )}
            </div>
          </div>

          <div className="col-md-6">
            <WidgetHeading heading="Profile Registered Region Wise" />
            <div className="chart2Widget">
              {dashboard_for_consortia_user_region_wiseData.isLoading && (
                <Loading />
              )}
              {!dashboard_for_consortia_user_region_wiseData.isLoading &&
                dashboard_for_consortia_user_region_wiseData.response && (
                  <BarChartRegion
                    Bardata={
                      dashboard_for_consortia_user_region_wiseData.response
                    }
                    customerID={customerID}
                    consortiamaster_id={consortiamaster_id}
                    admin_type={admin_type}
                  />
                )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConsortiaAdmin;
