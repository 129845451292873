import React from 'react'

/**
* @author
* @function Page404
**/

const Page404 = () => {
  return (
    <div>Page404</div>
  )
}


export default Page404