import React from 'react';
import ReactToPrint from "react-to-print";
import { useRef } from 'react';
import ReactHtmlParser from "react-html-parser";

export const DeliveryNoteDDRNotAvailableTemplate = ({ emailData, modalClose, data, isChangedSubject }) => {
    let componentRef = useRef();
    const userName = emailData && emailData.user_name.charAt(0).toUpperCase() + emailData.user_name.slice(1);
    const articleTitle = ReactHtmlParser(emailData && emailData?.article_name);
    const journalTitle = ReactHtmlParser(emailData && emailData?.journal_name);
    const authorTitle = ReactHtmlParser(emailData && emailData?.authors);

    const handlePrint = () => {
        window.location.reload();
    }
    const handlePrintButton = () => {
        modalClose();
    }
    return (
        <>

            <div ref={(el) => (componentRef = el)} className='ddr_note_body'>
                <h6 className='ddr_note_headr'>Delivery Note</h6>
                <div className="ddrr_status_note_body">
                    <div className='ddr_note_body_formate'>
                        <h6>To</h6>
                        {emailData.user_name && (<p>{userName}</p>)}
                        {emailData.user_department && (<p>Department :  {emailData?.user_department}</p>)}
                        {emailData.roll_no && (<p>Roll number : {emailData?.roll_no}</p>)}
                        {emailData.requester_customer_name && (<p>{emailData?.requester_customer_name}</p>)}
                        {emailData.requester_address && (<p>{emailData?.requester_address}</p>)}
                        {emailData.requester_city && (<p>{emailData?.requester_city}</p>)}
                        {emailData.requester_state && (<p>{emailData?.requester_state}</p>)}
                        {emailData.to_mail && (<p>Email : {emailData?.to_mail}</p>)}
                        {emailData.requester_contactno && (<p>Phone : {emailData?.requester_contactno}</p>)} <br />
                        {isChangedSubject === true ? (<p>Sub : {data?.mail_subject}</p>) : (<p>Sub : {emailData?.mail_subject},{emailData?.action_name}</p>)}
                    </div>
                    <div className='ddr_note_body_formate_art'>
                        <h6>Inter Library Loan Details</h6>
                        {emailData.article_name && (<span>Artice Title :  {articleTitle}</span>)}<br />
                        {emailData.journal_name && (<span> Journal<span className='ddr_articledata_formate'> : </span>{journalTitle}</span>)}<br />
                        {emailData.authors && (<span> Authors <span className='ddr_articledata_formate_authors'> : </span>{authorTitle}</span>)}<br />
                        {emailData.volume && (<span> Volume <span className='ddr_articledata_formate_volume'> : </span>{emailData?.volume}</span>)}<br />
                        {emailData.issue && (<span> Issue <span className='ddr_articledata_formate_issue'> : </span>{emailData?.issue}</span>)}<br />
                        {emailData.issue_year && (<span> Year  <span className='ddr_articledata_formate_year'> : </span> {emailData?.issue_year}</span>)}<br />
                        {emailData.requestedDate && (<span> Date of Request : {emailData?.requestedDate}</span>)}<br />
                    </div>
                    <div className='ddr_note_body_formate'>
                        <h6>From</h6>
                        {emailData.Fulfilling_customer_name && (<p>{emailData?.Fulfilling_customer_name}</p>)}
                        {emailData.fulfilling_address && (<p>{emailData?.fulfilling_address}</p>)}
                        {emailData.city && (<p>{emailData?.city}</p>)}
                        {emailData.state && (<p>{emailData?.state}</p>)}
                    </div>
                </div>
            </div>
            <div>
                <ReactToPrint
                    trigger={() => {
                        return (
                            <div className='ddr_note_print'><button
                                type="submit"
                                className="btn btn-primary "
                                onClick={handlePrintButton}
                            >
                                Print
                            </button>
                            </div>)
                    }}
                    content={() => componentRef}
                    onAfterPrint={() => { handlePrint() }}
                />
            </div>
        </>
    )
}