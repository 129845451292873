import React, { useState, useEffect } from "react";
import _ from "lodash";
import InputArea from "../../atoms/InputArea/InputArea";
import { updateConsortiaDDRForLibrarianEmail, } from "../../containers/DDR/constants";
import InputField from "../../atoms/InputField/InputField";
import { DeliveryNoteDDRNotAvailableTemplate } from "./DeliveryNoteDDRNotAvailableTemplate";
import ReactHtmlParser from "react-html-parser";
import { DDRDiscardStatusModal } from "./DDRDiscardStatusModel";

export const DDRNotAvailiableTemplate = ({ modalClose, fnData, getDDR, customerID }) => {

  const [data, setData] = useState(fnData);
  const [error, setError] = useState("");
  const [isDeliveryNote, setIsDeliveryNote] = useState(false);
  const [isChangedSubject, setIschangedSubject] = useState(false);

  useEffect(() => {
    let _error = "Message ";
    if (data["contu_message"] !== "") {
      setError("");
    } else {
      setError("Fill the neccessay field " + _error);
    }
  }, [data])



  useEffect(() => {
    const user = data?.user_name || "Sir / Madam ";
    const user_name = user && user.charAt(0).toUpperCase() + user.slice(1)
    const article_Title = ReactHtmlParser(data && data?.article_name);
    const journal_name = ReactHtmlParser(data && data?.journal_name);
    const authors = ReactHtmlParser(data && data?.authors);
    const volume = data && data?.volume;
    const issue = data && data?.issue;
    const year = data && data?.issue_year;
    const date_of_request = data && data?.requestedDate;
    const client_name = data && data?.Fulfilling_customer_name;
    const client_address = data && data?.fulfilling_address;
    const client_city = data && data?.city;
    const client_state = data && data?.state;
    const mailFormat = _.cloneDeep(data);

    mailFormat["contu_message"] = `Dear ${user_name !== null ? user_name : ""},

Sorry, your request could not be fulfilled due to non availability of the article.

Requested article details :
${article_Title !== null ? `Article Title  : ${article_Title}` : ""}
${journal_name !== null ? `Journal        : ${journal_name}` : ""}
${authors !== null ? `Authors       : ${authors}` : ""}
${volume !== null ? `Volume        : ${volume}` : ""}
${issue !== null ? `Issue            : ${issue}` : ""}
${year !== null ? `Year             : ${year}` : ""}
${date_of_request !== null ? `Date of Request : ${date_of_request}` : ""}

Regards
${client_name !== null ? `${client_name}` : ""}
${client_address !== null ? `${client_address}` : ""}
${client_city !== null ? client_city : ""}
${client_state !== null ? client_state : ""}`;
    setData(mailFormat);

  }, []);

  const handleInputchange = (value, name) => {
    const _dat = _.cloneDeep(data);
    _dat[name] = value;
    setData(_dat);

    if (name === "mail_subject") {
      setIschangedSubject(true);
    } else {
      setIschangedSubject(false);
    }
  };

  const handleApi = async () => {

    const _dat = _.cloneDeep(data);
    const _data = {};
    _data["consortiaddr_id"] = getDDR;
    _data["ddr_status"] = "4";
    _data["mode_of_delivery"] = null;
    _data["delivered_date"] = null;
    _data["ddr_reference_no"] = _dat["mail_subject"];
    _data["delivery_remarks"] = null;
    _data["courier_no"] = null;
    _data["delay_reason"] = null;
    _data["discard_reason"] = null;
    _data["delivering_userid"] = customerID;
    _data["is_duplicate"] = false;

    const str = `{"data":[${JSON.stringify(_data)}]}`;
    const formData = new FormData();


    if (isChangedSubject === true) {
      formData.append("detailjson", str);
      formData.append("attachment_file", null);
      formData.append("To_mail_Id", _dat["to_mail"]);
      // formData.append("To_mail_Id", "shylaja@informaticsglobal.com");
      // formData.append("CC_mail_Id", "shylabhatt@gmail.com");
       formData.append("CC_mail_Id", _dat["cc_email"] + ";" + _dat["approver_admin_mail"]);
      // formData.append("Subject", _dat["mail_subject"] + "," + _dat["action_name"]);
      formData.append("Subject", _dat["mail_subject"]);
      formData.append("Message", _dat["contu_message"]);

      fetch(updateConsortiaDDRForLibrarianEmail, {
        method: "POST",
        body: formData,
      })
        .then((response) => response.json())
        .then((result) => {
          if (result.data[0][0] === "Success") {
            setIsDeliveryNote(true);
          } else {
            alert("Something went wrong")
            setIsDeliveryNote(false);
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    } else {

      formData.append("detailjson", str);
      formData.append("attachment_file", null);
       formData.append("To_mail_Id", _dat["to_mail"]);
      // formData.append("To_mail_Id", "shylaja@informaticsglobal.com");
      // formData.append("CC_mail_Id", "shylabhatt@gmail.com");
       formData.append("CC_mail_Id", _dat["cc_email"] + ";" + _dat["approver_admin_mail"]);
      // formData.append("Subject", _dat["mail_subject"] + "," + _dat["action_name"]);
      formData.append("Subject", _dat["mail_subject"] + "," + _dat["action_name"]);
      formData.append("Message", _dat["contu_message"]);

      fetch(updateConsortiaDDRForLibrarianEmail, {
        method: "POST",
        body: formData,
      })
        .then((response) => response.json())
        .then((result) => {
          if (result.data[0][0] === "Success") {
            setIsDeliveryNote(true);
          } else {
            alert("Something went wrong")
            setIsDeliveryNote(false);
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }

  };

  const handleCancel = () => {
    modalClose();
  };
  const handleSubmit = () => {
    handleApi();

  };

  const handleClear = () => {
    let text_message = _.cloneDeep(data);
    text_message["contu_message"] = "";
    setData(text_message);
  }

  const handleSubmitButtonDisabled = () => {
    return error
  }

  const modalData = (
    <DeliveryNoteDDRNotAvailableTemplate
      emailData={data && data}
      modalClose={modalClose}
      data={data}
      isChangedSubject={isChangedSubject}
    />
  );
  return (
    <div>
      < DDRDiscardStatusModal
        show={isDeliveryNote}
        modalHandle={setIsDeliveryNote}
        data={modalData}
        size="lg"
        heading="Your mail has been sent successfully"

      />
      {!!error && <div className="error text-danger">{error}</div>}
      <div>
        <InputField
          placeholder={"To"}
          name={"to_mail"}
          value={data["to_mail"] || ""}
          handleInputchange={handleInputchange}
          classname={`w-100-p to_mail`}
          parentclassname="w-100-p"
          readonly={true}
        />
      </div>
      <div>
        <InputField
          placeholder={"CC"}
          name={"cc_email"}
          value={data["cc_email"] + "," + data["approver_admin_mail"] || ""}
          handleInputchange={handleInputchange}
          classname={`w-100-p cc_email`}
          parentclassname="w-100-p"
          readonly={true}
        />
      </div>
      <div>
        <InputField
          placeholder={"Subject"}
          name={"mail_subject"}
          defaultValue={data["mail_subject"] + ", " + data["action_name"] || ""}
          handleInputchange={handleInputchange}
          classname={`w-100-p mail_subject`}
          parentclassname="w-100-p"
        />
      </div>
      <div>
        <span className="input_label_clr">Message</span><InputArea
          name={"contu_message"}
          value={data["contu_message"] || ""}
          handleInputchange={handleInputchange}
          classname={`w-100-p delivery_date messageContainer`}
          parentclassname="w-100-p"
        />
      </div>

      <div className="btn_group ddr_statu_mail_btns">
        <div>
          <button
            type="submit"
            className="btn btn-primary "
            onClick={handleSubmit}
            disabled={handleSubmitButtonDisabled()}
          >
            Submit
          </button>
        </div>
        <div>
          <button
            type="submit"
            className="btn btn-secondary ddr_statu_btns"
            onClick={handleClear}
          >
            Clear
          </button>
        </div>
        <div>
          <button
            type="submit"
            className="btn btn-secondary ddr_statu_btns"
            onClick={() => handleCancel()}
          >
            Cancel
          </button>
        </div>

      </div>
    </div>
  );
};

export default DDRNotAvailiableTemplate;
