import React, { useEffect, useState } from "react";
import ToggleSidebar from "../../components/Sidebar/ToggleSidebar";
import {
  DDRMenuHeading,
  DDRMgmtMenu,
} from "../../components/Sidebar/constants";
import DDRSend from "../../components/DDRComponents/DDRSend";
import DDRRecived from "../../components/DDRComponents/DDRRecived";
import "./DDR.css";
import { baseUrl } from "../../utilities/constants";

/**
 * @author
 * @function LibraryAdminDDR
 **/

const LibraryAdminDDR = ({ customerID, consortiamaster_id, admin_type }) => {
  const [active, setActive] = useState("");
  const [priority, setPriority] = useState([]);

  useEffect(() => {
    fetch(
      `${baseUrl}/api/get_dat_ddr_priority/get_dat_ddr_priority`
    )
      .then((response) => response.json())
      .then((val) => setPriority(val));
  }, []);
  const handleActiveCallback = (active) => {
    setActive(active);
  };

  const renderSwitch = (active) => {
    switch (active) {
      case "DDRSend":
        return (
          <DDRSend
            customerID={customerID}
            consortiamaster_id={consortiamaster_id}
            admin_type={admin_type}
            priority={priority}
          />
        );
      default:
        return (
          <DDRRecived
            customerID={customerID}
            consortiamaster_id={consortiamaster_id}
            admin_type={admin_type}
            priority={priority}
          />
        );
    }
  };

  return (
    <div className="DDR WithToggleSidebar ddr_mrgn_from_top">
      <ToggleSidebar
        customerID={customerID}
        consortiamaster_id={consortiamaster_id}
        admin_type={admin_type}
        menu={DDRMgmtMenu}
        heading={DDRMenuHeading}
        setSelectedMenu={handleActiveCallback}
      />

      <div className="DDRContainer ContainerWithToggleSidebar">
        {renderSwitch(active)}
      </div>
    </div>
  );
};

export default LibraryAdminDDR;
