import React, { Fragment, useState } from "react";
import CommonHeading from "../../atoms/Heading/CommonHeading";
import {
  reportTypeDropdown,
  getServiceUsageReportForAdmin,
  getResourceUsageReportForAdmin,
  getPublisherUsageReportForAdmin,
  downloadServiceUsageReportForAdminUrl,
  downloadResourceUsageReportForAdminUrl,
  downloadPublisherUsageReportForAdminUrl,
  formatedObject,
  deviceTypeDropdown,
  getAppDownloadCount,
  getTotalAppDownloadCount,
} from "../../containers/Reports/constants";
import SelectDropDown from "../../atoms/DropDown/SelectDropDown";
import { Button } from "react-bootstrap";
import Radio from "../../atoms/Radio/Radio";
import InputField from "../../atoms/InputField/InputField";
import _ from "lodash";
import { fetchUrl } from "../../utilities/apiFunctions/fatchUrl";
import ServiceUsageReport from "./ServiceUsage";
import JournalUsageReport from "./JournalsUsage";
import PublisherUsageReport from "./PublisherUsage";
import ConferenceUsageReport from "./ConferenceUsage";
import { useEffect } from "react";
import Loading from "../Loading/Loading";
import BasicSelectDropDown from "../../atoms/DropDown/BasicSelectDropDown";
import { useFetch } from "../../utilities/apiFunctions/useFetch";
import { getConsortiaMemberList } from "../../containers/DDR/constants";

const Usage = ({ customerID, consortiamaster_id, admin_type }) => {
  let currentyear = new Date().getFullYear();
  let currentDate = new Date();
  let currentMonth = (currentDate.getMonth() + 1).toString().padStart(2, "0");
  let fromDateFormate = `${currentyear}-${currentMonth}-01`;

  const [data, setData] = useState({
    report_from_date: fromDateFormate.toString(),
    report_to_date: formatDate(),
    report_type: "0",
    resource_type: "0",
    device_type: "0",
  });

  const [isTableLoad, setIsTableLoad] = useState(false);
  const [usageSumarryDataTable, setUsageSumarryDataTable] = useState([]);
  const [usageDetailsDataTable, setUsageDetailsDataTable] = useState([]);
  const [isUsageData, setIsUsageData] = useState(false);
  const [journalDetailsTable, setJournalDetailsTable] = useState([]);
  const [isJournalData, setIsJournalData] = useState(false);
  const [publisherDetailsTable, setPublisherDetailsTable] = useState([]);
  const [isPublisherData, setIsPublisherData] = useState(false);
  const [conferenceDetailsTable] = useState([]);
  const [isConferenceData, setIsConferenceData] = useState(false);
  const [instituteMemberId, setInstituteMemberId] = useState("");
  const [isDetailedUsage, setIsDetailedUsage] = useState(false);
  const [totalMobileAppDownloadedCount, setTotalMobileAppDownloadedCount] =
    useState([]);

  const consortiaMemberList = useFetch(
    getConsortiaMemberList(consortiamaster_id),
    {}
  );

  const handleInputchange = (value, name) => {
    const dynamic = _.cloneDeep(data);
    dynamic[name] = value;
    setData(dynamic);
  };

  function formatDate() {
    var d = new Date(),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }

  useEffect(() => {
    handleServiceUsageReport();
  }, []);

  const handleServiceUsageReport = async () => {
    setIsPublisherData(false);
    setIsConferenceData(false);
    setIsJournalData(false);
    setIsUsageData(true);
    setIsTableLoad(true);

    const _dat = _.cloneDeep(data);
    _dat["consortia_id"] = consortiamaster_id || "0";
    // _dat["CustomerId"] = customerID;_dat["CustomerId"] = customerID;

    if (admin_type === "consortia_admin") {
      let consortiaMeberId;
      if (instituteMemberId === "") {
        consortiaMeberId = "0";
      } else {
        consortiaMeberId = instituteMemberId;
      }

      _dat["CustomerId"] = consortiaMeberId;
    } else {
      _dat["CustomerId"] = customerID;
    }

    if (_dat["device_type"] === "") {
      _dat["device_type"] = "0";
    }
    if (_dat["device_type"] === "2") {
      setIsDetailedUsage(true);
    } else {
      setIsDetailedUsage(false);
    }

    let str = Object.entries(_dat)
      .map(([key, val]) => `${key}${val ? "=" : ""}${val}`)
      .join("&");

    const usage_report = getServiceUsageReportForAdmin(str);
    const usage_data = await fetchUrl(usage_report);

    setUsageSumarryDataTable(
      usage_data &&
        usage_data.data &&
        usage_data.data[0][0] &&
        usage_data.data[0][0].usage_sumarry
    );

    setUsageDetailsDataTable(
      usage_data &&
        usage_data.data &&
        usage_data.data[0][0] &&
        usage_data.data[0][0].usage_details
    );

    if (_dat["device_type"] == 2 && _dat["report_type"] === "0") {
      handleAppDownloadCount();
      handleTotalMobileAppDownloadCount();
    }

    setIsTableLoad(false);
  };

  const handleTotalMobileAppDownloadCount = async () => {
    const loggedin_customer_id =
      admin_type === "consortia_admin" ? "0" : customerID;

    const data = {
      customer_id: loggedin_customer_id,
      consortia_id: consortiamaster_id || "0",
    };

    let str = Object.entries(data)
      .map(([key, val]) => `${key}${val ? "=" : ""}${val}`)
      .join("&");

    const usage_report = getTotalAppDownloadCount(str);
    const TotalMobileAppata = await fetchUrl(usage_report);
    setTotalMobileAppDownloadedCount(TotalMobileAppata?.data[0][0]);
  };

  const handleAppDownloadCount = async () => {
    setIsPublisherData(false);
    setIsConferenceData(false);
    setIsJournalData(false);
    setIsUsageData(true);
    setIsTableLoad(true);

    const _dat = _.cloneDeep(data);
    _dat["consortia_id"] = consortiamaster_id || "0";
    // _dat["CustomerId"] = customerID;_dat["CustomerId"] = customerID;

    if (admin_type === "consortia_admin") {
      let consortiaMeberId;
      if (instituteMemberId === "") {
        consortiaMeberId = "0";
      } else {
        consortiaMeberId = instituteMemberId;
      }

      _dat["customer_id"] = consortiaMeberId;
    } else {
      _dat["customer_id"] = customerID;
    }

    if (_dat["device_type"] === "") {
      _dat["device_type"] = "0";
    }

    let str = Object.entries(_dat)
      .map(([key, val]) => `${key}${val ? "=" : ""}${val}`)
      .join("&");

    const usage_report = getAppDownloadCount(str);
    const app_data = await fetchUrl(usage_report);

    setUsageSumarryDataTable((prevData) => [...prevData, app_data?.data[0][0]]);
    setIsTableLoad(false);
  };

  const handleJournalUsageReport = async () => {
    setIsTableLoad(true);
    const _dat = _.cloneDeep(data);
    const reportType = data["resource_type"];
    _dat["consortia_id"] = consortiamaster_id || "0";
    // _dat["customer_id"] = customerID;

    if (admin_type === "consortia_admin") {
      let consortiaMeberId;
      if (instituteMemberId === "") {
        consortiaMeberId = "0";
      } else {
        consortiaMeberId = instituteMemberId;
      }
      _dat["customer_id"] = consortiaMeberId;
    } else {
      _dat["customer_id"] = customerID;
    }
    delete _dat["report_type"];

    let str = Object.entries(_dat)
      .map(([key, val]) => `${key}${val ? "=" : ""}${val}`)
      .join("&");

    if (reportType === "1") {
      setIsJournalData(true);
      setIsPublisherData(false);
      setIsConferenceData(false);
      setIsUsageData(false);
      const Journal_report = getResourceUsageReportForAdmin(str);
      const Journal_data = await fetchUrl(Journal_report);
      setJournalDetailsTable(
        Journal_data && Journal_data.data && Journal_data.data[0][0]
      );
    }

    if (reportType === "2") {
      setIsJournalData(false);
      setIsPublisherData(true);
      setIsConferenceData(false);
      setIsUsageData(false);
      const publisher_report = getPublisherUsageReportForAdmin(str);
      const publisher_data = await fetchUrl(publisher_report);
      setPublisherDetailsTable(
        publisher_data &&
          publisher_data.data &&
          publisher_data.data[0] &&
          publisher_data.data[0][0]
      );
    }
    setIsTableLoad(false);
  };

  const handleServiceUsageReportDownload = () => {
    const _dat = _.cloneDeep(data);
    _dat["consortia_id"] = consortiamaster_id || "0";
    // _dat["CustomerId"] = customerID;
    if (admin_type === "consortia_admin") {
      let consortiaMeberId;
      if (instituteMemberId === "") {
        consortiaMeberId = "0";
      } else {
        consortiaMeberId = instituteMemberId;
      }

      _dat["CustomerId"] = consortiaMeberId;
    } else {
      _dat["CustomerId"] = customerID;
    }

    let str = Object.entries(_dat)
      .map(([key, val]) => `${key}${val ? "=" : ""}${val}`)
      .join("&");
    window.open(downloadServiceUsageReportForAdminUrl(str));
  };

  const handleResourceUsageReportDownload = () => {
    const _dat = _.cloneDeep(data);
    _dat["consortia_id"] = consortiamaster_id || "0";
    // _dat["customer_id"] = customerID;

    if (admin_type === "consortia_admin") {
      let consortiaMeberId;
      if (instituteMemberId === "") {
        consortiaMeberId = "0";
      } else {
        consortiaMeberId = instituteMemberId;
      }
      _dat["customer_id"] = consortiaMeberId;
    } else {
      _dat["customer_id"] = customerID;
    }
    delete _dat["report_type"];

    let str = Object.entries(_dat)
      .map(([key, val]) => `${key}${val ? "=" : ""}${val}`)
      .join("&");

    window.open(downloadResourceUsageReportForAdminUrl(str));
  };

  const handlePublisherUsageReportDownload = () => {
    const _dat = _.cloneDeep(data);
    _dat["consortia_id"] = consortiamaster_id || "0";
    // _dat["customer_id"] = customerID;

    if (admin_type === "consortia_admin") {
      let consortiaMeberId;
      if (instituteMemberId === "") {
        consortiaMeberId = "0";
      } else {
        consortiaMeberId = instituteMemberId;
      }
      _dat["customer_id"] = consortiaMeberId;
    } else {
      _dat["customer_id"] = customerID;
    }
    delete _dat["report_type"];

    let str = Object.entries(_dat)
      .map(([key, val]) => `${key}${val ? "=" : ""}${val}`)
      .join("&");

    window.open(downloadPublisherUsageReportForAdminUrl(str));
  };

  const handleInputchangeInstitute = (value) => {
    setInstituteMemberId(value);
  };

  return (
    <div>
      <CommonHeading heading="Usage Reports " />
      {isDetailedUsage && (
        <div style={{ textAlign: "right", marginRight: "150px", color: "red" }}>
          <strong>
            Total Mobile App Downloaded Count:{" "}
            {`${Object.values(totalMobileAppDownloadedCount)}`}{" "}
          </strong>
        </div>
      )}
      <div className="ReportsRow">
        {admin_type === "consortia_admin" && (
          <div className="common-select-space w-300 inline">
            <BasicSelectDropDown
              defaultSelectValue={data["consortia_member_id"]}
              data={formatedObject(consortiaMemberList?.response?.data)}
              name="consortia_member_id"
              label="Institution"
              handleInputchange={handleInputchangeInstitute}
            />
          </div>
        )}
        <InputField
          placeholder={"From date"}
          name={"report_from_date"}
          type="date"
          value={data["report_from_date"] || ""}
          handleInputchange={handleInputchange}
          classname={`w-260 report_from_date`}
          max={formatDate()}
        />

        <InputField
          placeholder={"To date"}
          name={"report_to_date"}
          type="date"
          value={data["report_to_date"] || ""}
          handleInputchange={handleInputchange}
          classname={`w-260 report_to_date`}
          max={formatDate()}
        />
      </div>

      <div className="ReportsTypeRow">
        <div>
          <SelectDropDown
            defaultSelectValue={data["resource_type"]}
            data={reportTypeDropdown}
            name="resource_type"
            label="Select Report Type"
            handleInputchange={handleInputchange}
          />
        </div>
        <div>
          {/* <SelectDropDown
            defaultSelectValue={data["device_type"]}
            data={deviceTypeDropdown}
            name="device_type"
            label="Select Device Type"
            handleInputchange={handleInputchange}
          /> */}
          {data["resource_type"] === "0" && (
            <BasicSelectDropDown
              defaultSelectValue={data["device_type"]}
              // data={formatedObject(consortiaMemberList?.response?.data)}
              data={deviceTypeDropdown}
              name="device_type"
              label="Select Device Type"
              handleInputchange={handleInputchange}
            />
          )}
        </div>
        {data["resource_type"] === "0" && (
          <Fragment>
            <div>
              <Radio
                label="Summary­­­"
                value={"0"}
                name="report_type"
                checkedValue={data["report_type"]}
                handleInputchange={handleInputchange}
              />
              <Radio
                label="Detailed"
                value={"1"}
                name="report_type"
                checkedValue={data["report_type"]}
                handleInputchange={handleInputchange}
              />
            </div>
            <div>
              <Button
                onClick={handleServiceUsageReport}
                className="contactPersondata"
              >
                View Report
              </Button>
              <Button
                onClick={handleServiceUsageReportDownload}
                className="contactPersondata"
              >
                {" "}
                Export Report to Excel
              </Button>
            </div>
          </Fragment>
        )}

        {data["resource_type"] === "1" && (
          <Fragment>
            <Button
              onClick={handleJournalUsageReport}
              className="contactPersondata"
            >
              View Report
            </Button>
            <Button
              onClick={handleResourceUsageReportDownload}
              className="contactPersondata"
            >
              {" "}
              Export Report to Excel
            </Button>
          </Fragment>
        )}

        {data["resource_type"] === "2" && (
          <Fragment>
            <Button
              onClick={handleJournalUsageReport}
              className="contactPersondata"
            >
              View Report
            </Button>
            <Button
              onClick={handlePublisherUsageReportDownload}
              className="contactPersondata"
            >
              {" "}
              Export Report to Excel
            </Button>
          </Fragment>
        )}
      </div>
      {isTableLoad && <Loading />}
      <div className="CountuReportRow">
        {isJournalData && isJournalData && (
          <JournalUsageReport journalDetailsTable={journalDetailsTable} />
        )}
        {isPublisherData && isPublisherData && (
          <PublisherUsageReport publisherDetailsTable={publisherDetailsTable} />
        )}
        {isConferenceData && isConferenceData && (
          <ConferenceUsageReport
            conferenceDetailsTable={conferenceDetailsTable}
          />
        )}
        {isUsageData && isUsageData && (
          <ServiceUsageReport
            usageSumarryDataTable={usageSumarryDataTable}
            usageDetailsDataTable={usageDetailsDataTable}
            isDetailedUsage={isDetailedUsage}
          />
        )}
      </div>
    </div>
  );
};

export default Usage;
