import React, { useState } from "react";
import CountuReport from "../../components/ReportsComponents/CountuReport";
import Usage from "../../components/ReportsComponents/Usage";
import DDRReport from "../../components/ReportsComponents/DDRReport";
import {
  ConsortiaDefinationReportsMenu,
  ReportsMenu,
  ReportsMenuHeading,
} from "../../components/Sidebar/constants";
import ToggleSidebar from "../../components/Sidebar/ToggleSidebar";
import "./Reports.css";
import { getLocalStorage } from "../../utilities/storage";
import ConsortiaDDRReport from "../../components/ReportsComponents/ConsortiaDDRReport";

const ConsoriaAdminReports = ({
  customerID,
  consortiamaster_id,
  admin_type,
}) => {
  const [active, setActive] = useState("");
  // const { customer } = getLocalStorage("customer");


  const handleActiveCallback = (active) => {
    setActive(active);
  };

  const renderSwitch = (active) => {
    switch (active) {
      case "Usage":
        return (
          <Usage
            customerID={customerID}
            consortiamaster_id={consortiamaster_id}
            admin_type={admin_type}
          />
        );
      case "DDRReport":
        return (
          <ConsortiaDDRReport
            customerID={customerID}
            consortiamaster_id={consortiamaster_id}
            admin_type={admin_type}
            from="consortia"
          />
        );
      case "ConterReport":
        return (
          <CountuReport
            customerID={customerID}
            consortiamaster_id={consortiamaster_id}
            admin_type={admin_type}
          />
        );

      default:
        return (
          <Usage
            customerID={customerID}
            consortiamaster_id={consortiamaster_id}
            admin_type={admin_type}
          />
        );
    }
  };

  return (
    <div className="Reports WithToggleSidebar reprts_mrgn_frm_top">
      {customerID && customerID === consortiamaster_id && consortiamaster_id ? (
        <div>
          <ToggleSidebar
            menu={ConsortiaDefinationReportsMenu}
            heading={ReportsMenuHeading}
            setSelectedMenu={handleActiveCallback}
          />
        </div>
      ) : (
        <div>
          <ToggleSidebar
            menu={ReportsMenu}
            heading={ReportsMenuHeading}
            setSelectedMenu={handleActiveCallback}
          />
        </div>)}

      <div className="ReportsContainer ContainerWithToggleSidebar">
        {renderSwitch(active)}
      </div>
    </div>
  );
};

export default ConsoriaAdminReports;
