import React, { useEffect, useState } from "react";
import DetailSection from "./DetailSection";
import { Pagination } from "../ReportsComponents/Pagination";
/**
 * @author
 * @function UsageMatrics
 **/

const ServiceUsageReport = ({
  usageSumarryDataTable,
  usageDetailsDataTable,
  isDetailedUsage
}) => {
  usageSumarryDataTable &&
    usageSumarryDataTable.forEach((item, ind) => {
      item.id = ind + 1;
    });

  usageDetailsDataTable &&
    usageDetailsDataTable.forEach((item, ind) => {
      item.id = ind + 1;
    });

  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(15);
  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords =
    usageSumarryDataTable &&
    usageSumarryDataTable.slice(indexOfFirstRecord, indexOfLastRecord);
  const nPages = Math.ceil(
    usageSumarryDataTable && usageSumarryDataTable.length / recordsPerPage
  );

  const [currentPage1, setCurrentPage1] = useState(1);
  const [recordsPerPage1, setRecordsPerPage1] = useState(15);
  const indexOfLastRecord1 = currentPage1 * recordsPerPage1;
  const indexOfFirstRecord1 = indexOfLastRecord1 - recordsPerPage1;
  const currentRecordsDetails =
    usageDetailsDataTable &&
    usageDetailsDataTable.slice(indexOfFirstRecord1, indexOfLastRecord1);
  const nPagesDetails = Math.ceil(
    usageDetailsDataTable && usageDetailsDataTable.length / recordsPerPage1
  );
  const [finalUserSummaryDetails, setFinalUserSummaryDetails] = useState([]);


  const filterActionUsage = (actionUsage) => {
    const unwantedSearchActions = [
      "Search Builder Results page",
      "Search Builder -  Document type",
      "Search Builder Home page"
    ];
  
    // Ensure actionUsage is an array before processing
    if (!Array.isArray(actionUsage)) {
      return [];
    }
  
    return actionUsage.filter(action => {
      // action should be an object with a single key-value pair
      const key = Object.keys(action)[0];
      return !unwantedSearchActions.includes(key);
    });
  };
  
  const filteredData = Array.isArray(currentRecordsDetails) 
    ? currentRecordsDetails
        .filter(data => !data.hasOwnProperty("Search History")) // Exclude "Search History"
        .map(data => {
          if (data.hasOwnProperty("Search") && Array.isArray(data.action_usage)) {
            // Process action_usage only if it's an array
            const filteredActionUsage = filterActionUsage(data.action_usage);
            return { ...data, action_usage: filteredActionUsage };
          }
          return data;
        })
    : []; // If currentRecordsDetails is not an array, return an empty array
  
 
useEffect(()=>{
  if(!isDetailedUsage){
    setFinalUserSummaryDetails(usageDetailsDataTable &&
    usageDetailsDataTable.slice(indexOfFirstRecord1, indexOfLastRecord1));
  }else{
    setFinalUserSummaryDetails(filteredData &&
    filteredData.slice(indexOfFirstRecord1, indexOfLastRecord1));
  }
},[isDetailedUsage, usageDetailsDataTable, filteredData])
  

  return (
    <>
      {usageSumarryDataTable && usageSumarryDataTable && (
        <div>
          <table className="table table-bordered table-striped">
            <thead className="summayTable">
              <tr>
                <th>SI.No</th>
                <th>Usage Metrics</th>
                <th>Total Count</th>
              </tr>
            </thead>
            <tbody>
              {!!currentRecords &&
                currentRecords.map((data, index) => (
                  <tr key={index} className="UsageMetricsTr contactPersondata">
                    <td className="text-center"> {data.id} </td>
                    <td className={"metrics" + index}>
                      {Object.keys(data)[0]}
                    </td>
                    <td className="text-center">{Object.values(data)[0] === null ? "0" : Object.values(data)[0]}</td>
                  </tr>
                ))}
            </tbody>
          </table>
          <div>
            <Pagination
              nPages={nPages && nPages}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              setRecordsPerPage={setRecordsPerPage}
              tableDataReport={usageSumarryDataTable && usageSumarryDataTable}
              recordsPerPage={recordsPerPage}
            />
          </div>
        </div>
      )}
      {!usageSumarryDataTable &&
        usageDetailsDataTable &&
        usageDetailsDataTable && (
          <div>
            <table className="table table-bordered table-striped">
              <thead className="summayTable">
                <tr>
                  <th>SI.No</th>
                  <th>Usage Metrics</th>
                  <th>Service</th>
                  <th>Total Count</th>
                </tr>
              </thead>
              <tbody>
                {!!finalUserSummaryDetails &&
                  finalUserSummaryDetails.map((data, index) => (
                    <tr
                      key={index}
                      className="UsageMetricsTr contactPersondata"
                    >
                      <td className="text-center"> {index + 1} </td>
                      <td className={"metrics" + index}>
                        {Object.keys(data)[0]}
                      </td>
                      <td>
                        {" "}
                        <DetailSection data={data} index={index} />
                      </td>
                      <td className="text-center">{Object.values(data)[0]}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
            <div>
              <Pagination
                nPages={nPagesDetails && nPagesDetails}
                currentPage={currentPage1}
                setCurrentPage={setCurrentPage1}
                setRecordsPerPage={setRecordsPerPage1}
                tableDataReport={usageDetailsDataTable && usageDetailsDataTable}
                recordsPerPage={recordsPerPage1}
              />
            </div>
          </div>
        )}
    </>
  );
};

export default ServiceUsageReport;
