import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { fetchUrl } from "../../utilities/apiFunctions/fatchUrl";
import { Reset_ForgotPasswordUrl } from "../../utilities/constants";
import Error, { ErrorMsg } from "../../atoms/Error/Error";
import { ForgotPassworSucsessDialog } from "../../atoms/Modals/ForgotPassworSucsessDialog";
import { ForgotPAsswordErrorInfoDialog } from "../../atoms/Modals/ForgotPassInfoDialog";

export default function ResetPassword() {
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState(false);
  const [isConfirmPassError, setIsConfirmPassError] = useState(false);
  const [userId, setUserId] = useState("");
  const [token, setTocken] = useState("");
  const [isSave, setIsSave] = useState(false);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    const urlElements = window.location.href.split("/");
    setUserId(urlElements[4]);
    setTocken(urlElements[5]);
  }, []);

  function handleSubmit(event) {
    event.preventDefault();
    handleLogin();
  }

  const handleLogin = async () => {
    const formData = new FormData();
    formData.append("profileuser_id", userId);
    formData.append("forgot_password_token", token);
    formData.append("new_password", newPassword);
    formData.append("re_enter_password", confirmPassword);
    const options = { method: "POST", body: formData };

    await fetchUrl(Reset_ForgotPasswordUrl, options);

  };

  const handleEnterNewpassword = (e) => {
    const validPassword = e.target.value;

    if (validPassword.includes(" ")) {
      setError("Space is not allowed");
    } else if (validPassword.includes(".")) {
      setError("Dot is not allowed");
    } else if (validPassword.length > 12 || validPassword.length < 6) {
      setError("Must be 6-12 characters long");
    } else {
      setError("");
    }
    if (validPassword === "") {
      setError("");
    }
    setNewPassword(validPassword);
  };

  const handleReEnterNewpassword = (e) => {
    setConfirmPassword(e.target.value);
  };

  useEffect(() => {
    if (confirmPassword !== newPassword) {
      setIsConfirmPassError(
        "New Password and Re-enter New Password is not matching"
      );
    } else {
      setIsConfirmPassError("");
    }
    if (confirmPassword === "") {
      setIsConfirmPassError("");
    }
  }, [confirmPassword, newPassword]);

  const handleClearButton = () => {
    setNewPassword("");
    setConfirmPassword("");
    setError("");
    setIsConfirmPassError("");
  };

  const handleValidation = () => {
    return newPassword.length <= 0 && confirmPassword.length <= 0;
  };

  const handleSumbitButtonValidation = () => {
    return (
      newPassword !== confirmPassword ||
      (newPassword.length <= 0 && confirmPassword.length <= 0) ||
      error !== "" ||
      isConfirmPassError !== ""
    );
  };

  return (
    <React.Fragment>
      <div className="Login logCard">
        <div className="LoginFormWrapper">
          <ForgotPassworSucsessDialog
            show={isSave}
            modalClose={setIsSave}
            content={"Reset password request sent to registered user Email id"}
            successCallback={handleSubmit}
            heading="Info"
          />
          <ForgotPAsswordErrorInfoDialog
            show={isError}
            modalClose={setIsError}
            content={"Something went wrong"}
            successCallback={handleSubmit}
            heading="Info"
          />
          <div className="card ">
            <div className="card-body logCardwdth">
              <h5 className="card-title">Reset password</h5>
              <Form onSubmit={handleSubmit}>
                <Form.Group size="lg" controlId="password">
                  <Form.Label>New Password</Form.Label>
                  <Form.Control
                    type="password"
                    value={newPassword}
                    onChange={handleEnterNewpassword}
                  />
                  <div className="forgotpasserror">
                    {error && <Error text={error} />}
                  </div>
                </Form.Group>
                <Form.Group size="lg" controlId="password">
                  <Form.Label>Confirm Password</Form.Label>
                  <Form.Control
                    type="password"
                    value={confirmPassword}
                    onChange={handleReEnterNewpassword}
                  />
                  <div className="forgotpasserror">
                    {isConfirmPassError && (
                      <ErrorMsg text={isConfirmPassError} />
                    )}
                  </div>
                </Form.Group>

                <div className="mt-3">
                  <Button
                    type="submit"
                    className="forgotpasswrdSubbtn"
                    onClick={handleSubmit}
                    disabled={handleSumbitButtonValidation()}
                  >
                    Submit
                  </Button>

                  <button
                    type="button"
                    className="btn btn-secondary forgotpasswrdClrbtn"
                    onClick={handleClearButton}
                    disabled={handleValidation()}
                  >
                    Clear
                  </button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
