import React from "react";
import CommonHeading from "../../atoms/Heading/CommonHeading";
import SelectDropDown from "../../atoms/DropDown/SelectDropDown";
import DDRWidgetContainer from "./DDRWidgetContainer";


const RequestWidget = ({
  commonheading,
  yearData,
  heading,
  classname,
  data,
  name,
  yearChanged,
  year,
}) => {
  const _commonheading = commonheading || "";
  const _heading = heading || "";
  const _data = data[0][0];

  const handleInputchange = (value, name) => {
    yearChanged(value, name);
  };

  return (
    <>
      <div className={classname}>
        {!!_commonheading && <CommonHeading heading={_commonheading} />}
        <SelectDropDown
          defaultSelectValue={year}
          data={yearData}
          name={`${name}_year`}
          handleInputchange={handleInputchange}
        />
      </div>
      {!!_heading && <DDRWidgetContainer heading={_heading} data={_data} />}
    </>
  );
};

export default RequestWidget;
