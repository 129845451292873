import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import InputField from "../../atoms/InputField/InputField";
import _ from "lodash";
import { NewModal } from "../Modals/Modal";
import {
  removeLibrarySubscribedPackageUrl,
  downloadLibrarySubscribedPackagesListUrl,
  get_library_subscribed_packages_list,
  downloadPackageDetailsByiId,
} from "../../utilities/constants";
import VCLRPackageModal from "./VCLRPackageModal";
import { ConfirmDialog } from "../../atoms/Modals/ConfirmDialog";
import { VCLRPackageTableProperty } from "../../containers/ResourceMgmt/constants";
import { fetchUrl } from "../../utilities/apiFunctions/fatchUrl";
import PackageTypeDropDown from "../../atoms/DropDown/PackageTypeDropDown";
import ResourcePackagesTable from "./ResourcePackagesTable";
import { Pagination } from "../ReportsComponents/Pagination";
import Loading from "../Loading/Loading";
import {
  getClientWiseSubscribedAggregatorListUrl,
  getClientWiseSubscribedPackageListUrl,
} from "../../utilities/constants";
import BasicSelectDropDown from "../../atoms/DropDown/BasicSelectDropDown";


const VCLRPackage = ({ customerID, aggregatorPackagesList }) => {
  const [data, setData] = useState({
    package_name: "",
    PrintOnlinePackages: "2",
    publisher_package: "0",
    aggregator_package: "0",
    package_id: "0",
  });

  const [loading, setLoading] = useState(false);
  const [viewOn, setViewOn] = useState(false);
  const [isAlertOn, setIsAlertOn] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [deleteUrl, setDeleteUrl] = useState("");
  const [isSearched, setIsSearched] = useState(false);
  const [aggregatorSubscribedPackages, setAggregatorSubscribedPackages] = useState("");

  const [aggregatorTypeData, setAggregatorTypeData] = useState({ aggregator_type: "0" });
  const [packageTypeData, setPackageTypeData] = useState({ aggregator_id: "0" });

  tableData && tableData.forEach((item, ind) => { item.id = ind + 1 });

  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(15);
  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = tableData && tableData.slice(indexOfFirstRecord, indexOfLastRecord);
  const nPages = Math.ceil(tableData && tableData.length / recordsPerPage);
  const [isPackageAssigned, setIsPackageAssigned] = useState(false);

  useEffect(() => {
    handleClineWiseSubscribedPackagesLoadButton();

  }, []);

  useEffect(() => {
    if (isPackageAssigned === true) {
      handleClineWiseSubscribedPackagesLoadButton();
    }

  }, [isPackageAssigned]);

  useEffect(() => {
    handleClineWiseSubscribedPackages();

  }, [aggregatorTypeData]);

  const handleInputchange = (value, name) => {
    const dynamic = _.cloneDeep(data);
    dynamic[name] = value;
    setData(dynamic);
  };

  const handleAggregatorType = (value, name) => {
    const dynamic = _.cloneDeep(data);
    dynamic[name] = value;
    setAggregatorTypeData(() => ({
      [name]: value,
    }));
  };

  const handlePackageTypeDropdown = (value, name) => {
    const dynamic = _.cloneDeep(data);
    dynamic[name] = value;
    setPackageTypeData(() => ({
      [name]: value,
    }));
  };

  const handleAddPackageSubscription = () => {
    setViewOn(true);
  };

  const handleAction = (item) => {
    const url = "CustomerId=" + customerID + "&package_id=" + item.package_id;
    setDeleteUrl(url);
    setIsAlertOn(true);
  };

  const handleLoadClick = async () => {
    setIsSearched(true);
    setLoading(true);
    setCurrentPage(1);
    const _data = {
      customer_id: customerID,
      aggregator_id: data.aggregator_package,
      package_type: data.publisher_package,
      ...data,
    };
    delete _data.PrintOnlinePackages;
    let str = Object.entries(_data)
      .map(([key, val]) => `${key}${val ? "=" : ""}${val}`)
      .join("&");

    const response = await fetchUrl(get_library_subscribed_packages_list(str));
    setTableData(response.data[0][0]);
    setLoading(false);
  };

  const handleClineWiseSubscribedPackages = async () => {
    const _data = {
      client_id: customerID,
      aggregator_type: aggregatorTypeData && aggregatorTypeData.aggregator_type,
    };
    let str = Object.entries(_data).map(([key, val]) => `${key}${val ? "=" : ""}${val}`).join("&");
    await fetch(getClientWiseSubscribedAggregatorListUrl(str)).then((res) => res.json()).then((response) =>
      setAggregatorSubscribedPackages(response && response.data[0][0])
    );
  };

  const handleClineWiseSubscribedPackagesLoadButton = async () => {
    setLoading(true);
    const _data = {
      client_id: customerID,
      aggregator_id: packageTypeData && packageTypeData.aggregator_id,
    };

    if (_data["aggregator_id"] === "") {
      _data["aggregator_id"] = "0"
    }

    let str = Object.entries(_data).map(([key, val]) => `${key}${val ? "=" : ""}${val}`).join("&");

    await fetch(getClientWiseSubscribedPackageListUrl(str)).then((res) => res.json()).then((response) => {
      setTableData(response && response.data[0][0])
      setLoading(false);
    });
    setCurrentPage(1);
  };

  const removeData = async () => {
    setLoading(true);
    await fetchUrl(removeLibrarySubscribedPackageUrl(deleteUrl));
    setDeleteUrl("");
    setLoading(false);
    setIsAlertOn(false);
    handleLoadClick();
  };
  const handleDownloadHoldings = () => {
    const _data = {
      customer_id: customerID,
      aggregator_id: data.aggregator_package,
      package_type: data.publisher_package,
      ...data,
    };

    delete _data.PrintOnlinePackages;
    delete _data.publisher_package;
    delete _data.aggregator_package;
    let str = Object.entries(_data).map(([key, val]) => `${key}${val ? "=" : ""}${val}`).join("&");
    window.open(downloadLibrarySubscribedPackagesListUrl(str));
  };

  const downloadPackageHoldings = (val) => {
    window.open(downloadPackageDetailsByiId(val.package_id));
  };

  const formatedSelect = (data) => {
    const _data =
      data && data.map((item) => {
        const a = {};
        a["value"] = Object.keys(item)[0];
        a["label"] = Object.values(item)[0];
        return a;
      })
    return _data;
  };

  const modalData = (
    <VCLRPackageModal
      aggregatorPackagesList={aggregatorPackagesList}
      customerID={customerID}
      setViewOn={setViewOn}
      setIsPackageAssigned={setIsPackageAssigned}
    />
  );

  const aggregatorType = [
    { 0: "All" },
    { 1: "Aggregator" },
    { 4: "Publisher" },
  ];

  return (
    <div>
      <NewModal
        show={viewOn}
        modalHandle={setViewOn}
        data={modalData}
        size="lg"
        heading="Add New Package Subscription Details"
        dialogClassName={"OrgViewTableModal"}
      />
      <ConfirmDialog
        show={isAlertOn}
        modalClose={setIsAlertOn}
        content={"Are you sure you want to remove this ?"}
        successCallback={removeData}
        heading="Confirm"
      />
      <div className="ViewConfigureLibraryResourcesRow">
        <Button
          variant="secondary contactPersondata"
          onClick={handleDownloadHoldings}
        > Download Holdings</Button>
        <Button
          onClick={handleAddPackageSubscription}
          className="contactPersondata"
        > Add New Package Subscription Details</Button>
      </div>
      <div className="ViewConfigureLibraryPackages">
        <InputField
          placeholder={"Search package Name"}
          name={"package_name"}
          value={data["package_name"] || ""}
          handleInputchange={handleInputchange}
          classname={`w-350 package_name `}
          onKeyPress={(event) => {
            if (event.key === "Enter") {
              handleLoadClick();
            }
          }}
        />
        <div onClick={handleLoadClick}>
          <i className="fa fa-search searchBtn"> </i>
        </div>
      </div>
      <div className="ViewConfigureLibraryPackages">
        <div className="common-select-space w-260">
          <PackageTypeDropDown
            data={formatedSelect(aggregatorType)}
            label="Aggregator / Publisher Package"
            name="aggregator_type"
            handleInputchange={handleAggregatorType}
          />
        </div>
        <div className="common-select-space w-260">
          <BasicSelectDropDown
            data={formatedSelect(aggregatorSubscribedPackages)}
            label="Package Name"
            name="aggregator_id"
            handleInputchange={handlePackageTypeDropdown}
          />
        </div>

        <div className="common-button contactPersondata">
          <Button onClick={handleClineWiseSubscribedPackagesLoadButton}>Load</Button>
        </div>
      </div>
      {loading && <Loading />}
      <div className="ViewConfigureLibraryResourcesRow">
        <ResourcePackagesTable
          tableProperty={VCLRPackageTableProperty}
          tableData={currentRecords && currentRecords}
          action="Delete"
          handleAction={handleAction}
          downloadPackageHoldings={downloadPackageHoldings}
          isSearched={isSearched}
        />
      </div>
      <div className="pgnation">
        <Pagination
          nPages={nPages && nPages}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          setRecordsPerPage={setRecordsPerPage}
          tableDataReport={tableData && tableData}
          recordsPerPage={recordsPerPage}
        />
      </div>
    </div>
  );
};

export default VCLRPackage;
