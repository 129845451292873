import React, { useState, useEffect, Fragment } from "react";
import InputField from "../../atoms/InputField/InputField";
import _ from "lodash";
import Button from "react-bootstrap/Button";
import { deleteByIndex } from "../../utilities/delete";
import { BiTrash, BiEdit } from "react-icons/bi";
import ContactPersonInputField from "../../atoms/InputField/ContactPersonInputFilelds";
import ContactPersonSelectDropDown from "../../atoms/DropDown/ContactPersonDropDown";

const OrgContactDetailsScreen = ({
  tableData,
  init,
  setData,
  accessTypeCustomerDetails,
  customerDetails,
  setError,
  error
}) => {
  const [orgTableData, setOrgTableData] = useState({ init });
  const [orgTableDatas, setOrgTableDatas] = useState([]);
  const [show, setShow] = useState(false);
  const [editIndex, setEditIndex] = useState();

  const custumerData =
    customerDetails && customerDetails.data[0][0].contactdetails;

  useEffect(() => {
    if (custumerData) {
      setOrgTableDatas(custumerData);
      setData(custumerData);
    }

  }, []);

  const _accessTypeSelectDetails = accessTypeCustomerDetails && accessTypeCustomerDetails
    ? accessTypeCustomerDetails.map((item) => {
      const obj = {};
      obj.label = Object.values(item)[0];
      obj.value = Object.keys(item)[0];
      return obj;
    })
    : [];

  const handleInputchange = (value, name) => {
    const dynamic = _.cloneDeep(orgTableData);
    if (name === "access_type ") {
      dynamic[name] = _accessTypeSelectDetails[value];
    } else {
      dynamic[name] = value;
    }
    // if (name === "email") {
    //   if (dynamic["email"] !== "undefined") {
    //     let regex =
    //       /^[a-z0-9]+(\.[_a-z0-9]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{3,15})$/;
    //     if (!regex.test(dynamic["email"])) {
    //       setError("Please enter valid Email Id");
    //     } else {
    //       setError("");
    //     }

    //     if (value === "") {
    //       setError("");
    //     }
    //   }
    // }
    setOrgTableData(dynamic);
  };

  const handleButtonClick = () => {
    const obj = _.cloneDeep(orgTableData);
    if (obj) {
      setError("");
      update();
    }
  };

  const update = () => {
    const array = _.cloneDeep(orgTableDatas);
    const obj = _.cloneDeep(orgTableData);
    array.push(obj);
    setOrgTableDatas(array);
    setData(array);
    setOrgTableData(init);
  };

  const handleContactDetailsDeleteButton = async (index) => {
    const cloned = _.cloneDeep(orgTableDatas);
    await deleteByIndex(cloned, index);
    setOrgTableDatas(cloned);
    setData(cloned);
  };

  const handleEditButton = async (index) => {
    setOrgTableData(orgTableDatas[index]);
    setShow(true);
    setEditIndex(index);
  };

  const handleUpdateButtonClick = () => {
    orgTableDatas.splice(editIndex, 1, orgTableData);
    setOrgTableDatas([...orgTableDatas]);
    setShow(false);
    setOrgTableData(init);
    setData([...orgTableDatas]);
  };

  const retriveBasedOnValue = (val) => {
    const a = _accessTypeSelectDetails.find((item) => item.value === val);
    if (a) {
      return a.label;
    } else {
      return "";
    }
  };

  const validateForm = () => {
    return (
      (orgTableData["firstname"] &&
        orgTableData["email"] &&
        orgTableData["access_type"]) && !error

    );
  };
  return (
    <>
      <div className="OrgTableInputFieldWrapper">
        {tableData.map((input, index) => (
          <Fragment key={index}>
            {input.key === "access_type" && (
              <div className="common-select-space">
                <ContactPersonSelectDropDown
                  key={index}
                  // defaultSelectValue={orgTableData[input.key] || ""}
                  data={_accessTypeSelectDetails}
                  name={input.key}
                  label={input.value}
                  value={orgTableData[input.key] || ""}
                  handleInputchange={handleInputchange}
                  classname={`w-350 ${input.key}`}
                />
              </div>
            )}

            {input.type === "text" && (
              <InputField
                key={index}
                placeholder={input.value}
                name={input.key}
                value={orgTableData[input.key] || ""}
                handleInputchange={handleInputchange}
                classname={`w-350 ${input.key}`}
              />
            )}
            {input.type === "validtext" && (
              <ContactPersonInputField
                key={index}
                placeholder={input.value}
                name={input.key}
                value={orgTableData[input.key] || ""}
                handleInputchange={handleInputchange}
                classname={`w-350 ${input.key}`}
              />
            )}
          </Fragment>
        ))}
        {!show ? (
          <Button
            size="lg"
            className="ipAddBtn"
            type="submit"
            onClick={handleButtonClick}
            disabled={!validateForm()}
          >
            Add
          </Button>
        ) : (
          <Button
            size="lg"
            className="ipAddBtn"
            type="submit"
            onClick={handleUpdateButtonClick}
            disabled={!validateForm()}
          >
            Update
          </Button>
        )}
      </div>

      <table className="table table-bordered table-striped">
        <thead>
          <tr className="summayTable">
            {tableData && tableData.map((head, index) => (
              <th key={index}>{head.value}</th>
            ))}
            <th className="inputActn">Action</th>
          </tr>
        </thead>
        <tbody>
          {orgTableDatas && orgTableDatas?.map((orgContact, index) => (
            <tr key={index} className="contactPersondata">
              {tableData.map((head, index) => (
                <td key={index} className="text-center">
                  {head.key === "access_type"
                    ? retriveBasedOnValue(orgContact[head.key])
                    : orgContact[head.key]}
                </td>
              ))}
              <td className="text-center">
                <BiTrash
                  className="deleteBtn"
                  onClick={() => handleContactDetailsDeleteButton(index)}
                />
                <BiEdit
                  className="deleteBtn ms-3"
                  onClick={() => handleEditButton(index)}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

export default OrgContactDetailsScreen;
