import React, { useState, useEffect } from "react";
import _ from "lodash";
import InputArea from "../../atoms/InputArea/InputArea";
import { selectReasons, updateConsortiaDDRForLibrarianEmail } from "../../containers/DDR/constants";
import InputField from "../../atoms/InputField/InputField";
import SelectDropDown from "../../atoms/DropDown/SelectDropDown";
import { DeliveryNoteDDRDelayTemplate } from "./DeliveryNoteDDRDelayTemplate";
import ReactHtmlParser from "react-html-parser";
import { DDRDiscardStatusModal } from "./DDRDiscardStatusModel";


const DDRDelayMailTemplate = ({ modalClose, fnData, getDDR, customerID }) => {
  const [data, setData] = useState(fnData);
  const [error, setError] = useState("");
  const [othersReason, setOthersReason] = useState(false);
  const [issueNotYetReceived, setIssueNotYetReceived] = useState(false);
  const [printCopyIsInCirculation, setPrintCopyIsInCirculation] = useState(false);
  const [isReason, setIsReason] = useState("");
  const [isEnteredReason, setIsEnteredReason] = useState("");
  const [isExpectedDate, setIsExpectedDate] = useState("");
  const [isDeliveryNote, setIsDeliveryNote] = useState(false);
  const [isChangedSubject, setIschangedSubject] = useState(false);


  useEffect(() => {
    let _error = "Message ";
    if (data["contu_message"] !== "") {
      setError("");
    } else {
      setError("Fill the neccessay field " + _error);
    }
  }, [data])

  useEffect(() => {
    const user = data?.user_name || "Sir / Madam ";
    const user_name = user && user.charAt(0).toUpperCase() + user.slice(1)
    const article_Title = ReactHtmlParser(data && data?.article_name);
    const journal_name = ReactHtmlParser(data && data?.journal_name);
    const authors = ReactHtmlParser(data && data?.authors);
    const volume = data && data?.volume;
    const issue = data && data?.issue;
    const year = data && data?.issue_year;
    const date_of_request = data && data?.requestedDate;
    const client_name = data && data?.Fulfilling_customer_name;
    const client_address = data && data?.fulfilling_address;
    const client_city = data && data?.city;
    const client_state = data && data?.state;
    const delayMailContent = _.cloneDeep(data);

    delayMailContent["contu_message"] = `Dear ${user_name !== null ? user_name : ""},

Sorry, there will be delay in fulfilling your request, thanks for your understanding

Requested article details :
${article_Title !== null ? `Article Title  : ${article_Title}` : ""}
${journal_name !== null ? `Journal        : ${journal_name}` : ""}
${authors !== null ? `Authors       : ${authors}` : ""}
${volume !== null ? `Volume        : ${volume}` : ""}
${issue !== null ? `Issue            : ${issue}` : ""}
${year !== null ? `Year             : ${year}` : ""}
${date_of_request !== null ? `Date of Request : ${date_of_request}` : ""}
${isReason !== "" ? `Reason for Delay : ${isReason}` : ""}
${isExpectedDate !== "" ? `Expected Date :  ${isExpectedDate}` : ""}

Regards
${client_name !== null ? `${client_name}` : ""}
${client_address !== null ? `${client_address}` : ""}
${client_city !== null ? client_city : ""}
${client_state !== null ? client_state : ""}`;
    setData(delayMailContent);

  }, []);

  useEffect(() => {
    const user = data?.user_name || "Sir / Madam ";
    const user_name = user && user.charAt(0).toUpperCase() + user.slice(1)
    const article_Title = ReactHtmlParser(data && data?.article_name);
    const journal_name = ReactHtmlParser(data && data?.journal_name);
    const authors = ReactHtmlParser(data && data?.authors);
    const volume = data && data?.volume;
    const issue = data && data?.issue;
    const year = data && data?.issue_year;
    const date_of_request = data && data?.requestedDate;
    const client_name = data && data?.Fulfilling_customer_name;
    const client_address = data && data?.fulfilling_address;
    const client_city = data && data?.city;
    const client_state = data && data?.state;
    const enteredReason = isEnteredReason && isEnteredReason.length;
    const delayMailContent = _.cloneDeep(data);

    if (othersReason === true) {
      delayMailContent["contu_message"] = `Dear ${user_name !== null ? user_name : ""},

Sorry, there will be delay in fulfilling your request, thanks for your understanding

Requested article details :
${article_Title !== null ? `Article Title  : ${article_Title}` : ""}
${journal_name !== null ? `Journal        : ${journal_name}` : ""}
${authors !== null ? `Authors       : ${authors}` : ""}
${volume !== null ? `Volume        : ${volume}` : ""}
${issue !== null ? `Issue            : ${issue}` : ""}
${year !== null ? `Year             : ${year}` : ""}
${date_of_request !== null ? `Date of Request : ${date_of_request}` : ""}
${isReason !== "" ? `Reason for Delay : ${isReason}` : ""} ${enteredReason >= 1 ? ` - ${isEnteredReason}` : ""}
${isExpectedDate !== "" ? `Expected Date :  ${isExpectedDate}` : ""}

Regards
${client_name !== null ? `${client_name}` : ""}
${client_address !== null ? `${client_address}` : ""}
${client_city !== null ? client_city : ""}
${client_state !== null ? client_state : ""}`;
      setData(delayMailContent);
    }

    if (issueNotYetReceived === true) {
      delayMailContent["contu_message"] = `Dear ${user_name !== null ? user_name : ""},

Sorry, there will be delay in fulfilling your request, thanks for your understanding

Requested article details :
${article_Title !== null ? `Article Title  : ${article_Title}` : ""}
${journal_name !== null ? `Journal        : ${journal_name}` : ""}
${authors !== null ? `Authors       : ${authors}` : ""}
${volume !== null ? `Volume        : ${volume}` : ""}
${issue !== null ? `Issue            : ${issue}` : ""}
${year !== null ? `Year             : ${year}` : ""}
${date_of_request !== null ? `Date of Request : ${date_of_request}` : ""}
${isReason !== "" ? `Reason for Delay : ${isReason}` : ""}
${isExpectedDate !== "" ? `Expected Date :  ${isExpectedDate}` : ""}

Regards
${client_name !== null ? `${client_name}` : ""}
${client_address !== null ? `${client_address}` : ""}
${client_city !== null ? client_city : ""}
${client_state !== null ? client_state : ""}`;
      setData(delayMailContent);
    }

    if (printCopyIsInCirculation === true) {
      delayMailContent["contu_message"] = `Dear ${user_name !== null ? user_name : ""},

Sorry, there will be delay in fulfilling your request, thanks for your understanding

Requested article details :
${article_Title !== null ? `Article Title  : ${article_Title}` : ""}
${journal_name !== null ? `Journal        : ${journal_name}` : ""}
${authors !== null ? `Authors       : ${authors}` : ""}
${volume !== null ? `Volume        : ${volume}` : ""}
${issue !== null ? `Issue            : ${issue}` : ""}
${year !== null ? `Year             : ${year}` : ""}
${date_of_request !== null ? `Date of Request : ${date_of_request}` : ""}
${isReason !== "" ? `Reason for Delay : ${isReason}` : ""}
${isExpectedDate !== "" ? `Expected Date :  ${isExpectedDate}` : ""}

Regards
${client_name !== null ? `${client_name}` : ""}
${client_address !== null ? `${client_address}` : ""}
${client_city !== null ? client_city : ""}
${client_state !== null ? client_state : ""}`;
      setData(delayMailContent);
    }

  }, [othersReason, isReason, isEnteredReason, isExpectedDate, printCopyIsInCirculation, issueNotYetReceived]);

  const handleInputchange = (value, name) => {
    const _dat = _.cloneDeep(data);
    _dat[name] = value;
    setData(_dat);

    if (name === "reasons") {
      setIsReason(value);
      if (value === "others_outside_ddr_system") {
        setOthersReason(true);
        setPrintCopyIsInCirculation(false);
        setIssueNotYetReceived(false)
      } else if (value === "print_copy_is_in_circulation") {
        setPrintCopyIsInCirculation(true);
        setOthersReason(false);
        setIssueNotYetReceived(false)
      } else {
        setIssueNotYetReceived(true)
        setPrintCopyIsInCirculation(false);
        setOthersReason(false);
      }
    }

    if (name === "delivery_remarks") {
      setIsEnteredReason(value);
    }
    if (name === "current_date") {
      setIsExpectedDate(value);
    }

    if (name === "mail_subject") {
      setIschangedSubject(true);
    } else {
      setIschangedSubject(false);
    }
  };

  const handleReaonsApi = async () => {
    const _dat = _.cloneDeep(data);
    const _data = {};
    _data["consortiaddr_id"] = getDDR;
    _data["ddr_status"] = "3";
    _data["mode_of_delivery"] = null;
    _data["delivered_date"] = _dat["current_date"];
    _data["ddr_reference_no"] = _dat["mail_subject"];
    _data["delivery_remarks"] = null;
    _data["courier_no"] = null;
    _data["delay_reason"] = _dat["reasons"];
    _data["discard_reason"] = null;
    _data["delivering_userid"] = customerID;
    _data["is_duplicate"] = false;

    const str = `{"data":[${JSON.stringify(_data)}]}`;

    const formData = new FormData();

    if (isChangedSubject === true) {
      formData.append("detailjson", str);
      formData.append("attachment_file", null);
      // formData.append("To_mail_Id", "shylaja@informaticsglobal.com");
      // formData.append("CC_mail_Id", "shylabhatt@gmail.com");
      formData.append("To_mail_Id",_dat["to_mail"]);
      formData.append("CC_mail_Id", _dat["cc_email"] + ";" +_dat["approver_admin_mail"]);
      formData.append("Subject", _dat["mail_subject"]);
      formData.append("Message", _dat["contu_message"]);

      fetch(updateConsortiaDDRForLibrarianEmail, {
        method: "POST",
        body: formData,
      })
        .then((response) => response.json())
        .then((result) => {
          if (result.data[0][0] === "Success") {
            setIsDeliveryNote(true);
          } else {
            alert("Something went wrong")
            setIsDeliveryNote(false);
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    } else {

      formData.append("detailjson", str);
      formData.append("attachment_file", null);
      // formData.append("To_mail_Id", "shylaja@informaticsglobal.com");
      // formData.append("CC_mail_Id", "shylabhatt@gmail.com");
      formData.append("To_mail_Id",_dat["to_mail"]);
      formData.append("CC_mail_Id", _dat["cc_email"] + ";" +_dat["approver_admin_mail"]);
      formData.append("Subject", _dat["mail_subject"] + ", Delay in fulfilling");
      formData.append("Message", _dat["contu_message"]);

      fetch(updateConsortiaDDRForLibrarianEmail, {
        method: "POST",
        body: formData,
      })
        .then((response) => response.json())
        .then((result) => {
          if (result.data[0][0] === "Success") {
            setIsDeliveryNote(true);
          } else {
            setIsDeliveryNote(false);
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }


  };

  const handleCancel = () => {
    modalClose();
  };

  const handleClear = () => {
    let text_message = _.cloneDeep(data);
    text_message["contu_message"] = "";
    setData(text_message);
  }

  const handleOthersReasonApi = () => {
    const _dat = _.cloneDeep(data);
    const _data = {};
    _data["consortiaddr_id"] = getDDR;
    _data["ddr_status"] = "3";
    _data["mode_of_delivery"] = null;
    _data["delivered_date"] = _dat["current_date"];
    _data["ddr_reference_no"] = _dat["mail_subject"];
    _data["delivery_remarks"] = _dat["delivery_remarks"] || null;
    _data["courier_no"] = null;
    _data["delay_reason"] = _dat["reasons"];
    _data["discard_reason"] = null;
    _data["delivering_userid"] = customerID;
    _data["is_duplicate"] = false;

    const str = `{"data":[${JSON.stringify(_data)}]}`;

    const formData = new FormData();

    if (isChangedSubject === true) {

      formData.append("detailjson", str);
      formData.append("attachment_file", null);
      formData.append("To_mail_Id",_dat["to_mail"]);
      formData.append("CC_mail_Id", _dat["cc_email"] + ";" +_dat["approver_admin_mail"]);

      // formData.append("To_mail_Id", "shylaja@informaticsglobal.com");
      // formData.append("CC_mail_Id", "shylabhatt@gmail.com");
      formData.append("Subject", _dat["mail_subject"]);
      formData.append("Message", _dat["contu_message"]);


      fetch(updateConsortiaDDRForLibrarianEmail, {
        method: "POST",
        body: formData,
      })
        .then((response) => response.json())
        .then((result) => {
          if (result.data[0][0] === "Success") {
            setIsDeliveryNote(true);
          } else {
            alert("Something went wrong")
            setIsDeliveryNote(false);
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    } else {
      formData.append("detailjson", str);
      formData.append("attachment_file", null);
      formData.append("To_mail_Id",_dat["to_mail"]);
      formData.append("CC_mail_Id", _dat["cc_email"] + ";" +_dat["approver_admin_mail"]);
      // formData.append("To_mail_Id", "shylaja@informaticsglobal.com");
      // formData.append("CC_mail_Id", "shylabhatt@gmail.com");
      formData.append("Subject", _dat["mail_subject"] + ", Delay in fulfilling");
      formData.append("Message", _dat["contu_message"]);

      fetch(updateConsortiaDDRForLibrarianEmail, {
        method: "POST",
        body: formData,
      })
        .then((response) => response.json())
        .then((result) => {
          if (result.data[0][0] === "Success") {
            setIsDeliveryNote(true);
          } else {
            alert("Something went wrong")
            setIsDeliveryNote(false);
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  }

  const handleSubmitButtonDisabled = () => {
    return error
  }

  const modalData = (
    <DeliveryNoteDDRDelayTemplate
      emailData={data && data}
      modalClose={modalClose}
      isEnteredReason={isEnteredReason && isEnteredReason}
      isExpectedDate={isExpectedDate && isExpectedDate}
      isReason={isReason && isReason}
      data={data}
      isChangedSubject={isChangedSubject}
    />
  );

  return (
    <div>
      < DDRDiscardStatusModal
        show={isDeliveryNote}
        modalHandle={setIsDeliveryNote}
        data={modalData}
        size="lg"
        heading="Your mail has been sent successfully"
      />
      {!!error && <div className="error text-danger">{error}</div>}
      <div>
        <InputField
          placeholder={"To"}
          name={"to_mail"}
          value={data["to_mail"] || ""}
          handleInputchange={handleInputchange}
          classname={`w-100-p to_mail`}
          parentclassname="w-100-p"
          readonly={true}
        />
      </div>
      <div>
        <InputField
          placeholder={"CC"}
          name={"cc_email"}
          value={data["cc_email"] + "," + data["approver_admin_mail"] || ""}
          handleInputchange={handleInputchange}
          classname={`w-100-p cc_email`}
          parentclassname="w-100-p"
          readonly={true}
        />
      </div>
      <div>
        <InputField
          placeholder={"Subject"}
          name={"mail_subject"}
          defaultValue={data["mail_subject"] + ", Delay in fulfilling"}
          handleInputchange={handleInputchange}
          classname={`w-100-p mail_subject`}
          parentclassname="w-100-p"
        />
      </div>
      <div className="flexRow">
        <div className="common-select-space w-260">
          <SelectDropDown
            data={selectReasons}
            label="Reasons"
            name="reasons"
            value={data["reasons"] || ""}
            handleInputchange={handleInputchange}
          />
        </div>
        {othersReason && (
          <div>
            <InputField
              placeholder={"Please enter the reason	"}
              name={"delivery_remarks"}
              value={data["delivery_remarks"] || ""}
              handleInputchange={handleInputchange}
              classname={`w-350 current_date`}
            />
          </div>
        )}
        <div>
          <InputField
            placeholder={"Expected Date"}
            name={"current_date"}
            type="date"
            value={data["current_date"] || ""}
            handleInputchange={handleInputchange}
            classname={`w-260 current_date`}
          />
        </div>

      </div>
      <div>
        <span className="input_label_clr">Message</span> <InputArea
          name={"contu_message"}
          value={data["contu_message"] || ""}
          handleInputchange={handleInputchange}
          classname={`w-100-p delivery_date messageContainer`}
          parentclassname="w-100-p"
        />

      </div>

      <div className="btn_group ddr_statu_mail_btns">
        {othersReason && (
          <div>
            <button
              type="submit"
              className="btn btn-primary"
              onClick={handleOthersReasonApi}
              disabled={handleSubmitButtonDisabled()}
            >
              Submit
            </button>
          </div>
        )}
        {issueNotYetReceived && (
          <div>
            <button
              type="submit"
              className="btn btn-primary"
              onClick={handleReaonsApi}
              disabled={handleSubmitButtonDisabled()}
            >
              Submit
            </button>
          </div>
        )}
        {printCopyIsInCirculation && (
          <div>
            <button
              type="submit"
              className="btn btn-primary"
              onClick={handleReaonsApi}
              disabled={handleSubmitButtonDisabled()}
            >
              Submit
            </button>
          </div>
        )}
        <div>
          <button
            type="submit"
            className="btn btn-secondary ddr_statu_btns"
            onClick={handleClear}
          >
            Clear
          </button>
        </div>
        <div>
          <button
            type="submit"
            className="btn btn-secondary ddr_statu_btns"
            onClick={() => handleCancel()}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default DDRDelayMailTemplate;
