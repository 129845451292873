import React from "react";

/**
 * @author
 * @function NoData
 **/

const NoData = ({ message = "No Data Available" }) => {
  return <div className="contactPersondata">{message}</div>;
};

export default NoData;
