import React from "react";
import "../default.css";
import { Form } from "react-bootstrap";


const Radio = ({ label, ariaLabel, name, checkedValue, value, handleInputchange, type }) => {
  const _ariaLabel = ariaLabel || label;
  const _checked = (checkedValue === value) ? true : false;
  const _type = type || "radio";
  return (
    <div className="Radio">
      <Form.Check
        type={_type}
        checked={_checked}
        value={value}
        name={name}
        label={label}
        aria-label={_ariaLabel}
        onChange={(e) => handleInputchange(e.target.value, name)}
      />
    </div>
  );
};

export default Radio;
