import React, { useState } from "react";
import "./OrgDetails.css";
import OrgImgSection from "../../components/OrgComponents/OrgImgSection";
import OrgDetailSection from "../../components/OrgComponents/OrgDetailSection";
import OrgContactPerson from "../../components/OrgComponents/OrgContactPerson";
import OrgModuleLogin from "../../components/OrgComponents/OrgModuleLogin";
import OrgViewSubscription from "../../components/OrgComponents/OrgViewSubscription";
import OrgIpRange from "../../components/OrgComponents/OrgIpRange";
import Button from "react-bootstrap/Button";
import { useFetch } from "../../utilities/apiFunctions/useFetch";
import {
  getCustomerDetailsUrl,
  updateCustomerDetailsByLibraryAdmin,
  getStateDetailsUrl,
  accessTypeCustomerDetailsUrl,
  getCityDetailsByState,
  getIpUpdatedUrl,
} from "../../utilities/constants";
import Loading from "../../components/Loading/Loading";
import { fetchUrl } from "../../utilities/apiFunctions/fatchUrl";
import { getLocalStorage } from "../../utilities/storage";
import { SaveModal } from "../../atoms/Modals/SaveModal";
import { useEffect } from "react";
import { SuccessAlertOrgDetails } from "../../atoms/Modals/SuccessAlertOrdDetails";
import { InfoDialogconsortia } from "../../atoms/Modals/InfoDialogconsortis";

const LibraryAdminOrg = ({
  customerID,
  consortiamaster_id,
  admin_type,
  userID,
}) => {
  const [orgDetailSection, setOrgDetailSection] = useState({});
  const [logo, setLogo] = useState({});
  const [orgContactPerson, setOrgContactPerson] = useState([]);
  const [moduleLogin, setModuleLogin] = useState({});
  const [isSave, setIsSave] = useState(false);
  const [countryId, setCountryId] = useState(0);
  const [stateDetails, setStateDetails] = useState("");
  const [stateId, setStateId] = useState(0);
  const [cityDetails, setCityDetails] = useState("");
  const [error, setError] = useState("");
  const [dynamicData, setDynamicData] = useState({});
  const [ips, setIps] = useState({});
  const [isIpUpdated, setIsIpUpdated] = useState(false);
  let _arrayData = {};
  const customerDetails = useFetch(getCustomerDetailsUrl(customerID), {});
  const cust_data = customerDetails && customerDetails?.response?.data[0][0];
  const { datinformaticsproductid } = getLocalStorage("customer");
  const [isPrimaryContact, setIsPrimaryContact] = useState(false);
  const [isMandatoryField, setIsMandatoryField] = useState(false);

  const _country_id = cust_data && cust_data?.country_id
  const _state_id = cust_data && cust_data?.state_id

  useEffect(() => {
    if (_country_id !== undefined) {
      setCountryId(_country_id);
    }
  }, [_country_id]);

  useEffect(() => {
    if (countryId && countryId !== 0) {
      fetch(getStateDetailsUrl(countryId))
        .then((res) => res.json())
        .then((item) => setStateDetails(item));
    }
  }, [countryId]);

  useEffect(() => {
    if (_state_id !== null) {
      setStateId(_state_id);
    }
  }, [_state_id]);

  useEffect(() => {
    if (stateId && stateId !== 0) {
      fetch(getCityDetailsByState(stateId))
        .then((res) => res.json())
        .then((item) => setCityDetails(item));
    } else {
      setCityDetails("");
    }
  }, [stateId, dynamicData?.state]);

  const accessTypeCustomerDetails = useFetch(
    accessTypeCustomerDetailsUrl(datinformaticsproductid),
    {}
  );

  if (!customerDetails.isLoading && customerDetails.response) {
    if (customerDetails.response.data.length > 0) {
      _arrayData = customerDetails.response.data[0][0];
    }
  }

  const data = {
    data: [
      {
        ..._arrayData,
        ...orgDetailSection,
        // ...orgParentSection,
        ...moduleLogin,
        contactdetails: [...orgContactPerson],
        ...ips,
        ...logo,
        // ...contactDetails,
      },
    ],
  };

  const handleSaveClick = async () => {
    const dat = data.data[0].contactdetails.map((val) => val.access_type);
    if (
      (data && data.data && data.data[0].state_id != null) &&
      (data.data[0].city_id != null)
    ) {
      if (dat && dat.includes("1")) {
        const _data = JSON.stringify(data);
        const formData = new FormData();
        formData.append("detailsjson", _data);
        await fetch(updateCustomerDetailsByLibraryAdmin, {
          method: "POST",
          body: formData,
        })
          .then((response) => response)
          .then((result) => {
            if (result && result.status === 200 && isIpUpdated === true) {
              fetchUrl(getIpUpdatedUrl(customerID));
              setIsSave(true);
            }
            if (result && result.status === 200) {
              setIsSave(true);
            } else {
              console.log(error);
            }
          });
      } else {
        setIsPrimaryContact(true);
      }
    } else {
      setIsMandatoryField(true);
    }
  };

  const validateForm = () => {
    return error === "";
  };

  return (
    <div className="OrgDetails">
      <SaveModal
        show={isSave}
        modalHandle={setIsSave}
        data={
          <SuccessAlertOrgDetails
            message={"Organization Details are Saved Successfully!!!"}
            onClose={setIsSave}
          />
        }
        dialogClassName="successModal"
        heading={"Info"}
        size="md"
      />
      <InfoDialogconsortia
        show={isPrimaryContact}
        modalClose={setIsPrimaryContact}
        content={"Please add Primary Contact Details to Proceed"}
        successCallback={handleSaveClick}
        heading="Info"
      />
      <InfoDialogconsortia
        show={isMandatoryField}
        modalClose={setIsMandatoryField}
        content={"Please select the mandatory fields *"}
        successCallback={handleSaveClick}
        heading="Info"
      />
      {customerDetails.isLoading && <Loading />}
      {!customerDetails.isLoading && customerDetails.response && (
        <>
          <div>
            <div className="row">
              <div className="col-11 ">
                <OrgDetailSection
                  setOrgDetailSection={setOrgDetailSection}
                  customerDetails={customerDetails?.response}
                  cityDetails={cityDetails && cityDetails}
                  stateDetails={stateDetails && stateDetails}
                  setStateId={setStateId}
                  setDynamicData={setDynamicData}
                  setError={setError}
                  error={error}
                />
              </div>
              <div className="col-1 org_mrg_frm_top">
                <OrgImgSection
                  setLogo={setLogo}
                  customerDetails={customerDetails}
                  userID={userID}
                  customerID={customerID}
                />
              </div>
            </div>
            {accessTypeCustomerDetails.isLoading && <Loading />}
            {!accessTypeCustomerDetails.isLoading &&
              accessTypeCustomerDetails.response &&
              accessTypeCustomerDetails.response.data && (
                <OrgContactPerson
                  setOrgContactPerson={setOrgContactPerson}
                  customerDetails={customerDetails.response}
                  admin_type={admin_type}
                  accessTypeCustomerDetails={
                    accessTypeCustomerDetails && accessTypeCustomerDetails?.response && accessTypeCustomerDetails?.response?.data
                  }
                />
              )}
            {customerDetails.isLoading && <Loading />}
            {!customerDetails.isLoading && customerDetails.response && (
              <OrgModuleLogin
                setModuleLogin={setModuleLogin}
                customerDetails={customerDetails.response}
                customerID={customerID}
              />
            )}
            {customerDetails.isLoading && <Loading />}
            {!customerDetails.isLoading && customerDetails.response && (
              <OrgViewSubscription
                customerDetails={customerDetails.response}
                customerID={customerID}
                consortiamaster_id={consortiamaster_id}
                admin_type={admin_type}
              />
            )}
            {customerDetails.isLoading && <Loading />}
            {!customerDetails.isLoading && customerDetails.response && (
              <OrgIpRange
                setIps={setIps}
                customerDetails={customerDetails && customerDetails?.response}
                customerID={customerID}
                consortiamaster_id={consortiamaster_id}
                admin_type={admin_type}
                orgContactPerson={orgContactPerson}
                setIsIpUpdated={setIsIpUpdated}
              />
            )}
          </div>
        </>
      )}
      <Button
        size="lg"
        className="ipAddBtn saveButtonmrg"
        type="submit"
        onClick={handleSaveClick}
        disabled={!validateForm()}
      >
        Save
      </Button>
      {/* <div className="mt-5">
        {customerDetails.isLoading && <Loading />}
        {!customerDetails.isLoading && customerDetails.response && (
          <OrgContactInformatics
            setContactDetails={setContactDetails}
            customerDetails={customerDetails.response}
            customerID={customerID}
            consortiamaster_id={consortiamaster_id}
            admin_type={admin_type}
          />
        )}
      </div> */}
    </div>
  );
};

export default LibraryAdminOrg;
