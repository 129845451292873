import React from "react";
import { Button } from "react-bootstrap";

/**
 * @author
 * @function ConsortiaResourceTable
 **/

const ConsortiaResourceTable = ({
  tableData,
  action,
  viewAction,
  handleAction,
  handleViewAction,
  isSearched,
}) => {
  const _action = action ? true : false;
  const _viewAction = action ? true : false;

  return (
    <>
      <>
        <table className="table table-bordered table-striped">
          <thead className="summayTable">
            <tr>
              <th>SI.No</th>
              <th>Package Name</th>
              <th>Count of Titles</th>
              <th>Last Updated Date</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {!tableData && (
              <tr>
                {!isSearched ? (
                  <td
                    colSpan="7"
                    className="text-center fw-bold contactPersondata"
                  >
                    No packages Configured for consortia
                  </td>
                ) : (
                  <td
                    colSpan="7"
                    className="text-center fw-bold contactPersondata"
                  >
                    Given Package not available as part of consortia
                  </td>
                )}
              </tr>
            )}
            {tableData &&
              tableData.map((data, index) => (
                <tr key={index} className="contactPersondata">
                  <td className="ddrtable"> {index + 1} </td>
                  <td> {data.package_name} </td>
                  <td className="ddrtable"> {data.unique_resource_count} </td>
                  <td className="ddrtable"> {data.last_modified_date} </td>

                  {_action && (
                    <td className="ddrtable">
                      <div>
                        {_viewAction && (
                          <Button
                            onClick={() => handleViewAction(data)}
                            className="contactPersondata"
                          >
                            {viewAction}
                          </Button>
                        )}
                        <Button
                          variant="danger contactPersondata"
                          onClick={() => handleAction(data)}
                        >{action}</Button>
                      </div>
                    </td>
                  )}
                </tr>
              ))}
          </tbody>
        </table>
        <br />
      </>
    </>
  );
};

export default ConsortiaResourceTable;
