import React from "react";
import BarCharts from "../../components/Charts/BarCharts";
import PiCharts from "../../components/Charts/PiCharts";
import UserCount from "../../components/DashboardComponents/UserCount";
import UsageSummary from "../../components/DashboardComponents/UsageSummary";
import UsageSummaryTable from "../../components/DashboardComponents/UsageSummaryTable";
import WidgetResources from "../../components/DashboardComponents/WidgetResources";
import "./Dashboard.css";
import { useFetch } from "../../utilities/apiFunctions/useFetch";
import Loading from "../../components/Loading/Loading";
import {
  libraryResourcesUrl,
  summaryTableUrl,
  userCountUrl,
  openResourcesUrl,
  ddrUsageLibraryUrl,
  usageSummaryDataUrl,
  topUsedPublisherByLibraryUrl,
  dashboardForConsortiaSubscribedResourceCountUrl,
  dashboardLibrarySubscribedResourceFromConsortia,
} from "../../utilities/constants";
import WidgetHeading from "../../atoms/Heading/WidgetHeading";
import PiChart from "../../components/Charts/PiChart";
/**
 * @author vimalkovath
 * @function LibraryAdmin
 **/

const LibraryAdmin = ({
  customerID,
  consortiamaster_id,
  admin_type,
  finalSubject_Ids,
  dataType_Ids,
}) => {
  const userCountData = useFetch(userCountUrl(customerID), {});
  const summaryTableData = useFetch(summaryTableUrl(customerID), {});
  // const libraryResourceData = useFetch(libraryResourcesUrl(customerID), {});
  const libraryResourceData = useFetch(
    dashboardForConsortiaSubscribedResourceCountUrl(customerID),
    {}
  );
  const openResource = useFetch(
    openResourcesUrl(dataType_Ids, finalSubject_Ids),
    {}
  );
  const ddrUsageData = useFetch(ddrUsageLibraryUrl(customerID), {});
  const usageSummaryData = useFetch(usageSummaryDataUrl(customerID), {});
  const topUsedPublisherData = useFetch(
    topUsedPublisherByLibraryUrl(customerID),
    {}
  );

  const ConsortiaMemberResourceData = useFetch(
    dashboardLibrarySubscribedResourceFromConsortia(customerID),
    {}
  );

  return (
    <>
      {consortiamaster_id !== null ? (
        <div className="content-wrapper Admin_wrapper dashbrd_mrg_frm_top">
          <div className="row ">
            <div className="col-md-12 ">
              <h2> Dashboard</h2>
              <WidgetHeading
                heading={"Available Resources"}
                customerID={customerID}
                consortiamaster_id={consortiamaster_id}
                admin_type={admin_type}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-6 d-flex gap-1">
              <div className="col-md-6">
                {ConsortiaMemberResourceData.isLoading && <Loading />}
                {!ConsortiaMemberResourceData.isLoading &&
                  ConsortiaMemberResourceData.response && (
                    <WidgetResources
                      resourceData={ConsortiaMemberResourceData.response}
                      openAccess={false}
                      heading="Consortia Subscribed Resources"
                      customerID={customerID}
                      consortiamaster_id={consortiamaster_id}
                      admin_type={admin_type}
                    />
                  )}
              </div>
              <div className="col-md-6 ">
                {libraryResourceData.isLoading && <Loading />}
                {!libraryResourceData.isLoading &&
                  libraryResourceData.response && (
                    <WidgetResources
                      resourceData={libraryResourceData.response}
                      openAccess={false}
                      heading="Library Subscribed Resources"
                      customerID={customerID}
                      consortiamaster_id={consortiamaster_id}
                      admin_type={admin_type}
                    />
                  )}
              </div>
            </div>
            <div className="col-md-6 ">
              {openResource.isLoading && <Loading />}
              {!openResource.isLoading &&
                openResource.response &&
                openResource.response.data && (
                  <WidgetResources
                    resourceData={openResource.response.data.jsonfacets}
                    openAccess={true}
                    heading="Open Access Resources"
                    customerID={customerID}
                    consortiamaster_id={consortiamaster_id}
                    admin_type={admin_type}
                  />
                )}
            </div>
          </div>

          <div className="chartRow">
            <div className="row ">
              <div className="col-md-2">
                {userCountData.isLoading && <Loading />}
                {!userCountData.isLoading && userCountData.response && (
                  <UserCount
                    userCountData={userCountData.response}
                    customerID={customerID}
                    consortiamaster_id={consortiamaster_id}
                    admin_type={admin_type}
                  />
                )}
              </div>

              <div className="col-md-5">
                <div className="row">
                  <div className="userCountHead">
                    <WidgetHeading heading="Top 10 Publishers by Usage(Full Text Downloads)" />
                  </div>
                  <div className="chart1Widget">
                    {topUsedPublisherData.isLoading && <Loading />}
                    {!topUsedPublisherData.isLoading &&
                      topUsedPublisherData.response && (
                        <PiCharts
                          topUsedPublisherData={topUsedPublisherData.response}
                          customerID={customerID}
                          consortiamaster_id={consortiamaster_id}
                          admin_type={admin_type}
                        />
                      )}
                  </div>
                </div>
              </div>
              <div className="col-md-5">
                <div className="userCountHead">
                  <WidgetHeading heading="DDR Summary" />
                </div>
                <div className="chart2Widget">
                  {ddrUsageData.isLoading && <Loading />}
                  {!ddrUsageData.isLoading && ddrUsageData.response && (
                    <BarCharts
                      ddrUsageData={ddrUsageData.response}
                      customerID={customerID}
                      consortiamaster_id={consortiamaster_id}
                      admin_type={admin_type}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="chartRow">
            <div className="row ">
              <div className="col-md-3">
                {usageSummaryData.isLoading && <Loading />}
                {!usageSummaryData.isLoading && usageSummaryData.response && (
                  <UsageSummary
                    usageSummaryData={usageSummaryData.response}
                    customerID={customerID}
                    consortiamaster_id={consortiamaster_id}
                    admin_type={admin_type}
                  />
                )}
              </div>

              <div className="col-md-9">
                <div className="row">
                  {summaryTableData.isLoading && <Loading />}
                  {!summaryTableData.isLoading && summaryTableData.response && (
                    <UsageSummaryTable
                      summaryTableData={summaryTableData.response}
                      customerID={customerID}
                      consortiamaster_id={consortiamaster_id}
                      admin_type={admin_type}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="content-wrapper Admin_wrapper dashbrd_mrg_frm_top">
          <div className="row">
            <div className="col-md-12">
              <h2> Dashboard</h2>
              <WidgetHeading
                heading={"Available Resources"}
                customerID={customerID}
                consortiamaster_id={consortiamaster_id}
                admin_type={admin_type}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-6 ">
              {libraryResourceData.isLoading && <Loading />}
              {!libraryResourceData.isLoading &&
                libraryResourceData.response && (
                  <WidgetResources
                    resourceData={libraryResourceData.response}
                    openAccess={false}
                    heading="Library Subscribed Resources"
                    customerID={customerID}
                    consortiamaster_id={consortiamaster_id}
                    admin_type={admin_type}
                  />
                )}
            </div>

            <div className="col-md-6 ">
              {openResource.isLoading && <Loading />}
              {!openResource.isLoading &&
                openResource.response &&
                openResource.response.data && (
                  <WidgetResources
                    resourceData={openResource.response.data.jsonfacets}
                    openAccess={true}
                    heading="Open Access Resources"
                    customerID={customerID}
                    consortiamaster_id={consortiamaster_id}
                    admin_type={admin_type}
                  />
                )}
            </div>
          </div>

          <div className="chartRow">
            <div className="row ">
              <div className="col-md-3">
                {userCountData.isLoading && <Loading />}
                {!userCountData.isLoading && userCountData.response && (
                  <UserCount
                    userCountData={userCountData.response}
                    customerID={customerID}
                    consortiamaster_id={consortiamaster_id}
                    admin_type={admin_type}
                  />
                )}
              </div>

              <div className="col-md-9">
                <div className="row">
                  <div className="col-md-6">
                    <div className="row">
                      <div className="userCountHead">
                        <WidgetHeading heading="Top 10 Publishers by Usage(Full Text Downloads)" />
                      </div>
                      <div className="chart1Widget">
                        {topUsedPublisherData.isLoading && <Loading />}
                        {!topUsedPublisherData.isLoading &&
                          topUsedPublisherData.response && (
                            <PiChart
                              topUsedPublisherData={
                                topUsedPublisherData.response
                              }
                              customerID={customerID}
                              consortiamaster_id={consortiamaster_id}
                              admin_type={admin_type}
                            />
                          )}
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div>
                      {usageSummaryData.isLoading && <Loading />}
                      {!usageSummaryData.isLoading &&
                        usageSummaryData.response && (
                          <UsageSummary
                            usageSummaryData={usageSummaryData.response}
                            customerID={customerID}
                            consortiamaster_id={consortiamaster_id}
                            admin_type={admin_type}
                          />
                        )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="chartRow">
            <div className="row ">
              <div className="col-md-12">
                <div className="row">
                  {summaryTableData.isLoading && <Loading />}
                  {!summaryTableData.isLoading && summaryTableData.response && (
                    <UsageSummaryTable
                      summaryTableData={summaryTableData.response}
                      customerID={customerID}
                      consortiamaster_id={consortiamaster_id}
                      admin_type={admin_type}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default LibraryAdmin;
