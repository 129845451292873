import React from "react";
import "./Header.css";
import LogoJgate from "./LogoJgate";
/**
 * @author vimalkovath
 * @function Header
 **/

const DefaultHeader = () => {
  
  return (
    <>
      <header className="headerposiotion headerBrdr">
        <div>
          <div className="HeaderLeft">
            <LogoJgate />
          </div>
        </div>
      </header>
    </>
  );
};

export default DefaultHeader;
