import React, { useState, useEffect, useMemo, Fragment } from "react";
import CommonHeading from "../../atoms/Heading/CommonHeading";
import { Button } from "react-bootstrap";
import Radio from "../../atoms/Radio/Radio";
import InputField from "../../atoms/InputField/InputField";
import _ from "lodash";
import { fetchUrl } from "../../utilities/apiFunctions/fatchUrl";
import {
  viewConfigureLibraryUrl,
  removePrintElectronicResourcesUrl,
  downloadPrintElectronicLibraryHoldingsUrl,
  getAggregatorPackagesListUrl,
  getPublisherPackagesListUrl,
  customerResourceType,
} from "../../utilities/constants";
import ResourceTable from "./ResourceTable";
import VCLRPackage from "./VCLRPackage";
import { VCLRTableProperty } from "../../containers/ResourceMgmt/constants";
import { ConfirmDialog } from "../../atoms/Modals/ConfirmDialog";
import { useFetch } from "../../utilities/apiFunctions/useFetch";
import Loading from "../../components/Loading/Loading";
import { NewModal } from "../Modals/Modal";
import AddSubscriptionModel from "./AddSubscriptionModel";
import { InfoDialog } from "../../atoms/Modals/InfoDialog";
import BasicSelectDropDown from "../../atoms/DropDown/BasicSelectDropDown";
import { Pagination } from "../ReportsComponents/Pagination";

const ViewConfigureLibraryResources = ({
  accessType,
  customerID,
  setResourceType,
  resourceTpye,
}) => {
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [isAlertOn, setIsAlertOn] = useState(false);
  const [isAlertOnUpload, setIsAlertOnUpload] = useState(false);
  const [isSearched, setIsSearched] = useState(false);
  const [deleteUrl, setDeleteUrl] = useState("");
  const [isAddSubscription, setIsAddSubscription] = useState(false);
  const aggregatorPackagesList = useFetch(getAggregatorPackagesListUrl);
  const publisherPackagesList = useFetch(getPublisherPackagesListUrl);

  tableData &&
    tableData.forEach((item, ind) => {
      item.id = ind + 1;
    });

  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(15);
  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords =
    tableData && tableData.slice(indexOfFirstRecord, indexOfLastRecord);
  const nPages = Math.ceil(tableData && tableData.length / recordsPerPage);

  const [dataTypes, setDataTypes] = useState(() => {
    const storedData = sessionStorage.getItem("resourceData");
    return storedData ? JSON.parse(storedData) : [];
  });

  console.log("resourceTpye", resourceTpye);

  // const dataTest = useMemo(
  //   () =>
  //     Array.isArray(dataTypes)
  //       ? dataTypes
  //           .filter((item) => item !== null)
  //           .flatMap((item) =>
  //             Object.entries(item).map(([key, value]) => ({
  //               label: value,
  //               value: key,
  //             }))
  //           )
  //       : [],
  //   [dataTypes]
  // );

  // const finalDataTypes = useMemo(
  //   () => dataTest.map((val) => Number(val.value)),
  //   [dataTest]
  // );

  const dataTest = [];
  if (Array.isArray(resourceTpye)) {
    for (let i = 0; i < resourceTpye.length; i++) {
      const item = resourceTpye[i];
      if (item !== null) {
        for (const [key, value] of Object.entries(item)) {
          dataTest.push({ label: value, value: key });
        }
      }
    }
  }

  const finalDataTypes = [];
  for (let i = 0; i < dataTest.length; i++) {
    finalDataTypes.push(Number(dataTest[i].value));
  }

  const finalDataTypesIds =
    finalDataTypes?.length > 0 ? finalDataTypes.join(",") : "";
  console.log("finalDataTypes", finalDataTypes);
  console.log("finalDataTypesIds", finalDataTypesIds);
  const [data, setData] = useState({
    resource_name: "",
    PrintOnlinePackages: "1",
    dat_resource_type: finalDataTypesIds,
    printorelectronicstatus: "",
  });

  useEffect(() => {
    fetch(customerResourceType(customerID), {})
      .then((val) => val.json())
      .then((res) => {
        setResourceType(res?.data);
        // if (res.data?.length) {
        //   sessionStorage.setItem("resourceData", JSON.stringify(res?.data));
        // } else {
        //   sessionStorage.removeItem("resourceData");
        // }
      })
      .catch((error) => console.error(error));
  }, [customerID]);

  useEffect(() => {
    setData((prevData) => ({
      ...prevData,
      // dat_resource_type: finalDataTypes,
    }));
  }, []);

  useEffect(() => {
    if (resourceTpye?.length > 0) {
      handleLoadButton();
    }
  }, [resourceTpye]);

  const handleInputchange = (value, name) => {
    const dynamic = _.cloneDeep(data);
    dynamic[name] = value;
    setData(dynamic);
  };

  const handleDataTypes = (value, name) => {
    const dynamic = _.cloneDeep(data);
    dynamic[name] = value;

    if (value === "") {
      dynamic[name] = finalDataTypesIds;
    }
    setData(dynamic);
  };

  const handleLoadClick = async () => {
    setIsSearched(true);
    setLoading(true);
    setCurrentPage(1);
    const _data = {
      CustomerId: customerID,
      ...data,
    };
    delete _data.PrintOnlinePackages;

    if (_data["dat_resource_type"] === "") {
      _data["dat_resource_type"] = finalDataTypes;
    }
    let str = Object.entries(_data)
      .map(([key, val]) => `${key}=${val}`)
      .join("&");

    const response = await fetchUrl(viewConfigureLibraryUrl(str));
    setTableData(response?.data[0][0]);
    setLoading(false);
  };

  const handleLoadButton = async () => {
    setIsSearched(false);
    setLoading(true);
    const _data = {
      CustomerId: customerID,
      ...data,
    };
    delete _data.PrintOnlinePackages;
    delete _data.resource_name;

    if (_data["dat_resource_type"] === "") {
      _data["dat_resource_type"] = finalDataTypesIds;
    }
    let str = Object.entries(_data)
      .map(([key, val]) => `${key}=${val}`)
      .join("&");

    const response = await fetchUrl(viewConfigureLibraryUrl(str));
    setTableData(response?.data[0][0]);
    setLoading(false);
    setCurrentPage(1);
  };

  const handleAction = (item) => {
    const url = "CustomerId=" + customerID + "&resource_id=" + item.record_id;
    setDeleteUrl(url);
    setIsAlertOn(true);
  };

  const removeData = async () => {
    setLoading(true);
    await fetchUrl(removePrintElectronicResourcesUrl(deleteUrl));
    setDeleteUrl("");
    setLoading(false);
    setIsAlertOn(false);
    handleLoadClick();
  };

  const handleDownloadHoldings = () => {
    const _data = {
      CustomerId: customerID,
      ...data,
    };
    delete _data.PrintOnlinePackages;

    if (_data["dat_resource_type"] === "") {
      _data["dat_resource_type"] = finalDataTypes;
    }

    let str = Object.entries(_data)
      .map(([key, val]) => `${key}=${val}`)
      .join("&");

    window.open(downloadPrintElectronicLibraryHoldingsUrl(str));
  };

  const handleAddSubscription = () => {
    setIsAddSubscription(true);
  };

  const handleClose = () => {
    setIsAddSubscription(false);
    setIsAlertOnUpload(false);
  };

  const successCallBackModal = () => {
    setIsAlertOnUpload(true);
  };

  const modalData = (
    <AddSubscriptionModel
      customerID={customerID}
      access_type={data["PrintOnlinePackages"]}
      successCallBackModal={successCallBackModal}
      handleClose={handleClose}
      setIsAddSubscription={setIsAddSubscription}
    />
  );

  const dataValidation = () => {
    return tableData;
  };

  const handleDisableButton = () => {
    return resourceTpye;
  };

  return (
    <div>
      <InfoDialog
        show={isAlertOnUpload}
        modalClose={setIsAlertOnUpload}
        content={"Titles Uploaded Successfully"}
        successCallback={handleClose}
        heading="Info"
      />

      <NewModal
        show={isAddSubscription}
        modalHandle={setIsAddSubscription}
        data={modalData}
        size="md"
        heading="Upload Library Holdings"
      />

      <ConfirmDialog
        show={isAlertOn}
        modalClose={setIsAlertOn}
        content={"Are you sure you want to remove this ?"}
        successCallback={removeData}
        heading="Confirm"
      />

      <div className="ViewConfigureLibraryHeadingRow">
        <CommonHeading heading="View / Configure Library Resources" />
        <Radio
          label="Print / Online Subscribed Titles"
          value={"1"}
          name="PrintOnlinePackages"
          checkedValue={data["PrintOnlinePackages"]}
          handleInputchange={handleInputchange}
        />
        <Radio
          label="Packages"
          value={"2"}
          checkedValue={data["PrintOnlinePackages"]}
          handleInputchange={handleInputchange}
          name="PrintOnlinePackages"
        />
      </div>

      {data["PrintOnlinePackages"] === "1" && (
        <Fragment>
          <div className="ViewConfigureLibraryResourcesRow">
            <Button
              onClick={handleAddSubscription}
              className="contactPersondata"
            >
              Add Subscriptions
            </Button>
          </div>

          <div className="ViewConfigureLibraryResourcesRowSearchbtn">
            <InputField
              placeholder={"Resource Name "}
              name={"resource_name"}
              value={data["resource_name"] || ""}
              handleInputchange={handleInputchange}
              classname={`w-300 resource_name `}
              onKeyPress={(event) => {
                if (event.key === "Enter") {
                  handleLoadClick();
                }
              }}
            />
            <div onClick={handleLoadClick}>
              <i className="fa fa-search searchBtn"> </i>
            </div>
          </div>
          <div className="ViewConfigureLibraryResourcesRowSearchbtn">
            <div className="common-select-space ViewConfigureLibraryDropdwn">
              <BasicSelectDropDown
                label="Select Resource Type"
                name="dat_resource_type"
                data={dataTest && dataTest?.map((val) => val)}
                classname={"w-260 common-border"}
                handleInputchange={handleDataTypes}
              />
            </div>
            <div className="common-select-space ViewConfigureLibraryDropdwn">
              <BasicSelectDropDown
                label="Select Access Type"
                name="printorelectronicstatus"
                data={accessType}
                classname={"w-260 common-border"}
                handleInputchange={handleInputchange}
              />
            </div>
            <Button
              className="m-2 contactPersondata"
              onClick={handleLoadButton}
              disabled={!handleDisableButton()}
            >
              Load
            </Button>
          </div>

          <div className="ViewConfigureLibraryResourcesRow">
            <Button
              variant="secondary contactPersondata"
              onClick={handleDownloadHoldings}
              disabled={!dataValidation()}
            >
              Download Holdings
            </Button>
          </div>
          <div>
            {loading && <Loading />}
            <div className="ViewConfigureLibraryResourcesRow">
              <ResourceTable
                tableProperty={VCLRTableProperty}
                tableData={currentRecords}
                action="Delete"
                handleAction={handleAction}
                loading={loading}
                isSearched={isSearched}
                resourceTpye={resourceTpye}
              />
            </div>
            <div className="pgnation">
              <Pagination
                nPages={nPages && nPages}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                setRecordsPerPage={setRecordsPerPage}
                tableDataReport={tableData}
                recordsPerPage={recordsPerPage}
              />
            </div>
          </div>
        </Fragment>
      )}

      {data["PrintOnlinePackages"] === "2" && (
        <>
          {(aggregatorPackagesList.isLoading ||
            publisherPackagesList.isLoading) && <Loading />}
          {!aggregatorPackagesList.isLoading &&
            !publisherPackagesList.isLoading &&
            (aggregatorPackagesList.response ||
              publisherPackagesList.response) && (
              <VCLRPackage
                customerID={customerID}
                aggregatorPackagesList={aggregatorPackagesList}
                publisherPackagesList={publisherPackagesList}
              />
            )}
        </>
      )}
    </div>
  );
};

export default ViewConfigureLibraryResources;
