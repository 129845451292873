import React from "react";
import { Form } from "react-bootstrap";
import "./DropDown.css";
/**
 * @author
 * @function SelectDropDown
 **/

const SelectStateDropDown = ({
  data,
  classname,
  name,
  value,
  label,
  handleStateDropDown,
  optional,
  defaultValue,
  defaultSelectValue,
}) => {
  const _label = label ? true : false;
  const _optional = optional || "";
  // const _default = defaultValue || label;
  return (
    <>
      {_label && <div className="inputFieldOrgDetailLabel1">{label}</div>}
      <Form.Control
        className={`SelectDropDown ${classname}`}
        as="select"
        custom
        defaultValue={defaultSelectValue}
        onChange={(e) => handleStateDropDown(e.target.value, name, _optional)}
      >
        <option value={""} className="placeHolderColor">
          {value || "select"}
        </option>
        {data && data?.map((item, index) => (
          <option key={index} value={item.value}>
            {item.label}
          </option>
        ))}
      </Form.Control>
    </>
  );
};

export default SelectStateDropDown;
