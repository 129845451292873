import React, { useState, useEffect, useCallback } from 'react';
import { getDetailsForDDRActionForm } from '../../containers/DDR/constants';
import { fetchUrl } from '../../utilities/apiFunctions/fatchUrl';
import ReactHtmlParser from "react-html-parser";
import Loading from '../Loading/Loading';

export const DelayStatusData = ({ statusDDRId }) => {
    const [statusData, setStatusData] = useState("")
    const user_name = statusData && statusData?.user_name;
    const userName = user_name && user_name.charAt(0).toUpperCase() + user_name.slice(1);
    const requestedBy = statusData && statusData?.requester_customer_name;
    const requestedTo = statusData && statusData?.Fulfilling_customer_name;
    const requestedDate = statusData && statusData?.requestedDate;
    const ddrReferenceID = statusData && statusData?.mail_subject;
    const deliveryDate = statusData && statusData?.current_date;
    const status = statusData && statusData?.action_name;
    const delayReason = statusData && statusData?.delay_reason;
    const article_Title = ReactHtmlParser(statusData && statusData?.article_name);
    const journal_name = ReactHtmlParser(statusData && statusData?.journal_name);
    const authors = ReactHtmlParser(statusData && statusData?.authors);
    const volume = statusData && statusData?.volume;
    const issue = statusData && statusData?.issue;
    const year = statusData && statusData?.issue_year;

    const handleInputchange = useCallback(async () => {
        const str = "ddr_id=" + statusDDRId + "&ddr_action=3"
        const response = await fetchUrl(getDetailsForDDRActionForm(str));
        setStatusData(response && response.data && response.data[0][0]);
    }, [statusDDRId]);

    useEffect(() => {
        handleInputchange();
    }, [handleInputchange])

    // const handleInputchange = async () => {
    //     const str = "ddr_id=" + statusDDRId + "&ddr_action=3"
    //     const response = await fetchUrl(getDetailsForDDRActionForm(str));
    //     setStatusData(response && response.data && response.data[0][0]);
    // }
    return (
        <div>
            {!statusData && <Loading />}
            <div>
                <div className='ddrStatusmodel'>
                    {user_name && user_name && (<p>Requestor's Name    <span style={{ marginLeft: 12 }}>:</span> {userName}</p>)}
                    {requestedBy && requestedBy && (<p>Requested By  <span style={{ marginLeft: 41 }}>:</span> {requestedBy}</p>)}
                    {requestedTo && requestedTo && (<p>Requested To <span style={{ marginLeft: 41 }}>:</span> {requestedTo}</p>)}
                    {ddrReferenceID && ddrReferenceID && (<p>DDR Reference No  <span style={{ marginLeft: 8 }}>:</span> {ddrReferenceID}</p>)}
                    {requestedDate && requestedDate && (<p>Requested Date <span style={{ marginLeft: 26 }}>:</span> {requestedDate}</p>)}
                    {deliveryDate && deliveryDate && (<p>Delivery Date <span style={{ marginLeft: 41 }}>:</span> {deliveryDate}</p>)}
                    {status && status && (<p>DDR Status <span style={{ marginLeft: 53 }}>:</span> {status}</p>)}
                    {delayReason && delayReason && (<p>Delayed Reason <span style={{ marginLeft: 25 }}>:</span> {delayReason}</p>)}
                </div>
                <div className='word_wrap'>
                    {article_Title && article_Title && (<p>Article Title</p>)}
                    {article_Title && article_Title && (<p style={{ marginLeft: 57 }}>: {article_Title}</p>)}
                </div>
                <div className='word_wrap'>
                    {journal_name && journal_name && (<p>Journal</p>)}
                    {journal_name && journal_name && (<p style={{ marginLeft: 82 }}>: {journal_name}</p>)}
                </div >
                <div className='word_wrap'>
                    {authors && authors && (<p>Authors </p>)}
                    {authors && authors && (<p style={{ marginLeft: 78 }}>: {authors} </p>)}
                </div>
                {volume && volume && (<p style={{ marginLeft: 35 }}>Volume<span style={{ marginLeft: 81 }}>:</span> {volume}</p>)}
                {issue && issue && (<p style={{ marginLeft: 35 }}>Issue<span style={{ marginLeft: 97 }}>:</span> {issue}</p>)}
                {year && year && (<p style={{ marginLeft: 35 }}>Year <span style={{ marginLeft: 98 }}>:</span> {year}</p>)}
            </div>
        </div>
    )
}