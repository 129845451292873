import React from "react";
import { getLocalStorage } from "../../utilities/storage";
import "./DDR.css";
import LibraryAdminDDR from "./LibraryAdminDDR";
import ConsortiaAdminDDR from "./ConsortiaAdminDDR";


/**
 * @author
 * @function DDR
 **/

const DDR = () => {
  const { customerID, admin_type } = getLocalStorage("user");
  const { consortiamaster_id } = getLocalStorage("customer");


  return (
    <>
      {admin_type === "library_admin" && (
        <LibraryAdminDDR customerID={customerID} consortiamaster_id={consortiamaster_id} admin_type={admin_type} />
      )}

      {admin_type !== "library_admin" && (
        <ConsortiaAdminDDR customerID={customerID} consortiamaster_id={consortiamaster_id} admin_type={admin_type} />
      )}
    </>
  );
};

export default DDR;
