import React from "react";
import SubscriptionDetailsTable from "../Table/SubscriptionDetails";



const OrgViewTable = ({ fetchOrgContact, productname, customerID }) => {
  return (
    <div className="OrgViewTable">
      {fetchOrgContact &&
        fetchOrgContact.data[0][0] &&
        fetchOrgContact.data[0][0].subscription_details && (
          <SubscriptionDetailsTable
            subscriptionTableData={
              fetchOrgContact && fetchOrgContact?.data[0][0]?.subscription_details
            }
            subscriptionResorceData={fetchOrgContact && fetchOrgContact?.data[0][0]?.resource_types}
            customername={fetchOrgContact && fetchOrgContact?.data[0][0]?.customer_name}
            productname={productname[0]}
            customerID={customerID}
          />
        )}
    </div>
  );
};

export default OrgViewTable;
