import React, { useState } from "react";
import { Button } from "react-bootstrap";
import _ from "lodash";
import {
  userUploadFormatExcelUrl,
  uploadUserList,
} from "../../utilities/constants";
import { UserListUploadDialog } from "../../atoms/Modals/UserListUploadDialog";
import { InfoDialogconsortia } from "../../atoms/Modals/InfoDialogconsortis";
import UserFileUpload from "../../atoms/FileUpload/UsersFileUpload";

const AddUserUploadData = ({ customerID, setIsAddUserData }) => {
  const [data, setData] = useState({
    file: [],
  });

  const [successData, setSuccessData] = useState("");
  const [isAlertOnUpload, setIsAlertOnUpload] = useState(false);
  const [isAlertOnRejected, setIsAlertOnRejected] = useState(false);
  const [isAlertOn, setIsAlertOn] = useState(false);

  const handleInputchange = (value, name) => {
    const dynamic = _.cloneDeep(data);
    dynamic[name] = value;
    setData(dynamic);
  };

  const handleExcelDownload = async () => {
    window.open(userUploadFormatExcelUrl(customerID));
  };

  const streamToCsv = (result) => {
    const url = window.URL.createObjectURL(new Blob([result]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `${Date.now()}.xlsx`);
    document.body.appendChild(link);
    link.click();
  };

  const handleUpload = async () => {
    const formData = new FormData();
    formData.append("Upload_User", data["file"]);
    formData.append("customer_id", customerID);
    fetch(uploadUserList, {
      method: "POST",
      body: formData,
    })
      .then((response) => response)
      .then((result) => {
        const contentType = result.headers.get("content-type");
        if (result.status === 200) {
          if (contentType === "application/json") {
            result.json().then((sucData) => {
              console.log("sucData",sucData)
              setSuccessData(sucData);
              if (sucData.Upload_count >= 1) {
                setIsAlertOnUpload(true);
              }
            });
          } else {
            result.blob().then((blob) => {
              streamToCsv(blob);
              setIsAlertOnRejected(true);
            });
          }
        } else {
          setIsAlertOn(true);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const sucessMessage = () => {
    return (
      <>
        <p>
          {successData.Upload_count} User(s) Created Successfully
        </p>
      </>
    );
  };

  return (
    <div>
      <UserListUploadDialog
        show={isAlertOnUpload}
        modalClose={setIsAlertOnUpload}
        content={sucessMessage()}
        successCallback={handleUpload}
        setIsAddUserData={setIsAddUserData}
        heading="Info"
      />
      <UserListUploadDialog
        show={isAlertOnRejected}
        modalClose={setIsAlertOnRejected}
        content={
          "Profile user list uploaded partially please check the downloaded excel for details"
        }
        successCallback={handleUpload}
        setIsAddUserData={setIsAddUserData}
        heading="Info"
      />
      <InfoDialogconsortia
        show={isAlertOn}
        modalClose={setIsAlertOn}
        content={" Please select file for upload"}
        successCallback={handleUpload}
        heading="Info"
      />
      <div className="AddSubscriptionModelRow"></div>

      <div className="AddSubscriptionModelRow">
        <UserFileUpload name="file" handleInputchange={handleInputchange} />
      </div>

      <div className="UserUpload">
        <Button secondary onClick={handleExcelDownload} className="usr_uplds">
          Download Format
        </Button>

        <Button onClick={handleUpload} className="usr_uplds">
          Upload
        </Button>
      </div>
    </div>
  );
};

export default AddUserUploadData;
