import React from "react";

/**
 * @author
 * @function ViewOAResourceTable
 **/

const ViewOAResourceTable = ({ tableData, response }) => {
  return (
    <>
      {response && response.length >= 1 && (
        <div>
          <strong className="contactPersondata">
            Total Open Access resources assigned : {response && response.length}
          </strong>
        </div>
      )}

      <table className="table table-bordered table-striped">
        <thead className="summayTable">
          <tr>
            <th>SI.No</th>
            <th>Resource Name</th>
            <th>Start Date</th>
            <th>End Date</th>
          </tr>
        </thead>
        <tbody>
          {tableData &&
            tableData.map((data, index) => (
              <tr key={index} className="contactPersondata">
                {/* <td> {index + 1} </td> */}
                <td className="ddrtable"> {data.id} </td>
                <td dangerouslySetInnerHTML ={{__html:data.resource_name}}/>
                {/* <td>{data.resource_name}</td> */}
                <td className="ddrtable">{data.start_date}</td>
                <td className="ddrtable">{data.end_date}</td>
              </tr>
            ))}

          {!tableData && (
            <tr className="contactPersondata">
              <td colSpan='4' className="ddrtable">No Data Available</td>
            </tr>
          )}
        </tbody>
      </table>
    </>
  );
};

export default ViewOAResourceTable;
