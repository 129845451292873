import React from "react";
import "../default.css";
import { Form } from "react-bootstrap";
/**
 * @author
 * @function Radio
 **/

const CheckRadio = ({
  label,
  ariaLabel,
  name,
  checkedValue,
  value,
  handleInputchange,
  type,
}) => {
  const _ariaLabel = ariaLabel || label;
  const _type = type || "radio";
  return (
    <div className="Radio">
      <Form.Check
        type={_type}
        name={name}
        checked={!!checkedValue}
        value={value}
        label={label}
        aria-label={_ariaLabel}
        onChange={(e) => handleInputchange(e.target.value, name)}
      />
    </div>
  );
};

export default CheckRadio;
