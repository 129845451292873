import React, { Fragment, useState } from "react";
import { ContuLendingInfoDialog } from "../../atoms/Modals/ContuLendingInfoDialog";
import { getContuDdrDetails } from "../../containers/Reports/constants";
import { fetchUrl } from "../../utilities/apiFunctions/fatchUrl";
import ContuLendingILLDeliveredDataTable from "../Table/ContulendingILLDeliveredData";
import _ from "lodash";
import { Pagination } from "../ReportsComponents/Pagination";

const ContuLendingDataTable = ({ tableData, isIndexed }) => {
  const [isContLendingData, setIsContLendingData] = useState(false);
  const [iLLReferenceData, setILLReferenceData] = useState([]);
  const [data,] = useState({ ddr_reference_id: "" });

  iLLReferenceData &&
    iLLReferenceData?.forEach((item, ind) => {
      item.id = ind + 1;
    });

  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(15);
  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords =
    iLLReferenceData &&
    iLLReferenceData?.slice(indexOfFirstRecord, indexOfLastRecord);
  const nPages = Math.ceil(
    iLLReferenceData && iLLReferenceData?.length / recordsPerPage
  );

  const [isTableLoad, setIsTableLoad] = useState(false);

  const handleButton = async (illData) => {
    setIsContLendingData(true);
    setIsTableLoad(true)
    const _dat = _.cloneDeep(data);
    _dat["ddr_reference_id"] = illData?.reference_id;

    let str = Object.entries(_dat)
      .map(([key, val]) => `${key}${val ? "=" : ""}${val}`)
      .join("&");

    const response = await fetchUrl(getContuDdrDetails(str));
    setILLReferenceData(response.data[0]);
    setIsTableLoad(false);

  };

  return (
    <Fragment>
      <ContuLendingInfoDialog
        show={isContLendingData}
        modalClose={setIsContLendingData}
        content={
          <Fragment>
            <div>
              <ContuLendingILLDeliveredDataTable
                iLLReferenceData={currentRecords}
                isIndexed="true"
                isTableLoad={isTableLoad}
              />
            </div>
            <div>
              <Pagination
                nPages={nPages && nPages}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                setRecordsPerPage={setRecordsPerPage}
                tableDataReport={iLLReferenceData && iLLReferenceData}
                recordsPerPage={recordsPerPage}
              />
            </div>
          </Fragment>
        }
        successCallback={handleButton}
        heading="CONTU Details"
        size={"lg"}
        dialogClassName={"ContuTableModal"}
      />
      {/* <div>
        <Pagination
          nPages={nPages && nPages}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          setRecordsPerPage={setRecordsPerPage}
          tableDataReport={iLLReferenceData && iLLReferenceData}
          recordsPerPage={recordsPerPage}
        />
      </div> */}

      <table className="table table-bordered table-striped">
        <thead className="summayTable">
          <tr>
            <th>SI.No</th>
            <th>Journal Name</th>
            <th>ILL Delivered </th>
            <th>Balance</th>
          </tr>
        </thead>
        <tbody>
          {!tableData && !tableData && (
            <tr className="text-center contactPersondata">
              <td colSpan="4">
                <strong>No Data Available</strong>
              </td>
            </tr>
          )}
          {!!tableData &&
            tableData.map((data, index) => (
              <tr key={index} className="contactPersondata">
                {/* {isIndexed && <td className="ddrtable"> {index + 1} </td>} */}
                <td className="ddrtable"> {data.id} </td>
                <td>{data.resource_name}</td>
                <td className="ddrtable ">
                  <span
                    className="coutuTable"
                    onClick={() => handleButton(data)}
                  >
                    {data.ill_fulfilled}
                  </span>
                </td>
                <td className="ddrtable">{data.balance} </td>
              </tr>
            ))}
        </tbody>
      </table>
    </Fragment>
  );
};

export default ContuLendingDataTable;
