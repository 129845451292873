import React from "react";
import WidgetHeading from "../../atoms/Heading/WidgetHeading";
import JprogressBar from "../../atoms/ProgressBar/JProgressBar";

/**
 * @author
 * @function UsageSummaary
 **/

const UsageSummary = ({ usageSummaryData }) => {
  let TotalHits = usageSummaryData.data[0][0].find(
    (obj) => Object.keys(obj)[0] === "Total_Hits"
  );
  const Clr = ["success", "info", "warning", "danger", ""];
  const progressMapper = usageSummaryData.data[0][0].map((item) => {
    const val = (Object.values(item)[0] / Object.values(TotalHits)[0]) * 100;
    const data = {
      count: Object.values(item)[0],
      value: val,
      label: Object.keys(item)[0],
      varient:
        val > 90
          ? Clr[0]
          : val > 70
          ? Clr[1]
          : val > 30
          ? Clr[2]
          : val > 2
          ? Clr[3]
          : Clr[4],
    };

    return data;
  });

  return (
    <>
      <WidgetHeading heading="Usage Summary " />
      <div className="sliderWidget">
        <div>
          {progressMapper.map((item, index) => (
            <JprogressBar {...item} key={index} />
          ))}
        </div>
      </div>
    </>
  );
};

export default UsageSummary;
