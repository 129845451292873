import React, { useState, useEffect, Fragment } from "react";
import InputField from "../../atoms/InputField/InputField";
import _ from "lodash";
import Button from "react-bootstrap/Button";
import { ipDeleteByIndex } from "../../utilities/delete";
import Error from "../../atoms/Error/Error";
import { getIPRange } from "get-ip-range";
import { BiTrash, BiEdit } from "react-icons/bi";

const IpTableWithDelete = ({
  tableData,
  init,
  setData,
  loadedData,
  ipaddress4,
  ipaddress6,
  setIsIpUpdated,
}) => {
  const [orgTableData, setOrgTableData] = useState(init);
  const [orgTableDatas, setOrgTableDatas] = useState([]);
  const [ipFromCustTable, setIpFromCustTable] = useState([]);
  const [ipFromCustTable1, setIpFromCustTable1] = useState("");
  const _loadedData = loadedData || [];
  const [error, setError] = useState("");
  const [show, setShow] = useState(false);
  const [totalIPData, setTotalIPData] = useState([]);
  let IPContainerData = [];

  useEffect(() => {
    orgTableDatas &&
      orgTableDatas?.forEach((val) => {
        const getTotalIPRange =
          val?.fromip4 && val?.toip4 && getIPRange(val?.fromip4, val?.toip4);
        IPContainerData.push(getTotalIPRange);
      });
    setTotalIPData(IPContainerData);

  }, [orgTableDatas]);

  useEffect(() => {
    orgTableDatas &&
      orgTableDatas?.forEach((val) => {
        if (val?.fromip4?.includes(orgTableData.fromip4)) {
          setIpFromCustTable(val.fromip4);
        }
      });

    orgTableDatas &&
      orgTableDatas?.forEach((val) => {
        if (val?.toip4?.includes(orgTableData.toip4)) {
          setIpFromCustTable1(val.toip4);
        }
      });
  }, [orgTableData, orgTableDatas]);

  useEffect(() => {
    if (_loadedData.length > 0) {
      loadInitialData();
    }

  }, []);

  const expressionip4 =
    /((^\s*((([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5]))\s*$)|(^\s*((([0-9A-Fa-f]{1,4}:){7}([0-9A-Fa-f]{1,4}|:))|(([0-9A-Fa-f]{1,4}:){6}(:[0-9A-Fa-f]{1,4}|((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){5}(((:[0-9A-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){4}(((:[0-9A-Fa-f]{1,4}){1,3})|((:[0-9A-Fa-f]{1,4})?:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){3}(((:[0-9A-Fa-f]{1,4}){1,4})|((:[0-9A-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){2}(((:[0-9A-Fa-f]{1,4}){1,5})|((:[0-9A-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){1}(((:[0-9A-Fa-f]{1,4}){1,6})|((:[0-9A-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(:(((:[0-9A-Fa-f]{1,4}){1,7})|((:[0-9A-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:)))(%.+)?\s*$))/;
  var expressionip6 =
    /(([0-9a-fA-F]{1,4}:){7,7}[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,7}:|([0-9a-fA-F]{1,4}:){1,6}:[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,5}(:[0-9a-fA-F]{1,4}){1,2}|([0-9a-fA-F]{1,4}:){1,4}(:[0-9a-fA-F]{1,4}){1,3}|([0-9a-fA-F]{1,4}:){1,3}(:[0-9a-fA-F]{1,4}){1,4}|([0-9a-fA-F]{1,4}:){1,2}(:[0-9a-fA-F]{1,4}){1,5}|[0-9a-fA-F]{1,4}:((:[0-9a-fA-F]{1,4}){1,6})|:((:[0-9a-fA-F]{1,4}){1,7}|:)|fe80:(:[0-9a-fA-F]{0,4}){0,4}%[0-9a-zA-Z]{1,}|::(ffff(:0{1,4}){0,1}:){0,1}((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])|([0-9a-fA-F]{1,4}:){1,4}:((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9]))/;

  const loadInitialData = () => {
    setOrgTableDatas(_loadedData);
  };

  const handleInputchange = (value, name) => {
    const dynamic = _.cloneDeep(orgTableData);
    dynamic[name] = value;

    if (name === "fromip4") {
      if (dynamic["fromip4"] !== "undefined") {
        if (!expressionip4.test(dynamic["fromip4"])) {
          setError("Please enter valid IP");
        } else {
          setError("");
        }
        if (value === "") {
          setError("");
        }
      }
    }
    if (name === "toip4") {
      if (dynamic["toip4"] !== "undefined") {
        if (!expressionip4.test(dynamic["toip4"])) {
          setError("Please enter valid IP");
        } else {
          setError("");
        }

        if (value === "") {
          setError("");
        }
      }
    }

    setOrgTableData(dynamic);
  };

  var checkIps = function checkIps(addr) {
    var parts = addr
      ?.toString()
      .split(".")
      .map(function (str) {
        return parseInt(str);
      });
    return (
      (parts[0] ? parts[0] << 24 : 0) +
      (parts[1] ? parts[1] << 16 : 0) +
      (parts[2] ? parts[2] << 8 : 0) +
      parts[3]
    );
  };

  var checkIps2 = function checkIps(addr) {
    var parts = addr
      ?.toString()
      .split(".")
      .map(function (str) {
        return parseInt(str);
      });
    return (
      (parts[0] ? parts[0] << 24 : 0) +
      (parts[1] ? parts[1] << 16 : 0) +
      parts[2]
    );
  };

  var checkIps3 = function checkIps(addr) {
    var parts = addr
      ?.toString()
      .split(".")
      .map(function (str) {
        return parseInt(str);
      });
    return (parts[0] ? parts[0] << 24 : 0) + parts[1];
  };
  var checkIps4 = function checkIps(addr) {
    var parts = addr
      ?.toString()
      .split(".")
      .map(function (str) {
        return parseInt(str);
      });
    return parts[0];
  };

  var checkIpaddrInRange = function checkIpaddrInRange(start, end) {
    return checkIps(start) === checkIps(end);
  };

  var checkIpaddrInRangeEqual = function checkIpaddrInRange(start, end) {
    return checkIps(start) > checkIps(end);
  };

  var checkIpaddrInRangeEqual2 = function checkIpaddrInRange(start, end) {
    return checkIps2(start) > checkIps2(end);
  };
  var checkIpaddrInRangeEqual3 = function checkIpaddrInRange(start, end) {
    return checkIps3(start) > checkIps3(end);
  };
  var checkIpaddrInRangeEqual4 = function checkIpaddrInRange(start, end) {
    return checkIps4(start) > checkIps4(end);
  };
  var checkIpaddrInRangeEqual5 = function checkIpaddrInRange(start, end) {
    return checkIps4(start) < checkIps4(end);
  };

  var checkIpaddrInRangeEqual6 = function checkIpaddrInRange(start, end) {
    return checkIps3(start) < checkIps3(end);
  };

  var checkIpaddrInRangeEqual7 = function checkIpaddrInRange(start, end) {
    return checkIps2(start) < checkIps2(end);
  };

  let resultData = [];

  function recursivePushElem1(arr) {
    arr.forEach((i) => {
      if (Array.isArray(i)) recursivePushElem1(i);
      else resultData.push(i);
    });
  }
  recursivePushElem1(totalIPData);
  const handleButtonClick = () => {
    const obj = _.cloneDeep(orgTableData);

    if (ipaddress4) {
      if (checkIpaddrInRangeEqual(orgTableData?.fromip4, orgTableData?.toip4)) {
        setError(
          "Check the input IP range, From IP should be less than To IP range"
        );
      } else if (
        checkIpaddrInRangeEqual2(orgTableData?.fromip4, orgTableData?.toip4)
      ) {
        setError(
          "Check the input IP range, From IP should be less than To IP range"
        );
      } else if (
        checkIpaddrInRangeEqual3(orgTableData?.fromip4, orgTableData?.toip4)
      ) {
        setError("Check the input From IP address & To IP address");
      } else if (
        checkIpaddrInRangeEqual4(orgTableData?.fromip4, orgTableData?.toip4)
      ) {
        setError("Check the input From IP address & To IP address");
      } else if (
        checkIpaddrInRangeEqual5(orgTableData?.fromip4, orgTableData?.toip4)
      ) {
        setError("Check the input From IP address & To IP address");
      } else if (
        checkIpaddrInRangeEqual6(orgTableData?.fromip4, orgTableData?.toip4)
      ) {
        setError("Check the input From IP address & To IP address");
      } else if (
        checkIpaddrInRangeEqual7(orgTableData?.fromip4, orgTableData?.toip4)
      ) {
        setError("Check the input From IP address & To IP address");
      } else if (resultData.find((val) => val === obj?.fromip4)) {
        setError("Entered IP Range already exists in the Saved IP Ranges");
      } else if (resultData.find((val) => val === obj?.toip4)) {
        setError("Entered IP Range already exists in the Saved IP Ranges");
      } else if (checkIpaddrInRange(orgTableData?.fromip4, ipFromCustTable)) {
        setError("Entered IP Range already exists in the Saved IP Ranges");
      } else if (checkIpaddrInRange(orgTableData?.fromip4, ipFromCustTable1)) {
        setError("Entered IP Range already exists in the Saved IP Ranges");
      } else if (
        orgTableDatas &&
        orgTableDatas?.includes((val) => val === obj?.fromip4)
      ) {
        setError("Entered IP Range already exists in the Saved IP Ranges");
      } else if (
        orgTableDatas &&
        orgTableDatas?.includes((val) => val === obj?.toip4)
      ) {
        setError("Entered IP Range already exists in the Saved IP Ranges");
      } else if (
        expressionip6.test(obj.fromip4) ||
        expressionip6.test(obj.toip4)
      ) {
        setError("Please enter valid IP");
      } else if (expressionip4.test(obj.fromip4)) {
        setError("");
        update();
      } else if (expressionip4.test(obj.toip4)) {
        setError(" Please enter From Range");
      } else {
        setError("Please enter valid IP");
      }
    } else if (ipaddress6) {
      if (expressionip6.test(obj.fromip6) || expressionip6.test(obj.toip6)) {
        setError("Please enter valid IP");
      } else {
        setError("error on ip 6");
      }
    } else {
      setError("");
      update();
    }
  };

  const update = () => {
    const array = _.cloneDeep(orgTableDatas);
    const obj = _.cloneDeep(orgTableData);
    setIsIpUpdated(true);
    if (obj.fromip4 !== obj.toip4) {
      array.push(obj);
    } else {
      const ipdata = orgTableData.fromip4;
      array.push({ fromip4: ipdata });
    }
    setOrgTableDatas(array);
    setData(array);
    setOrgTableData(init);
  };
  const handleIpDeleteClick = async (index) => {
    const cloned = _.cloneDeep(orgTableDatas);
    await ipDeleteByIndex(cloned, index);
    setOrgTableDatas(cloned);
    setData(cloned);
  };

  const handleEditButton = async (index) => {
    const cloned = _.cloneDeep(orgTableDatas);
    await ipDeleteByIndex(cloned, index);
    setOrgTableDatas(cloned);
    setData(cloned);
    setOrgTableData(orgTableDatas[index]);
    setShow(true);
  };

  const handleUpdateButtonClick = () => {
    setIsIpUpdated(true);
    setShow(false);
    const array = _.cloneDeep(orgTableDatas);
    const obj = _.cloneDeep(orgTableData);
    if (obj.fromip4 !== obj.toip4) {
      array.push(obj);
    } else {
      const ipdata = orgTableData.fromip4;
      array.push({ fromip4: ipdata });
    }
    setOrgTableDatas(array);
    setData(array);
    setOrgTableData(init);
  };

  const handleIpUpdateButtonClick = () => {
    const obj = _.cloneDeep(orgTableData);

    if (ipaddress4) {
      if (checkIpaddrInRangeEqual(orgTableData?.fromip4, orgTableData?.toip4)) {
        setError(
          "Check the input IP range, From IP should be less than To IP range"
        );
      } else if (
        checkIpaddrInRangeEqual2(orgTableData?.fromip4, orgTableData?.toip4)
      ) {
        setError(
          "Check the input IP range, From IP should be less than To IP range"
        );
      } else if (
        checkIpaddrInRangeEqual3(orgTableData?.fromip4, orgTableData?.toip4)
      ) {
        setError("Check the input From IP address & To IP address");
      } else if (
        checkIpaddrInRangeEqual4(orgTableData?.fromip4, orgTableData?.toip4)
      ) {
        setError("Check the input From IP address & To IP address");
      } else if (
        checkIpaddrInRangeEqual5(orgTableData?.fromip4, orgTableData?.toip4)
      ) {
        setError("Check the input From IP address & To IP address");
      } else if (
        checkIpaddrInRangeEqual6(orgTableData?.fromip4, orgTableData?.toip4)
      ) {
        setError("Check the input From IP address & To IP address");
      } else if (
        checkIpaddrInRangeEqual7(orgTableData?.fromip4, orgTableData?.toip4)
      ) {
        setError("Check the input From IP address & To IP address");
      } else if (resultData.find((val) => val === obj?.fromip4)) {
        setError("Entered IP Range already exists in the Saved IP Ranges");
      } else if (resultData.find((val) => val === obj?.toip4)) {
        setError("Entered IP Range already exists in the Saved IP Ranges");
      } else if (
        expressionip6.test(obj.fromip4) ||
        expressionip6.test(obj.toip4)
      ) {
        setError("Please enter valid IP");
      } else if (expressionip4.test(obj.fromip4)) {
        setError("");
        handleUpdateButtonClick();
      } else if (expressionip4.test(obj.toip4)) {
        setError(" Please enter From Range");
      } else {
        setError("Please enter valid IP");
      }
    } else if (ipaddress6) {
      if (expressionip6.test(obj.fromip6) || expressionip6.test(obj.toip6)) {
        setError("Please enter valid IP");
      } else {
        setError("error on ip 6");
      }
    } else {
      setError("");
      handleUpdateButtonClick();
    }
  };

  const validateForm = () => {
    return error === "";
  };

  return (
    <>
      {!!error && <Error text={error} />}

      <div className="OrgTableInputFieldWrapper">
        {tableData.map((input, index) => (
          <Fragment key={index}>
            <InputField
              key={index}
              placeholder={input.value}
              name={input.key}
              value={orgTableData[input.key]}
              handleInputchange={handleInputchange}
              classname={input.key}
            />
          </Fragment>
        ))}
        {!show ? (
          <Button
            size="lg"
            className="ipAddBtn"
            type="submit"
            onClick={handleButtonClick}
            disabled={!validateForm()}
          >
            Add
          </Button>
        ) : (
          <Button
            size="lg"
            className="ipAddBtn"
            type="submit"
            onClick={handleIpUpdateButtonClick}
          >
            Update
          </Button>
        )}
      </div>

      <table className="table table-bordered table-striped">
        <thead>
          <tr className="summay_table">
            {tableData.map((head, index) => (
              <th key={index}>{head.value}</th>
            ))}
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {orgTableDatas.map((orgContact, index) => (
            <tr key={index}>
              {tableData.map((head, index) => (
                <td key={index}>{orgContact[head.key]}</td>
              ))}
              <td className="text-center">
                <BiTrash
                  className="deleteBtn"
                  onClick={() => handleIpDeleteClick(index)}
                />
                <BiEdit
                  className="deleteBtn ms-3"
                  onClick={() => handleEditButton(index)}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

export default IpTableWithDelete;
