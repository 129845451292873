import React, { useState } from "react";
import ViewOAResourceTable from "./ViewOAResourceTable";
import { Pagination } from "../ReportsComponents/Pagination";
import Loading from "../Loading/Loading";
/**
 * @author
 * @function ViewHoldingSummaryComponent
 **/

const ViewHoldingSummaryComponent = ({
  Data,
  tableProperty,
  // oaassigned,
  tableViewHoldingSummary,
}) => {
  const response = Data[0][0];
  response &&
    response.forEach((item, ind) => {
      item.id = ind + 1;
    });

  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(15);
  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords =
    response && response.slice(indexOfFirstRecord, indexOfLastRecord);
  const nPages = Math.ceil(response && response.length / recordsPerPage);

  // const filterredTableData = (data) =>
  //   data.filter((item) => item.oaassigned === true);

  // const tableData = oaassigned
  //   ? filterredTableData(Data[0][0] ? Data[0][0] : [])
  //   : Data[0][0];

  return (
    <>
      {currentRecords && currentRecords.loading && <Loading />}
      <ViewOAResourceTable
        tableProperty={tableProperty}
        // tableData={tableData}
        tableData={currentRecords && currentRecords}
        tableViewHoldingSummary={tableViewHoldingSummary}
        response={response}
      />

      <div className="pgnation">
        <Pagination
          nPages={nPages && nPages}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          setRecordsPerPage={setRecordsPerPage}
          tableDataReport={response && response}
          recordsPerPage={recordsPerPage}
        />
      </div>
    </>
  );
};

export default ViewHoldingSummaryComponent;
