import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { getLocalStorage } from "../../utilities/storage";
import LibraryAdmin from "./LibraryAdmin";
import ConsortiaAdmin from "./ConsortiaAdmin";


const Dashboard = () => {
  let history = useHistory();
  const { customerID, admin_type } = getLocalStorage("user");
  const { consortiamaster_id , datatypes, subject} = getLocalStorage("customer") || {};

  const dataType_Ids = datatypes?.map((val)=>val.dat_resourcetype_id).join(" OR ")
  const subJect_Ids = subject?.map((val)=>val.datsubjectmasterlevel1_id)
  const finalSubject_Ids = [...new Set(subJect_Ids)].join(" OR ");

  useEffect(() => {
    history.push("/dashboard");
  }, [history]);

  return (
    <>
      {admin_type === "library_admin" && (
        <LibraryAdmin
          customerID={customerID}
          consortiamaster_id={consortiamaster_id}
          admin_type={admin_type}
          dataType_Ids={dataType_Ids}
          finalSubject_Ids={finalSubject_Ids}
        />
      )}

      {admin_type !== "library_admin" && (
        <ConsortiaAdmin
          customerID={customerID}
          consortiamaster_id={consortiamaster_id}
          admin_type={admin_type}
          dataType_Ids={dataType_Ids}
          finalSubject_Ids={finalSubject_Ids}
        />
      )}
    </>
  );
};

export default Dashboard;
