import React, { Fragment } from "react";
import Loading from "../../components/Loading/Loading";
import {
  getAggregatorMasterListUrl,
  getConsortiaMemberListUrl,
  getPackagesListAvailableForConsortiaUrl,
} from "../../utilities/constants";
import { useFetch } from "../../utilities/apiFunctions/useFetch";
import ManageConsortiaSubscription from "./ManageConsortiaSubscription";

/**
 * @author
 * @function ManageConsortiaContainer
 **/
const ManageConsortiaContainer = ({
  customerID,
  consortiamaster_id,
}) => {
  const aggregatorMasterList = useFetch(getAggregatorMasterListUrl);

  const packagesListAvailableForConsortia = useFetch(
    getPackagesListAvailableForConsortiaUrl(consortiamaster_id)
  );
  const consortiaMemberList = useFetch(
    getConsortiaMemberListUrl(consortiamaster_id)
  );
  return (
    <Fragment>
      {(packagesListAvailableForConsortia.isLoading ||
        consortiaMemberList.isLoading) && <Loading />}
      <ManageConsortiaSubscription
        customerID={customerID}
        consortiamaster_id={consortiamaster_id}
        aggregatorMasterList={aggregatorMasterList && aggregatorMasterList}
        consortiaMemberList={consortiaMemberList && consortiaMemberList?.response && consortiaMemberList?.response?.data}
        packagesListAvailableForConsortia={
          packagesListAvailableForConsortia && packagesListAvailableForConsortia?.response && packagesListAvailableForConsortia?.response?.data
        }
      />
    </Fragment>
  );
};

export default ManageConsortiaContainer;
