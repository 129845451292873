import React, { useState } from "react";
import _ from "lodash";
import InputArea from "../../atoms/InputArea/InputArea";
import { updateConsortiaDDRForLibrarianEmail } from "../../containers/DDR/constants";
import { DeliveryNoteDDRDiscardTemplate } from "./DeliveryNoteDDRDiscardTemplate";
import { DDRDiscardStatusModal } from "./DDRDiscardStatusModel";

const DDRDiscardlMailTemplate = ({ modalClose, fnData, getDDR, customerID }) => {
  const [data, setData] = useState(fnData);
  const [isContuMessage, setIsContuMessage] = useState("");
  const [isDeliveryNote, setIsDeliveryNote] = useState(false);


  const handleInputchange = (value, name) => {
    const _dat = _.cloneDeep(data);
    _dat[name] = value;
    setData(_dat);

    if (name === "contu_message") {
      setIsContuMessage(value)
    }
  };

  const handleApi = async () => {
    const _dat = _.cloneDeep(data);
    const _data = {};
    _data["consortiaddr_id"] = getDDR;
    _data["ddr_status"] = "5";
    _data["mode_of_delivery"] = null;
    _data["delivered_date"] = null;
    _data["ddr_reference_no"] = _dat["mail_subject"];
    _data["delivery_remarks"] = null;
    _data["courier_no"] = _dat["courier_no"] || null;
    _data["delay_reason"] = null;
    _data["discard_reason"] = _dat["contu_message"];
    _data["delivering_userid"] = customerID;
    _data["is_duplicate"] = false;

    const str = `{"data":[${JSON.stringify(_data)}]}`;

    const formData = new FormData();

    formData.append("detailjson", str);
    formData.append("attachment_file", null);
    formData.append("To_mail_Id", _dat["to_mail"]);
    formData.append("CC_mail_Id", _dat["cc_email"] + ";" + _dat["approver_admin_mail"]);
    // formData.append("To_mail_Id", "shylaja@informaticsglobal.com");
    // formData.append("CC_mail_Id", "shylabhatt@gmail.com");
    formData.append("Subject", _dat["mail_subject"]);
    formData.append("Message", _dat["contu_message"]);

    fetch(updateConsortiaDDRForLibrarianEmail, {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then((result) => {
        if (result.data[0][0] === "Success") {
          setIsDeliveryNote(true);
        } else {
          alert("Something went wrong")
          setIsDeliveryNote(false);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const handleCancel = () => {
    modalClose();
  };
  const handleSubmit = () => {
    handleApi();
  };

  const handleSubmitButtonDisabled = () => {
    return isContuMessage.length <= 0
  }

  const modalData = (
    <DeliveryNoteDDRDiscardTemplate
      emailData={data && data}
      modalClose={modalClose}
    />
  );

  return (
    <div>
      < DDRDiscardStatusModal
        show={isDeliveryNote}
        modalHandle={setIsDeliveryNote}
        data={modalData}
        size="lg"
        heading="Discard reason has been saved successfully"
      />
      <div>
        <span className="input_label_clr">Discard Reason</span> <InputArea
          lebel={"Discard Reason1"}
          name={"contu_message"}
          value={data["contu_message"] || ""}
          handleInputchange={handleInputchange}
          classname={`w-100-p delivery_date`}
          parentclassname="w-100-p"
        />
      </div>

      <div className="btn_group ddr_statu_mail_btns">
        <div>
          <button
            type="submit"
            className="btn btn-primary"
            onClick={() => handleSubmit()}
            disabled={handleSubmitButtonDisabled()}
          >
            Save
          </button>
        </div>
        <div>
          <button
            type="submit"
            className="btn btn-secondary ddr_statu_btns"
            onClick={() => handleCancel()}
          >
            Cancel
          </button>
        </div>

      </div>
    </div>
  );
};

export default DDRDiscardlMailTemplate;
