import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import "./DropDown.css";
import { removeAllStorage, removeLocalStorage } from "../../utilities/storage";
import { SaveModal } from "../Modals/SaveModal";
import ResetPassword from "../../containers/Login/ResetPassword";
import Logout from "../../containers/Login/Logout";
import ProfileImgSection from "../../components/OrgComponents/ProfileImage";
import Dummy_user from "../../assets/img/user.png";
import { baseUrl } from "../../utilities/constants";

const HeaderDropDownMenu = ({ customerID, userID }) => {
  const [isSave, setIsSave] = useState(false);
  const [isLogout, setIsLogout] = useState(false);
  const [profile, setProfile] = useState(false);
  const [imgUrl, setImgUrl] = useState({});

  const handleMenuClick = () => {
    setProfile(true);
  };

  useEffect(() => {
    fetch(
      `${baseUrl}/api/get_librarian_profile_image/get_librarian_profile_image?customer_id=${customerID}&user_id=${userID}`
    )
      .then((val) => val.json())
      .then((res) => {
        setImgUrl(res.data[0][0].image_path);
      });
  }, [customerID, userID]);

  const logout = () => {
    removeLocalStorage("user");
    removeLocalStorage("customer");
    removeAllStorage();
    window.location.href = "/";
  };

  const handleLogout = () => {
    logout();
  };

  return (
    <div className="BasicDropDown">
      <SaveModal
        show={isSave}
        modalHandle={setIsSave}
        data={<ResetPassword logoutData={logout} />}
        dialogClassName="successModal"
        heading={"Reset password"}
        size="md"
      />
      <SaveModal
        show={profile}
        modalHandle={setProfile}
        data={<ProfileImgSection userID={userID} customerID={customerID} />}
        dialogClassName="successModal"
        heading={"My Profile"}
        size="lg"
      />
      <SaveModal
        show={isLogout}
        modalHandle={setIsLogout}
        data={<Logout />}
        dialogClassName="successModal"
        heading={"LogOut"}
        size="md"
      />
      <Dropdown>
        <Dropdown.Toggle
          variant="custom"
          id="dropdown-basic"
          className="test-basic"
        >
          <input
            type="file"
            className="wropper"
            accept="image/*"
            id="file"
            style={{ display: "none" }}
          />
          {(imgUrl === "") || (imgUrl == null) ? (
            <img
              className="wropper"
              alt=""
              src={imgUrl ? imgUrl : Dummy_user}
            />
          ) : (
            <img className="wropper" alt="" src={imgUrl} />
          )}
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <Dropdown.Item
            onClick={(e) => handleMenuClick()}
            className="headerDrpDwn"
          >
            My Profile
          </Dropdown.Item>
          <Dropdown.Item
            onClick={(e) => handleLogout()}
            className="headerDrpDwn"
          >
            Logout
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default HeaderDropDownMenu;
