// install (please make sure versions match peerDependencies)
// yarn add @nivo/core @nivo/bar
import React from "react";
import { ResponsiveBar } from "@nivo/bar";
// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.

export default function BarCharts({ ddrUsageData }) {
  const datas = ddrUsageData.data[0][0];

  const data = [
    {
      DDR: " Received Vs Fullfilled",
      "Sent/Received": datas.received_count,
      "Sent/ReceivedColor": "hsl(235, 70%, 50%)",
      Fullfilled: datas.fulfiled_received_count,
      FullfilledColor: "hsl(147, 70%, 50%)",
    },
    {
      DDR: "Sent Vs Fullfilled",
      "Sent/Received": datas.sent_count,
      "Sent/ReceivedColor": "hsl(235, 70%, 50%)",
      Fullfilled: datas.fulfiled_sent_count,
      FullfilledColor: "hsl(147, 70%, 50%)",
    },
  ];

  return (
    <>

      <div className="chartDiv">
        <ResponsiveBar
          data={data}
          keys={["Fullfilled", "Sent/Received"]}
          indexBy="DDR"
          margin={{ top: 20, right: 170, bottom: 40, left: 60 }}
          padding={0.3}
          valueScale={{ type: "linear" }}
          indexScale={{ type: "band", round: true }}
          colors={{ scheme: "nivo" }}
          defs={[
            {
              id: "dots",
              type: "patternDots",
              background: "inherit",
              color: "#38bcb2",
              size: 4,
              padding: 1,
              stagger: true,
            },
            {
              id: "lines",
              type: "patternLines",
              background: "inherit",
              color: "#eed312",
              rotation: -45,
              lineWidth: 6,
              spacing: 10,
            },
          ]}
          borderColor={{ from: "color", modifiers: [["darker", 1.6]] }}
          axisTop={null}
          axisRight={null}
          axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: "DDR",
            legendPosition: "middle",
            legendOffset: 32,
          }}
          axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: "DDR",
            legendPosition: "middle",
            legendOffset: -40,
          }}
          labelSkipWidth={12}
          labelSkipHeight={12}
          labelTextColor={{ from: "color", modifiers: [["darker", 1.6]] }}
          legends={[
            {
              dataFrom: "keys",
              anchor: "top-right",
              direction: "column",
              justify: false,
              translateX: 120,
              translateY: 0,
              itemsSpacing: 2,
              itemWidth: 100,
              itemHeight: 18,
              itemDirection: "left-to-right",
              itemOpacity: 0.85,
              symbolSize: 15,
              effects: [
                {
                  on: "hover",
                  style: {
                    itemOpacity: 1,
                  },
                },
              ],
            },
          ]}
          animate={true}
          motionStiffness={90}
          motionDamping={15}
        />
      </div>
    </>
  );
}
