import React from "react";
/**
 * @author
 * @function UsageMatrics
 **/

const ConferenceUsageReport = ({ conferenceDetailsTable }) => {
  return (
    <>
      <div>
        <table className="table table-bordered table-striped">
          <thead className="summayTable">
            <tr>
              <th>SI.No</th>
              <th>Conference/Proceeding Name</th>
              <th>Hits</th>
            </tr>
          </thead>
          <tbody>
            {!!conferenceDetailsTable &&
              conferenceDetailsTable.map((data, index) => (
                <tr key={index} className="UsageMetricsTr">
                  <td className="text-center"> {index + 1} </td>
                  <td className={"metrics" + index}>{Object.keys(data)[0]}</td>
                  <td className="text-center">{Object.values(data)[0]}</td>
                </tr>
              ))}
            {!conferenceDetailsTable && (
              <tr className="text-center fw-bold">
                <td colSpan="3" className="contactPersondata">
                  No Data Available
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default ConferenceUsageReport;
