import _ from "lodash";
import React, { useState, useEffect, Fragment } from "react";
import { Button } from "react-bootstrap";
import InputField from "../../atoms/InputField/InputField";
import { getPasswordValuesFromData } from "../../utilities/mapperFunctions";
import OrgSectionHeading from "./OrgSectionHeading";
import { getUserAvailability, ResetAdminUserLoginPasswordbyAdmin, ResetScholarUserPasswordbyAdmin, } from "../../utilities/constants";
import { InfoDialogconsortia } from "../../atoms/Modals/InfoDialogconsortis";
import ErrorMsg from "../../atoms/Error/Error";
import ValidatinMessage from "../../atoms/Error/Error";


const OrgModuleLogin = ({ setModuleLogin, customerDetails, customerID }) => {
  const scholarModuleKey = { scholarusername: "", scholarpassword: "" };
  const adminModuleKey = { adminusername: "", adminpassword: "" };
  const [scholarModule, setScholarModule] = useState(scholarModuleKey);
  const [adminModule, setAdminModule] = useState(adminModuleKey);
  const [isSave, setIsSave] = useState(false);
  const [isSaveScholarPassword, setIsSaveScholarPassword] = useState(false);
  const [error, setError] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const scholarModuleHeading = "Scholar Module Credentials";
  const adminModuleHeading = "Admin Module Credentials";

  const scholarModuleData = [
    { key: "scholarusername", value: "User Name" },
    { key: "scholarpassword", value: "Password" },
  ];
  const adminModuleData = [
    { key: "adminusername", value: "User Name" },
    { key: "adminpassword", value: "Password" },
  ];


  useEffect(() => {
    getValuesFromDatas();

  }, []);

  useEffect(() => {
    if (scholarModule["scholarusername"] === adminModule["adminusername"]) {
      setError("Scholar & Admin username cannot be same");
    }
    else if (scholarModule["scholarpassword"].includes(" ")) {
      setError("Space is not allowed");
    } else if (scholarModule["scholarpassword"].includes(".")) {
      setError("Dot is not allowed");
    } else if (scholarModule["scholarpassword"].length > 12) {
      setError("Must be 6-12 characters long");
    }
    else if ((scholarModule["scholarpassword"].length >= 1) && (scholarModule["scholarpassword"].length < 6)) {
      setError("Must be 6-12 characters long");
    } else {
      setError("");
    }
  }, [scholarModule])

  useEffect(() => {
    if (adminModule["adminusername"] === scholarModule["scholarusername"]) {
      setErrorMsg("Scholar & Admin username cannot be same");
    } else if (adminModule["adminpassword"].includes(" ")) {
      setErrorMsg("Space is not allowed");
    } else if (adminModule["adminpassword"].includes(".")) {
      setErrorMsg("Dot is not allowed")
    } else if (adminModule["adminpassword"].length > 12) {
      setErrorMsg("Must be 6-12 characters long");
    } else if ((adminModule["adminpassword"].length >= 1) && (adminModule["adminpassword"].length < 6)) {
      setErrorMsg("Must be 6-12 characters long");
    } else {
      setErrorMsg("");
    }
  }, [adminModule])


  useEffect(() => {
    const moduleData = { ...scholarModule, ...adminModule };
    setModuleLogin(moduleData);

  }, [scholarModule, adminModule]);

  const getValuesFromDatas = async () => {
    const _customerDetails = _.cloneDeep(customerDetails.data[0][0]);
    const _scholarModule = _.cloneDeep(scholarModule);
    const _adminModule = _.cloneDeep(adminModule);

    const __scholarModuleData = await getPasswordValuesFromData(
      _customerDetails,
      _scholarModule
    );
    const __adminModuleData = await getPasswordValuesFromData(
      _customerDetails,
      _adminModule
    );

    setScholarModule(__scholarModuleData);
    setAdminModule(__adminModuleData);
  };

  const handleInputchange = (value, name) => {
    const dynamic = _.cloneDeep(scholarModule);
    dynamic[name] = value;
    setScholarModule(dynamic);
  };

  const handleInputchangeAdmin = (value, name) => {
    const dynamic = _.cloneDeep(adminModule);
    dynamic[name] = value;
    setAdminModule(dynamic);
  };

  const sucessData = () => {
    const data_customer = customerDetails?.data[0][0]?.contactdetails;
    const primaryContactEmails = data_customer && data_customer.map((val) => val.access_type === "1" ? val.email : "");
    const finalEmails = primaryContactEmails.join(", ")

    return (
      <>
        <div>
          <p>Admin password changed sucessfully</p>
          <p>New Admin credentials are sent to <strong>{finalEmails}</strong></p>
        </div>
      </>
    );
  };

  const scholarSucessData = () => {
    const data_customer = customerDetails?.data[0][0]?.contactdetails;
    const primaryContactEmails = data_customer && data_customer.map((val) => val.access_type === "1" ? val.email : "");
    const finalEmails = primaryContactEmails.join(", ")

    return (
      <>
        <div>
          <p>Scholar password changed sucessfully</p>
          <p>New Scholar credentials are sent to <strong>{finalEmails}</strong></p>
        </div>
      </>
    );
  };

  const handleScalarUser = async () => {
    await fetch(getUserAvailability(scholarModule.scholarusername))
      .then((res) => res.json())
      .then((val) => {
        val.data[0][0] === "User name already exists..." &&
          alert(
            "Scholar user name already exists please enter different user name"
          );
      });
  };

  const handleAdminUser = async () => {
    await fetch(getUserAvailability(adminModule.adminusername))
      .then((res) => res.json())
      .then((val) => {
        val.data[0][0] === "User name already exists..." &&
          alert(
            "Admin user name already exists please enter different user name"
          );
      });
  };

  const handleAdminResetPassword = async () => {
    const formData = new FormData();
    formData.append("customer_id", customerID);
    formData.append("user_name", adminModule.adminusername);
    formData.append("new_password", adminModule.adminpassword);

    await fetch(ResetAdminUserLoginPasswordbyAdmin, {
      method: "POST",
      body: formData,
    })
      .then((response) => response)
      .then((result) => {
        if (result && result.message !== "Success") {
          setIsSave(true);
          setAdminModule({
            adminpassword: "",
            adminusername: adminModule.adminusername,
          });
        } else {
          alert("Somthing went wrong....");
        }
      });
  };
  const handleScholarResetPassword = async () => {
    const formData = new FormData();
    formData.append("customer_id", customerID);
    formData.append("user_name", scholarModule.scholarusername);
    formData.append("new_password", scholarModule.scholarpassword);

    await fetch(ResetScholarUserPasswordbyAdmin, {
      method: "POST",
      body: formData,
    })
      .then((response) => response)
      .then((result) => {
        if (result && result.message !== "Success") {
          setIsSaveScholarPassword(true);
          setScholarModule({
            scholarpassword: "",
            scholarusername: scholarModule.scholarusername,
          });
        } else {
          alert("Somthing went wrong....");
        }
      });
  };

  const scholarPasswordValidateForm = () => {
    return (
      !error && scholarModule["scholarpassword"].length !== 0)

  };
  const adminPasswordValidateForm = () => {
    return !errorMsg && adminModule["adminpassword"].length !== 0;

  };

  return (
    <section className="OrgModule">
      <InfoDialogconsortia
        show={isSave}
        modalClose={setIsSave}
        content={sucessData()}
        successCallback={handleAdminResetPassword}
        heading="Info"
        size="lg"
      />
      <InfoDialogconsortia
        show={isSaveScholarPassword}
        modalClose={setIsSaveScholarPassword}
        content={scholarSucessData()}
        successCallback={handleScholarResetPassword}
        heading="Info"
        size="lg"
      />
      <div className="OrgModuleScholar">
        <OrgSectionHeading heading={scholarModuleHeading} />
        <div className="d-flex">
          <div>{error && <ValidatinMessage text={error} />}</div>
        </div>

        {scholarModuleData.map((input, index) => (
          <Fragment key={index}>
            <InputField
              key={index}
              placeholder={input.value}
              name={input.key}
              value={scholarModule[input.key] || ""}
              handleInputchange={handleInputchange}
              classname={`w-200 ${input.key}`}
            />
            {input.key === "scholarusername" && (
              <Button
                className="me-2 contactPersondata"
                onClick={handleScalarUser}
              >
                Check
              </Button>
            )}
            {input.key === "scholarpassword" && (
              <Button
                onClick={handleScholarResetPassword}
                disabled={!scholarPasswordValidateForm()}
                className="contactPersondata"
              >
                Reset Password
              </Button>
            )}
          </Fragment>
        ))}
      </div>

      <div className="OrgModuleAdmin">
        <OrgSectionHeading heading={adminModuleHeading} />
        <div>{errorMsg && <ErrorMsg text={errorMsg} />}</div>
        {adminModuleData.map((input, index) => (
          <Fragment key={index}>
            <InputField
              key={index}
              placeholder={input.value}
              name={input.key}
              value={adminModule[input.key] || ""}
              handleInputchange={handleInputchangeAdmin}
              classname={`w-180`}
            />
            {input.key === "adminusername" && (
              <Button
                className="me-2 contactPersondata"
                onClick={handleAdminUser}
              >
                Check
              </Button>
            )}
            {input.key === "adminpassword" && (
              <Button
                onClick={handleAdminResetPassword}
                disabled={!adminPasswordValidateForm()}
                className="contactPersondata"
              >
                Reset Password
              </Button>
            )}
          </Fragment>
        ))}
      </div>
    </section>
  );
};

export default OrgModuleLogin;
