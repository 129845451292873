import React, { useState, useEffect } from "react";
import _ from "lodash";
import { Button } from "react-bootstrap";
import CommonHeading from "../../atoms/Heading/CommonHeading";
import CheckRadio from "../../atoms/Radio/CheckRadio";
import { formatedObject } from "../../containers/Reports/constants";
import { addNewMembersToconsortiaPackageUrl } from "../../utilities/constants";
import { fetchUrl } from "../../utilities/apiFunctions/fatchUrl";
import { InfoDialogConsortia } from "../../atoms/Modals/InfoDialogConsortia";
import { InfoDialogconsortia } from "../../atoms/Modals/InfoDialogconsortis";



const ConsortiaAddNewPackages = ({
  packagesListAvailableForConsortia,
  consortiaMemberList,
  consortiamaster_id,
  fnClose,
}) => {

  const [rightHandSide, setRightHandSide] = useState([]);
  const [leftHandSide, setLeftHandSide] = useState([]);
  const [isAssign, setIsAssign] = useState(false);
  const [content, setContent] = useState(false);
  const [addContent, setAddContent] = useState(false);
  const [checkedData, setCheckedData] = useState(false);
  const [isSelectPackage, setIsSelectPackage] = useState(false);
  const [isSelectRightSidePackage, setIsSelectRightSidePackage] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [leftSelected, setLeftSelected] = useState(new Set());
  const [rightSelected, setRightSelected] = useState(new Set());
  const [memberSelected, setMemberSelected] = useState(new Set());

  const memberList = formatedObject(consortiaMemberList);

  const [keyword, setKeyword] = useState("");
  const [newData, setNewData] = useState("");

  memberSelected.delete("");

  useEffect(() => {
    setNewData(memberList);
    const initialData = formatedObject(packagesListAvailableForConsortia);
    setRightHandSide([]);
    setLeftHandSide(initialData);

  }, []);

  const handleInputchangeLeft = (value) => {
    const _dataLS = _.cloneDeep(leftSelected);
    const mySet1 = new Set(_dataLS);
    if (mySet1.has(value)) {
      mySet1.delete(value);
    } else {
      mySet1.add(value);
    }
    setLeftSelected(mySet1);
  };

  const handleInputchangeRight = (value) => {
    const _dataLS = _.cloneDeep(rightSelected);
    const mySet1 = new Set(_dataLS);
    if (mySet1.has(value)) {
      mySet1.delete(value);
    } else {
      mySet1.add(value);
    }
    setRightSelected(mySet1);
  };
  const handleAssign = async () => {
    const _packageID = JSON.stringify(rightHandSide.map((item) => item.value));
    const _memberID = JSON.stringify(Array.from(memberSelected).map((item) => item));
    const strUrl = `{"package_id":${_packageID},"consortia_id":${consortiamaster_id},"member_id":${_memberID}}`;
    const strUrl2 = `{"package_id":${_packageID},"consortia_id":${consortiamaster_id},"member_id":[${consortiamaster_id}]}`;

    if (rightHandSide.length <= 0) {
      setAddContent(true);
    }

    if (memberSelected.size <= 0 && rightHandSide.length >= 1) {
      const response2 = await fetchUrl(addNewMembersToconsortiaPackageUrl(strUrl2));
      if (response2 && response2.data && response2.data[0][0]) {
        setIsAdmin(true);
      }
    }

    if (memberSelected.size >= 1 && rightHandSide.length >= 1) {
      const response = await fetchUrl(addNewMembersToconsortiaPackageUrl(strUrl));
      if (
        response &&
        response.data &&
        response.data[0][0] === "Selected packages added succesfully!"
      ) {
        setIsAssign(true);
      }
    }
  };

  const handleMoveSelectedRight = () => {
    const _dataL = _.cloneDeep(leftHandSide);
    const _dataLS = _.cloneDeep(leftSelected);
    if (leftSelected.size >= 1) {
      const _dataObj = _dataL.filter((item) => _dataLS.has(String(item.value))).map((item) => {
        const data = { ...item, selected: true };
        return data;
      });
      const _dataRemObj = _dataL.filter((item) => _dataLS.has(String(item.value)) === false);
      const _dataR = [..._dataObj, ...rightHandSide];

      setLeftHandSide(_dataRemObj);
      setRightHandSide(_dataR);
      setLeftSelected(new Set());
    } else {
      setIsSelectPackage(true);
    }
  };

  const handleRemoveSelectedRight = () => {
    const _dataR = _.cloneDeep(rightHandSide);
    const _dataRS = _.cloneDeep(rightSelected);
    if (rightSelected.size >= 1) {
      const _dataObj = _dataR
        .filter((item) => _dataRS.has(String(item.value)))
        .map((item) => {
          const data = { ...item, selected: true };
          return data;
        });
      const _dataRemObj = _dataR.filter(
        (item) => _dataRS.has(String(item.value)) === false
      );

      const _dataL = [..._dataObj, ...leftHandSide];

      setRightHandSide(_dataRemObj);
      setLeftHandSide(_dataL);
      setRightSelected(new Set());
    } else {
      setIsSelectRightSidePackage(true);
    }
  };
  const clearPackage = () => {
    const _dataR = _.cloneDeep(rightHandSide);
    const changeDR = _dataR.map((item) => {
      const data = { ...item, selected: false };
      return data;
    });
    const _dataL = [...changeDR, ...leftHandSide];
    setLeftHandSide(_dataL);
    setRightHandSide([]);
  };

  const handleInputchangeMember = (value, name) => {
    const _dataMember = _.cloneDeep(memberSelected);
    const mySet1 = new Set(_dataMember);
    if (mySet1.has(value)) {
      mySet1.delete(value);
      setCheckedData(false);
    } else {
      mySet1.add(value);
      if (memberList.length === mySet1.size) {
        setCheckedData(true);
      }
    }
    setMemberSelected(mySet1);
  };

  const handleSelectAllCheckBox = (value) => {
    const memberdata = memberList && memberList.map((item) => item.value);
    const _dataMember = _.cloneDeep(memberdata);
    const mySet1 = new Set(_dataMember);
    if (mySet1.has(value)) {
      mySet1.delete(value);
    } else {
      mySet1.add(value);
      setMemberSelected(mySet1);
      setCheckedData(!checkedData);
    }
    if (checkedData) {
      setMemberSelected(new Set());
    }
  };

  const handlebutton = (e) => {
    setKeyword(e.target.value);
  };

  const member_data = newData && newData.filter((item) => {
    if (keyword !== "") {
      return item.label.toLowerCase().includes(keyword.toLowerCase());
    }
    return item;
  });

  const clearAssignedMembers = () => {
    setMemberSelected(new Set());
    setCheckedData(false);
  };

  const hadleClearSearch = () => {
    setKeyword("");
  };

  return (
    <div>
      <InfoDialogConsortia
        show={isAssign}
        modalClose={setIsAssign}
        content={
          "Packages are assigned successfully to the preferred Member(s)"
        }
        successCallback={handleAssign}
        heading="Info"
        handle_close={fnClose}
      />
      <InfoDialogConsortia
        show={isAdmin}
        modalClose={setIsAdmin}
        content={"Packages are assigned successfully to the Consortia"}
        successCallback={handleAssign}
        heading="Info"
        handle_close={fnClose}
      />
      <InfoDialogconsortia
        show={content}
        modalClose={setContent}
        content={"Kindly select at least one Member before Assigning"}
        successCallback={handleAssign}
        heading="Info"
        handle_close={fnClose}
      />
      <InfoDialogconsortia
        show={addContent}
        modalClose={setAddContent}
        content={"Kindly select at least one Package before Assigning"}
        successCallback={handleAssign}
        heading="Info"
        handle_close={fnClose}
      />
      <InfoDialogconsortia
        show={isSelectPackage}
        modalClose={setIsSelectPackage}
        content={"Kindly select at least one Package before Adding"}
        successCallback={handleAssign}
        heading="Info"
        handle_close={fnClose}
      />
      <InfoDialogconsortia
        show={isSelectRightSidePackage}
        modalClose={setIsSelectRightSidePackage}
        content={"Kindly select at least one Package before De-Select"}
        successCallback={handleAssign}
        heading="Info"
        handle_close={fnClose}
      />
      <div className="row">
        <div className="col-md-6">
          <CommonHeading heading="Add New Packages" />
          <div className="listOfpackageHeading contactPersondata">
            List of Packages­­­­­
          </div>
          <div
            id="style-1"
            className="ConsortiaAddNewPackagesColmn1 scrollable"
          >
            {leftHandSide && leftHandSide.sort().map((item, index) => (
              <div key={index}>
                <CheckRadio
                  label={item.label}
                  type="checkbox"
                  checkedValue={leftSelected.has(String(item.value))}
                  value={item.value}
                  name={`left${index}`}
                  handleInputchange={handleInputchangeLeft}
                />
              </div>
            ))}
          </div>
          <div className="p-3">
            <Button
              variant="secondary contactPersondata"
              onClick={clearPackage}
            >
              Clear
            </Button>
            <Button
              onClick={handleMoveSelectedRight}
              className="contactPersondata"
            >
              Add
            </Button>
          </div>
        </div>
        <div className="col-md-6">
          <CommonHeading heading="Selected Packages " />
          <div className="ConsortiaAddNewPackagesColmn2 scrollable">
            {rightHandSide && rightHandSide.map((item, index) => (
              <div key={index}>
                <CheckRadio
                  label={item.label}
                  type="checkbox"
                  checkedValue={rightSelected.has(String(item.value))}
                  value={item.value}
                  name={`left${index}`}
                  handleInputchange={handleInputchangeRight}
                />
              </div>
            ))}
          </div>
          {rightHandSide.length > 0 && (
            <div className="p-3">
              <Button
                variant="secondary mt-3 contactPersondata"
                onClick={handleRemoveSelectedRight}
              >
                De-Select
              </Button>
            </div>
          )}
        </div>
      </div>
      <hr></hr>
      <div className="row">
        <div className="col-md-9">
          <CommonHeading heading="Assign to Members" />
          <div>
            <input
              type="text"
              className="w-75"
              placeholder="Search here"
              value={keyword}
              onChange={handlebutton}
            />
            {keyword.length > 0 && (
              <div className="searchclear" onClick={hadleClearSearch}>
                <span>X</span>
              </div>
            )}
          </div>
          <div
            id="style-1"
            className="ConsortiaAddNewPackagesColmn3 scrollable"
          >
            <CheckRadio
              label="Select All"
              type="checkbox"
              checkedValue={checkedData}
              //value="0"
              handleInputchange={handleSelectAllCheckBox}
            />
            {member_data && member_data.map((item, index) => {
              return (
                <CheckRadio
                  label={item.label}
                  type="checkbox"
                  checkedValue={memberSelected.has(String(item.value))}
                  value={item.value}
                  name={`member${index}`}
                  handleInputchange={handleInputchangeMember}
                />
              );
            })}
          </div>
        </div>
      </div>
      <div className="p-4">
        <Button
          variant="secondary contactPersondata"
          onClick={clearAssignedMembers}
        >
          Clear
        </Button>
        <Button onClick={handleAssign} className="contactPersondata">
          Assign
        </Button>
      </div>
    </div>
  );
};

export default ConsortiaAddNewPackages;
