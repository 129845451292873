import React, { Fragment, useEffect, useState } from "react";
import {
    BsChevronDoubleLeft,
    BsChevronDoubleRight,
    BsChevronLeft,
    BsChevronRight,
} from "react-icons/bs";

export const DDRPagination = ({
    // nPages,
    currentPage,
    setCurrentPage,
    setRecordsPerPage,
    tableDataReport,
    recordsPerPage,
    numOfPages,
}) => {

    const [arrOfCurrentButton, setArrOfCurrentButtons] = useState([numOfPages]);

    useEffect(() => {
        let tempNumberOfPages = [...(numOfPages && numOfPages)];

        let dotsInitial = "...";
        let dotsLeft = "... ";
        let dotsRight = " ...";

        if (numOfPages.length < 6) {
            tempNumberOfPages = numOfPages;
        } else if (currentPage >= 1 && currentPage <= 3) {
            tempNumberOfPages = [1, 2, 3, 4, "...", numOfPages.length];
        } else if (currentPage === 4) {
            const sliced = numOfPages.slice(0, 5);
            tempNumberOfPages = [...sliced, dotsInitial, numOfPages.length];
        } else if (currentPage > 4 && currentPage < numOfPages.length - 2) {
            const sliced1 = numOfPages.slice(currentPage - 2, currentPage);
            const sliced2 = numOfPages.slice(currentPage, currentPage + 1);
            tempNumberOfPages = [
                1,
                dotsLeft,
                ...sliced1,
                ...sliced2,
                dotsRight,
                numOfPages.length,
            ];
        } else if (currentPage > numOfPages.length - 3) {
            const sliced = numOfPages.slice(numOfPages.length - 4);
            tempNumberOfPages = [1, dotsLeft, ...sliced];
        } else if (currentPage === dotsInitial) {
            setCurrentPage(arrOfCurrentButton[arrOfCurrentButton.length - 3] + 1);
        } else if (currentPage === dotsRight) {
            setCurrentPage(arrOfCurrentButton[3] + 2);
        } else if (currentPage === dotsLeft) {
            setCurrentPage(arrOfCurrentButton[3] - 2);
        }

        setArrOfCurrentButtons(tempNumberOfPages);

    }, [currentPage, tableDataReport, recordsPerPage, numOfPages]);

    const nextPage = () => {
        if (currentPage !== numOfPages) setCurrentPage(currentPage + 1);
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };
    const nextPage1 = () => {
        if (currentPage !== numOfPages) {
            setCurrentPage(numOfPages && numOfPages?.length);
        }

        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };
    const prevPage = () => {
        if (currentPage !== 1) setCurrentPage(currentPage - 1);
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };
    const prevPage1 = () => {
        if (currentPage !== 1) setCurrentPage(1);
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };
    const handledPerPageCount15 = () => {
        setRecordsPerPage(15);
        setCurrentPage(1);
    };
    const handledPerPageCount25 = () => {
        setRecordsPerPage(25);
        setCurrentPage(1);
    };
    const handledPerPageCount50 = () => {
        setRecordsPerPage(50);
        setCurrentPage(1);
    };
    // const handledPerPageCount100 = () => {
    //     setRecordsPerPage(100);
    //     setCurrentPage(1);
    // };

    return (
        <Fragment>

            <nav className="pag_firmate">
                {tableDataReport.length >= 1 && (
                    <ul className="pagination justify-content-start ">
                        <li className={`page-item ${recordsPerPage === 15 ? "active" : ""} `}>
                            <button
                                className="page-link contactPersondata"
                                onClick={handledPerPageCount15}
                            >
                                15
                            </button>
                        </li>
                        <li className={`page-item ${recordsPerPage === 25 ? "active" : ""} `}>
                            <button
                                className="page-link contactPersondata"
                                onClick={handledPerPageCount25}
                            >
                                25
                            </button>
                        </li>
                        <li className={`page-item ${recordsPerPage === 50 ? "active" : ""} `}>
                            <button
                                className="page-link contactPersondata"
                                onClick={handledPerPageCount50}
                            >
                                50
                            </button>
                        </li>
                        {/* {tableDataReport && tableDataReport.length > 50 && (
                            <li
                                className={`page-item ${recordsPerPage === 100 ? "active" : ""} `}
                            >
                                <button
                                    className="page-link contactPersondata"
                                    onClick={handledPerPageCount100}
                                >
                                    100
                                </button>
                            </li>
                        )} */}
                    </ul>)}
                {tableDataReport && tableDataReport.length >= 1 && (
                    <ul className="pagination   justify-content-end">

                        <li
                            className={`page-item ${arrOfCurrentButton.includes(currentPage - 1)
                                ? ""
                                : "paginate-disabled"
                                } `}
                        >
                            <button className="page-link contactPersondata" onClick={prevPage1}>
                                <BsChevronDoubleLeft />
                            </button>
                        </li>
                        {/* )}
                    {tableDataReport && tableDataReport.length >= 1 && ( */}
                        <li
                            className={`page-item ${arrOfCurrentButton.includes(currentPage - 1)
                                ? ""
                                : "paginate-disabled"
                                } `}
                        >
                            <button className="page-link contactPersondata" onClick={prevPage}>
                                <BsChevronLeft />
                            </button>
                        </li>
                        {/* )} */}
                        {arrOfCurrentButton &&
                            arrOfCurrentButton?.map((pgNumber) => (
                                <li
                                    key={pgNumber}
                                    className={`page-item ${currentPage === pgNumber ? "active" : ""
                                        } `}
                                >
                                    <button
                                        onClick={() => setCurrentPage(pgNumber)}
                                        className="page-link contactPersondata"
                                    >
                                        {pgNumber}
                                    </button>
                                </li>
                            ))}
                        {/* {tableDataReport && tableDataReport.length >= 1 && ( */}
                        <li
                            className={`page-item ${numOfPages.includes(currentPage + 1) ? "" : "paginate-disabled"
                                } `}
                        >
                            <button className="page-link contactPersondata" onClick={nextPage}>
                                <BsChevronRight />
                            </button>
                        </li>
                        {/* )}
                    {tableDataReport && tableDataReport.length >= 1 && ( */}
                        <li
                            className={`page-item ${numOfPages.includes(currentPage + 1) ? "" : "paginate-disabled"
                                } `}
                        >
                            <button className="page-link contactPersondata" onClick={nextPage1}>
                                <BsChevronDoubleRight />
                            </button>
                        </li>
                        {/* )} */}
                    </ul>
                )}
            </nav>
        </Fragment>
    );
};
