import React, { useEffect, useState } from "react";
import ManageOASubscription from "../../components/ResourceMgmtComponents/ManageOASubscription";
import ToggleSidebar from "../../components/Sidebar/ToggleSidebar";
import {
  ResourcesMgmtMenuHeading,
  ResourceMgmtMenu,
  ResourceMgmtMenus,
  ConosrtiaResourceMgmtMenu,
} from "../../components/Sidebar/constants";
import "./ResourceMgmt.css";
import ViewConfigureLibraryResources from "../../components/ResourceMgmtComponents/ViewConfigureLibraryResources";
import ViewHoldingSummary from "../../components/ResourceMgmtComponents/ViewHoldingSummary";
import ViewConsortiaResources from "../../components/ResourceMgmtComponents/ViewConsortiaResources";
import { getLocalStorage } from "../../utilities/storage";
import { accessType } from "./constants";
import { customerResourceType } from "../../utilities/constants";



const LibraryAdminRmgmt = ({ customerID, consortiamaster_id, admin_type }) => {
  const [active, setActive] = useState("");
  const [resourceTpye, setResourceType] = useState([]);
  const { datatypes, informaticscustomer_id, product_type } = getLocalStorage("customer");
  const _datatypes =
    datatypes &&
    datatypes.map((item) => {
      const data = { label: item.accesstype, value: item.dat_resourcetype_id };
      return data;
    });

    useEffect(() => {
      fetch(customerResourceType(customerID))
        .then((val) => val.json())
        .then((res) => {
          setResourceType(res?.data)
          // if(res.data?.length){
          //   sessionStorage.setItem("resourceData", JSON.stringify(res?.data))
          // }else{
          //   sessionStorage.removeItem("resourceData")
          // }
          
        })
        .catch((error) => console.error(error));
    }, [customerID]);

  const handleActiveCallback = (active) => {
    setActive(active);
  };
  const renderSwitch = (active) => {
    switch (active) {
      case "ViewConfigureLibraryresources":
        return (
          <ViewConfigureLibraryResources
            // datatypes={_datatypes}
            accessType={accessType}
            customerID={customerID}
            setResourceType={setResourceType}
            resourceTpye={resourceTpye}
          />
        );
      case "ViewHoldingsSummary":
        return (
          <ViewHoldingSummary
            customerID={customerID}
            consortiamaster_id={consortiamaster_id}
            admin_type={admin_type}
          />
        );
      case "ManageOASubscription":
        return (
          <ManageOASubscription
            customerID={customerID}
            consortiamaster_id={consortiamaster_id}
            admin_type={admin_type}
          />
        );
      case "ViewConsortiaResources":
        return (
          <ViewConsortiaResources
            informaticscustomerID={informaticscustomer_id}
            consortiamaster_id={consortiamaster_id}
            admin_type={admin_type}
          />
        );
      default:
        return (
          <ViewConfigureLibraryResources
            // datatypes={_datatypes}
            accessType={accessType}
            customerID={customerID}
            consortiamaster_id={consortiamaster_id}
            admin_type={admin_type}
            serResourceType={setResourceType}
            resourceTpye={resourceTpye}
          />
        );
    }
  };

  return (
    <div className="ResourceMgnmt WithToggleSidebar resourceMng_mrg_frm_top_tagle">

      {consortiamaster_id && consortiamaster_id !== null ? (
        <div>
          {product_type === "7" ? (
            <ToggleSidebar
              menu={ConosrtiaResourceMgmtMenu}
              heading={ResourcesMgmtMenuHeading}
              setSelectedMenu={handleActiveCallback}
            />) : (
            <ToggleSidebar
              menu={ResourceMgmtMenu}
              heading={ResourcesMgmtMenuHeading}
              setSelectedMenu={handleActiveCallback}
            />)}
        </div>

      ) : (
        <div>
          <ToggleSidebar
            menu={ResourceMgmtMenus}
            heading={ResourcesMgmtMenuHeading}
            setSelectedMenu={handleActiveCallback}
          />
        </div>

      )}

      <div className="ResourceMgnmtContainer ContainerWithToggleSidebar">
        {renderSwitch(active)}
      </div>
    </div>
  );
};

export default LibraryAdminRmgmt;
