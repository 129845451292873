import React, { useEffect, useRef, useState } from "react";
import Dummy_user from "../../assets/img/user.png";
import { ProfileIamgeSuccessAlert } from "../../atoms/Modals/ProfileImageSuccessAlert";
import { SaveModal } from "../../atoms/Modals/SaveModal";
import { baseUrl } from "../../utilities/constants";
import { InfoDialogconsortia } from "../../atoms/Modals/InfoDialogconsortis";
import { Button } from "react-bootstrap";

const ProfileImgSection = ({ customerID, userID }) => {
  const inputFile = useRef(null);
  const [imgUrl, setImgUrl] = useState({});
  const [isSave, setIsSave] = useState(false);
  const [isImageBig, setIsImageBig] = useState(false);
  const [isCurrectFileFormat, setIsCurrectFileFormat] = useState(false);
  const [isRightFileFormat, setIsRightFileFormat] = useState(false);
  const [isProfileImgDeleted, setIsProfileImgDeleted] = useState(false);

  console.log("imgUrl", imgUrl);

  useEffect(() => {
    fetch(
      `${baseUrl}/api/get_librarian_profile_image/get_librarian_profile_image?customer_id=${customerID}&user_id=${userID}`
    )
      .then((val) => val.json())
      .then((res) => {
        setImgUrl(res.data[0][0].image_path);
      });
  }, [customerID, userID]);

  const onFileChange = (event) => {
    const url = `${baseUrl}/upload-file/`;
    const formData = new FormData();
    const profileImage = "profileImage";
    const ProImage = event.target.files[0];
    const ProImageSize = ProImage.size;
    const ProImageType = ProImage.type;

    formData.append("blob", event.target.files[0]);
    formData.append("container_name", profileImage);
    formData.append("customer_id", customerID);
    formData.append("user_id", userID);

    if (ProImageType !== "video/mp4") {
      fetch(url, {
        method: "POST",
        body: formData,
      })
        .then((response) => response.json())
        .then((resp) => {
          if (resp && resp.access_url) {
            if (
              ProImageType === "image/png" ||
              ProImageType === "image/jpeg" ||
              ProImageType === "image/jpg"
            ) {
              if (ProImageSize <= 500000) {
                fetch(
                  `${baseUrl}/api/update_librarian_profile_image/update_librarian_profile_image?customer_id=${customerID}&user_id=${userID}&image_path=${resp.access_url}`
                )
                  .then((val) => val.json())
                  .then((item) => {});
                setIsSave(true);
              } else {
                setIsImageBig(true);
              }
            } else {
              setIsCurrectFileFormat(true);
            }
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    } else {
      setIsRightFileFormat(true);
    }
  };

  const handleUpload = () => {
    inputFile.current.click();
  };

  const handleDelteProfileImage = () => {
    const access_url = null;

    fetch(
      `${baseUrl}/api/update_librarian_profile_image/update_librarian_profile_image?customer_id=${customerID}&user_id=${userID}&image_path=${access_url}`
    )
      .then((val) => val.json())
      .then((item) => {
        console.log("item", item);
        setIsProfileImgDeleted(true);
      });
  };

  return (
    <section className="OrgDetailsImgComponent">
      <SaveModal
        show={isSave}
        modalHandle={setIsSave}
        data={
          <ProfileIamgeSuccessAlert
            message={"Profile image has been updated Successfully!"}
            onClose={setIsSave}
          />
        }
        dialogClassName="successModal"
        heading={"Info"}
        size="md"
      />

      <SaveModal
        show={isProfileImgDeleted}
        modalHandle={setIsProfileImgDeleted}
        data={
          <ProfileIamgeSuccessAlert
            message={"Profile image has been deleted Successfully!"}
            onClose={setIsProfileImgDeleted}
          />
        }
        dialogClassName="successModal"
        heading={"Info"}
        size="md"
      />
      <InfoDialogconsortia
        show={isImageBig}
        modalClose={setIsImageBig}
        content={"Image too Big, please select a image less than 500KB"}
        successCallback={handleUpload}
        heading="Info"
      />
      <InfoDialogconsortia
        show={isCurrectFileFormat}
        modalClose={setIsCurrectFileFormat}
        content={"Please use a valid photo file format"}
        successCallback={handleUpload}
        heading="Info"
      />
      <InfoDialogconsortia
        show={isRightFileFormat}
        modalClose={setIsRightFileFormat}
        content={"Please use a valid photo file format"}
        successCallback={handleUpload}
        heading="Info"
      />
      <div className="OrgDetailsImg profile_size">
        <div>
          <p>
            <strong className="text-secondary">System will accept </strong>
            <strong className="text-danger">
              .png, .jpg, .jpeg ,max size 500KB
            </strong>
          </p>
        </div>

        <input
          type="file"
          accept="image/png,image/jpeg,image/jpg"
          id="file"
          onChange={onFileChange}
          ref={inputFile}
          style={{ display: "none" }}
        />
        <div>
          {imgUrl === "" || imgUrl === "null" || imgUrl == null ? (
            <img className="containerImage" alt="" src={Dummy_user} />
          ) : (
            <img className="containerImage" alt="" src={imgUrl} />
          )}
        </div>
        {imgUrl === "" || imgUrl === "null" || imgUrl == null ? (
          // <span onClick={() => handleUpload()} className="orglogo">
          //   Add Profile Image
          // </span>
          <div className="mt-5">
          <Button onClick={() => handleUpload()}> Add Profile Image</Button>
          </div>
        ) : (
          <div className="mt-5">
            {/* <span onClick={() => handleUpload()} className="orglogo"> */}
              {/* Update Profile Image */}
              {/* Update 
            </span>
            <span onClick={() => handleDelteProfileImage()} className="orglogo"> */}
              {/* Delete Profile Image */}
              {/* Delete 
            </span> */}
            <Button onClick={() => handleUpload()}> Update</Button>
            <Button onClick={() => handleDelteProfileImage()}>Delete</Button>
          </div>
        )}
      </div>
    </section>
  );
};

export default ProfileImgSection;
