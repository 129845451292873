import React, { useState,useEffect } from "react";
import ToggleSidebar from "../../components/Sidebar/ToggleSidebar";
import {
  DDRMenuHeading,
  DDRMgmtMenu,
} from "../../components/Sidebar/constants";
import "./DDR.css";
import ConsortiaDDRRecived from "../../components/DDRComponents/ConsortiaDDR";
import ConsortiaDDRSend from "../../components/DDRComponents/ConsortiaDDRSent";
import { baseUrl } from "../../utilities/constants";

/**
 * @author
 * @function ConsortiaAdminDDR
 **/

const ConsortiaAdminDDR = ({ customerID, consortiamaster_id, admin_type }) => {
  const [active, setActive] = useState("");

  const [priority, setPriority] = useState([]);

  useEffect(() => {
    fetch(
      `${baseUrl}/api/get_dat_ddr_priority/get_dat_ddr_priority`
    )
      .then((response) => response.json())
      .then((val) => setPriority(val));
  }, []);

  const handleActiveCallback = (active) => {
    setActive(active);
  };

  const renderSwitch = (active) => {
    switch (active) {
      case "DDRSend":
        return (
          <ConsortiaDDRSend
            customerID={customerID}
            consortiamaster_id={consortiamaster_id}
            admin_type={admin_type}
            priority={priority}
          />
        );
      default:
        return (
          <ConsortiaDDRRecived
            customerID={customerID}
            consortiamaster_id={consortiamaster_id}
            admin_type={admin_type}
            priority={priority}
          />
        );
    }
  };

  return (
    <div className="DDR WithToggleSidebar mt-5">
      <ToggleSidebar
        customerID={customerID}
        consortiamaster_id={consortiamaster_id}
        admin_type={admin_type}
        menu={DDRMgmtMenu}
        heading={DDRMenuHeading}
        setSelectedMenu={handleActiveCallback}
      />

      <div className="DDRContainer ContainerWithToggleSidebar">
        {renderSwitch(active)}
      </div>
    </div>
  );
};

export default ConsortiaAdminDDR;
