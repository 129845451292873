import React from 'react'
import Widget from '../DashboardComponents/Widget';

const DDRWidgetContainer = ({ heading, data }) => {

  return (
    <div className="widgetContainer">
      <div className="widgetMain">
        {Object.keys(data).map((item, index) => (
          <Widget
            item={item}
            value={data[item]}
            key={index}
            classname="widgetDDR"
            fa={true} />
        ))}
      </div>
      <div className="widget_footer text-center"> {heading} </div>
    </div>
  )
}


export default DDRWidgetContainer