import React, { Fragment } from "react";
import "./OrgDetails.css";
import { getLocalStorage } from "../../utilities/storage";
import LibraryAdminOrg from "./LibraryAdminOrg";


const OrgDetails = () => {
  const { customerID, admin_type, userID } = getLocalStorage("user");
  const { consortiamaster_id } = getLocalStorage("customer");

  return (
    <Fragment>
      <LibraryAdminOrg
        customerID={customerID}
        consortiamaster_id={consortiamaster_id}
        admin_type={admin_type}
        userID={userID}
      />
    </Fragment>
  );
};

export default OrgDetails;
