import React, { useState } from "react";

/**
 * @author
 * @function FileUpload
 **/

const UserFileUpload = ({ name, handleInputchange }) => {
    const [selectedFile, setSelectedFile] = useState();
    const [isFilePicked, setIsFilePicked] = useState(false);

    const changeHandler = (event) => {

        setSelectedFile(event.target.files[0]);
        setIsFilePicked(true);
        handleInputchange(event.target.files[0], name);
    };

    return (
        <div>
            <input
                type="file"
                name="file"
                onChange={changeHandler}
                className="UserUploadSpace"
            />

            {isFilePicked ? (
                <div>
                    <p className="usr_uplds">Filename: {selectedFile.name}</p>
                </div>
            ) : (
                <p></p>
            )}
        </div>
    );
};

export default UserFileUpload;
