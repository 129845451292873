import React from "react";
import { ResponsivePie } from "@nivo/pie";

export default function PiCharts({ topUsedPublisherData }) {
  const data = topUsedPublisherData.data[0][0]
    ? topUsedPublisherData.data[0][0].map((item) => {
        let rObj = {};
        rObj["id"] = item.publisher_name;
        rObj["label"] = item.publisher_name;
        rObj["value"] = item.count;
        rObj["color"] = "hsl(140, 70%, 50%)";

        return rObj;
      })
    : [];

  return (
    <>
      <div className="chartDiv">
        {!!data && (
          <ResponsivePie
            data={data}
            margin={{ top: 20, right: 25, bottom: 20, left: -280 }}
            innerRadius={0}
            padAngle={0.7}
            cornerRadius={3}
            // colors={{ scheme: "nivo" }}
            colors={{ scheme: "set3" }}
            enableArcLinkLabels={false}
            borderWidth={1}
            borderColor={{ from: "color", modifiers: [["darker", 0.2]] }}
            radialLabelsSkipAngle={10}
            radialLabelsTextColor="#333333"
            radialLabelsLinkColor={{ from: "color" }}
            sliceLabelsSkipAngle={10}
            sliceLabelsTextColor="#333333"
            legends={[
              {
                anchor: "top-right",
                direction: "column",
                // justify: true,
                translateX: -170,
                translateY: 0,
                itemsSpacing: 0,
                itemWidth: 100,
                itemHeight: 18,
                itemTextColor: "#999",
                itemDirection: "left-to-right",
                itemOpacity: 1,
                symbolSize: 10,
                symbolShape: "circle",
                effects: [
                  {
                    on: "hover",
                    style: {
                      itemTextColor: "#000",
                    },
                  },
                ],
              },
            ]}
          />
        )}
      </div>
    </>
  );
}
