import React, { useState, useEffect } from "react";
import _ from "lodash";
import { Button } from "react-bootstrap";
import CheckRadio from "../../atoms/Radio/CheckRadio";
import { fetchUrl } from "../../utilities/apiFunctions/fatchUrl";
import {
  addOASubjectAssignmentUrl,
} from "../../utilities/constants";
import { MoaAddSubscriptionInfoDialog } from "../../atoms/Modals/MoaAddSubscriptionInfoDialog";


const AddOAResources = ({
  customerID,
  fnClose,
  setViewOn,
  oaassignedSbjByClient,
}) => {
  const [rightHandSide, setRightHandSide] = useState([]);
  const [leftHandSide, setLeftHandSide] = useState([]);

  const [leftSelected, setLeftSelected] = useState(new Set());
  const [rightSelected, setRightSelected] = useState(new Set());
  const [isAlertOn, setIsAlertOn] = useState(false);
  const [viewOASubscribedData] = useState(oaassignedSbjByClient);


  const initialData = viewOASubscribedData &&
    viewOASubscribedData.data &&
    viewOASubscribedData.data[0][0];



  useEffect(() => {
    if (initialData) {
      const _rightHandSide = initialData && initialData.filter((item) => !!item.oaassigned);
      const _leftHandSide = initialData && initialData.filter(
        (item) => !!item.oaassigned === false
      );
      setRightHandSide(_rightHandSide);
      setLeftHandSide(_leftHandSide);
    }
  }, [initialData])

  const handleMoveAll = (toSide) => {
    if (toSide === "left") {
      const _dataR = _.cloneDeep(rightHandSide);
      const changeDR = _dataR.map((item) => {
        const data = { ...item, oaassigned: false };
        return data;
      });
      const _dataL = [...changeDR, ...leftHandSide];
      setLeftHandSide(_dataL);
      setRightHandSide([]);
      setRightSelected(new Set());
      
    } else {
      const _dataL = _.cloneDeep(leftHandSide);
      const changeDL = _dataL.map((item) => {
        const data = { ...item, oaassigned: true };
        return data;
      });
      const _dataR = [...changeDL, ...rightHandSide];
      setLeftHandSide([]);
      setRightHandSide(_dataR);
      setLeftSelected(new Set()); 
    }
  };

  const handleInputchangeLeft = (value, name) => {
    const _dataLS = _.cloneDeep(leftSelected);
    const mySet1 = new Set(_dataLS);
    if (mySet1.has(value)) {
      mySet1.delete(value);
    } else {
      mySet1.add(value);
    }
    setLeftSelected(mySet1);
  };
  const handleInputchangeRight = (value, name) => {
    const _dataRS = _.cloneDeep(rightSelected);
    const mySet1 = new Set(_dataRS);
    if (mySet1.has(value)) {
      mySet1.delete(value);
    } else {
      mySet1.add(value);
    }
    setRightSelected(mySet1);
  };

  const handleMoveSelectedRight = () => {
    const _dataL = _.cloneDeep(leftHandSide);
    const _dataLS = _.cloneDeep(leftSelected);
    const _dataObj = _dataL
      .filter((item) => _dataLS.has(String(item.subject_id)))
      .map((item) => {
        const data = { ...item, oaassigned: true };
        return data;
      });
    const _dataRemObj = _dataL.filter(
      (item) => _dataLS.has(String(item.subject_id)) === false
    );

    const _dataR = [..._dataObj, ...rightHandSide];
    setLeftHandSide(_dataRemObj);
    setRightHandSide(_dataR);
    setLeftSelected(new Set()); 
  };
  const handleMoveSelectedLeft = (selected) => {
    const _dataR = _.cloneDeep(rightHandSide);
    const _dataRS = _.cloneDeep(rightSelected);
    const _dataObj = _dataR
      .filter((item) => _dataRS.has(String(item.subject_id)))
      .map((item) => {
        const data = { ...item, oaassigned: false };
        return data;
      });

    const _dataRemObj = _dataR.filter(
      (item) => _dataRS.has(String(item.subject_id)) === false
    );

    const _dataL = [..._dataObj, ...leftHandSide];
    setLeftHandSide(_dataL);
    setRightHandSide(_dataRemObj);
    setRightSelected(new Set());
  };

  const handleBtnSave = async () => {
    const _dataID = JSON.stringify(
      rightHandSide.map((item) => item.subject_id)
    );
    const strUrl = `{"oasubjectdetails":[{"customer_id":${customerID},"level1subjects":${_dataID}}]}`;
    const response = await fetchUrl(addOASubjectAssignmentUrl(strUrl));
    if (response && response?.data[0][0] === "SUCCESS") {
      setIsAlertOn(true);
    }
  };

  const handleDisableSaveButton =()=>{
     return   (leftSelected.size >=1) 
  }



  return (
    <div>
      <MoaAddSubscriptionInfoDialog
        show={isAlertOn}
        modalClose={setIsAlertOn}
        content={"Open Access resources updated successfully"}
        successCallback={handleBtnSave}
        heading="Info"
        setIsAddSubscription={setViewOn}
        fnClose={fnClose}
      />
      <div className="AddOAResources">
        <div className="AvailiableSubjectContanber">
          <div className="AvailiableSubjectContanberHeading">
            Available Subject(s)
          </div>

          {leftHandSide.map((item, index) => (
            <div key={index}>
              <CheckRadio
                label={item.subject_name}
                type="checkbox"
                checkedValue={leftSelected.has(String(item.subject_id))}
                value={item.subject_id}
                name={`left${index}`}
                handleInputchange={handleInputchangeLeft}
              />
            </div>
          ))}
        </div>

        <div className="AddOAResourceControls">
          <div
            onClick={handleMoveSelectedRight}
            className="AddOAResourceControlsEach"
          >
            <i className="fas fa-step-forward"></i>{" "}
          </div>
          <div
            onClick={() => handleMoveAll("right")}
            className="AddOAResourceControlsEach"
          >
            <i className="fas fa-fast-forward"></i>
          </div>
          <div
            onClick={handleMoveSelectedLeft}
            className="AddOAResourceControlsEach"
          >
            <i className="fas fa-step-backward"></i>
          </div>
          <div
            onClick={() => handleMoveAll("left")}
            className="AddOAResourceControlsEach"
          >
            <i className="fas fa-fast-backward"></i>
          </div>
        </div>

        <div className="AvailiableSubjectContanber">
          <div className="AvailiableSubjectContanberHeading">
            Selected Subject(s)
          </div>
          {rightHandSide.map((item, index) => (
            <div key={index}>
              <CheckRadio
                label={item.subject_name}
                type="checkbox"
                value={item.subject_id}
                checkedValue={rightSelected.has(String(item.subject_id))}
                name={`right${index}`}
                handleInputchange={handleInputchangeRight}
              />
            </div>
          ))}
        </div>
      </div>
      <div className="AvailiableSubjectBtn">
        <Button  onClick={handleBtnSave} disabled={handleDisableSaveButton()}>Save</Button>
      </div>
    </div>
  );
};

export default AddOAResources;
