/**
 * @author vimalkovath
 * @function Login
 **/

import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import "./Login.css";
import { fetchUrl } from "../../utilities/apiFunctions/fatchUrl";
import {
  loginUrl,
  customerByIDUrl,
  loginUsageUrl,
} from "../../utilities/constants";
import { setLocalStorage } from "../../utilities/storage";
import { generateSessionID } from "../../utilities/generateSessionID";
import Error from "../../atoms/Error/Error";
import { ForgotPassworSucsessDialog } from "../../atoms/Modals/ForgotPassworSucsessDialog";
import { NewModal } from "../../components/Modals/Modal";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";

export default function Login({ setUser, setCustomer, setLoading }) {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [password, setPassword] = useState("");
  const [isMessage, setIsMessage] = useState(false);
  const [isSubscriptionExpaired, setIsSubscriptionExpaired] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [ipAddress, setIpAddress] = useState("");

  useEffect(() => {
    const fetchIpAddress = async () => {
      try {
        const response = await fetch("https://api.ipify.org?format=json");
        const data = await response.json();
        setIpAddress(data?.ip);
      } catch (error) {
        setIpAddress("0");
        console.log("Error fetching IP address:", error);
      }
    };

    fetchIpAddress();
  }, []);

  function validateForm() {
    return email.length > 0 && password.length > 0;
  }

  function handleSubmit(event) {
    event.preventDefault();
    handleLogin();
  }

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleLogin = async () => {
    setLoading(true);
    const formData = new FormData();
    formData.append("username", email);
    formData.append("password", password);
    const options = { method: "POST", body: formData };
    const data = await fetchUrl(loginUrl, options);
    if (data.data === "Success") {
      setLocalStorage("user", {
        userID: data.user_id,
        customerID: data.informaticscustomer_id,
        sessionID: generateSessionID(),
        admin_type: data.admin_type,
      });

      const customerDetails = await fetchUrl(
        customerByIDUrl(data.informaticscustomer_id)
      );
      const _customer = customerDetails.data[0][0];
      logSearchUsageData(4, data, _customer);
      setLocalStorage("customer", _customer);
      setUser({
        userID: data.user_id,
        admin_type: data.admin_type,
        customerID: data.informaticscustomer_id,
      });
      setCustomer(_customer);
      setLoading(false);
      sessionStorage.setItem("user_id", data.user_id);
      setError("");
    } else if (data.data === "Client Subscription_expired.") {
      setError("");
      setIsSubscriptionExpaired(true);
    } else {
      setError("Invalid username or password");
    }
  };

  const handleForgotPassword = () => {
    setIsMessage(true);
  };

  const logSearchUsageData = async (
    usageActionID,
    profileData,
    customerData
  ) => {
    const userMasterId = profileData.user_id ? profileData.user_id : null;
    const informaticscustomerId = profileData.informaticscustomer_id
      ? profileData.informaticscustomer_id
      : null;
    const sessionKey = profileData.session_key ? profileData.session_key : null;
    const ip_4_Address = ipAddress ? ipAddress : null;
    const consortiamasterId = customerData?.consortiamaster_id
      ? customerData?.consortiamaster_id
      : null;
    const logdetailsjson = {
      data: [
        {
          usermaster_id: userMasterId,
          informaticscustomer_id: informaticscustomerId,
          consortiamaster_id: consortiamasterId,
          usage_action_id: usageActionID,
          context_id: null,
          action_origin_id: null,
          article_id: null,
          resourceissue_id: null,
          publisher_id: null,
          resorucemaster_id: null,
          ip_v4: ip_4_Address,
          ip_v6: null,
          user_session: sessionKey,
          device_type_id: 1,
          remarks: null,
        },
      ],
    };
    const formData = new FormData();
    formData.append("searchlog", JSON.stringify(logdetailsjson));
    const options = { method: "POST", body: formData };
    try {
      const data = await fetchUrl(loginUsageUrl, options);
    } catch (err) {
      return null;
    }
  };

  const handleMessageFormate = () => {
    return (
      <div>
        <p className="errorMessagefs">
          Please write to{" "}
          <a
            href="mailto:jcoordinator@informaticsglobal.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            jcoordinator@informaticsglobal.com{" "}
          </a>
          for assistance regarding the credentials to access J-Gate Admin Module
        </p>
        <p className="errorMessagefs">
          It would be of great help if you can mention the Subject of Email as
          below
        </p>
        <p className="errorMessagefs">
          "Request for J-Gate Admin Credentials - (Institute Name) - please
          provide your Institute name to identify the access"
        </p>
      </div>
    );
  };

  const handleSubscriptionPopup = () => {
    return (
      <div>
        <p>
          Subscription Expired, Please contact
          jcoordinator@informaticsglobal.com
        </p>
      </div>
    );
  };

  return (
    <>
      <NewModal
        show={isSubscriptionExpaired}
        modalHandle={setIsSubscriptionExpaired}
        data={handleSubscriptionPopup()}
        size="lg"
        heading="Info"
      />

      <div className="Login logCard">
        <ForgotPassworSucsessDialog
          show={isMessage}
          modalClose={setIsMessage}
          content={handleMessageFormate()}
          successCallback={handleForgotPassword}
          heading="Info"
          size={"lg"}
        />
        <div className="LoginFormWrapper auth-wr ">
          <div className="card logCardwdth">
            <div className="card-body">
              <h5 className="card-title">Login</h5>
              <Form onSubmit={handleSubmit}>
                <Form.Group size="lg" controlId="email">
                  <Form.Label>Username</Form.Label>
                  <Form.Control
                    autoFocus
                    type="text"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Form.Group>
                <Form.Group size="lg" controlId="password">
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    type={showPassword ? "text" : "password"}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <div style={{ marginTop: "-44px", marginLeft: "290px" }}>
                    {!showPassword ? (
                      <AiOutlineEyeInvisible
                        onClick={togglePasswordVisibility}
                        style={{
                          height: "40px",
                          width: "25px",
                          cursor: "pointer",
                        }}
                      />
                    ) : (
                      <AiOutlineEye
                        onClick={togglePasswordVisibility}
                        style={{
                          height: "40px",
                          width: "25px",
                          cursor: "pointer",
                        }}
                      />
                    )}
                  </div>
                </Form.Group>

                {error && <Error text={error} />}

                <Form.Group size="lg" controlId="button">
                  <Button
                    block
                    size="lg"
                    className="loginBtn"
                    type="submit"
                    disabled={!validateForm()}
                  >
                    Login
                  </Button>
                </Form.Group>
                <span className="forgotpasswrd" onClick={handleForgotPassword}>
                  Forgot Password
                </span>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
