import _accounsIcon from "../../assets/img/accountsIcon.png";
import _reportsIcon from "../../assets/img/reportsIcon.png";
import _logoutIcon from "../../assets/img/logoutIcon.png";
import _dashboardIcon from "../../assets/img/dashboardIcon.png";
import _ddrIcon from "../../assets/img/ddrIcon.png";
import _memberIcon from "../../assets/img/memberIcon.png";
import _orgIcon from "../../assets/img/orgIcon.png";
import _resourceIcon from "../../assets/img/resourceIcon.png";
import _useruploadIcon from "../../assets/img/user-uploading.png";
import _userUploadIcon from "../../assets/img/upload.png";

export const accounsIcon = _accounsIcon;
export const reportsIcon = _reportsIcon;
export const dashboardIcon = _dashboardIcon;
export const logoutIcon = _logoutIcon;
export const ddrIcon = _ddrIcon;
export const memberIcon = _memberIcon;
export const orgIcon = _orgIcon;
export const resourceIcon = _resourceIcon;
export const useruploadIcon = _useruploadIcon;
export const userUploadIcon = _userUploadIcon;
