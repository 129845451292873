import React from "react";

/**
 * @author
 * @function DetailSection
 **/

const DetailSection = ({ data,index }) => {
  return (
    <div className="detailsection">
      {!!data["action_usage"] &&
        data["action_usage"].map((detailedItem, cIndex) => (
          <div key={"c" + cIndex}>
            <>
              <span>
                {" "}
                {Object.keys(detailedItem)[0] &&
                  Object.keys(detailedItem)[0]}{" "}
              </span> - 
              <span>
                {" "}
                {Object.values(detailedItem)[0] &&
                  Object.values(detailedItem)[0]}{" "}
              </span>
            </>
          </div>
        ))}
    </div>
  );
};

export default DetailSection;
