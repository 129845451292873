import React from "react";
import InputGroup from "react-bootstrap/InputGroup";
import BasicSelectDropDown from "./BasicSelectDropDown";


const DropDownGroupCoontuReport = ({
    label,
    data,
    handleInputchange,
    name,
    defaultValue,
    classname,
    defaultSelectValue,
}) => {
    return (
        <div className="DropDownGroup">
            <InputGroup>
                <InputGroup.Prepend>
                    <InputGroup.Text>{label}</InputGroup.Text>
                </InputGroup.Prepend>

                <BasicSelectDropDown
                    data={data}
                    as={InputGroup.Append}
                    handleInputchange={handleInputchange}
                    name={name}
                    defaultValue={defaultValue}
                    classname={classname}
                    defaultSelectValue={defaultSelectValue || ""}
                />
            </InputGroup>
        </div>
    );
};

export default DropDownGroupCoontuReport;
