import React from "react";
// import { NavLink } from "react-router-dom";
// import LogoInformatics from "../Header/LogoInformatics";
import LogoInformaticsImg from "../../assets/img/Logo/informatics-logo.png";
import "./Footer.css";

/**
 * @author VimalKovath
 * @function Footer
 **/

const Footer = (props) => {
  return (
    <footer className={`main-footer ${props.isSidebar} footer`}>
      <div className="container ">
        <div className="footerContents ">
        <div>
            <img src={LogoInformaticsImg} width="143" height="33" alt="" />
          </div>
          {/* <div className="copyright">
            Copyright © 2024 . Informatics India Ltd. All Rights Reserved.
          </div> */}
          <div className="Links">
            <span className="acount-icon">
              <a href="/aboutus" target="_blank">
                <span className="footerfont"> About</span>
              </a>
              <a href="/faq" target="_blank">
                <span className="footerfont"> FAQ</span>
              </a>
              <a href="/contactus" target="_blank">
                <span className="footerfont"> Contact Us</span>
              </a>
            </span>
          </div>
          {/* <div>
            <img src={LogoInformaticsImg} width="143" height="33" alt="" />
          </div> */}
          <div className="copyright">
            Copyright © 2024 . Informatics India Ltd. All Rights Reserved.
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
