import React from 'react'

/**
* @author
* @function UserProfile
**/

const UserProfile = (props) => {
  return(
    <div>UserProfile</div>
   )
  }


export default UserProfile