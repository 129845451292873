import React, { useEffect, useState } from "react";
import ToggleSidebarHeading from "./ToggleSidebarHeading";
import "./ToggleSidebar.css";
import { Accordion, Card } from "react-bootstrap";

/**
 * @author
 * @function ToggleSidebar
 **/

const ToggleSidebar = ({ heading, menu, setSelectedMenu }) => {

  const _heading = heading || "heading";
  //const [selectedClass, setSelectedClass] = useState("");
  const defaultActive = menu[0].key || "ManageMyLibrarySubscriptions";
  const [selectedClass, setSelectedClass] = useState(defaultActive);

  const onClickMenu = (key) => {
    setSelectedClass(key);
    setSelectedMenu(key);
  };

  useEffect(() => {
    if (selectedClass === "") {
      setSelectedClass(defaultActive);
    }
  }, [defaultActive, selectedClass]);

  return (
    <div className="ToggleSidebar">
      <ToggleSidebarHeading heading={_heading} />

      <nav className="ToggleSidebarNav">
        <Accordion defaultActiveKey={defaultActive}>
          {menu.map((item, index) => (
            <Card key={index}>
              <Accordion.Toggle as={Card.Header} eventKey={item.key} className={
                      selectedClass === item.key
                        ? "  ToggleSidebarselected"
                        : ""
                    }>
                {item.submenu && <div >{item.label}</div>}
                {!item.submenu && (
                  <div
                    onClick={() => onClickMenu(item.key)}
                    className={
                      selectedClass === item.key
                        ? "  ToggleSidebarselected"
                        : ""
                    }
                  >
                    {item.label}
                  </div>
                )}
              </Accordion.Toggle>

              <Accordion.Collapse eventKey={item.key} >
                <>
                  {item.submenuData.map((sMenu) => (
                    <Card.Body
                      className={
                        selectedClass === sMenu.key
                          ? "ToggleSidebarNavLi SubMenuLevel1 ToggleSidebarselected"
                          : "ToggleSidebarNavLi SubMenuLevel1"
                      }
                      key={sMenu.key}
                      onClick={() => onClickMenu(sMenu.key)}
                    >
                      {sMenu.label}
                    </Card.Body>
                  ))}
                </>
              </Accordion.Collapse>
            </Card>
          ))}
        </Accordion>
      </nav>
    </div>
  );
};

export default ToggleSidebar;
