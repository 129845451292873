import { toNumber } from "lodash";
import React from "react";
import ProgressBar from "react-bootstrap/ProgressBar";
import "./JprogressBar.css";
/**
 * @author
 * @function JprogressBar
 **/

const JprogressBar = ({ value, label, count, varient }) => {
  const _value = toNumber(value);
  const _varient = varient || "info";
  return (
    <>
      <div className="ProgressBarLabels">
        <span>{label}</span>
        <span> {count}</span>
      </div>
      <ProgressBar
        variant={_varient}
        now={_value}
        label={`${_value ? _value : 0}%`}
      />
    </>
  );
};

export default JprogressBar;
