import React, { useState } from "react";
import _ from "lodash";
import SelectDropDown from "../../atoms/DropDown/SelectDropDown";
import {
  getDetailsForDDRActionForm,
  selectStatusData,
} from "../../containers/DDR/constants";
import { fetchUrl } from "../../utilities/apiFunctions/fatchUrl";
import { NewModal } from "../Modals/Modal";
import DDRDuplicatelMailTemplate from "./DDRDuplicateMailTemplate";
import DDRFulfillMailTemplate from "./DDRFulfillMailTemplate";
import DDRDelayMailTemplate from "./DDRDelayMailTemplate";
import DDRNotAvailiableTemplate from "./DDRNotAvailiableTemplate";
import DDRDiscardlMailTemplate from "./DDRDiscardMailTemplate";
import ReactHtmlParser from "react-html-parser";
import { Button } from "react-bootstrap";
import { FullFilledStatusData } from "./FullFilledStatusData";
import { NotAvailableStatusData } from "./NotAvailableStatusData";
import { DiscardStatusData } from "./DiscardStatusData";
import { DuplicateStatusData } from "./DuplicateStatusData";
import { DelayStatusData } from "./DelayStatusData";

const DDRArticleTable = ({
  customerID,
  tableData,
  currentPage,
  recordsPerPage,
  linkResolverData,
  admin_type,
}) => {
  const [data, setData] = useState({
    ddr_id: "0",
    ddr_action: "0",
  });
  const [templateData, setTemplateData] = useState({});
  const [isFulfillEmailTemplate, setIsFulfillEmailTemplate] = useState(false);
  const [isDelayEmailTemplate, setIsDelayEmailTemplate] = useState(false);
  const [isNAEmailTemplate, setIsNAEmailTemplate] = useState(false);
  const [isDiscardEmailTemplate, setIsDiscardEmailTemplate] = useState(false);
  const [isDuplicateEmailTemplate, setIsDuplicateEmailTemplate] =
    useState(false);
  const [DDRId, setDDRId] = useState("");
  const [statusDDRId, setStatusDDRId] = useState("");

  const [isFullFilledStatus, setIsFullFilledStatus] = useState(false);
  const [isNotAvailableStatus, setIsNotAvailableStatus] = useState(false);
  const [isDiscardStatus, setIsDiscardStatus] = useState(false);
  const [isDuplicateStatus, setIsDuplicateStatus] = useState(false);
  const [isDelayStatus, setIsDelayStatus] = useState(false);

  const handleEmailTemplate = (val) => {
    if (val === "2") {
      setIsFulfillEmailTemplate(true);
    } else if (val === "3") {
      setIsDelayEmailTemplate(true);
    } else if (val === "4") {
      setIsNAEmailTemplate(true);
    } else if (val === "5") {
      setIsDiscardEmailTemplate(true);
    } else if (val === "7") {
      setIsDuplicateEmailTemplate(true);
    } else {
      setIsFulfillEmailTemplate(false);
      setIsDelayEmailTemplate(false);
      setIsNAEmailTemplate(false);
      setIsDiscardEmailTemplate(false);
      setIsDuplicateEmailTemplate(false);
    }
  };

  const modalClose = () => {
    setIsFulfillEmailTemplate(false);
    setIsDelayEmailTemplate(false);
    setIsDuplicateEmailTemplate(false);
    setIsDiscardEmailTemplate(false);
    setIsNAEmailTemplate(false);
  };

  const handleInputchange = async (value, name, optional) => {
    const _dat = _.cloneDeep(data);
    _dat[name] = value;
    _dat["ddr_id"] = optional;
    setData(_dat);
    setDDRId(optional);
    if (!!value) {
      const str = "ddr_id=" + optional + "&ddr_action=" + value;
      const response = await fetchUrl(getDetailsForDDRActionForm(str));
      setTemplateData(response && response.data && response.data[0][0]);
      handleEmailTemplate(value);
    }
  };

  const getData = () => templateData;

  const _dataFulfill = (
    <DDRFulfillMailTemplate
      modalClose={modalClose}
      fnData={getData}
      getDDR={DDRId}
      customerID={customerID}
      setIsFulfillEmailTemplate={setIsFulfillEmailTemplate}
    />
  );

  const _dataDelay = (
    <DDRDelayMailTemplate
      modalClose={modalClose}
      fnData={getData}
      getDDR={DDRId}
      customerID={customerID}
    />
  );

  const _dataNA = (
    <DDRNotAvailiableTemplate
      modalClose={modalClose}
      fnData={getData}
      getDDR={DDRId}
      customerID={customerID}
    />
  );
  const _dataDiscard = (
    <DDRDiscardlMailTemplate
      modalClose={modalClose}
      fnData={getData}
      getDDR={DDRId && DDRId}
      customerID={customerID}
    />
  );

  const _dataDuplicate = (
    <DDRDuplicatelMailTemplate
      modalClose={modalClose}
      fnData={getData}
      getDDR={DDRId && DDRId}
      customerID={customerID}
    />
  );

  const _fullFilledStatusData = (
    <FullFilledStatusData statusDDRId={statusDDRId && statusDDRId} />
  );

  const _notAvailableStatusData = (
    <NotAvailableStatusData statusDDRId={statusDDRId && statusDDRId} />
  );

  const _discardStatusData = (
    <DiscardStatusData statusDDRId={statusDDRId && statusDDRId} />
  );

  const _duplicateStatusData = (
    <DuplicateStatusData statusDDRId={statusDDRId && statusDDRId} />
  );

  const _delayStatusData = (
    <DelayStatusData statusDDRId={statusDDRId && statusDDRId} />
  );

  const availiableData = (label, item) => {
    return (
      <>
        {item && (
          <div>
            <strong>{label}: </strong>
            {item}{" "}
          </div>
        )}
      </>
    );
  };

  const handleViewButton = (id, status) => {
    setStatusDDRId(id);
    if (status === "Fulfilled") {
      setIsFullFilledStatus(true);
      setIsNotAvailableStatus(false);
      setIsDiscardStatus(false);
      setIsDuplicateStatus(false);
      setIsDelayStatus(false);
    } else if (status === "Delayed") {
      setIsDelayStatus(true);
      setIsFullFilledStatus(false);
      setIsNotAvailableStatus(false);
      setIsDiscardStatus(false);
      setIsDuplicateStatus(false);
    } else if (status === "Article not Available") {
      setIsNotAvailableStatus(true);
      setIsFullFilledStatus(false);
      setIsDiscardStatus(false);
      setIsDuplicateStatus(false);
      setIsDelayStatus(false);
    } else if (status === "Discarded") {
      setIsDiscardStatus(true);
      setIsFullFilledStatus(false);
      setIsNotAvailableStatus(false);
      setIsDuplicateStatus(false);
      setIsDelayStatus(false);
    } else if (status === "Duplicate Request") {
      setIsDuplicateStatus(true);
      setIsDiscardStatus(false);
      setIsFullFilledStatus(false);
      setIsNotAvailableStatus(false);
      setIsDelayStatus(false);
    } else {
      setIsDelayStatus(false);
      setIsFullFilledStatus(false);
      setIsNotAvailableStatus(false);
      setIsDiscardStatus(false);
      setIsDuplicateStatus(false);
    }
  };

  const handleClick = (data) => {
    window.open(data, "_blank");
  };
  return (
    <>
      <div>
        <NewModal
          show={isFulfillEmailTemplate}
          modalHandle={setIsFulfillEmailTemplate}
          data={_dataFulfill}
          size="lg"
          heading="Fullfill Request"
          dialogClassName={"OrgViewTableModal"}
        />
        <NewModal
          show={isDelayEmailTemplate}
          modalHandle={setIsDelayEmailTemplate}
          data={_dataDelay}
          size="lg"
          heading="Delay Request"
          dialogClassName={"OrgViewTableModal"}
        />
        <NewModal
          show={isNAEmailTemplate}
          modalHandle={setIsNAEmailTemplate}
          data={_dataNA}
          size="lg"
          heading="Not Availiable Request"
          dialogClassName={"OrgViewTableModal"}
        />
        <NewModal
          show={isDiscardEmailTemplate}
          modalHandle={setIsDiscardEmailTemplate}
          data={_dataDiscard}
          size="lg"
          heading="Discard Request"
          dialogClassName={"OrgViewTableModal"}
        />
        <NewModal
          show={isDuplicateEmailTemplate}
          modalHandle={setIsDuplicateEmailTemplate}
          data={_dataDuplicate}
          size="lg"
          heading="Duplicate Request"
          dialogClassName={"OrgViewTableModal"}
        />
        <NewModal
          show={isFullFilledStatus}
          modalHandle={setIsFullFilledStatus}
          data={_fullFilledStatusData}
          size="lg"
          heading="Document Delivery Request"
          dialogClassName={"OrgViewTableModal"}
        />
        <NewModal
          show={isNotAvailableStatus}
          modalHandle={setIsNotAvailableStatus}
          data={_notAvailableStatusData}
          size="lg"
          heading="Document Delivery Request"
          dialogClassName={"OrgViewTableModal"}
        />
        <NewModal
          show={isDiscardStatus}
          modalHandle={setIsDiscardStatus}
          data={_discardStatusData}
          size="lg"
          heading="Document Delivery Request"
          dialogClassName={"OrgViewTableModal"}
        />
        <NewModal
          show={isDuplicateStatus}
          modalHandle={setIsDuplicateStatus}
          data={_duplicateStatusData}
          size="lg"
          heading="Document Delivery Request"
          dialogClassName={"OrgViewTableModal"}
        />
        <NewModal
          show={isDelayStatus}
          modalHandle={setIsDelayStatus}
          data={_delayStatusData}
          size="lg"
          heading="Document Delivery Request"
          dialogClassName={"OrgViewTableModal"}
        />

        <table className="MoaSubscription table-bordered">
          <thead>
            <tr className="text-center contactPersondata">
              <th>SI.No</th>
              <th>Document Details</th>
              <th>Status</th>
              <th>Date</th>
              {admin_type === "library_admin" && <th>Action</th>}
            </tr>
          </thead>
          <tbody>
            {tableData && tableData !== null ? (
              tableData.map((data, index) => {
                const matchedLinkData = linkResolverData?.data?.find(
                  (link) => link?.article_id === data?.article_id
                );
                return (
                  <tr key={index} className="contactPersondata">
                    {/* < td className="text-center" > {data.id}</td> */}
                    <td className="text-center">
                      {index + (currentPage - 1) * recordsPerPage + 1}{" "}
                    </td>
                    <td>
                      {data.article_name &&
                        availiableData(
                          "Document Title",
                          ReactHtmlParser(data && data?.article_name)
                        )}
                      {data.issue_month ||
                      data.issue_date ||
                      data.issue_year ||
                      data.source_name ||
                      data.issue_volume ||
                      data.issue_number ||
                      data.page_no ? (
                        <strong>Source Details:</strong>
                      ) : (
                        ""
                      )}
                      {data.issue_year ? "(" + data.issue_year : ""}
                      {data.issue_month ? "-" + data.issue_month : ""}
                      {data.issue_date ? "-" + data.issue_date : ""}
                      {data.issue_month || data.issue_date || data.issue_year
                        ? ")"
                        : ""}
                      {data && data?.source_name} {data.issue_volume}
                      {data.issue_number ? "(" + data.issue_number + ") " : ""}
                      {data.page_no ? ", PP :" + data.page_no : ""}
                      {data.requester_customer_name &&
                        availiableData(
                          "Requester Institute Name",
                          data && data.requester_customer_name
                        )}
                      {data.delivery_customer_name &&
                        availiableData(
                          "Delivery Institute Name",
                          data && data.delivery_customer_name
                        )}
                      {data.ddr_id && availiableData("DDR ID", data.ddr_id)}
                      {data.authors &&
                        availiableData(
                          "Author(s) ",
                          ReactHtmlParser(data && data.authors)
                        )}
                      {data.user_name &&
                        availiableData(
                          "Requester Name",
                          data && data.user_name
                        )}
                      {data.user_department &&
                        availiableData(
                          "Requester Department",
                          data && data.user_department
                        )}
                      {data.user_designation &&
                        availiableData(
                          "Requester Designation",
                          data.user_designation
                        )}
                      {data.user_email &&
                        availiableData("Requester Email Id", data.user_email)}
                      {data.user_mobileno &&
                        availiableData(
                          "Requester Phone Number",
                          data.user_mobileno
                        )}
                      {data.requesteddate &&
                        availiableData("DDR Request Date", data.requesteddate)}
                      {data.request_priority &&
                        availiableData(
                          "Requester Priority",
                          data.request_priority
                        )}
                      {matchedLinkData && (
                        <div>
                          <span>
                            <strong>Document availability type:</strong>
                          </span>
                          <span>
                            {matchedLinkData?.links.map((val, idx) =>
                              val == null ? (
                                <span key={idx}>
                                  <strong style={{ marginLeft: "5px" }}>
                                    Print
                                  </strong>
                                </span>
                              ) : (
                                <span
                                  key={idx}
                                  onClick={() => handleClick(val)}
                                  style={{
                                    color: "blue",
                                    cursor: "pointer",
                                    marginLeft: "5px",
                                  }}
                                >
                                  <strong>Online</strong>
                                </span>
                              )
                            )}
                          </span>
                        </div>
                      )}
                      {data.is_duplicate === true && (
                        <div style={{ color: "red" }}>
                          <strong>Duplicate Request</strong>
                        </div>
                      )}
                    </td>

                    <td className="text-center">
                      <span> {data.status}</span>
                    </td>
                    {/* <td className="text-center">{data.requestedDate}</td> */}
                    <td className="text-center">{data.requesteddate}</td>
                    {admin_type === "library_admin" &&
                      (data && data.status === "Pending" ? (
                        <td className="text-center">
                          <SelectDropDown
                            data={selectStatusData}
                            label="Status"
                            name="ddr_action"
                            optional={data.ddr_id}
                            handleInputchange={handleInputchange}
                          />
                        </td>
                      ) : (
                        <td className="text-center">
                          <Button
                            onClick={() =>
                              handleViewButton(data.ddr_id, data.status)
                            }
                          >
                            View
                          </Button>
                        </td>
                      ))}
                  </tr>
                );
              })
            ) : (
              <tr className="text-center ">
                <td colSpan="6">
                  <strong className="contactPersondata">
                    No Data Available
                  </strong>
                </td>
              </tr>
            )}
          </tbody>
        </table>
        <p className="mt-4 contactPersondata">
          <strong>Disclaimer : </strong>
          The ILL facility is provided in J-Gate to facilitate end-users to
          identify the availability of the article in any library that user
          finds in the search. Informatics takes no responsibility for accuracy
          of the information provided or facilitating article delivery by any
          library to the requesting user.
        </p>
      </div>
    </>
  );
};

export default DDRArticleTable;
