import React from "react";
import { NavLink } from "react-router-dom";
import "./Sidebar.css";
import { MenuItems, ConsortiaMenuItems, ConsortiaMenuItemsForDefination } from "./constants";

/**
 * @author vimalkovath
 * @function Sidebar
 **/

const Sidebar = ({ user, customer }) => {
  const { admin_type } = user;

  return (
    <div className="Sidebar">
      <nav className="SidebarNav">
        {admin_type === "library_admin" && (
          <>
            {customer && customer.consortiamaster_id === null ? (
              <div>
                {MenuItems.map((item, index) => (
                  <div key={index}>
                    {item.label !== "DDR" && (
                      <NavLink to={item.url} key={index}>
                        <li className="SidebarNavLi">
                          <img src={item.icon} alt="img" />
                          <br />
                          {item.label}
                        </li>
                      </NavLink>
                    )}
                  </div>
                ))}
              </div>
            ) : (
              <div>
                {MenuItems.map((item, index) => (
                  <NavLink to={item.url} key={index}>
                    <li className="SidebarNavLi">
                      <img src={item.icon} alt="img" />
                      <br />
                      {item.label}
                    </li>
                  </NavLink>
                ))}
              </div>
            )}
          </>
        )}

        {admin_type === "consortia_admin" && (
          <>
            {customer && customer?.informaticscustomer_id === customer?.consortiamaster_id ? (
              <div>
                {/* {ConsortiaMenuItemsForDefination && ConsortiaMenuItemsForDefination.map((item, index) => ( */}
                {ConsortiaMenuItems && ConsortiaMenuItems.map((item, index) => (
                  <NavLink to={item.url} key={index}>
                    <li className="SidebarNavLi">
                      <img src={item.icon} alt="img" />
                      <br />
                      {item.label}
                    </li>
                  </NavLink>
                ))}
              </div>
            ) : (
              <div>
                {ConsortiaMenuItems && ConsortiaMenuItems.map((item, index) => (
                  <NavLink to={item.url} key={index}>
                    <li className="SidebarNavLi">
                      <img src={item.icon} alt="img" />
                      <br />
                      {item.label}
                    </li>
                  </NavLink>
                ))}
              </div>
            )}
          </>
        )}
      </nav>
    </div>
  );
};

export default Sidebar;
