import React, { Fragment, useState } from "react";
import { Pagination } from "./Pagination";


const PublisherUsageReport = ({ publisherDetailsTable }) => {

  publisherDetailsTable && publisherDetailsTable.forEach((item, ind) => {
    item.id = ind + 1
  });

  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(15);
  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords =
    publisherDetailsTable &&
    publisherDetailsTable.slice(indexOfFirstRecord, indexOfLastRecord);
  const nPages = Math.ceil(
    publisherDetailsTable && publisherDetailsTable.length / recordsPerPage
  );
  return (
    <Fragment>
      <div>
        <table className="table table-bordered table-striped">
          <thead className="summayTable">
            <tr>
              <th>SI.No</th>
              <th>Publisher Name</th>
              <th>Hits</th>
            </tr>
          </thead>
          <tbody>
            {!!currentRecords &&
              currentRecords.map((data, index) => (
                <tr key={index} className="UsageMetricsTr">
                  <td className="text-center"> {data.id} </td>
                  <td >{data.publisher_name}</td>
                  <td className="text-center">{data.hits}</td>
                </tr>
              ))}
            {!publisherDetailsTable && (
              <tr className="text-center fw-bold">
                <td colSpan="3" className="contactPersondata">
                  No Data Available
                </td>
              </tr>
            )}
          </tbody>
        </table>
        <div>
          <Pagination
            nPages={nPages && nPages}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            setRecordsPerPage={setRecordsPerPage}
            tableDataReport={publisherDetailsTable && publisherDetailsTable}
            recordsPerPage={recordsPerPage}
          />
        </div>
      </div>
    </Fragment>
  );
};

export default PublisherUsageReport;
