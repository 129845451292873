import React from "react";
import WidgetHeading from "../../atoms/Heading/WidgetHeading";
import Widget from "./Widget";

export default function UserCount({ userCountData }) {
  const data = userCountData.data[0][0];
  return (
    <>
      <div className="userCount">
        <WidgetHeading heading="User Count" />
        <div className="userCountWidget">
          {Object.keys(data).map((item, index) => (
            <Widget value={data[item]} item={item} key={index} />
          ))}
        </div>
      </div>
    </>
  );
}
