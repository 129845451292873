import React, { useState } from "react";
import CountuReport from "../../components/ReportsComponents/CountuReport";
import Usage from "../../components/ReportsComponents/Usage";
import DDRReport from "../../components/ReportsComponents/DDRReport";
import {
  ReportsMenu,
  ReportsMenuHeading,
  ReportsMenus,
} from "../../components/Sidebar/constants";
import ToggleSidebar from "../../components/Sidebar/ToggleSidebar";
import "./Reports.css";


const LibraryAdminReports = ({
  customerID,
  consortiamaster_id,
  admin_type,
}) => {
  const [active, setActive] = useState("");
  const handleActiveCallback = (active) => {
    setActive(active);
  };

  const renderSwitch = (active) => {
    switch (active) {
      case "ConterReport":
        return (
          <CountuReport
            customerID={customerID}
            consortiamaster_id={consortiamaster_id}
            admin_type={admin_type}
          />
        );
      case "Usage":
        return (
          <Usage
            customerID={customerID}
            consortiamaster_id={consortiamaster_id}
            admin_type={admin_type}
          />
        );
      case "DDRReport":
        return (
          <DDRReport
            customerID={customerID}
            consortiamaster_id={consortiamaster_id}
            admin_type={admin_type}
            from="library"
          />
        );

      default:
        return (
          <Usage
            customerID={customerID}
            consortiamaster_id={consortiamaster_id}
            admin_type={admin_type}
          />
        );
    }
  };

  return (
    <div className="Reports WithToggleSidebar reprts_mrgn_frm_top">
      {consortiamaster_id && consortiamaster_id !== null ? (
        <ToggleSidebar
          menu={ReportsMenu}
          heading={ReportsMenuHeading}
          setSelectedMenu={handleActiveCallback}
        />
      ) : (
        <ToggleSidebar
          menu={ReportsMenus}
          heading={ReportsMenuHeading}
          setSelectedMenu={handleActiveCallback}
        />
      )}

      <div className="ReportsContainer ContainerWithToggleSidebar">
        {renderSwitch(active)}
      </div>
    </div>
  );
};

export default LibraryAdminReports;
