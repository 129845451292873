import React, { useState, useEffect } from "react";
import ReactTooltip from "react-tooltip";

const ScrollBottomArrow = () => {
  const [showScroll, setShowScroll] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", checkScrollTop);
    return function cleanup() {
      window.removeEventListener("scroll", checkScrollTop);
    };

  }, []);

  const checkScrollTop = () => {
    if (!showScroll && window.pageYOffset > 300) {
      setShowScroll(true);
    } else if (showScroll && window.pageYOffset <= 300) {
      setShowScroll(false);
    }
  };
  const scrollToBottom = () => {
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: "smooth",
      block: "end",
    });
  };
  return (
    <>
      <button
        onClick={scrollToBottom}
        className="go-to-bottom"
        style={{ display: showScroll ? "flex" : "none" }}
        data-tip="Back to Down"
      >
        &#8681;
      </button>
      <ReactTooltip />
    </>
  );
};

export default ScrollBottomArrow;
