import React from 'react'

const CommonHeading = ({ heading }) => {
  return (
    <h6>{heading}</h6>
  )
}

export default CommonHeading

