import React from "react";

/**
 * @author
 * @function WidgetHeading
 **/

const WidgetHeading = ({ heading }) => {
  return <span className="dashboardHeadings">{heading}</span>;
};

export default WidgetHeading;
