import React from 'react'
import './InputArea.css';
export default function InputArea({ name, placeholder, handleInputchange, value, classname,rows,parentclassname }) {
    // const _placeholder = placeholder || "Enter Value";
    const _placeholder = placeholder ;
    const _classname = classname || "";
    const _parentclassname = parentclassname || "inputFieldOrgDetail";

    return (
        <>
            <div className={_parentclassname}>
                <div className="inputFieldOrgDetailLabel">{_placeholder}</div>
                {/* <Form.Group controlId={name}>
                // */}

                <textarea 
                 name={name}
                 className={_classname}
                 placeholder={_placeholder}
                rows={rows || 5}
                value={value} 
                onChange={(e) => handleInputchange(e.target.value, name)}
                />
               
                  
                {/* </Form.Group> */}
            </div>
        </>
    )
}
