import React from "react";
import "./UserUpload.css";
import { getLocalStorage } from "../../utilities/storage";
import { LibraryAdminUploads } from "./UserUploads";
/**
 * @author
 * @function UserUpload
 **/

const UserUpload = () => {
  const { customerID, admin_type } = getLocalStorage("user");
  const { consortiamaster_id } = getLocalStorage("customer");

  return (
    <>
      {admin_type === "library_admin" && (
        <LibraryAdminUploads
          customerID={customerID}
          consortiamaster_id={consortiamaster_id}
          admin_type={admin_type}
        />
      )}
    </>
  );
};

export default UserUpload;
