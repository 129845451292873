export const getValuesFromData = (arrayData, objectKeys) => {
  Object.keys(objectKeys).forEach((item) => {
    if (arrayData[item]) {
      objectKeys[item] = arrayData[item];
    } else {
      objectKeys[item] = "";
    }
  });

  return objectKeys;
};

export const getPasswordValuesFromData = (arrayData, objectKeys) => {
  Object.keys(objectKeys).forEach((item) => {
    if (arrayData[item]) {
      objectKeys[item] = arrayData[item];
    }
    // else{
    //      objectKeys[item] ="";
    // }
  });

  return objectKeys;
};
