import React from "react";
import { Modal } from "react-bootstrap";
import "./modals.css";

export const ContuLendingInfoDialog = ({
  show,
  modalClose,
  heading,
  content,
  // full = false,
  size = "lg",
  successCallback,
  dialogClassName = "dialog",
  handle_close,
  setIsAddUserData,
  setIsAddSubscription,
}) => {
  return (
    <Modal
      show={show}
      size={size}
      onHide={modalClose}
      backdrop={false}
      dialogClassName={dialogClassName}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <h4>{heading}</h4>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>{content}</div>
        <br />
        <div className="text-center">
        </div>
      </Modal.Body>
    </Modal>
  );
};
