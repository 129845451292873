import React from "react";
import { ThreeDots } from "react-loader-spinner";

/**
 * @author
 * @function Loading
 **/

const Loading = () => {
  return (
    <div className="d-flex ">
      <p className=" mt-3 fw-bold"> Loading</p>
      <div>
        <ThreeDots
          height="70"
          width="30"
          radius="9"
          color="blue"
          ariaLabel="loading"
        />
      </div>
    </div>
  );
};

export default Loading;
