import React, { Fragment } from "react";
import NoData from "../../atoms/NoData/NoData";



const ConsortiaViewMemberModel = ({ memberTableData }) => {
  const _memberTableData = memberTableData
    ? memberTableData.map((item) => {
      return { name: Object.values(item)[0] };
    })
    : [];

  return (
    <>
      {!_memberTableData && <NoData />}
      {!!_memberTableData && (
        <Fragment>
          <table className="OrgTable">
            <thead>
              <tr>
                <th>SI.No</th>
                <th className="ps-4">Member Details</th>
              </tr>
            </thead>
            <tbody>
              {_memberTableData && _memberTableData.map((data, index) => (
                <tr key={index}>
                  <td> {index + 1} </td>
                  <td className="ps-4"> {data.name} </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Fragment>
      )}
    </>
  );
};

export default ConsortiaViewMemberModel;
