import React, { useState, useEffect, Fragment } from "react";
import _ from "lodash";
import { fetchUrl } from "../../utilities/apiFunctions/fatchUrl";
import {
  downloadLibraryHoldingSummary,
  get_holding_summary_report_for_library,
} from "../../utilities/constants";
import Loading from "../../components/Loading/Loading";
import CommonHeading from "../../atoms/Heading/CommonHeading";
import {
  ViewHoldingsSummary,
  vhSubscriptionType,
} from "../../containers/ResourceMgmt/constants";
import NoData from "../../atoms/NoData/NoData";
import { Button } from "react-bootstrap";
import BasicSelectDropDown from "../../atoms/DropDown/BasicSelectDropDown";
import { Pagination } from "../ReportsComponents/Pagination";

/**
 * @author
 * @function ViewHoldingSummary
 **/

const ViewHoldingSummary = ({ customerID }) => {
  const [data, setData] = useState({
    subscriptionType: "0",
  });
  const [loading, setLoading] = useState(true);
  const [tableViewHoldingSummary, setTableViewHoldingSummary] = useState([]);
  const [tableData, setTableData] = useState([]);

  tableViewHoldingSummary &&
    tableViewHoldingSummary.forEach((item, ind) => {
      item.id = ind + 1;
    });

  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(15);
  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords =
    tableViewHoldingSummary &&
    tableViewHoldingSummary.slice(indexOfFirstRecord, indexOfLastRecord);
  const nPages = Math.ceil(
    tableViewHoldingSummary && tableViewHoldingSummary.length / recordsPerPage
  );

  const handleLoadButton = async () => {
    setLoading(true);
    const dynamic = _.cloneDeep(data);
    if (dynamic["subscriptionType"] === "") {
      dynamic["subscriptionType"] = "0";
    }
    const response = await fetchUrl(
      get_holding_summary_report_for_library(customerID, data.subscriptionType)
    );
    setTableViewHoldingSummary(response.data[0][0].subscription_list);
    setTableData(response.data[0][0].client_name);
    setLoading(false);
  };

  const handleInputchange = (value, name) => {
    const dynamic = _.cloneDeep(data);
    dynamic[name] = value;
    if (dynamic["subscriptionType"] === "") {
      dynamic["subscriptionType"] = "0";
    }
    setData(dynamic);
  };

  useEffect(() => {
    handleLoadButton();

  }, []);

  const handleButton = () => {
    const downloadUrl = downloadLibraryHoldingSummary(
      customerID,
      data.subscriptionType
    );
    window.open(downloadUrl);
  };

  const countData = tableViewHoldingSummary && tableViewHoldingSummary.map((item) => item.resource_count);
  return (
    <div className="ManageOASubscription">
      <CommonHeading heading={ViewHoldingsSummary} />

      <div className="common-select-space">
        <BasicSelectDropDown
          label="Select Access Type"
          name="subscriptionType"
          data={vhSubscriptionType}
          classname={"w-260 common-border"}
          hasall={false}
          handleInputchange={handleInputchange}
        />
        <Button className="viewhld_btn" onClick={handleLoadButton}>Load</Button>
      </div>
      {loading && <Loading />}
      <div className="d-flex">
        <table className="table table-bordered table-striped ">
          <thead className="summayTable">
            <tr>
              <th>SI No</th>
              <th>Member Name</th>
              <th>Type of Subscription</th>
              <th>Unique Count</th>
            </tr>
          </thead>
          <tbody>
            <>
              {currentRecords &&
                currentRecords.map((data, index) => (
                  <Fragment key={index}>
                    {data.subscription_type !== "Unique Total" && (
                      <tr key={index} className="contactPersondata">
                        <td className="text-center"> {data.id} </td>
                        {tableData && <td> {tableData}</td>}
                        <td className="text-center">
                          {data.subscription_type}
                        </td>
                        <td className="text-center ">{data.resource_count}</td>
                      </tr>
                    )}

                    {data.subscription_type === "Unique Total" && (
                      <>
                        <tr>
                          <td colSpan="3" className="text-center">
                            <strong>{data.subscription_type}</strong>
                          </td>
                          <td className="text-center contactPersondata">
                            <strong>{data.resource_count}</strong>
                          </td>
                        </tr>
                        <tr className="text-end ">
                          <td colSpan="4 ">
                            <Button
                              className={"holdingSummery contactPersondata"}
                              onClick={handleButton}
                            >
                              Download
                            </Button>
                          </td>
                        </tr>
                      </>
                    )}
                  </Fragment>
                ))}
            </>
          </tbody>
        </table>
      </div>
      {countData >= 1 && (
        <div className="text-end ">
          <Button onClick={handleButton} className="contactPersondata">
            Download{" "}
          </Button>
        </div>
      )}
      {!tableViewHoldingSummary && <NoData />}
      <div className=" mt-2 pgnation">
        <Pagination
          nPages={nPages && nPages}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          setRecordsPerPage={setRecordsPerPage}
          tableDataReport={tableViewHoldingSummary && tableViewHoldingSummary}
          recordsPerPage={recordsPerPage}
        />
      </div>
    </div>
  );
};

export default ViewHoldingSummary;
