import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { existUserUploadExcelFormatUrl } from "../../utilities/constants";
import { NewModal } from "../Modals/Modal";
import DeleteProfileUserData from "./DeleteProfileUsers";
import { UserInformation } from "./UserInformation";
import AddUserUploadData from "./UserUploadData";

export const LibraryAdminUploads = ({ customerID }) => {
  const [isAddUserData, setIsAddUserData] = useState(false);
  const [isDeleteUserData, setIsDeleteUserData] = useState(false);
  const [isHelpData, setIsHelpData] = useState(false);

  const handleAdduserUpload = () => {
    setIsAddUserData(true);
  };

  const handleDeleteUserData = () => {
    setIsDeleteUserData(true);
  };

  const modalData = (
    <AddUserUploadData
      customerID={customerID}
      setIsAddUserData={setIsAddUserData}
    />
  );

  const modalContent = <UserInformation />;

  const modalData_delete = (
    <DeleteProfileUserData
      customerID={customerID}
      setIsDeleteUserData={setIsDeleteUserData}
    />
  );

  const handleUserListExcelDownload = async () => {
    window.open(existUserUploadExcelFormatUrl(customerID));
  };

  const handleHelpButton = () => {
    setIsHelpData(true);
  };

  return (
    <div className="ms-5 total_body usr_uplds_mrgn_frm_top">
      <NewModal
        show={isHelpData}
        modalHandle={setIsHelpData}
        data={modalContent}
        size="lg"
        heading="Instructions"
      // dialogClassName={"OrgViewTableModal"}
      />
      <NewModal
        show={isAddUserData}
        modalHandle={setIsAddUserData}
        data={modalData}
        size="md"
        heading="Upload Users"
      // dialogClassName={"OrgViewTableModal"}
      />

      <NewModal
        show={isDeleteUserData}
        modalHandle={setIsDeleteUserData}
        data={modalData_delete}
        size="md"
        heading="Upload Users for Deletion"
      // dialogClassName={"OrgViewTableModal"}
      />
      <div>
        <div>
          <h6 className="header">Upload User profile Data</h6>
        </div>
        <div className="content_help">
          <Button
            className="content_button usr_uplds"
            variant="secondary "
            onClick={handleHelpButton}
          >
            Help
          </Button>
        </div>
        <div className="buttons_user">
          <Button onClick={handleAdduserUpload} className="usr_uplds">
            Upload Profile Users
          </Button>
          <Button className="ms-4 usr_uplds" onClick={handleDeleteUserData}>
            Delete Profile Users
          </Button>
          <Button
            className="ms-4 usr_uplds"
            onClick={handleUserListExcelDownload}
          >
            Download User Profile List
          </Button>
        </div>
      </div>
    </div>
  );
};
