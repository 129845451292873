import React from "react";
import LogoWhiteJgate from "../../assets/img/Logo/jgate.png";


const LogoJgate = () => {
  return (
    <div className="LogoJgate">
      <img className="Logo" src={LogoWhiteJgate} alt="img" />
    </div>
  );
};

export default LogoJgate;
