import React, { useState, Fragment } from "react";
import { ContuLendingInfoDialog } from "../../atoms/Modals/ContuLendingInfoDialog";
import ContuBorrowedILLDeliveredDataTable from "./ContuBorrowedDeleverdDataTable";
import { Pagination } from "../ReportsComponents/Pagination";
import { baseUrl } from "../../utilities/constants";



const ContuBrrrowDataTable = ({ tableData }) => {
  const [isContLendingData, setIsContLendingData] = useState(false);
  const [iLLBorrowedData, setILLBorrowedData] = useState([]);

  iLLBorrowedData &&
    iLLBorrowedData?.forEach((item, ind) => {
      item.id = ind + 1;
    });

  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(15);
  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords =
    iLLBorrowedData &&
    iLLBorrowedData?.slice(indexOfFirstRecord, indexOfLastRecord);
  const nPages = Math.ceil(
    iLLBorrowedData && iLLBorrowedData?.length / recordsPerPage
  );
  const [isTableLoad, setIsTableLoad] = useState(false);

  const handleButton = async (illData) => {
    setIsContLendingData(true);
    setIsTableLoad(true)
    await fetch(
      `${baseUrl}/api/get_contu_ddr_details/get_contu_ddr_details?ddr_reference_id=${illData?.reference_id}`
    )
      .then((val) => val.json())
      .then((res) => {
        setILLBorrowedData(res.data[0]);
        setIsTableLoad(false);

      });

  };

  return (
    <Fragment>
      <ContuLendingInfoDialog
        show={isContLendingData}
        modalClose={setIsContLendingData}
        content={
          <Fragment>
            <div>
              <ContuBorrowedILLDeliveredDataTable
                iLLReferenceData={currentRecords}
                isIndexed="true"
                isTableLoad={isTableLoad}
              />
            </div>
            <div>
              <Pagination
                nPages={nPages && nPages}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                setRecordsPerPage={setRecordsPerPage}
                tableDataReport={iLLBorrowedData && iLLBorrowedData}
                recordsPerPage={recordsPerPage}
              />
            </div>
          </Fragment>
        }
        successCallback={handleButton}
        heading="CONTU Details"
        size={"lg"}
        dialogClassName={"ContuTableModal"}
      />
      <table className="table table-bordered table-striped">
        <thead className="summayTable">
          <tr>
            <th>SI.No</th>
            <th>Journal Name</th>
            <th>ILL Borrowed </th>
            <th>Balance</th>
          </tr>
        </thead>
        <tbody>
          {!tableData && (
            <tr className="text-center contactPersondata">
              <td colSpan="4">
                <strong>No Data Available</strong>
              </td>
            </tr>
          )}
          {!!tableData &&
            tableData.map((data, index) => (
              <tr key={index} className="contactPersondata">
                {/* {isIndexed && <td className="ddrtable"> {index + 1} </td>} */}
                <td className="ddrtable"> {data.id} </td>
                <td>{data.resource_name}</td>
                <td className="ddrtable ">
                  <span
                    className="coutuTable"
                    onClick={() => handleButton(data)}
                  >
                    {data.ill_fulfilled}
                  </span>
                </td>
                <td className="ddrtable">{data.balance} </td>
              </tr>
            ))}
        </tbody>
      </table>
    </Fragment>
  );
};

export default ContuBrrrowDataTable;
