import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import InputField from "../../atoms/InputField/InputField";
import _ from "lodash";
import { MANAGECONSORTIASUBSCRIPTION } from "../../containers/ResourceMgmt/constants";
import { NewModal } from "../Modals/Modal";

import {
  removeConsortiaSubscribedPackageUrl,
  getConsortiaPackageAssignedMemberListUrl,
  getConsortiaSbdPackagesListUrl,
  downloadConsortiaSubscribedPackagesListUrl,
} from "../../utilities/constants";

import ConsortiaViewMemberModel from "./ConsortiaViewMemberModel";
import ConsortiaResourceTable from "./ConsortiaResourceTable";
import { ConfirmDialog } from "../../atoms/Modals/ConfirmDialog";
import { ConsrtiaSubscriptionProperty } from "../../containers/ResourceMgmt/constants";
import { fetchUrl } from "../../utilities/apiFunctions/fatchUrl";
import ConsortiaAddNewPackages from "./AddNewPackages";
import CommonHeading from "../../atoms/Heading/CommonHeading";
import PackageTypeDropDown from "../../atoms/DropDown/PackageTypeDropDown";
import { InfoDialogconsortia } from "../../atoms/Modals/InfoDialogconsortis";
import { Pagination } from "../ReportsComponents/Pagination";
import Loading from "../Loading/Loading";


const ManageConsortiaSubscription = ({
  consortiamaster_id,
  aggregatorMasterList,
  consortiaMemberList,
  packagesListAvailableForConsortia,
}) => {
  const [data, setData] = useState({
    package_name: "",
    publisher_package: "0",
    aggregator_id: "0",
    package_type: "0",
  });

  const [loading, setLoading] = useState(false);

  const [viewOn, setViewOn] = useState(false);
  const [isAlertOn, setIsAlertOn] = useState(false);
  const [isDeleteAlertOn, setIsDeleteAlertOn] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [deleteUrl, setDeleteUrl] = useState("");
  const [memberTableData, setMemberTableData] = useState([]);
  const [isViewMember, setIsViewMember] = useState(false);
  const [isSearched, setIsSearched] = useState(false);

  tableData && tableData.forEach((item, ind) => { item.id = ind + 1 });

  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(15);
  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords =
    tableData && tableData.slice(indexOfFirstRecord, indexOfLastRecord);
  const nPages = Math.ceil(tableData && tableData.length / recordsPerPage);

  useEffect(() => {
    handleLoadButton();

  }, []);

  const handleInputchange = (value, name) => {
    const dynamic = _.cloneDeep(data);
    dynamic[name] = value;
    setData(dynamic);
  };

  const handleAddPackageSubscription = () => {
    setViewOn(true);
  };
  const fnClose = () => {
    setViewOn(false);
  };

  const aggregatorType = [
    { 0: "All" },
    { 1: "Aggregator" },
    { 2: "Publisher" },
  ];

  const handleViewAction = async (viewData) => {
    const str = `package_id=${viewData.package_id}&consortia_id=${consortiamaster_id}`;
    const response = await fetchUrl(getConsortiaPackageAssignedMemberListUrl(str));
    setMemberTableData(response.data[0][0]);
    setIsViewMember(true);
  };

  const handleAction = (item) => {
    const url = `package_id=${item.package_id}&consortia_id=${consortiamaster_id}`;
    setDeleteUrl(url);
    setIsAlertOn(true);
  };

  const handleLoadClick = async () => {
    setIsSearched(true);
    setLoading(true);

    const _data = {
      consortia_id: consortiamaster_id,
      ...data,
    };

    delete _data.publisher_package;
    if (data.aggregator_id === "") {
      _data.aggregator_id = "0";
    }
    let str = Object.entries(_data).map(([key, val]) => `${key}${val ? "=" : ""}${val}`).join("&");
    const response = await fetchUrl(getConsortiaSbdPackagesListUrl(str));
    setTableData(response.data[0][0]);
    setLoading(false);
  };

  const handleLoadButton = async () => {
    setIsSearched(false);
    setLoading(true);

    const _data = {
      consortia_id: consortiamaster_id,
      ...data,
    };

    delete _data.publisher_package;
    delete _data.package_name;
    if (data.aggregator_id === "") {
      _data.aggregator_id = "0";
    }
    let str = Object.entries(_data)
      .map(([key, val]) => `${key}${val ? "=" : ""}${val}`)
      .join("&");

    const response = await fetchUrl(getConsortiaSbdPackagesListUrl(str));
    setTableData(response.data[0][0]);
    setLoading(false);
  };

  const removeData = async () => {
    setLoading(true);
    const response = await fetchUrl(removeConsortiaSubscribedPackageUrl(deleteUrl));

    if (
      response &&
      response?.data[0][0] === "Selected package removed successfully!"
    ) {
      setIsDeleteAlertOn(true);
      setLoading(false);
      setIsAlertOn(false);

      handleLoadClick();
    }
  };
  const handleDownloadHoldings = () => {
    const _data = {
      consortia_id: consortiamaster_id,
      aggregator_id: data.aggregator_id,
      package_type: data.publisher_package,
      ...data,
    };

    delete _data.publisher_package;
    delete _data.aggregator_id;
    let str = Object.entries(_data).map(([key, val]) => `${key}${val ? "=" : ""}${val}`).join("&");
    window.open(downloadConsortiaSubscribedPackagesListUrl(str));
  };

  const modalData = (
    <ConsortiaAddNewPackages
      packagesListAvailableForConsortia={packagesListAvailableForConsortia && packagesListAvailableForConsortia}
      consortiaMemberList={consortiaMemberList}
      consortiamaster_id={consortiamaster_id}
      fnClose={fnClose}
    />
  );

  const modalViewMemberData = (
    <ConsortiaViewMemberModel
      aggregatorPackagesList={aggregatorMasterList}
      memberTableData={memberTableData}
    />
  );

  const mapSelectPackageData = (data) => {
    const _dat = data;
    const _data = _dat.map((item) => {
      const a = {};
      a["value"] = Object.keys(item)[0];
      a["label"] = Object.values(item)[0];
      return a;
    });

    return _data;
  };

  return (
    <div>
      <NewModal
        show={isViewMember}
        modalHandle={setIsViewMember}
        data={modalViewMemberData}
        size="md"
        heading="View Member"
      />
      <NewModal
        show={viewOn}
        modalHandle={setViewOn}
        data={modalData}
        size="lg"
        heading="Add New Package Subscription Details"
        dialogClassName={"OrgViewTableModal"}
      />
      <ConfirmDialog
        show={isAlertOn}
        modalClose={setIsAlertOn}
        content={"Are you sure you want to remove this ?"}
        successCallback={removeData}
        heading="Confirm"
      />
      <InfoDialogconsortia
        show={isDeleteAlertOn}
        modalClose={setIsDeleteAlertOn}
        content={" Removed Successfully"}
        successCallback={removeData}
        heading="Confirm"
      />

      <CommonHeading heading={MANAGECONSORTIASUBSCRIPTION} />
      <div className="ViewConfigureLibraryResourcesRow">
        <Button
          variant="secondary contactPersondata"
          onClick={handleDownloadHoldings}
        >Download Holdings</Button>
        <Button
          onClick={handleAddPackageSubscription}
          className="contactPersondata"
        > Add New Package Subscription Details</Button>
      </div>

      <div className="ViewConfigureLibraryResourcesRowSearchbtn">
        <InputField
          placeholder={"Search package Name"}
          name={"package_name"}
          value={data["package_name"] || ""}
          handleInputchange={handleInputchange}
          classname={`w-350 package_name `}
          onKeyPress={(event) => {
            if (event.key === "Enter") {
              handleLoadClick();
            }
          }}
        />
        <div onClick={handleLoadClick}>
          <i className="fa fa-search searchBtn"> </i>
        </div>
      </div>
      <div className="ViewConfigureLibraryResourcesRowSearchbtn">
        <div className="common-select-space w-300">
          <PackageTypeDropDown
            data={mapSelectPackageData(aggregatorType)}
            label="Package Type"
            name="package_type"
            handleInputchange={handleInputchange}
          />
        </div>
        <div className="common-button">
          <Button onClick={handleLoadButton} className="contactPersondata">Load</Button>
        </div>
      </div>

      {loading && <Loading />}
      <div className="ViewConfigureLibraryResourcesRow ConsortiaResourceTableRow">
        <ConsortiaResourceTable
          tableProperty={ConsrtiaSubscriptionProperty}
          tableData={currentRecords && currentRecords}
          action="Delete"
          viewAction="View members"
          handleAction={handleAction}
          handleViewAction={handleViewAction}
          isSearched={isSearched}
        />
      </div>
      <div>
        <Pagination
          nPages={nPages && nPages}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          setRecordsPerPage={setRecordsPerPage}
          tableDataReport={tableData && tableData}
          recordsPerPage={recordsPerPage}
        />
      </div>
    </div>
  );
};

export default ManageConsortiaSubscription;
