import React from "react";
import { Form } from "react-bootstrap";
import "./DropDown.css";

const SelectDropDown = ({
  data,
  classname,
  name,
  value,
  label,
  handleInputchange,
  optional,
  defaultValue,
  defaultSelectValue,
}) => {
  const _label = label ? true : false;
  const _optional = optional || "";
  return (
    <>
      {_label && <div className="inputFieldOrgDetailLabel">{label}</div>}
      <Form.Control
        className={` SelectDropDown ${classname}`}
        required
        as="select"
        custom
        defaultValue={defaultSelectValue}
        onChange={(e) => handleInputchange(e.target.value, name, _optional)}
      >
        <option value={""} className="placeHolderColor">
          {defaultValue || "Select"}
        </option>

        {data &&
          data?.map((item, index) => (
            <option key={index} value={item.value} className="instfntsize">
              {item.label}
            </option>
          ))}
      </Form.Control>
    </>
  );
};

export default SelectDropDown;
