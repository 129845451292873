import React from "react";

export const UserInformation = () => {
  return (
    <div className="m-5">
      <div>
        <p className="hding">This feature is to create the profile users for J-Gate in bulk. </p>
      </div>
      <div>
        <p>
          <strong>Upload Profile Users </strong>
        </p>

        <ol>
          <li>Click on “Upload Profile Users” </li>
          <li>Click on Download format. </li>
          <li>
            Open the downloaded file(which will be available in Download folder
            in your desktop){" "}
          </li>
          <li>Enter the user details in excel </li>
          <li>
            Please ensure to fill in First Name, Email-id and password as
            mandatory. Password should be minimum of 6 characters.{" "}
          </li>
          <li>Save the file in your desktop </li>
          <li>Select this file for upload on click for choose file </li>
          <li>Click on “Upload” Button</li>
          <li>
            If there is no error w.r.t Email ID and password given is not “Null”
            or blank then users will be created successfully{" "}
          </li>
          <li>
            If error found , then message will be displayed regarding partial
            creation of users and error list will be downloaded{" "}
          </li>
        </ol>
      </div>
      <div>
        <p>
          <strong>Delete Profile Users</strong>
        </p>
        <ol>
          <li>Click on “Delete Profile Users” </li>
          <li>Click on Download user list. </li>
          <li>
            Open the downloaded file(which will be available in Download folder
            in your desktop)
          </li>
          <li>This list contains all the user list of the Institute</li>
          <li>
            Select the user name / Email ID and mark as TRUE for the column name
            “Is Deleted”{" "}
          </li>
          <li>
            Other users which are not to be deleted can be removed from the
            sheet.{" "}
          </li>
          <li>Save the file in your desktop </li>
          <li>Select this file for upload on click for choose file </li>
          <li>Click on “Upload” Button </li>
          <li>
            If there is no error w.r.t Email ID and password given is not “Null”
            or blank then users will be deleted successfully{" "}
          </li>
          <li>
            If error found , then message will be displayed regarding partial
            deletion of users and error list will be downloaded{" "}
          </li>
        </ol>
      </div>
      <div>
        <p>Download Profile Users </p>
      </div>
      <div>
        <p>Click to this feature will download the user list.</p>
      </div>
    </div>
  );
};
