import React, { useState } from "react";
import _ from "lodash";
import SelectDropDown from "../../atoms/DropDown/SelectDropDown";
import InputField from "../../atoms/InputField/InputField";
import { selectDeliveyModeData, updateConsortiaDDRForLibrarianEmail, } from "../../containers/DDR/constants";
import InputArea from "../../atoms/InputArea/InputArea";
import { useEffect } from "react";
import FileUpload from "../../atoms/FileUpload/FileUpload";
import { DeliveryNoteDDRFullfillRequest } from "./DeliveryNoteDDRFullfillRequest";
import ReactHtmlParser from "react-html-parser";
import { DDRDiscardStatusModal } from "./DDRDiscardStatusModel";

const DDRFulfillMailTemplate = ({ customerID, modalClose, fnData, getDDR, setIsFulfillEmailTemplate }) => {
  const [data, setData] = useState(fnData);
  const [isOnline, setOnline] = useState(false);
  const [isCourier, setCourier] = useState(false);
  const [fullfillOutsideDDRSystem, setFullfillOutsideDDRSystem] = useState(false);
  const [error, setError] = useState("");
  const [isFileUpload, setIsFileUpload] = useState("");
  const isFileUploadData = isFileUpload.name;
  const [modeOfDelivery, setModeOfDelivery] = useState({ mode_of_delivery: "Online" });
  const [isCourierNumber, setIsCourierNumber] = useState("");
  const [isDeliveryMode, setIsDeliveryMode] = useState("");
  const [isDeliveryNote, setIsDeliveryNote] = useState(false);
  const [isChangedSubject, setIschangedSubject] = useState(false);

  useEffect(() => {
    if (modeOfDelivery["mode_of_delivery"] === "Online") {
      setOnline(true)
      setIsDeliveryMode("online")
    }
  }, [modeOfDelivery])

  useEffect(() => {
    if (isFileUpload === true) {
      setIsDeliveryMode("online")
    }
  }, [isFileUpload])

  useEffect(() => {
    let _error = "Message ";
    if (data["contu_message"] !== "") {
      setError("");
    } else {
      setError("Fill the neccessay field " + _error);
    }
  }, [data])

  useEffect(() => {
    const user = data?.user_name || "Sir / Madam ";
    const user_name = user && user.charAt(0).toUpperCase() + user.slice(1)
    const article_Title = ReactHtmlParser(data && data?.article_name);
    const journal_name = ReactHtmlParser(data && data?.journal_name);
    const authors = ReactHtmlParser(data && data?.authors);
    const volume = data && data?.volume;
    const issue = data && data?.issue;
    const year = data && data?.issue_year;
    const date_of_request = data && data?.requestedDate;
    const client_name = data && data?.Fulfilling_customer_name;
    const client_address = data && data?.fulfilling_address;
    const client_city = data && data?.city;
    const client_state = data && data?.state;
    const mailContent = _.cloneDeep(data);

    if (fullfillOutsideDDRSystem === true) {
      setIsCourierNumber("");
      mailContent["contu_message"] = `Dear ${user_name !== null ? user_name : ""},

The requested article will be Fulfilled outside the DDR system.
${isDeliveryMode !== "" ? `Delivery Mode : Fulfilled -  ${isDeliveryMode}` : ""}

Regards
${client_name !== null ? `${client_name}` : ""}
${client_address !== null ? `${client_address}` : ""}
${client_city !== null ? client_city : ""}
${client_state !== null ? client_state : ""}`;

      setData(mailContent);
    };

    if (isCourier === true) {
      const couriesr_id = isCourierNumber && isCourierNumber.length;
      mailContent["contu_message"] = `Dear ${user_name !== null ? user_name : ""},

The requested article is couriered to your institutional library and should be reaching shortly. You may please contact your librarian for the same
${isDeliveryMode !== "" ? `Delivery Mode: Fulfilled -  ${isDeliveryMode}` : ""}
${couriesr_id >= 1 ? `Courier Details: ${isCourierNumber}` : ""}

Requested article details :
${article_Title !== null ? `Article Title  : ${article_Title}` : ""}
${journal_name !== null ? `Journal        : ${journal_name}` : ""}
${authors !== null ? `Authors       : ${authors}` : ""}
${volume !== null ? `Volume        : ${volume}` : ""}
${issue !== null ? `Issue            : ${issue}` : ""}
${year !== null ? `Year             : ${year}` : ""}
${date_of_request !== null ? `Date of Request : ${date_of_request}` : ""}

Regards
${client_name !== null ? `${client_name}` : ""}
${client_address !== null ? `${client_address}` : ""}
${client_city !== null ? client_city : ""}
${client_state !== null ? client_state : ""}`;
      setData(mailContent);
    };

    if (isOnline === true) {
      setIsCourierNumber("");
      mailContent["contu_message"] = `Dear ${user_name !== null ? user_name : ""},

Please find attached the requested article.
${isDeliveryMode !== "" ? `Delivery Mode : Fulfilled -  ${isDeliveryMode}` : ""}

Requested article details :
${article_Title !== null ? `Article Title  : ${article_Title}` : ""}
${journal_name !== null ? `Journal        : ${journal_name}` : ""}
${authors !== null ? `Authors       : ${authors}` : ""}
${volume !== null ? `Volume        : ${volume}` : ""}
${issue !== null ? `Issue            : ${issue}` : ""}
${year !== null ? `Year             : ${year}` : ""}
${date_of_request !== null ? `Date of Request : ${date_of_request}` : ""}

Regards
${client_name !== null ? `${client_name}` : ""}
${client_address !== null ? `${client_address}` : ""}
${client_city !== null ? client_city : ""}
${client_state !== null ? client_state : ""}`;
      setData(mailContent);
    };

  }, [fullfillOutsideDDRSystem, isOnline, isCourier, isCourierNumber]);

  const handleInputchange = (value, name) => {
    const _dat = _.cloneDeep(data);
    _dat[name] = value;
    setData(_dat);

    setModeOfDelivery(_dat[name])
    if (name === "mode_of_delivery") {
      setIsDeliveryMode(value);
      if (value === "online") {
        setCourier(false);
        setOnline(true);
        setFullfillOutsideDDRSystem(false)
      } else if (value === "courier") {
        setOnline(false);
        setCourier(true);
        setFullfillOutsideDDRSystem(false)
      } else if (value === "fullfill_outside_ddr_system") {
        setOnline(false);
        setCourier(false);
        setFullfillOutsideDDRSystem(true);
      } else {
        setOnline(false);
        setCourier(true);
        setFullfillOutsideDDRSystem(false);
      }

    }

    if (name === "courier_no") {
      setIsCourierNumber(value);
    }

    if (name === "file") {
      if (value.type === "application/pdf") {
        setError("");
        modeOfDelivery["mode_of_delivery"] = "Online";
      } else {
        setError("Please upload Pdf file format ");
      }
    }

    if (name === "mail_subject") {
      setIschangedSubject(true);
    } else {
      setIschangedSubject(false);
    }
  };

  const handleCancel = () => {
    modalClose();
  };

  const handleSubmitCouriesSubmitButton = () => {
    let _error = "Message ";
    if (data["contu_message"] !== "") {
      setError("");
      handleCouriesSubmitButton();
    } else {
      setError("Fill the neccessay field " + _error);
    }
  };

  const handleClear = () => {
    let text_message = _.cloneDeep(data);
    text_message["contu_message"] = "";
    setData(text_message);
  }

  const handleCouriesSubmitButton = () => {
    const _dat = _.cloneDeep(data);
    const _data = {};
    _data["consortiaddr_id"] = getDDR;
    _data["ddr_status"] = "2";
    _data["mode_of_delivery"] = _dat["mode_of_delivery"];
    _data["delivered_date"] = _dat["current_date"];
    _data["ddr_reference_no"] = _dat["mail_subject"];
    _data["delivery_remarks"] = null;
    _data["courier_no"] = _dat["courier_no"] || null;
    _data["delay_reason"] = null;
    _data["discard_reason"] = null;
    _data["delivering_userid"] = customerID;
    _data["is_duplicate"] = false;
    const str = `{"data":[${JSON.stringify(_data)}]}`;
    const formData = new FormData();

    if (isChangedSubject === true) {

      formData.append("detailjson", str);
      formData.append("attachment_file", null);
      // formData.append("To_mail_Id", "shylaja@informaticsglobal.com");
      // formData.append("CC_mail_Id", "shylabhatt@gmail.com");
      formData.append("To_mail_Id",_dat["to_mail"]);
      formData.append("CC_mail_Id", _dat["cc_email"] + ";" +_dat["approver_admin_mail"]);
      formData.append("Subject", _dat["mail_subject"]);
      formData.append("Message", _dat["contu_message"]);

      fetch(updateConsortiaDDRForLibrarianEmail, {
        method: "POST",
        body: formData,
      })
        .then((response) => response.json())
        .then((result) => {
          if (result.data[0][0] === "Success") {
            setIsDeliveryNote(true);
          } else {
            alert("Something went wrong")
            setIsDeliveryNote(false);
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    } else {
      formData.append("detailjson", str);
      formData.append("attachment_file", null);
      // formData.append("To_mail_Id", "shylaja@informaticsglobal.com");
      // formData.append("CC_mail_Id", "shylabhatt@gmail.com");
      formData.append("To_mail_Id",_dat["to_mail"]);
      formData.append("CC_mail_Id", _dat["cc_email"] + ";" +_dat["approver_admin_mail"]);
      formData.append("Subject", _dat["mail_subject"] + "," + data["action_name"]);
      formData.append("Message", _dat["contu_message"]);

      fetch(updateConsortiaDDRForLibrarianEmail, {
        method: "POST",
        body: formData,
      })
        .then((response) => response.json())
        .then((result) => {
          if (result.data[0][0] === "Success") {
            setIsDeliveryNote(true);
          } else {
            alert("Something went wrong")
            setIsDeliveryNote(false);
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  }


  const handleOnlineSubmitButton = () => {
    const _dat = _.cloneDeep(data);
    const _data = {};
    _data["consortiaddr_id"] = getDDR;
    _data["ddr_status"] = "2";
    _data["mode_of_delivery"] = _dat["mode_of_delivery"];
    _data["delivered_date"] = _dat["current_date"];
    _data["ddr_reference_no"] = _dat["mail_subject"];
    _data["delivery_remarks"] = null;
    _data["courier_no"] = _dat["courier_no"] || null;
    _data["delay_reason"] = null;
    _data["discard_reason"] = null;
    _data["delivering_userid"] = customerID;
    _data["is_duplicate"] = false;
    const str = `{"data":[${JSON.stringify(_data)}]}`;
    const formData = new FormData();

    if (isChangedSubject === true) {
      formData.append("detailjson", str);
      formData.append("attachment_file", data["file"]);
      // formData.append("To_mail_Id", "shylaja@informaticsglobal.com");
      // formData.append("CC_mail_Id", "shylabhatt@gmail.com");
      formData.append("To_mail_Id",_dat["to_mail"]);
      formData.append("CC_mail_Id", _dat["cc_email"] + ";" +_dat["approver_admin_mail"]);
      formData.append("Subject", _dat["mail_subject"]);
      formData.append("Message", _dat["contu_message"]);

      fetch(updateConsortiaDDRForLibrarianEmail, {
        method: "POST",
        body: formData,
      })
        .then((response) => response.json())
        .then((result) => {
          if (result.data[0][0] === "Success") {
            setIsDeliveryNote(true);
          } else {
            alert("Something went wrong")
            setIsDeliveryNote(false);
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    } else {
      formData.append("detailjson", str);
      formData.append("attachment_file", data["file"]);
      // formData.append("To_mail_Id", "shylaja@informaticsglobal.com");
      // formData.append("CC_mail_Id", "shylabhatt@gmail.com");
      formData.append("To_mail_Id",_dat["to_mail"]);
      formData.append("CC_mail_Id", _dat["cc_email"] + ";" +_dat["approver_admin_mail"]);
      formData.append("Subject", _dat["mail_subject"] + "," + data["action_name"]);
      formData.append("Message", _dat["contu_message"]);

      fetch(updateConsortiaDDRForLibrarianEmail, {
        method: "POST",
        body: formData,
      })
        .then((response) => response.json())
        .then((result) => {
          if (result.data[0][0] === "Success") {
            setIsDeliveryNote(true);
          } else {
            alert("Something went wrong")
            setIsDeliveryNote(false);
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }

  }

  const handleOnlineSubmitButtonDisabled = () => {
    return !isFileUploadData || error
  }

  const handleFullfillOutsideDDRSystemDisabled = () => {
    return error
  }

  const handlehandleCouriesSubmitButtonDisabled = () => {
    return error
  }

  const handleFullfillOutsideDDRSystem = () => {
    const _dat = _.cloneDeep(data);
    const _data = {};
    _data["consortiaddr_id"] = getDDR;
    _data["ddr_status"] = "2";
    _data["mode_of_delivery"] = _dat["mode_of_delivery"];
    _data["delivered_date"] = _dat["current_date"];
    _data["ddr_reference_no"] = _dat["mail_subject"];
    _data["delivery_remarks"] = null;
    _data["courier_no"] = null;
    _data["delay_reason"] = null;
    _data["discard_reason"] = null;
    _data["delivering_userid"] = customerID;
    _data["is_duplicate"] = false;
    const str = `{"data":[${JSON.stringify(_data)}]}`;
    const formData = new FormData();

    if (isChangedSubject === true) {
      formData.append("detailjson", str);
      formData.append("attachment_file", null);
      // formData.append("To_mail_Id", "shylaja@informaticsglobal.com");
      // formData.append("CC_mail_Id", "shylabhatt@gmail.com");
      formData.append("To_mail_Id",_dat["to_mail"]);
      formData.append("CC_mail_Id", _dat["cc_email"] + ";" +_dat["approver_admin_mail"]);
      formData.append("Subject", _dat["mail_subject"]);
      formData.append("Message", _dat["contu_message"]);

      fetch(updateConsortiaDDRForLibrarianEmail, {
        method: "POST",
        body: formData,
      })
        .then((response) => response.json())
        .then((result) => {
          if (result.data[0][0] === "Success") {
            setIsDeliveryNote(true);
          } else {
            alert("Something went wrong")
            setIsDeliveryNote(false);
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    } else {
      formData.append("detailjson", str);
      formData.append("attachment_file", null);
      // formData.append("To_mail_Id", "shylaja@informaticsglobal.com");
      // formData.append("CC_mail_Id", "shylabhatt@gmail.com");
      formData.append("To_mail_Id",_dat["to_mail"]);
      formData.append("CC_mail_Id", _dat["cc_email"] + ";" +_dat["approver_admin_mail"]);
      formData.append("Subject", _dat["mail_subject"] + "," + data["action_name"]);
      formData.append("Message", _dat["contu_message"]);

      fetch(updateConsortiaDDRForLibrarianEmail, {
        method: "POST",
        body: formData,
      })
        .then((response) => response.json())
        .then((result) => {
          if (result.data[0][0] === "Success") {
            setIsDeliveryNote(true);
          } else {
            alert("Something went wrong")
            setIsDeliveryNote(false);
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }

  }

  const modalData = (
    <DeliveryNoteDDRFullfillRequest
      emailData={data && data}
      modalClose={modalClose}
      isDeliveryMode={isDeliveryMode && isDeliveryMode}
      data={data}
      isChangedSubject={isChangedSubject && isChangedSubject}
      isCourierNumber={isCourierNumber && isCourierNumber}
      setIsFulfillEmailTemplate={setIsFulfillEmailTemplate}
    />
  );


  return (
    <div>
      < DDRDiscardStatusModal
        show={isDeliveryNote}
        modalHandle={setIsDeliveryNote}
        data={modalData}
        size="lg"
        heading="Your mail has been sent successfully"
      />

      {!!error && <div className="error text-danger">{error}</div>}
      <div>
        <InputField
          placeholder={"To"}
          name="to_mail"
          value={data["to_mail"] || ""}
          handleInputchange={handleInputchange}
          classname={`w-100-p to_mail`}
          parentclassname="w-100-p"
          readonly={true}
        />
      </div>
      <div>
        <InputField
          placeholder={"CC"}
          name="cc_email"
          value={data["cc_email"] + ";" + data["approver_admin_mail"] || ""}
          handleInputchange={handleInputchange}
          classname={`w-100-p cc_email`}
          parentclassname="w-100-p"
          readonly={true}
        />
      </div>
      <div>
        <InputField
          placeholder={"Subject"}
          name={"mail_subject"}
          defaultValue={data["mail_subject"] + ", " + data["action_name"]}
          handleInputchange={handleInputchange}
          classname={`w-100-p mail_subject`}
          parentclassname="w-100-p"
        />
      </div>
      <div className="flexRow">
        <div className="common-select-space w-260">
          <SelectDropDown
            data={selectDeliveyModeData}
            defaultValue={modeOfDelivery["mode_of_delivery"]}
            label="Delivery Mode"
            name="mode_of_delivery"
            handleInputchange={handleInputchange}
          />
        </div>
        {isCourier && (
          <div>
            <InputField
              placeholder={"Contu Balance"}
              name="contu_balance"
              value={data["contu_balance"] || ""}
              handleInputchange={handleInputchange}
              classname={`w-100-p courier_no`}
              parentclassname="w-200"
              readonly={true}
            />
          </div>
        )}
        {isCourier && (
          <div className="ddr_statu_btns">
            <InputField
              placeholder={"Courier Details"}
              name="courier_no"
              value={data["courier_no"] || ""}
              handleInputchange={handleInputchange}
              classname={`w-100-p courier_no `}
              parentclassname="w-200"
            />
          </div>
        )}
        <div className="ddr_statu_btns">
          <InputField
            placeholder={"Delivery date"}
            name="current_date"
            type="date"
            value={data["current_date"] || ""}
            handleInputchange={handleInputchange}
            classname={`w-260 current_date `}
          />
        </div>
      </div>
      {isOnline && (
        <FileUpload name="file" handleInputchange={handleInputchange} setIsFileUpload={setIsFileUpload} />
      )}
      <div>
        <span className="input_label_clr">Message</span> <InputArea
          name="contu_message"
          value={data["contu_message"] || ""}
          handleInputchange={handleInputchange}
          classname={`w-100-p contu_message messageContainer`}
          parentclassname="w-100-p"
          rows={3}
        />
      </div>

      <div className="btn_group ddr_statu_mail_btns">
        {isCourier && (
          <div>
            <button
              type="submit"
              className="btn btn-primary"
              onClick={handleSubmitCouriesSubmitButton}
              disabled={handlehandleCouriesSubmitButtonDisabled()}
            >
              Submit
            </button>
          </div>
        )}
        {isOnline && (
          <div>
            <button
              type="submit"
              className="btn btn-primary"
              onClick={handleOnlineSubmitButton}
              disabled={handleOnlineSubmitButtonDisabled()}
            >
              Submit
            </button>
          </div>
        )}
        {fullfillOutsideDDRSystem && (
          <div>
            <button
              type="submit"
              className="btn btn-primary"
              onClick={handleFullfillOutsideDDRSystem}
              disabled={handleFullfillOutsideDDRSystemDisabled()}
            >
              Submit
            </button>
          </div>
        )}
        <div>
          <button
            type="button"
            className="btn btn-secondary ddr_statu_btns"
            onClick={handleClear}
          >
            Clear
          </button>
        </div>
        <div>
          <button
            type="button"
            className="btn btn-secondary ddr_statu_btns"
            onClick={handleCancel}
          >
            Cancel
          </button>
        </div>

      </div>
    </div>
  );
};

export default DDRFulfillMailTemplate;
