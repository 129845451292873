import React, { useState } from "react";
import { Button } from "react-bootstrap";
import _ from "lodash";
import { baseUrl } from "../../utilities/constants";
import PackageTypeDropDown from "../../atoms/DropDown/PackageTypeDropDown";
import { useEffect } from "react";
import "../../containers/ResourceMgmt/ResourceMgmt.css";
import Loading from "../Loading/Loading";
import { Packagedialog } from "../../atoms/Modals/Packagedialog";
import BasicSelectDropDown from "../../atoms/DropDown/BasicSelectDropDown";
import { NewPackageSubscriptionDialog } from "../../atoms/Modals/NewPackageSubScription";



const VCLRPackageModal = ({ customerID, setViewOn, setIsPackageAssigned }) => {
  const [loading, setLoading] = useState(false);
  const [packData, setPackData] = useState("");
  const [subPackData, setSubPackData] = useState("");
  const [packageData, setPackageData] = useState({ agent_type: "0" });
  const [subPacksData, setSubPacksData] = useState({ aggregator_id: "0" });
  const [selectedPackageId, setSlectedPackageId] = useState("");
  const [isSelectedData, setIsSelectedData] = useState(false);
  const [isSave, setIsSave] = useState(false);

  subPackData && subPackData.forEach((item, ind) => { item.id = ind + 1 });

  const handleInputchange = (value, name) => {
    const dynamic = _.cloneDeep(packageData);
    dynamic[name] = value;
    setPackageData(() => ({
      [name]: value,
    }));
  };

  const handleLoad = async () => {
    setLoading(true);
    await fetch(
      `${baseUrl}/api/get_client_wise_package_master_list/get_client_wise_package_master_list?client_id=${customerID}&aggregator_id_in=${subPacksData.aggregator_id}`
    ).then((val) => val.json()).then((res) => {
      setSubPackData(res?.data[0][0]);
      setLoading(false);
    });
  };

  const aggregatorType = [
    { 0: "All" },
    { 1: "Aggregator" },
    { 4: "Publisher" },
  ];

  useEffect(() => {
    fetch(
      `${baseUrl}/api/get_client_wise_aggregator_master_list/get_client_wise_aggregator_master_list?client_id=${customerID}&aggregator_type=${packageData.agent_type}`
    ).then((item) => item.json()).then((res) => setPackData(res.data[0][0]));
  }, [packageData, customerID]);

  const mapSelectPackageData = (data) => {
    const _dat = data;
    const _data =
      _dat && _dat.map((item) => {
        const a = {};
        a["value"] = Object.keys(item)[0];
        a["label"] = Object.values(item)[0];

        return a;
      });
    return _data;
  };

  const packageTypeDataMapping = (data) => {
    const _dat = data;
    const _data =
      _dat && _dat.map((item) => {
        const a = {};
        a["value"] = Object.keys(item);
        a["label"] = Object.values(item);

        return a;
      });
    return _data;
  };

  const handlePackageDropDown = (value, name) => {
    const dynamic = _.cloneDeep(subPacksData);
    dynamic[name] = value;
    if (value === "") {
      value = "0";
    }
    setSubPacksData(() => ({ [name]: value }));
  };

  const handleCheckBox = (e) => {
    const value = e.target.value;
    const slectedValue = _.cloneDeep(selectedPackageId);
    const finalData = new Set(slectedValue);
    if (finalData.has(value)) {
      finalData.delete(value);
    } else {
      finalData.add(value);
    }
    const finalDataArray = Array.from(finalData);
    setSlectedPackageId(finalDataArray);
  };

  const handleSaveButton = async () => {
    if (selectedPackageId.length >= 1) {
      await fetch(
        `${baseUrl}/api/add_packages_to_library/add_packages_to_library?json_data={"customer_id":${customerID},"package_list":[${selectedPackageId}]}`
      ).then((val) => val.json()).then((res) => {
        if (res.data[0][0] === "Selected Packages added successfully") {
          setIsSave(true);
        }
      });
    } else {
      setIsSelectedData(true);
    }
  };

  const handleValidation = () => {
    return selectedPackageId.length >= 1;
  };

  const handleDisabled = (data) => {
    return data && data === true;
  };

  return (
    <div>
      <div className="ViewConfigureLibraryResourcesRows">
        <NewPackageSubscriptionDialog
          show={isSave}
          modalClose={setIsSave}
          content={"Selected Package(s) added successfully"}
          successCallback={handleSaveButton}
          heading="Info"
          setViewOn={setViewOn}
          setIsPackageAssigned={setIsPackageAssigned}
        />
        <Packagedialog
          show={isSelectedData}
          modalClose={setIsSelectedData}
          content={"Kindly select at least one Package before Saving"}
          successCallback={handleSaveButton}
          heading="Info"
          setViewOn={setViewOn}
        />
        <div className="common-select-space w-260">
          <PackageTypeDropDown
            data={mapSelectPackageData(aggregatorType)}
            label="Package Type"
            name="agent_type"
            handleInputchange={handleInputchange}
          />
        </div>

        <div className="common-select-space  w-260">
          <BasicSelectDropDown
            data={packageTypeDataMapping(packData)}
            label="Packages"
            name="aggregator_id"
            handleInputchange={handlePackageDropDown}
          />
        </div>
        <div className="common-button ms-3 contactPersondata">
          <Button onClick={handleLoad}> Load </Button>
        </div>
      </div>

      <div>
        <div> {loading && <Loading />}</div>
        {subPackData && subPackData && (
          <div className="sub_package_save_button contactPersondata">
            <Button onClick={handleSaveButton} disabled={!handleValidation()}>Save</Button>
          </div>
        )}
        <div>
          <table className="table table-bordered table-striped">
            {subPackData && subPackData && (
              <thead className="summayTable">
                <tr className="contactPersondata">
                  <th className="text-center">SI.No</th>
                  <th className="ps-3">Type of Package</th>
                  <th className="text-center">Aggregator / Publisher Name</th>
                  <th className="ps-5">Package Name</th>
                  <th>Select</th>
                </tr>
              </thead>
            )}
            <tbody>
              {subPackData &&
                subPackData &&
                subPackData?.map((data, index) => (
                  <tr key={index}>
                    <td className="text-center">{data.id}</td>
                    <td className="ps-3">{data.package_type}</td>
                    <td className="text-center">{data.aggregator_name}</td>
                    <td>{data.package_name}</td>
                    <td className="text-center">
                      <input
                        type="checkbox"
                        className={
                          data && data.is_assigned === true
                            ? "check_wd"
                            : "check_wd_tr"
                        }
                        value={data.package_id}
                        onChange={handleCheckBox}
                        disabled={handleDisabled(data && data.is_assigned)}
                      />
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default VCLRPackageModal;
