import React from "react";
import blueUser from "../../assets/img/blue_user.png";
import greenUser from "../../assets/img/green_user.png";
import redUser from "../../assets/img/red_user.png";
import bookImg from "../../assets/img/icon_books.png";
import thesisImg from "../../assets/img/themes_img.png";
import articlesImg from "../../assets/img/articles_img.png";

import { convertToInternationalCurrencySystem } from "../../utilities/utilFunctions";

const Widget = ({ item, value, text, classname, fa }) => {
  const defaultImg = {
    new_users_created: blueUser,
    active_users: greenUser,
    inactive_users: redUser,
    "Journal Articles": articlesImg,
    Thesis: thesisImg,
    "Books Series": bookImg,
    fulfiled_count: "fas fa-calendar-check icon-green",
    pending_count: "fas fa-redo icon-orange",
    discard_count: "fas fa-trash icon-red",
    delay_count: "fas fa-clock icon-violet",
    not_available_count: "fas fa-exclamation-circle icon-red",
    duplicate_count: "fas fa-clone icon-green",
    received_count: "fas fa-envelope-open-text icon-blue",
    sent_count: "fas fa-envelope-open-text icon-blue",
  };

  const defaultText = {
    new_users_created: "New Users -Current Year",
    active_users: "Active Users",
    inactive_users: "Inactive Users",
    fulfiled_count: "fulfiled",
    pending_count: "Pending",
    discard_count: "Discard",
    delay_count: "Delay",
    not_available_count: "Not availiable",
    duplicate_count: "Duplicate",
    received_count: "received",
    sent_count: "sent",
  };
  const widgetContentSub = defaultText[item];
  const widgetImg = defaultImg[item];
  const _classname = classname || "widget";
  return (
    <div className={_classname}>
      <span className="widgetImg">{!!fa && <i className={widgetImg}></i>}</span>
      <span className="widgetContent">
        <span className="widgetContentNo">
          {Number(value) ? convertToInternationalCurrencySystem(value) : 0}
        </span>
        {widgetContentSub && (
          <span className="widgetContentSub">{widgetContentSub}</span>
        )}
        {text && <span className="widgetContentText">{text}</span>}
      </span>
    </div>
  );
};

export default Widget;
