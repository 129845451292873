import React from "react";
import { Button } from "react-bootstrap";


const ResourceTable = ({
  tableData,
  action,
  handleAction,
  isSearched,
  resourceTpye
}) => {
  const _action = action ? true : false;

  return (
    <>
      <table className=" table table-bordered table-striped">
        <thead>
          <tr className="summayTable">
            <th>SI.No</th>
            <th>Access Type</th>
            <th> Resource Name</th>
            <th>From </th>
            <th>To</th>
            <th> Resource Type</th>
            {_action && <th>Action</th>}
          </tr>
        </thead>
        <tbody>
          {!resourceTpye?.length && (
            <tr>
               <td
                  colSpan="7"
                  className="text-center fw-bold contactPersondata"
                >
                  No Resources Configured for My Library
                </td>
            </tr>
          )}
          {!tableData && (
            <tr>
              {!isSearched ?  (
                <td
                  colSpan="7"
                  className="text-center fw-bold contactPersondata"
                >
                  No Resources Configured for My Library
                </td>
              ) : (
                <td
                  colSpan="7"
                  className="text-center fw-bold contactPersondata"
                >
                  Given Resource not available as part of My Library
                </td>
              )}
            </tr>
          )}

          {tableData &&
            tableData?.map((data, index) => (
              <tr key={index} className="contactPersondata">
                <td className="text-center"> {data.id} </td>
                <td> {data.access_type} </td>
                <td> {data.resource_name} </td>
                <td> {data.start_date} </td>
                <td> {data.end_date} </td>
                <td> {data.resource_type} </td>

                {_action && (
                  <td>
                    <Button
                      variant="danger contactPersondata"
                      onClick={() => handleAction(data)}
                    >
                      {action}
                    </Button>
                  </td>
                )}
              </tr>
            ))}
        </tbody>
      </table>
    </>
  );
};

export default ResourceTable;
