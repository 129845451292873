import React, { useState, useEffect } from "react";
import ReactTooltip from "react-tooltip";

const ScrollTopArrow = () => {
  const [showScroll, setShowScroll] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", checkScrollTop);
    return function cleanup() {
      window.removeEventListener("scroll", checkScrollTop);
    };
  });

  const checkScrollTop = () => {
    if (!showScroll && window.pageYOffset > 450) {
      setShowScroll(true);
    } else if (showScroll && window.pageYOffset <= 450) {
      setShowScroll(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <>
      <button
        onClick={scrollToTop}
        className="back-to-top"
        style={{ display: showScroll ? "flex" : "none" }}
        data-tip="Back to Top"
      >
        &#8679;
      </button>
      <ReactTooltip />
    </>
  );
};

export default ScrollTopArrow;
