import React, { useState, useEffect } from "react";
import NoData from "../../atoms/NoData/NoData";
import { Button } from "react-bootstrap";
import { NewModal } from "../../components/Modals/Modal";
import { formatedObjectDirectArray } from "../Reports/constants";
import InputField from "../../atoms/InputField/InputField";
import _ from "lodash";
import LibraryAdminOrg from "../OrgDetails/LibraryAdminOrg";
import { Pagination } from "../../components/ReportsComponents/Pagination";
/**
 * @author
 * @function MemberViewTable
 **/

const MemberViewTable = ({ memberTableData }) => {
  const [isMemberEdit, setIsMemberEdit] = useState(false);
  const [memberCustomerID, setMemberCustomerID] = useState("");
  const [filter, setFilter] = useState("");
  const [length, setlength] = useState(0);
  const [_memberTableData, set_memberTableData] = useState([]);
  const [deepClonedData, setDeepClonedData] = useState([]);

  _memberTableData && _memberTableData.forEach((item, ind) => { item.id = ind + 1 });

  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(15);
  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords =
    _memberTableData &&
    _memberTableData.slice(indexOfFirstRecord, indexOfLastRecord);
  const nPages = Math.ceil(
    _memberTableData && _memberTableData.length / recordsPerPage
  );

  useEffect(() => {
    setlength(!!memberTableData ? memberTableData.length : 0);
    const _memDat = memberTableData
      ? formatedObjectDirectArray(memberTableData)
      : [];
    set_memberTableData(_memDat);
    setDeepClonedData(_memDat);

  }, []);

  const handleMemberEdit = (item) => {
    setMemberCustomerID(item.value);
    setIsMemberEdit(true);
  };

  useEffect(() => {
    const _memberDataClone = _.cloneDeep(deepClonedData);
    if (!!filter) {
      const _memberTableDataFiltered = _memberDataClone.filter((item) =>
        item["label"].toLowerCase().includes(filter.toLowerCase())
      );
      set_memberTableData(_memberTableDataFiltered);
    } else {
      const _memDat = memberTableData
        ? formatedObjectDirectArray(memberTableData)
        : [];

      set_memberTableData(_memDat);
    }

  }, [filter]);

  const modalmemberEditData = <LibraryAdminOrg customerID={memberCustomerID} />;

  const handlefilter = (value) => {
    setFilter(value);
    setCurrentPage(1);
  }

  return (
    <>
      <div>
        <>
          <NewModal
            show={isMemberEdit}
            modalHandle={setIsMemberEdit}
            data={modalmemberEditData}
            size="lg"
            heading="Edit"
            dialogClassName="BigModal"
          />

          <div className="flex">
            <div>
              <InputField
                placeholder={"Filter with name"}
                name={"filter"}
                value={filter || ""}
                // handleInputchange={(value) => setFilter(value)}
                handleInputchange={handlefilter}
                classname={`w-400 filter `}
              />
            </div>
          </div>

          <table className="table table-bordered table-striped">
            <thead className="summayTable txtFont">
              <tr>
                <td>S.No</td>
                <td>Name</td>
                <td>Action</td>
              </tr>
            </thead>
            <tbody>
              {currentRecords &&
                currentRecords.map((item, index) => (
                  <tr key={index} className="contactPersondata">
                    <td className="ddrtable contactPersondata">{item.id}</td>
                    <td> {item.label}</td>
                    <td className="ddrtable"><Button onClick={() => handleMemberEdit(item)} className="contactPersondata">
                      View /Edit
                    </Button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>

          {!!length === false && <NoData />}
        </>
        <div>
          <Pagination
            nPages={nPages && nPages}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            setRecordsPerPage={setRecordsPerPage}
            tableDataReport={_memberTableData && _memberTableData}
            recordsPerPage={recordsPerPage}
          />
        </div>
      </div>
    </>
  );
};

export default MemberViewTable;
