import { baseUrl } from "../../utilities/constants";

// 52
export const getContuReport = (str) =>
  baseUrl + "/api/get_contu_report/get_contu_report?" + str;
export const exportContuReport = (str) =>
  baseUrl + "/api/Export_contu_report/Export_contu_report?" + str;

export const getContuDdrDetails = (str) =>
  baseUrl + "/api/get_contu_ddr_details/get_contu_ddr_details?" + str;

export const getClientWiseSentDdrFromDate = (str) =>
  baseUrl +
  "/api/get_client_wise_sent_ddr_from_date/get_client_wise_sent_ddr_from_date?" +
  str;

export const exportContuLendingReport = (str) =>
  baseUrl + "/api/Export_contu_ddr_details/Export_contu_ddr_details?" + str;
// 57a
export const getPublisherUsageReportForAdmin = (str) =>
  baseUrl +
  "/api/get_publisher_usage_report_for_admin/get_publisher_usage_report_for_admin?" +
  str;
// 57b
export const getDDRReport = (str) =>
  baseUrl + "/api/get_ddr_report/get_ddr_report?" + str;
// 169
export const get_dat_region_list =
  baseUrl + "/api/get_dat_region_list/get_dat_region_list";
// 64
export const getServiceUsageReportForAdmin = (str) =>
  baseUrl +
  "/api/get_service_usage_report_for_admin/get_service_usage_report_for_admin?" +
  str;

  export const getAppDownloadCount = (str) =>
    baseUrl +
    "/api/get_app_download_count/get_app_download_count?" +
    str;

    export const getTotalAppDownloadCount = (str) =>
      baseUrl +
      "/api/get_total_app_download_count/get_total_app_download_count?" +
      str;
// 58
export const getResourceUsageReportForAdmin = (str) =>
  baseUrl +
  "/api/get_resource_usage_report_for_admin/get_resource_usage_report_for_admin?" +
  str;
// 170
export const exportServiceUsageReportForAdminUrl = (str) =>
  baseUrl +
  "/api/Export_service_usage_report_for_admin/Export_service_usage_report_for_admin?" +
  str;

export const downloadServiceUsageReportForAdminUrl = (str) =>
  baseUrl +
  "/api/download_service_usage_report_for_admin/download_service_usage_report_for_admin?" +
  str;
// 172
export const exportPublisherUsageReportForAdminUrl = (str) =>
  baseUrl +
  "/api/Export_publisher_usage_report_for_admin/Export_publisher_usage_report_for_admin?" +
  str;
export const downloadPublisherUsageReportForAdminUrl = (str) =>
  baseUrl +
  "/api/download_publisher_usage_report_for_admin/download_publisher_usage_report_for_admin?" +
  str;
// 173
export const exportResourceUsageReportForAdminUrl = (str) =>
  baseUrl +
  "/api/Export_resource_usage_report_for_admin/Export_resource_usage_report_for_admin?" +
  str;

export const downloadResourceUsageReportForAdminUrl = (str) =>
  baseUrl +
  "/api/download_resource_usage_report_for_admin/download_resource_usage_report_for_admin?" +
  str;
// 166
export const exportReceivedDDRListUrl = (str) =>
  baseUrl + "/api/download_ddr_report/download_ddr_report?" + str;
// 167
export const exportSentDDRListUrl = (str) =>
  baseUrl + "/api/download_ddr_report/download_ddr_report?" + str;
//

export const exportReceivedDDRMngListUrl = (str) =>
  baseUrl + "/api/Export_received_ddr_list/Export_received_ddr_list?" + str;

export const exportSentDDRMngListUrl = (str) =>
  baseUrl + "/api/Export_sent_ddr_list/Export_sent_ddr_list?" + str;

export const exportFromDateUrl = (customerID, consortia_member_id) =>
  baseUrl +
  "/api/get_client_wise_sent_ddr_from_date/get_client_wise_sent_ddr_from_date?customer_id=" +
  customerID +
  "member_id=" +
  consortia_member_id;

export const RequestedFromLabel = "Requested From";
export const Year = "Year";
export const ILL = "ILL";
export const ContuReport = "CONTU Report";
export const institution = "Institution";

export const testDropdown = [
  { label: "red", value: "red" },
  { label: "blue", value: "blue" },
  { label: "green", value: "green" },
];

export const illSelectDropdown = [
  { label: "Lending", value: "0" },
  { label: "Borrowed", value: "1" },
];

export const requestRRSelectData = [
  { label: "Request Received", value: "1" },
  { label: " Request Sent", value: "2" },
  // { label: "Request Delivered", value: "2" },
  // {label: "Both", value: "3"},
];

export const reportTypeDropdown = [
  { label: "Service Usage", value: 0 },
  { label: "Journal Usage", value: 1 },
  { label: "Publisher Usage", value: 2 },
  // { label: "Conference /Proceedings Usage", value: 3 },
];

export const deviceTypeDropdown = [
  { label: "Web Usage", value: 1 },
  { label: "Mobile Usage", value: 2 },
  
];

export const accessTypeDropdown = [
  { label: "Primary Contact", value: 1 },
  { label: "DDR Contact", value: 2 },
  { label: "Technical Contact", value: 3 },
  { label: "Admin Access", value: 4 },
  { label: "Scholar Access", value: 5 },
];

export const memberDropdown = [
  { label: "All", value: "All" },
  { label: "Active", value: "Active" },
  { label: "Expired", value: "Expired" },
];

export const countuYearDropdown = [
  { label: "2020", value: 2020 },
  { label: "2019", value: 2019 },
  { label: "2018", value: 2018 },
];

export const countReportKeys = [
  { key: "resource_name", value: "Journal Name" },
  { key: "ill_fulfilled", value: "ILL Deliver(count)" },
  { key: "balance", value: "ILL Delivered(count)" },
];
export const ddrReportKeys = [
  { key: "request_by_or_to_client", value: "request_by_or_to_client" },
  { key: "delayed", value: "delayed" },
  { key: "delivered", value: "delivered" },
  { key: "discarded", value: "discarded" },
  { key: "duplicate", value: "duplicate" },
  { key: "pending", value: "pending" },
  { key: "total_count", value: "total_count" },
  { key: "unfulfilled", value: "unfulfilled" },
];

export const countReportData = [
  {
    JournalName: "Journal Name",
    ILLDeliver: "ILL Deliver(count)",
    ILLDelivered: "ILL Delivered(count)",
  },
  {
    JournalName: "Journal Name",
    ILLDeliver: "ILL Deliver(count)",
    ILLDelivered: "ILL Delivered(count)",
  },
];

export const usageReportKeys = [
  { key: "UsageMetrics", value: "Usage Metrics" },
  { key: "services", value: "service" },
  { key: "TotalCount", value: "Total Count" },
];

export const usageReportData = [
  { key: "View/Save Search history", value: "View/Save Search history" },
  { key: "E mail Alerts", value: "E mail Alerts" },
  { key: "Scite", value: "Scite" },
  { key: "TOC Browsing", value: "TOC Browsing" },
  { key: "Searches", value: "Searches" },
  { key: "Pre_print Alerts", value: "Pre_print Alerts" },
  { key: "RSS Feeds", value: "RSS Feeds" },
  { key: "Filter Results", value: "Filter Results" },
  { key: "Profiles Created", value: "Profiles Created" },
  { key: "ILL Requests/Enquiries", value: "ILL Requests/Enquiries" },
  { key: "Sessions", value: "Sessions" },
  {
    key: "Preview/Print/Email/Download Marked",
    value: "Preview/Print/Email/Download Marked",
  },
  { key: "Add To Folder", value: "Add To Folder" },
  { key: "Sorting results", value: "Sorting results" },
  { key: "Fulltext / Abstract Views", value: "Fulltext / Abstract Views" },
];

export const formatedSelect = (data) => {
  const _dat = data && data[0][0];
  const _data =
    _dat &&
    _dat?.map((item) => {
      const a = {};
      a["value"] = Object.keys(item)[0];
      a["label"] = Object.values(item)[0];
      return a;
    });

  return _data;
};

export const formatedObject = (data) => {
  const _dat = data && data[0][0];
  const _data =
    _dat &&
    _dat
      .map((item) => {
        const a = {};
        a["value"] = Object.keys(item)[0];
        a["label"] = Object.values(item)[0];
        return a;
      })
      .sort((a, b) => {
        if (a.label < b.label) return -1;
        return a.label > b.label ? 1 : 0;
      });

  return _data;
};

export const formatedObjectDirectArray = (data) => {
  const _data =
    data &&
    data
      .map((item) => {
        const a = {};
        a["value"] = Object.keys(item)[0];
        a["label"] = Object.values(item)[0];
        return a;
      })
      .sort((a, b) => {
        if (a.label < b.label) return -1;
        return a.label > b.label ? 1 : 0;
      });

  return _data;
};
