import {
  dashboardIcon,
  ddrIcon,
  logoutIcon,
  memberIcon,
  orgIcon,
  reportsIcon,
  resourceIcon,
  userUploadIcon,
} from "./ImgConstants";

export const MenuItems = [
  {
    label: "Dashboard",
    icon: dashboardIcon,
    url: "/dashboard",
  },
  {
    label: "Organization Details",
    icon: orgIcon,
    url: "/orgDetails",
  },
  {
    label: "Resource Management",
    icon: resourceIcon,
    url: "/ResourceMgmt",
  },
  {
    label: "DDR",
    icon: ddrIcon,
    url: "/DDR",
  },
  {
    label: "Reports",
    icon: reportsIcon,
    url: "/Reports",
  },
  {
    label: "User Uploads",
    icon: userUploadIcon,
    url: "/UserUpload",
  },
  {
    label: "Logout",
    icon: logoutIcon,
    url: "/Logout",
  },
];

export const ConsortiaMenuItems = [
  {
    label: "Dashboard",
    icon: dashboardIcon,
    url: "/dashboard",
  },
  {
    label: "Organization Details",
    icon: orgIcon,
    url: "/orgDetails",
  },
  {
    label: "Member Libraries",
    icon: memberIcon,
    url: "/memberLibraries",
  },
  {
    label: "Resource Management",
    icon: resourceIcon,
    url: "/ResourceMgmt",
  },
  {
    label: "DDR",
    icon: ddrIcon,
    url: "/DDR",
  },
  {
    label: "Reports",
    icon: reportsIcon,
    url: "/Reports",
  },
  {
    label: "Logout",
    icon: logoutIcon,
    url: "/Logout",
  },
];

export const ResourceMgmtMenu = [
  {
    label: "Manage My Library Subscriptions",
    key: "ManageMyLibrarySubscriptions",
    icon: "DashboardIcon",
    submenu: true,
    url: "",
    submenuData: [
      {
        label: "View / Configure Library resources",
        key: "ViewConfigureLibraryresources",
        icon: "DashboardIcon",
        url:
          "/ResourceMgmt/ManageMyLibrarySubscriptions/ViewConfigureLibraryresources",
      },
      {
        label: "View Holdings Summary",
        key: "ViewHoldingsSummary",
        icon: "DashboardIcon",
        url: "/ResourceMgmt/ManageMyLibrarySubscriptions/ViewHoldingsSummary",
      },
    ],
  },
  {
    label: "Manage OA Resources",
    icon: "DashboardIcon",
    key: "ManageOASubscription",
    url: "/ResourceMgmt/ManageOASubscription",
    submenu: false,
    submenuData: [],
  },
  {
    label: "View Consortia Resources",
    icon: "DashboardIcon",
    key: "ViewConsortiaResources",
    url: "/ResourceMgmt/ViewConsortiaResources",
    submenu: false,
    submenuData: [],
  },
];

export const ResourceMgmtMenus = [
  {
    label: "Manage My Library Subscriptions",
    key: "ManageMyLibrarySubscriptions",
    icon: "DashboardIcon",
    submenu: true,
    url: "",
    submenuData: [
      {
        label: "View / Configure Library resources",
        key: "ViewConfigureLibraryresources",
        icon: "DashboardIcon",
        url:
          "/ResourceMgmt/ManageMyLibrarySubscriptions/ViewConfigureLibraryresources",
      },
      {
        label: "View Holdings Summary",
        key: "ViewHoldingsSummary",
        icon: "DashboardIcon",
        url: "/ResourceMgmt/ManageMyLibrarySubscriptions/ViewHoldingsSummary",
      },
    ],
  },
  {
    label: "Manage OA Resources",
    icon: "DashboardIcon",
    key: "ManageOASubscription",
    url: "/ResourceMgmt/ManageOASubscription",
    submenu: false,
    submenuData: [],
  },
];

export const consortiaResourceMgmtMenu = [
  {
    label: "Manage Consortia Subscription",
    icon: "DashboardIcon",
    key: "ManageConsortiaSubscription",
    url: "/ResourceMgmt/ManageOASubscription",
    submenu: false,
    submenuData: [],
  },
  {
    label: "View Consortia Resources",
    icon: "DashboardIcon",
    key: "ViewConsortiaResources",
    url: "/ResourceMgmt/ViewConsortiaResources",
    submenu: false,
    submenuData: [],
  },
];

export const ResourcesMgmtMenuHeading = "Resources Management";
export const DDRMenuHeading = "Document Delivery Request";
export const ReportsMenuHeading = "Reports";

export const DDRMgmtMenu = [
  {
    label: "DDR Received",
    key: "DDRRecived",
    icon: "DashboardIcon",
    url: "/DDR",
    submenu: false,
    submenuData: [],
  },
  {
    label: "DDR Sent",
    key: "DDRSend",
    icon: "DashboardIcon",
    url: "/DDR",
    submenu: false,
    submenuData: [],
  },
];

export const ReportsMenu = [
  {
    label: "Usage",
    key: "Usage",
    icon: "DashboardIcon",
    url: "/Reports/Usage",
    submenu: false,
    submenuData: [],
  },
  {
    label: "DDR",
    key: "DDR",
    icon: "DashboardIcon",
    url: "/Reports/DDR",
    submenu: true,
    submenuData: [
      {
        label: "DDR Report",
        key: "DDRReport",
        icon: "DashboardIcon",
        url: "/Reports",
      },
      {
        label: "CONTU Report",
        key: "ConterReport",
        icon: "DashboardIcon",
        url: "/Reports",
      },
    ],
  },
];

export const ReportsMenus = [
  {
    label: "Usage",
    key: "Usage",
    icon: "DashboardIcon",
    url: "/Reports/Usage",
    submenu: false,
    submenuData: [],
  },
];

export const ConsortiaMenuItemsForDefination = [
  {
    label: "Dashboard",
    icon: dashboardIcon,
    url: "/dashboard",
  },
  {
    label: "Organization Details",
    icon: orgIcon,
    url: "/orgDetails",
  },
  {
    label: "Member Libraries",
    icon: memberIcon,
    url: "/memberLibraries",
  },
  {
    label: "Resource Management",
    icon: resourceIcon,
    url: "/ResourceMgmt",
  },
  {
    label: "Reports",
    icon: reportsIcon,
    url: "/Reports",
  },
  {
    label: "Logout",
    icon: logoutIcon,
    url: "/Logout",
  },
];

export const ConsortiaDefinationReportsMenu = [
  {
    label: "Usage",
    key: "Usage",
    icon: "DashboardIcon",
    url: "/Reports/Usage",
    submenu: false,
    submenuData: [],
  },
  {
    label: "DDR Report",
    key: "DDRReport",
    icon: "DashboardIcon",
    url: "/Reports",
    submenu: false,
    submenuData: [],
  },
];

export const ConosrtiaResourceMgmtMenu = [
  {
    label: "Manage My Library Subscriptions",
    key: "ManageMyLibrarySubscriptions",
    icon: "DashboardIcon",
    submenu: true,
    url: "",
    submenuData: [
      {
        label: "View / Configure Library resources",
        key: "ViewConfigureLibraryresources",
        icon: "DashboardIcon",
        url:
          "/ResourceMgmt/ManageMyLibrarySubscriptions/ViewConfigureLibraryresources",
      },
      // {
      //   label: "View Holdings Summary",
      //   key: "ViewHoldingsSummary",
      //   icon: "DashboardIcon",
      //   url: "/ResourceMgmt/ManageMyLibrarySubscriptions/ViewHoldingsSummary",
      // },
    ],
  },
  {
    label: "View Consortia Resources",
    icon: "DashboardIcon",
    key: "ViewConsortiaResources",
    url: "/ResourceMgmt/ViewConsortiaResources",
    submenu: false,
    submenuData: [],
  },
];
