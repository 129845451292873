import React, { useState } from "react";
import ToggleSidebar from "../../components/Sidebar/ToggleSidebar";
import {
  ResourcesMgmtMenuHeading,
  consortiaResourceMgmtMenu,
} from "../../components/Sidebar/constants";
import "./ResourceMgmt.css";
import ViewConsortiaResources from "../../components/ResourceMgmtComponents/ViewConsortiaResources";
import { getLocalStorage } from "../../utilities/storage";
import ManageConsortiaContainer from "../../components/ResourceMgmtComponents/ManageConsortiaContainer";


const ConsortiaAdminRmgmt = ({
  customerID,
  consortiamaster_id,
  admin_type,
}) => {
  const [active, setActive] = useState("");
  const { datatypes, informaticscustomer_id } = getLocalStorage("customer");
  datatypes && datatypes.map((item) => {
    const data = { label: item.accesstype, value: item.dat_resourcetype_id };
    return data;
  });

  const handleActiveCallback = (active) => {
    setActive(active);
  };

  const renderSwitch = (active) => {
    switch (active) {
      case "ManageConsortiaSubscription":
        return (
          <ManageConsortiaContainer
            customerID={customerID}
            consortiamaster_id={consortiamaster_id}
            admin_type={admin_type}
          />
        );
      case "ViewConsortiaResources":
        return (
          <ViewConsortiaResources
            informaticscustomerID={informaticscustomer_id}
            consortiamaster_id={consortiamaster_id}
            admin_type={admin_type}
          />
        );
      default:
        return (
          <ManageConsortiaContainer
            customerID={customerID}
            consortiamaster_id={consortiamaster_id}
            admin_type={admin_type}
          />
        );
    }
  };

  return (
    <>
      <div className="ResourceMgnmt WithToggleSidebar resourceMng_mrg_frm_top_tagle">
        <ToggleSidebar
          menu={consortiaResourceMgmtMenu}
          heading={ResourcesMgmtMenuHeading}
          setSelectedMenu={handleActiveCallback}
        />

        <div className="ResourceMgnmtContainer ContainerWithToggleSidebar">
          {renderSwitch(active)}
        </div>
      </div>
    </>
  );
};

export default ConsortiaAdminRmgmt;
