import React from "react";
import { Modal } from "react-bootstrap";

export const DDRDiscardStatusModal = ({
    show,
    modalHandle,
    heading,
    data,
    full = false,
    backdrop = true,
    size = "lg",
    dialogClassName = "dialog",
}) => {

    const handleCloseButton = () => {
        window.location.reload();
    }
    return (
        <Modal
            show={show}
            size={size}
            onHide={modalHandle}
            backdrop={backdrop}
            dialogClassName={dialogClassName}
        >
            <Modal.Header >

                <Modal.Title>
                    <h6 className="ddr_note_status_header">{heading}</h6>
                </Modal.Title>
                <button onClick={handleCloseButton}>x</button>
            </Modal.Header>
            <Modal.Body>{data}</Modal.Body>
        </Modal>
    );
};
