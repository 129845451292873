import React, { useEffect } from 'react'
import { removeLocalStorage, removeAllStorage } from '../../utilities/storage';



const Logout = () => {

  useEffect(() => {
    LogOutfn();
  }, [])

  const LogOutfn = () => {
    removeLocalStorage("user");
    removeLocalStorage("customer");
    removeAllStorage();
    window.location.href = '/';
  };


  return (
    <div> Logging out </div>
  )
}


export default Logout