import React, { useState, useEffect } from "react";
import CommonHeading from "../../atoms/Heading/CommonHeading";
import { Button } from "react-bootstrap";

import {
  ExportOAassignedSubjectByClientId,
  getOAAssignedSubjectByClientIDUrl,
  getOASubscribedHoldingsUrl,
} from "../../utilities/constants";
import {
  tablePropertyVHS,
} from "../../containers/ResourceMgmt/constants";
import ViewHoldingSummaryComponent from "./ViewHoldingSummaryComponent";
import Loading from "../../components/Loading/Loading";
import { NewModal } from "../Modals/Modal";
import AddOAResources from "./AddOAResources";
import { fetchUrl } from "../../utilities/apiFunctions/fatchUrl";




const ManageOASubscription = ({ customerID }) => {
  const [viewOn, setViewOn] = useState(false);
  const [loading, setLoading] = useState(false);
  const [viewHoldingSummary, setviewHoldingSummary] = useState([]);
  const [oaassignedSbjByClient, setOaassignedSbjByClient] = useState("");

  const ManageOAResources = "Manage OA Resources"


  useEffect(() => {
    fetchData();

  }, []);
  useEffect(() => {
    viewFetchOASubscribedData();

  }, []);

  const fetchData = async () => {
    setLoading(true);
    const _viewHoldingSummary = await fetchUrl(
      getOASubscribedHoldingsUrl(customerID)
    );
    setviewHoldingSummary(_viewHoldingSummary);
    setLoading(false);
  };

  const viewFetchOASubscribedData = async () => {
    await fetch(
      getOAAssignedSubjectByClientIDUrl(customerID)
    ).then((val) => val.json()).then((res) => setOaassignedSbjByClient(res));
  };

  const handleViewClick = () => {
    setViewOn(true);
  };

  const handleHoldingsClick = () => {
    window.open(ExportOAassignedSubjectByClientId(customerID));
  };

  const fnClose = async () => {
    setLoading(true);
    await fetchData();
    viewFetchOASubscribedData();
  };

  const _data = (
    <AddOAResources
      // fnMOAData={fnMOAData}
      customerID={customerID}
      fnClose={fnClose}
      setViewOn={setViewOn}
      oaassignedSbjByClient={oaassignedSbjByClient}
    />
  );

  return (
    <div className="ManageOASubscription">
      <NewModal
        show={viewOn}
        modalHandle={setViewOn}
        data={_data}
        size="lg"
        heading="Add OA Resources"
        dialogClassName={"OrgViewTableModal"}
      />
      <CommonHeading heading={ManageOAResources} />

      <div className="ManageOASubscriptionBtns">
        <Button
          variant="secondary contactPersondata"
          onClick={handleHoldingsClick}
        >
          Download List
        </Button>
        <Button onClick={handleViewClick} className="contactPersondata">
          Add OA Resources
        </Button>
      </div>

      <div>
        {loading && <Loading />}
        {!loading && viewHoldingSummary && viewHoldingSummary.data && (
          <ViewHoldingSummaryComponent
            Data={viewHoldingSummary.data}
            tableProperty={tablePropertyVHS}
            oaassigned={true}
            loading={loading}
            setLoading={setLoading}
          />
        )}
      </div>
    </div>
  );
};

export default ManageOASubscription;
