import React, { useState, useEffect } from "react";
import OrgSectionHeading from "./OrgSectionHeading";
import Error from "../../atoms/Error/Error";
import OrgContactDetailsScreen from "./OrgContactDetalsTable";

/**
 * @author
 * @function OrgContactPerson
 **/

const OrgContactPerson = ({
  setOrgContactPerson,
  admin_type,
  accessTypeCustomerDetails,
  customerDetails,
}) => {

  const [orgContactPersons, setOrgContactPersons] = useState([]);
  const keys = {
    salutation: "",
    firstname: "",
    lastname: "",
    department: "",
    designation: "",
    email: "",
    mobile: "",
    access_type: "",
    //access_type_val: "Primary Contact",
  };

  const tableData = [
    { key: "salutation", value: "Salutation", type: "text" },
    { key: "firstname", value: "First Name", type: "validtext" },
    { key: "lastname", value: "Last Name", type: "text" },
    { key: "department", value: "Department", type: "text" },
    { key: "designation", value: "Designation", type: "text" },
    { key: "email", value: "Email", type: "validtext" },
    { key: "mobile", value: "Mobile No", type: "text" },
    { key: "access_type", value: "Access Type" },
    //{ key: "access_type_val", value: "access_type_val" },
  ];

  const [error, setError] = useState("");

  useEffect(() => {
    setOrgContactPerson(orgContactPersons);

  }, [orgContactPersons]);

  const _heading = "Contact Person";
  return (
    <section className="OrgContactPerson">
      <OrgSectionHeading heading={_heading} />
      {error && <Error text={error} />}
      <OrgContactDetailsScreen
        tableData={tableData}
        init={keys}
        setData={setOrgContactPersons}
        admin_type={admin_type}
        accessTypeCustomerDetails={accessTypeCustomerDetails && accessTypeCustomerDetails}
        customerDetails={customerDetails}
        setError={setError}
        error={error}
      />
    </section>
  );
};

export default OrgContactPerson;
