export const ACTION_INVALID = "This action is invalid!";

//export const baseUrl = "https://diskover.informaticsglobal.com";

//export const baseUrl = "http://104.211.96.199:8300";

//export const baseUrl = "https://betaapi.informaticsglobal.com";

export const baseUrl = "https://diskoverapi.jgatenext.com";

// Login
// 153
export const loginUrl = baseUrl + "/user/Admin_login_request";

export const loginUsageUrl =
  baseUrl + "/api/insert_product_usage_log/insert_product_usage_log";
// 176
export const ResetPasswordUrl = baseUrl + "/user/change_password_admin_user";

export const customerByIDUrl = (id) =>
  baseUrl + "/api/CustomerDetailsbyID/CustomerDetailsbyID?customerId=" + id;

// Dashboard

// 86
export const userCountUrl = (id) =>
  baseUrl +
  "/api/dashboard_for_library_user_count/dashboard_for_library_user_count?CustomerId=" +
  id;

export const summaryTableUrl = (id) =>
  baseUrl +
  "/api/get_dashboard_for_usage_summary_for_library/get_dashboard_for_usage_summary_for_library?informaticustomer_id=" +
  id;
// 108
// export const libraryResourcesUrl = (id) =>
//   baseUrl +
//   "/api/dashboard_for_library_subscribed_resource_count/dashboard_for_library_subscribed_resource_count?customerid=" +
//   id;

export const libraryResourcesUrl = (id) =>
  baseUrl +
  "/api/dashboard_for_ls_resource_count_for_consortia_admin/dashboard_for_ls_resource_count_for_consortia_admin?customerid=" +
  id;

export const ddrUsageLibraryUrl = (id) =>
  baseUrl +
  "/api/dashboard_for_ddr_usage_by_consortia_member/dashboard_for_ddr_usage_by_consortia_member?customerid=" +
  id;
// 104
export const usageSummaryDataUrl = (id) =>
  baseUrl +
  "/api/dashboard_for_usage_stats_by_library/dashboard_for_usage_stats_by_library?customerid=" +
  id;
export const usageSummaryDataUrlForConsortia = (id) =>
  baseUrl +
  "/api/get_dashboard_for_usage_summary_for_consortia/get_dashboard_for_usage_summary_for_consortia?consortia_id=" +
  id;

// export const openResourcesUrl =
//   baseUrl +
//   '/api/indexing/solr/articlecore2?rows=1&fulltext_ACL=0&json_facet={"data_type":{"type":"terms","field":"data_type","limit":30}}';

export const openResourcesUrl = (dataType_Ids, finalSubject_Ids) =>
  baseUrl +
  '/api/indexing/solr/articlecore2?rows=1&fq__fulltext=true&fq__active_flag=true&json_facet={"data_type":{"type":"terms","field":"data_type","limit":30}}&fl=article_id&fq__resource_type=(' +
  dataType_Ids +
  ")&fq__subjects_id_l1=(" +
  finalSubject_Ids +
  ")";

// 101
export const topUsedPublisherByLibraryUrl = (customerid) =>
  baseUrl +
  "/api/dashboard_for_top_used_publisher_by_library/dashboard_for_top_used_publisher_by_library?customerid=" +
  customerid;
// 98
export const topUsedResourcesByLibraryUrl = (customerid) =>
  baseUrl +
  "/api/dashboard_for_top_used_resources_by_library/dashboard_for_top_used_resources_by_library?CustomerId=" +
  customerid;

// dasboard consortia

// 171
// export const dashboardForConsortiaSubscribedResourceCountUrl = (consortia_id) =>
//   baseUrl +
//   "/api/dashboard_for_consortia_subscribed_resource_count/dashboard_for_consortia_subscribed_resource_count?consortia_id=" +
//   consortia_id;

export const dashboardForConsortiaSubscribedResourceCountUrl = (consortia_id) =>
  baseUrl +
  "/api/dashboard_for_library_subscribed_resource_count/dashboard_for_library_subscribed_resource_count?customerid=" +
  consortia_id;

  export const dashboardLibrarySubscribedResourceFromConsortia = (Customer_id) =>
    baseUrl +
    "/api/dashboard_library_subscribed_resource_from_consortia/dashboard_library_subscribed_resource_from_consortia?Customer_id=" +
    Customer_id;
// 87
export const forConsortiaUserCountUrl = (consortia_id) =>
  baseUrl +
  "/api/dashboard_for_consortia_user_count/dashboard_for_consortia_user_count?consortia_id=" +
  consortia_id;
// 109
export const forTopConsortiaMembersByFulltextUsageUrl = (consortia_id) =>
  baseUrl +
  "/api/dashboard_for_top_consortia_members_by_fulltext_usage/dashboard_for_top_consortia_members_by_fulltext_usage?consortia_id=" +
  consortia_id;
// 100
export const forTopUsedPublisherByConsortiaUrl = (consortia_id) =>
  baseUrl +
  "/api/dashboard_for_top_used_publisher_by_consortia/dashboard_for_top_used_publisher_by_consortia?consortia_id=" +
  consortia_id;
// 106
export const forTopConsortiaMembersByUsageUrl = (consortia_id) =>
  baseUrl +
  "/api/dashboard_for_top_consortia_members_by_usage/dashboard_for_top_consortia_members_by_usage?consortia_id=" +
  consortia_id;
// 105
export const forTopDDRFullfilledMembersListUrl = (consortia_id) =>
  baseUrl +
  "/api/dashboard_for_top_ddr_fullfilled_members_list/dashboard_for_top_ddr_fullfilled_members_list?consortia_id=" +
  consortia_id;
// 144
export const getDashboardForUsageSummaryForConsortiaUrl = (consortia_id) =>
  baseUrl +
  "/api/get_dashboard_for_usage_summary_for_consortia/get_dashboard_for_usage_summary_for_consortia?consortia_id=" +
  consortia_id;
// 103
export const forDDRUsageByConsortiaMemberUrl = (customerid) =>
  baseUrl +
  "/api/dashboard_for_ddr_usage_by_consortia_member/dashboard_for_ddr_usage_by_consortia_member?customerid=" +
  customerid;
//

export const forDashboard_for_consortia_ddr_summary = (customerid) =>
  baseUrl +
  "/api/dashboard_for_consortia_ddr_summary/dashboard_for_consortia_ddr_summary?consortia_id=" +
  customerid;

// dashboard_for_consortia_ddr_summary/dashboard_for_consortia_ddr_summary?consortia_id=1874
// export const openResourcesUrlConsortia =
//   baseUrl +
//   '/api/indexing/solr/articlecore2?rows=1&groupedquery=(fulltext_ACL:0  OR  ishybridopenaccess:true)&json_facet={"data_type":{"type":"terms","field":"data_type","limit":30}}';

export const openResourcesUrlConsortia = (dataType_Ids, finalSubject_Ids) =>
  baseUrl +
  '/api/indexing/solr/articlecore2?rows=1&groupedquery=(fulltext_ACL:0 OR ishybridopenaccess:true)&json_facet={"data_type":{"type":"terms","field":"data_type","limit":30}}&fl=article_id&fq__resource_type=(' +
  dataType_Ids +
  ")&fq__subjects_id_l1=(" +
  finalSubject_Ids +
  ")&fq__active_flag=true";

// 175 bar chart

export const dashboard_for_consortia_user_region_wise = (customerid) =>
  baseUrl +
  "/api/dashboard_for_consortia_user_region_wise/dashboard_for_consortia_user_region_wise?consortia_id=" +
  customerid;

// Org Detail

// 110
export const accessTypeCustomerDetailsUrl = (datinformaticsproductid) =>
  baseUrl +
  "/api/get_dat_informatics_admin_access_type/get_dat_informatics_admin_access_type?access_type=" +
  datinformaticsproductid;

export const getUserAvailability = (userid) =>
  baseUrl +
  "/api/check_username_availabilty/check_username_availabilty?user_name=" +
  userid;

export const getCustomerDetailsUrl = (customerid) =>
  baseUrl +
  "/api/get_customer_details_for_library_admin/get_customer_details_for_library_admin?customerid=" +
  customerid;
// 43
export const getCityDetailsUrl = (city) =>
  baseUrl + "/api/get_city_details/get_city_details?city_name=" + city;

export const getCityDetailsByState = (state) =>
  baseUrl +
  "/api/get_city_list_by_state/get_city_list_by_state?state_id=" +
  state;
// 51
export const getCountryDetailsUrl = (country_name) =>
  baseUrl +
  "/api/get_country_details/get_country_details?country_name=" +
  country_name;
// 50
export const getStateDetailsUrl = (country_id) =>
  baseUrl + "/api/get_state_details/get_state_details?country_id=" + country_id;
// 173
export const viewAccountSubscriptionDetailsUrl = (str) =>
  baseUrl +
  "/api/view_account_subscription_details/view_account_subscription_details?" +
  str;
//
export const updateCustomerDetailsByLibraryAdmin =
  baseUrl +
  "/api/update_customer_details_by_library_admin/update_customer_details_by_library_admin";
export const getIpUpdatedUrl = (customer_id) =>
  baseUrl +
  "/api/send_mail_after_ip_changes/send_mail_after_ip_changes?customer_id=" +
  customer_id;

// Resource mgmt

export const viewConfigureLibraryUrl = (url) =>
  baseUrl +
  "/api/get_print_electronic_library_holdings/get_print_electronic_library_holdings?" +
  url;

export const removeLibrarySubscribedPackageUrl = (url) =>
  baseUrl +
  "/api/remove_library_subscribed_package/remove_library_subscribed_package?" +
  url;
// 44
// export const getAggregatorMasterListUrl = baseUrl+"/api/get_aggregator_master_list/get_aggregator_master_list";
export const getAggregatorMasterListUrl =
  baseUrl +
  "/api/get_aggregator_master_list/get_aggregator_master_list?aggregator_name&agent_type=0";
// 40
export const getPackagesListUrl = (url) =>
  baseUrl +
  "/api/get_packages_list/get_packages_list?package_name=&aggregator_id=0&package_type=1&package_status=TRUE";
export const getConsortiaPackagesListUrl = (str) =>
  baseUrl + "/api/get_packages_list/get_packages_list?" + str;

export const getConsortiaSbdPackagesListUrl = (str) =>
  baseUrl +
  "/api/get_consortia_subscribed_packages_list/get_consortia_subscribed_packages_list?" +
  str;

// 59
export const getPackageDetailsByIdUrl = (id) =>
  baseUrl +
  "/api/get_package_details_by_id/get_package_details_by_id?package_id=" +
  id;
// 62
export const getAggregatorPackagesListUrl =
  baseUrl + "/api/get_aggregator_packages_list/get_aggregator_packages_list";
// 63
export const getPublisherPackagesListUrl =
  baseUrl + "/api/get_publisher_packages_list/get_publisher_packages_list";
// 68
export const getPackagesByAggregatorIDUrl = (id) =>
  baseUrl +
  "/api/get_packages_by_aggregator_id/get_packages_by_aggregator_id?aggregator_id=" +
  id;
// 71
export const getDatResourcetypeUrl =
  baseUrl + "/api/get_dat_resourcetype/get_dat_resourcetype";
// 72
export const getProductpackagesListByProductidUrl = (id) =>
  baseUrl +
  "/api/get_productpackages_list_by_productid/get_productpackages_list_by_productid?datinformaticsproductid=" +
  id;
// 77
export const removePrintElectronicResourcesUrl = (url) =>
  baseUrl +
  "/api/remove_print_electronic_resources/remove_print_electronic_resources?" +
  url;
// 160
export const getConsortiaSubscribedPackagesForLibrariesUrl = (
  informaticscustomerid
) =>
  baseUrl +
  "/api/get_consortia_subscribed_packages_for_libraries/get_consortia_subscribed_packages_for_libraries?informaticscustomerid=" +
  informaticscustomerid;
// 90
export const getOASubscribedHoldingsUrl = (customer_id) =>
  baseUrl +
  "/api/get_oa_subscribed_holdings/get_oa_subscribed_holdings?customer_id=" +
  customer_id;
// 89
export const getOAAssignedSubjectByClientIDUrl = (customer_id) =>
  baseUrl +
  "/api/get_oaassigned_subject_by_client_id/get_oaassigned_subject_by_client_id?customer_id=" +
  customer_id;
// 41
export const getPublisherTypeUrl =
  baseUrl + "/api/get_publisher_type/get_publisher_type";
// 161
export const downloadPrintElectronicLibraryHoldingsUrl = (url) =>
  baseUrl +
  "/api/Download_print_electronic_library_holdings/Download_print_electronic_library_holdings?" +
  url;

// 162
export const downloadLibrarySubscribedPackagesListUrl = (url) =>
  baseUrl +
  "/api/Download_library_subscribed_packages_list/Download_library_subscribed_packages_list?" +
  url;
export const downloadPackageDetailsByiId = (str) =>
  baseUrl +
  "/api/download_package_details_by_id/download_package_details_by_id?package_id=" +
  str;

export const downloadConsortiaSubscribedPackagesListUrl = (url) =>
  baseUrl +
  "/api/download_consortia_subscribed_packages_list/download_consortia_subscribed_packages_list?" +
  url;

export const get_library_subscribed_packages_list = (url) =>
  baseUrl +
  "/api/get_library_subscribed_packages_list/get_library_subscribed_packages_list?" +
  url;
// 88
export const addOASubjectAssignmentUrl = (str) =>
  baseUrl +
  "/api/add_oa_subject_assignment/add_oa_subject_assignment?detailsjson=" +
  str;
// 75
export const uploadPrintElectronicResourcesToLibraryUrl =
  baseUrl +
  "/api/upload_print_electronic_resources_to_library/upload_print_electronic_resources_to_library";
// 164
export const downloadPrintElectronicUploadFormatExcelUrl = (CustomerId) =>
  baseUrl +
  "/api/Download_print_electronic_upload_format_excel/Download_print_electronic_upload_format_excel?CustomerId=" +
  CustomerId;
// 177
export const ExportOAassignedSubjectByClientId = (CustomerId) =>
  baseUrl +
  "/api/Export_oaassigned_subject_by_client_id/Export_oaassigned_subject_by_client_id?customer_id=" +
  CustomerId;

export const get_holding_summary_report_for_library = (CustomerId, type) =>
  baseUrl +
  "/api/get_holding_summary_report_for_library/get_holding_summary_report_for_library?informaticustomer_id=" +
  CustomerId +
  "&report_type=" +
  type;

export const downloadLibraryHoldingSummary = (CustomerId, type) =>
  baseUrl +
  "/api/download_library_holding_summary/download_library_holding_summary?informaticustomer_id=" +
  CustomerId +
  "&report_type=" +
  type;

export const get_holding_summary_report_for_library1 = (str) =>
  baseUrl +
  "/api/get_holding_summary_report_for_library/get_holding_summary_report_for_library?" +
  str;

// packages

export const packageTypeUrl = (agent_type) =>
  baseUrl +
  "/api/get_aggregator_master_list/get_aggregator_master_list?aggregator_name&agent_type=" +
  agent_type;

export const packagesTypeUrl = (agent_type) =>
  baseUrl +
  "api/get_aggregator_master_list_by_type/get_aggregator_master_list_by_type?aggregator_type=" +
  agent_type;

export const getSubPackahesUrl = () =>
  baseUrl + "/api/get_packages_list/get_packages_list?package_name=";
// Resource mgmt consortia

// 80
export const getConsortiaSubscribedPackagesListUrl = (str) =>
  baseUrl +
  "/api/get_consortia_subscribed_packages_list/get_consortia_subscribed_packages_list?" +
  str;
// 81
export const getConsortiaPackageAssignedMemberListUrl = (str) =>
  baseUrl +
  "/api/get_consortia_package_assigned_member_list/get_consortia_package_assigned_member_list?" +
  str;
export const getClientWiseSubscribedAggregatorListUrl = (url) =>
  baseUrl +
  "/api/get_client_wise_subscribed_aggregator_list/get_client_wise_subscribed_aggregator_list?" +
  url;

export const getClientWiseSubscribedPackageListUrl = (url) =>
  baseUrl +
  "/api/get_client_wise_subscribed_package_list/get_client_wise_subscribed_package_list?" +
  url;

// 84
export const removeConsortiaSubscribedPackageUrl = (str) =>
  baseUrl +
  "/api/remove_consortia_subscribed_package/remove_consortia_subscribed_package?" +
  str;
// 60
export const getConsortiaMemberListUrl = (consortia_id) =>
  baseUrl +
  "/api/get_consortia_member_list/get_consortia_member_list?member_name&consortia_id=" +
  consortia_id;

// 61
export const getPackagesListAvailableForConsortiaUrl = (consortia_id) =>
  baseUrl +
  "/api/get_packages_list_available_for_consortia/get_packages_list_available_for_consortia?consortia_id=" +
  consortia_id;
// 83
export const addNewMembersToconsortiaPackageUrl = (str) =>
  baseUrl +
  "/api/add_new_members_to_consortia_package/add_new_members_to_consortia_package?detailsJson=" +
  str;

// Reports
// 57
export const getDdrReportUrl =
  baseUrl +
  "/api/get_ddr_report/get_ddr_report?customer_id=1759&consortia_member_id=0&report_type=1&ddr_from_date=2020-10-22&ddr_to_date=2020-12-01&zone_id_val=0&consortia_id=0";
// 52
export const getContuReportUrl =
  baseUrl +
  "/api/get_contu_report/get_contu_report?customer_id=1759&requesting_customer_id=1682&report_type=0&selected_year=2020";
export const downloadConsortiaSubscribedPackagesUrl = (informaticscustomerid) =>
  baseUrl +
  "/api/download_consortia_subscribed_packages_for_libraries/download_consortia_subscribed_packages_for_libraries?informaticscustomerid=" +
  informaticscustomerid;

// Uploads

export const userUploadFormatExcelUrl = (CustomerId) =>
  baseUrl +
  "/api/Download_admin_module_profile_users_format_excel/Download_admin_module_profile_users_format_excel?CustomerId=" +
  CustomerId;

export const uploadUserList =
  baseUrl +
  "/api/upload_admin_module_profile_users/upload_admin_module_profile_users";

export const existUserUploadExcelFormatUrl = (CustomerId) =>
  baseUrl +
  "/api/Download_admin_module_profile_users_with_existing_Userdata/Download_admin_module_profile_users_with_existing_Userdata?CustomerId=" +
  CustomerId;

export const deleteUserUrl =
  baseUrl +
  "/api/delete_admin_module_profile_users/delete_admin_module_profile_users";
// +
// CustomerId +
// "&Delete_User=" +
// Delete_User;

export const Reset_ForgotPasswordUrl =
  baseUrl + "user/Forget_password_Newpassword_generation";

export const ForgotPasswordUrl =
  baseUrl + "/user/Forget_password_token_generation";

export const downloadSubscribedDetailsListUrl = (url) =>
  baseUrl +
  "/api/download_account_subscription_details/download_account_subscription_details?" +
  url;

export const ResetAdminUserLoginPasswordbyAdmin =
  baseUrl +
  "/api/reset_user_login_password_by_admin/reset_user_login_password_by_admin";

export const ResetScholarUserPasswordbyAdmin =
  baseUrl +
  "/api/reset_scholar_user_password_by_admin/reset_scholar_user_password_by_admin";

export const downloadConsortiaPackageDetailsById = (str) =>
  baseUrl +
  "/api/download_consortia_package_details_by_id/download_consortia_package_details_by_id?package_id=" +
  str;

export const diskoverSearchLoginUrl =
  baseUrl + "/user/generate_product_login_token_from_admin";

export const validateLibraryAdminLoginByToken =
  baseUrl + "/user/validate_library_admin_login_by_token";

export const customerResourceType = (id) =>
  baseUrl +
  "/api/get_subscription_data_types/get_subscription_data_types?customer_id=" +
  id;
