import { useRef } from "react";
import { useEffect, useState } from "react";
import { useIdleTimer } from "react-idle-timer";
import { IdleTimerContainerDialog } from "../../atoms/Modals/IdleTimerAlert";
import { removeAllStorage, removeLocalStorage } from "../../utilities/storage";
import Timer from "./Timer";

export const IdleTimerContainer = () => {
  const [isAlertOn, setIsAlertOn] = useState(false);
  // const [remaining, setRemaining] = useState(0);
  const sessionTimeOutRef = useRef(null);

  const logout = () => {
    removeLocalStorage("user");
    removeLocalStorage("customer");
    removeAllStorage();
    clearTimeout(sessionTimeOutRef.current);
    window.location.href = "/";
  };

  const onIdle = () => {
    setIsAlertOn(true);
    sessionTimeOutRef.current = setTimeout(logout, 60000);
  };

  const { isPrompted, getRemainingTime } = useIdleTimer({
    onIdle,
    timeout: 1000 * 60 * 15,
    promptTimeout: 0,
    events: [
      "mousemove",
      "keydown",
      "wheel",
      "DOMMouseScroll",
      "mousewheel",
      "mousedown",
      "touchstart",
      "touchmove",
      "MSPointerDown",
      "MSPointerMove",
      "visibilitychange",
    ],
    immediateEvents: [],
    debounce: 0,
    throttle: 0,
    eventsThrottle: 200,
    element: document,
    startOnMount: true,
    startManually: false,
    stopOnIdle: false,
    crossTab: true,
    name: "idle-timer",
    syncTimers: 0,
    leaderElection: false,
  });

  const handleTextMessage = () => {
    return (
      <div className="text-center">
        <p>
          <strong>Your session is about to expire! </strong>
        </p>
        <div>
          <p>
            You will be logged out in <Timer /> seconds
          </p>
        </div>

        <p>Do you want to stay signed in?</p>
      </div>
    );
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (isPrompted()) {
        Math.ceil(getRemainingTime() / 1000);
      }
    });
    return () => {
      clearInterval(interval);
    };
  }, [getRemainingTime, isPrompted, isAlertOn]);

  return (
    <>
      <IdleTimerContainerDialog
        show={isAlertOn}
        modalClose={setIsAlertOn}
        content={handleTextMessage()}
        successCallback={logout}
        heading="Session Warning!!!"
        logout={logout}
        sessionTimeOutRef={sessionTimeOutRef}
      />
    </>
  );
};
