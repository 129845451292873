import React, { useState, useEffect, Fragment, useCallback } from "react";
import InputField from "../../atoms/InputField/InputField";
import _ from "lodash";
import OrgSectionHeading from "./OrgSectionHeading";
import { InfoDialog } from "../../atoms/Modals/InfoDialog";
import SelectStateDropDown from "../../atoms/DropDown/StateDropDown";
import SelectCityDropDown from "../../atoms/DropDown/Citydropdown";
import Error from "../../atoms/Error/Error";
import { formatedObject } from "../../containers/Reports/constants";

const OrgDetailSection = ({
  setOrgDetailSection,
  customerDetails,
  cityDetails,
  stateDetails,
  setStateId,
  setDynamicData,
  error,
  setError,
}) => {
  const [orgDetails, setOrgDetails] = useState({
    organization_name: "",
    displayname: "",
    shortname: "",
    address: "",
    street: "",
    city: "",
    state: "",
    country: "",
    pin: "",
    phonenumber: "",
    city_id: "",
    state_id: "",
    country_id: "",
    fax: "",
    websiteurl: "",
    ofcemailid: "",
    pan_no: "",
    gst_no: "",
    twitter_id: "",
    facebook_id: "",
    skype_id: "",
    linkedin_id: "",
  });

  const cityDetailsList = formatedObject(cityDetails?.data);
  const stateDetailsList = formatedObject(stateDetails?.data);
  const [isSave, setIsSave] = useState(false);
  const [isSave1, setIsSave1] = useState(false);

  const OrgDetailInput = [
    { key: "organization_name", value: "Organization Name", readonly: true, type: "text" },
    { key: "displayname", value: "Display Name", readonly: true, type: "text" },
    { key: "shortname", value: "Short Name", type: "text" },
    { key: "address", value: "Address", type: "text" },
    { key: "street", value: "Street", type: "text" },
    { key: "country", value: "Country", readonly: true, type: "text" },
    { key: "state", value: "State", type: "select", data: stateDetailsList, },
    { key: "city", value: "City", type: "select", data: cityDetailsList, },
    { key: "pin", value: "Pin Code", type: "text" },
    { key: "phonenumber", value: "Phone Number", type: "text" },
    { key: "fax", value: "Fax", type: "text" },
    { key: "websiteurl", value: "Website Url", type: "url" },
    { key: "ofcemailid", value: "Office Mail Id", type: "email" },
    { key: "pan_no", value: "PAN No", type: "text" },
    { key: "gst_no", value: "GST No", type: "text" },
    { key: "twitter_id", value: "Twitter", type: "text" },
    { key: "facebook_id", value: "Facebook", type: "text" },
    { key: "skype_id", value: "Skype", type: "text" },
    { key: "linkedin_id", value: "Linkedin", type: "text" },
  ];

  const getValuesFromData = useCallback(() => {
    const _orgDetails = _.cloneDeep(customerDetails.data[0][0]);
    setOrgDetails(_orgDetails);
  }, [customerDetails]);

  useEffect(() => {
    getValuesFromData();
  }, [getValuesFromData]);



  const handleInputchange = (value, name) => {
    const dynamic = _.cloneDeep(orgDetails);
    dynamic[name] = value;
    if (name === "ofcemailid") {
      if (dynamic["ofcemailid"] !== "undefined") {
        let regex =
          /^[a-z0-9]+(\.[_a-z0-9]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{3,15})$/;
        if (!regex.test(dynamic["ofcemailid"])) {
          setError("Please enter Email Id");
        } else {
          setError("");
        }

        if (value === "") {
          setError("");
        }
      }
    } else if (name === "pan_no") {
      if (dynamic["pan_no"] !== "undefined") {
        let regex = /^([A-Z]){5}([0-9]){4}([A-Z]){1}?$/;
        if (!regex.test(dynamic["pan_no"])) {
          setError("Please enter valid PAN");
        } else {
          setError("");
        }

        if (value === "") {
          setError("");
        }
      }
    } else if (name === "gst_no") {
      if (dynamic["gst_no"] !== "undefined") {
        let regex =
          /^([0-9]){2}([A-Z]){5}([0-9]){4}([A-Z]){1}([1-9A-Z]){1}Z([0-9A-Z]){1}$/;
        if (!regex?.test(dynamic["gst_no"])) {
          setError("Please enter valid GST number");
        } else {
          setError("");
        }

        if (value === "") {
          setError("");
        }
      }
    } else if (name === "websiteurl") {
      if (dynamic["websiteurl"] !== "undefined") {
        let regex = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;

        if (!regex?.test(dynamic["websiteurl"])) {
          setError("Please enter valid URL");
        } else {
          setError("");
        }

        if (value === "") {
          setError("");
        }
      }
    } else {
      setError("");
    }

    setOrgDetails(dynamic);
    setDynamicData(dynamic);
    setOrgDetailSection(dynamic);
  };

  const handleStateDropDown = (value, name) => {
    const dynamic = _.cloneDeep(orgDetails);
    dynamic[name] = value;
    setStateId(value);
    dynamic["state_id"] = value;
    if (dynamic["state"] === "") {
      dynamic["state_id"] = setOrgDetails("");
    }
    dynamic["city"] = "";
    dynamic["city_id"] = setOrgDetails("");
    setOrgDetails(dynamic);
    setDynamicData(dynamic);
    setOrgDetailSection(dynamic);
  };

  const handleCityDropDown = (value, name) => {
    const dynamic = _.cloneDeep(orgDetails);
    dynamic[name] = value;
    dynamic["city_id"] = value;
    setOrgDetails(dynamic);
    setDynamicData(dynamic);
    setOrgDetailSection(dynamic);
  };

  const _heading = "Organization Details";

  return (
    <section className="OrgDetailOrganization">
      <InfoDialog
        show={isSave}
        modalClose={setIsSave}
        content={"Please Select State"}
        successCallback={handleInputchange}
        heading="Confirm"
      />
      <InfoDialog
        show={isSave1}
        modalClose={setIsSave1}
        content={"Please Select City"}
        successCallback={handleInputchange}
        heading="Confirm"
      />
      <div className="org_mrg_frm_top">
        <OrgSectionHeading heading={_heading} />
      </div>

      {error && <Error text={error} />}

      <div>
        {OrgDetailInput && OrgDetailInput.map((input, index) => (
          <Fragment key={index}>
            {input.key === "state" && (
              <div className="common-select-space inline w-350">
                <SelectStateDropDown
                  defaultSelectValue={orgDetails[input.key] || ""}
                  data={input.data}
                  // name={input.key}
                  value={orgDetails[input.key] || ""}
                  label={input.value}
                  handleStateDropDown={handleStateDropDown}
                  classname={`w-350 ${input.key}`}
                  key={index}
                />
              </div>
            )}
            {input.key === "city" && (
              <div className="common-select-space inline w-350">
                <SelectCityDropDown
                  defaultSelectValue={orgDetails[input.key] || ""}
                  data={input.data}
                  // name={input.key}
                  value={orgDetails[input.key] || ""}
                  label={input.value}
                  handleCityDropDown={handleCityDropDown}
                  classname={`w-350 ${input.key}`}
                  key={index}
                />
              </div>
            )}
            {input.type === "text" && (
              <>
                <InputField
                  type="text"
                  key={index}
                  placeholder={input.value}
                  name={input.key}
                  value={orgDetails[input.key] || ""}
                  handleInputchange={handleInputchange}
                  classname={`w-350 ${input.key}`}
                  readonly={input.readonly ? input.readonly : false}
                  required={input.key}
                />
              </>
            )}
            {input.type === "number" && (
              <>
                <InputField
                  type="number"
                  key={index}
                  placeholder={input.value}
                  name={input.key}
                  value={orgDetails[input.key] || ""}
                  handleInputchange={handleInputchange}
                  classname={`w-350 ${input.key}`}
                  readonly={input.readonly ? input.readonly : false}
                  required={input.key}
                />
              </>
            )}
            {input.type === "url" && (
              <>
                <InputField
                  type="url"
                  key={index}
                  placeholder={input.value}
                  name={input.key}
                  value={orgDetails[input.key] || ""}
                  handleInputchange={handleInputchange}
                  classname={`w-350 ${input.key}`}
                  readonly={input.readonly ? input.readonly : false}
                  required={input.key}
                />
              </>
            )}
            {input.type === "email" && (
              <>
                <InputField
                  type="email"
                  key={index}
                  placeholder={input.value}
                  name={input.key}
                  value={orgDetails[input.key] || ""}
                  handleInputchange={handleInputchange}
                  classname={`w-350 ${input.key}`}
                  readonly={input.readonly ? input.readonly : false}
                  required={input.key}
                />
              </>
            )}
          </Fragment>
        ))}
      </div>
    </section>
  );
};

export default OrgDetailSection;
