import React from "react";

const ResourcePackagesTable = ({
  tableData,
  action,
  handleAction,
  downloadPackageHoldings,
  isSearched,
}) => {
  const _action = action ? true : false;

  return (
    <table className="table table-bordered table-striped">
      <thead className="summayTable">
        <tr>
          <th>SI.No</th>
          <th className="ps-3">Aggregator / Publisher Name</th>
          <th>Package Name</th>
          {_action && <th colSpan="2">Action</th>}
        </tr>
      </thead>
      <tbody>
        {!tableData && (
          <tr>
            {!isSearched ? (
              <td colSpan="7" className="text-center fw-bold contactPersondata">
                No packages Configured for My Library
              </td>
            ) : (
              <td colSpan="7" className="text-center fw-bold contactPersondata">
                Given Package not available as part of My Library
              </td>
            )}
          </tr>
        )}
        {tableData &&
          tableData.map((data, index) => (
            <tr key={index} className="contactPersondata">
              <td className="ddrtable"> {data.id} </td>
              <td>{data.aggregator_name}</td>
              <td>{data.package_name}</td>
              {_action && (
                <td className="ddrtable">
                  <button
                    type="button"
                    className="downloadbtn contactPersondata"
                    onClick={() => downloadPackageHoldings(data)}
                  >Download</button>
                </td>
              )}
              <td className="ddrtable">
                <button
                  type="button"
                  className="dltButtn"
                  onClick={() => handleAction(data)}
                >Delete</button>
              </td>
            </tr>
          ))}
      </tbody>
    </table>
  );
};

export default ResourcePackagesTable;
