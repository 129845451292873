import React, { useState } from "react";


const FileUpload = ({ name, handleInputchange, setIsFileUpload }) => {
  const [selectedFile, setSelectedFile] = useState();
  const [isFilePicked, setIsFilePicked] = useState(false);

  const changeHandler = (event) => {
    setSelectedFile(event.target.files[0]);
    setIsFilePicked(true);
    handleInputchange(event.target.files[0], name);
    setIsFileUpload(event.target.files[0])

  };

  return (
    <div>
      <input
        type="file"
        name="file"
        onChange={changeHandler}
        className="UserUploadSpace"
      />

      {isFilePicked ? (
        <div>
          <p className="usr_uplds">Filename: {selectedFile.name}</p>
        </div>
      ) : (
        <p></p>
      )}
    </div>
  );
};

export default FileUpload;
