import React from 'react'

/**
* @author
* @function AdminHeading
**/

const AdminHeading = ({admin_type}) => {
  return(
    <div className="AdminHeading">
      {( admin_type !== "consortia_admin" ) && <>Library Admin </>}
      {( admin_type === "consortia_admin" ) && <>Consortia Admin </>}
      
      </div>
   )
  }


export default AdminHeading