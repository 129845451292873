import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import {
  downloadConsortiaPackageDetailsById,
  downloadConsortiaSubscribedPackagesUrl,
  getConsortiaSubscribedPackagesListUrl,
} from "../../utilities/constants";
import { viewConsortiaResources } from "../../containers/ResourceMgmt/constants";
import CommonHeading from "../../atoms/Heading/CommonHeading";
import { fetchUrl } from "../../utilities/apiFunctions/fatchUrl";
import { Pagination } from "../ReportsComponents/Pagination";
import Loading from "../Loading/Loading";



const ViewConsortiaResources = ({ informaticscustomerID }) => {
  const [vcrData, setVcrData] = useState([]);
  const [loading, setLoading] = useState(false);

  vcrData &&
    vcrData.forEach((item, ind) => {
      item.id = ind + 1;
    });

  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(15);
  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords =
    vcrData && vcrData.slice(indexOfFirstRecord, indexOfLastRecord);
  const nPages = Math.ceil(vcrData && vcrData.length / recordsPerPage);

  const getData = async () => {
    setLoading(true);
    const str = `consortia_id=${informaticscustomerID}&package_name,&aggregator_id=0&package_type=0`;
    const data = await fetchUrl(
      getConsortiaSubscribedPackagesListUrl(str)
    );
    setVcrData(data && data.data && data.data[0][0]);
    setLoading(false);
  };

  useEffect(() => {
    getData();

  }, []);

  const handleDownloadHoldings = () => {
    const baseUrl = downloadConsortiaSubscribedPackagesUrl(
      informaticscustomerID
    );
    window.open(baseUrl);
  };

  const downloadPackageDetails = (val) => {
    window.open(downloadConsortiaPackageDetailsById(val.package_id));
  };
  return (
    <div className="ManageOASubscription">
      <CommonHeading heading={viewConsortiaResources} />

      <div className="ManageOASubscriptionBtns">
        <Button
          variant="secondary contactPersondata"
          onClick={handleDownloadHoldings}
        >
          Download Holdings
        </Button>
      </div>
      <div>
        {loading && <Loading />}
        <table className="table table-bordered table-striped">
          <thead className="summayTable">
            <tr>
              <th>SI No</th>
              <th>Aggregator/Publisher Consortia Name</th>
              <th>Package Name</th>
              <th>Unique Count</th>
              <th>Last Updated Date</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {!currentRecords && !currentRecords && (
              <tr className="text-center fw-bold contactPersondata">
                <td colSpan="5">No Data Available</td>
              </tr>
            )}
            {currentRecords &&
              currentRecords.map((data, index) => (
                <tr key={index} className="contactPersondata">
                  <td className="ddrtable"> {data.id} </td>
                  <td>{data.aggregator_name}</td>
                  <td>{data.package_name}</td>
                  <td className="ddrtable">{data.unique_resource_count}</td>
                  <td className="ddrtable">{data.last_modified_date}</td>
                  <td className="ddrtable">
                    <button
                      type="button"
                      className="downloadbtn contactPersondata"
                      onClick={() => downloadPackageDetails(data)}
                    >
                      Download
                    </button>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      <div className="pgnation">
        <Pagination
          nPages={nPages && nPages}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          setRecordsPerPage={setRecordsPerPage}
          tableDataReport={vcrData}
          recordsPerPage={recordsPerPage}
        />
      </div>
    </div>
  );
};

export default ViewConsortiaResources;
