import React from "react";
import "./ResourceMgmt.css";
import { getLocalStorage } from "../../utilities/storage";
import LibraryAdminRmgmt from "./LibraryAdminRmgmt";
import ConsortiaAdminRmgmt from "./ConsortiaAdminRMgmt";


const ResourceMgnmt = () => {
  const { customerID, admin_type } = getLocalStorage("user");
  const { consortiamaster_id } = getLocalStorage("customer");

  return (
    <div>
      {admin_type === "library_admin" && (
        <LibraryAdminRmgmt
          customerID={customerID}
          consortiamaster_id={consortiamaster_id}
          admin_type={admin_type}
        />
      )}

      {admin_type !== "library_admin" && (
        <ConsortiaAdminRmgmt
          customerID={customerID}
          consortiamaster_id={consortiamaster_id}
          admin_type={admin_type}
        />
      )}
    </div>
  );
};

export default ResourceMgnmt;
