import React, { useState } from "react";
import { NewModal } from "../Modals/Modal";
import OrgViewTable from "./OrgViewTable";
import { Button } from "react-bootstrap";
import { fetchUrl } from "../../utilities/apiFunctions/fatchUrl";
import { viewAccountSubscriptionDetailsUrl } from "../../utilities/constants";


const OrgTableWithView = ({ tableData, orgTableDatas, customerID }) => {
  const [viewOn, setViewOn] = useState(false);
  const [fetchOrgContact, setFetchOrgContact] = useState([]);

  const tableProperty = [
    { key: "product_type", value: "Product" },
    { key: "subscribed_product", value: "Product Package" },
    { key: "subscription_type", value: "Subscription Type" },
    // { key: "DateofRenew", value: "Date of Renew" },
    { key: "start_date", value: "Subscription Start Date" },
    { key: "end_date", value: "Subscription End Date" },
    { key: "grace_period", value: "Grace Period" },
  ];

  const handleViewClick = async (item) => {
    const str = `customerId=${customerID}&subscription_level_id=${item.subscriprion_level_id}&subscription_details_id=${item.reference_id}`;
    const viewASDetailsData = await fetchUrl(
      viewAccountSubscriptionDetailsUrl(str)
    );
    setFetchOrgContact(viewASDetailsData);
    setViewOn(true);
  };

  const data = (
    <OrgViewTable
      tableProperty={tableProperty}
      fetchOrgContact={fetchOrgContact}
      productname={orgTableDatas && orgTableDatas.map((item) => item.product)}
      customerID={customerID}
    />
  );
  return (
    <>
      <NewModal
        show={viewOn}
        modalHandle={setViewOn}
        data={data}
        size="lg"
        heading="Subscription Details"
        dialogClassName={"OrgViewTableModal"}
      />

      <table className="OrgTable">
        <thead>
          <tr>
            {tableData.map((head, index) => (
              <th key={index}>{head.value}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {orgTableDatas.map((orgContact, index) => (
            <tr key={index} className="contactPersondata">
              <td>{orgContact.product}</td>
              <td>{orgContact.Subscription_level}</td>
              <td>
                <Button onClick={() => handleViewClick(orgContact)}>
                  View
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

export default OrgTableWithView;
