import React from "react";
import { Button } from "react-bootstrap";
import { downloadSubscribedDetailsListUrl } from "../../utilities/constants";



const SubscriptionDetailsTable = ({
  subscriptionTableData,
  customername,
  subscriptionResorceData,
  customerID,
}) => {
  const subscriptionDetailId =
    subscriptionTableData &&
    subscriptionTableData.map((val) => val.subscription_detail_id);

  const subscriptionLevel =
    subscriptionTableData &&
    subscriptionTableData.map((val) => val.subscription_level);
  const resourceData =
    subscriptionResorceData &&
    subscriptionResorceData.map((val) => {
      const a = {};
      a["value"] = Object.keys(val)[0];
      a["label"] = Object.values(val)[0];
      return <li key={a.value}>{a.label}</li>;
    });

  const handleDownloadButton = () => {
    const _data = {
      customerId: customerID,
      subscription_level_id: subscriptionLevel,
      subscription_details_id: subscriptionDetailId,
    };

    let str = Object.entries(_data)
      .map(([key, val]) => `${key}${val ? "=" : ""}${val}`)
      .join("&");
    window.open(downloadSubscribedDetailsListUrl(str));
  };

  return (
    <>
      <div>
        <h6>Organization Name: {customername}</h6>
      </div>
      <div>
        <table className="table table-bordered table-striped">
          <thead className="summayTable">
            <tr>
              <th>S.No</th>
              <th>Product Name</th>
              <th>Subscribed Package</th>
              <th>Account Type</th>
              <th>Subscription Start Date</th>
              <th>Subscription End Date</th>
              <th>Source Types</th>
            </tr>
          </thead>
          <tbody>
            {!!subscriptionTableData &&
              subscriptionTableData.map((data, index) => (
                <tr key={index} className="contactPersondata">
                  <td className="ddrtable">{index + 1}</td>
                  <td className="ddrtable">{data.product_type}</td>
                  <td className="ddrtable">{data.subscribed_product}</td>
                  <td className="ddrtable">{data.subscription_type}</td>
                  <td className="ddrtable">{data.start_date}</td>
                  <td className="ddrtable">{data.end_date}</td>
                  <td className="ddrtable resourceDatafont">
                    {resourceData}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
        <div className="resourceDataDwnBttn">
          <Button className="contactPersondata" onClick={handleDownloadButton}>
            Download
          </Button>
        </div>
      </div>
    </>
  );
};

export default SubscriptionDetailsTable;
