import { baseUrl } from "../../utilities/constants";

// 47
export const getReceivedDDROverviewStatus = (cID, year) =>
  baseUrl +
  "/api/get_received_ddr_overview_status/get_received_ddr_overview_status?Customerid=" +
  cID +
  "&Year=" +
  year;
// 165
export const getSentDDROverviewStatus = (cID, year) =>
  baseUrl +
  "/api/get_sent_ddr_overview_status/get_sent_ddr_overview_status?Customerid=" +
  cID +
  "&Year=" +
  year;

// 60
export const getConsortiaMemberList = (consortiamasterID) =>
  baseUrl +
  "/api/get_consortia_member_list/get_consortia_member_list?consortia_id=" +
  consortiamasterID +
  "&member_name";
// 33
export const getSentDDRList = (str) =>
  baseUrl + "/api/get_sent_ddr_list/get_sent_ddr_list?" + str;

export const getSentDDRListPagewise = (str) =>
  baseUrl + "/api/get_sent_ddr_list_pagewise/get_sent_ddr_list_pagewise?" + str;
// 32
export const getReceivedDDRList = (str) =>
  baseUrl + "/api/get_received_ddr_list/get_received_ddr_list?" + str;

export const getReceivedDDRListPageWise = (str) =>
  baseUrl +
  "/api/get_received_ddr_list_pagewise/get_received_ddr_list_pagewise?" +
  str;

export const getDetailsForDDRActionForm = (str) =>
  baseUrl +
  "/api/get_details_for_ddr_action_form/get_details_for_ddr_action_form?" +
  str;
// 46
export const updateConsortiaDDRForLibrarianRes = (str) =>
  baseUrl +
  "/api/update_consortia_ddr_for_librarian_response/update_consortia_ddr_for_librarian_response?detailjson=" +
  str;
// 46 post
export const updateConsortiaDDRForLibrarianEmail =
  baseUrl +
  "/api/update_consortia_ddr_for_librarian_response/update_consortia_ddr_for_librarian_response?";

export const getDDRLinkResolverLink =
  baseUrl + "/api/link_resolver_bulk_records/link_resolver_bulk_records";

// DDR priority

export const getDDRDataPriority =
  baseUrl + "api/get_dat_ddr_priority/get_dat_ddr_priority";

// export const IncomingRequest = "Incoming Request";
// export const OutgoingRequest = "Outgoing Request";
export const IncomingRequest = "Received Request";
export const OutgoingRequest = "Sent Request";
export const DDRStatusOverview = "DDR Status Overview";

export const yearData = [
  { label: "2021", value: "2021" },
  { label: "2020", value: "2020" },
  { label: "2019", value: "2019" },
  { label: "2018", value: "2018" },
  { label: "2017", value: "2017" },
];

export const lastFiveYears = () => {
  var year = new Date().getFullYear();
  const years = Array(5)
    .fill(null)
    .map((r, i) => {
      const latestYear = year - i;
      const latest_year = {};
      latest_year.label = latestYear;
      latest_year.value = latestYear;
      return latest_year;
    });
  return years;
};

export const tablePropertyDDRR = [
  { key: "status", value: "Status" },
  { key: "requestedDate", value: "Date" },
];

export const tableSendPropertyDDRR = [
  { key: "status", value: "Status" },
  { key: "requestedDate", value: "Date" },
  { key: "remarks", value: "Remarks" },
];

export const selectYearData = [
  { label: "2021", value: "2021" },
  { label: "2020", value: "2020" },
  { label: "2019", value: "2019" },
  { label: "2018", value: "2018" },
  { label: "2017", value: "2017" },
];
export const selectProrityData = [
  { label: "Urgent", value: "Urgent" },
  { label: "Normal", value: "Normal" },
];

export const radioDataValues = {
  Pending: "1",
  "Fulfilled Request": "2",
  Delayed: "3",
  "Article not Available": "4",
  Discarded: "5",
  // "Delivered outside the DDR system": "6",
  "Duplicate Request": "7",
  All: "0",
};
export const selectStatusData = [
  { label: "Fulfill Request", value: "2" },
  { label: "Delay", value: "3" },
  { label: "Not availiable", value: "4" },
  { label: "Discard", value: "5" },
  // { label: "Delivered outside the DDR system", value: "6" },
  // { label: "Outside DDR System", value: "6" },
  { label: "Duplicate", value: "7" },
];

export const selectDeliveyModeData = [
  { label: "Online", value: "online" },
  { label: "Courier", value: "courier" },
  {
    label: "Fullfill outside DDR system",
    value: "fullfill_outside_ddr_system",
  },
];

export const selectReasons = [
  {
    label: "Print copy is in circulation",
    value: "print_copy_is_in_circulation",
  },
  { label: "Issue not yet received", value: "issue_not_yet_received" },
  { label: "Others outside DDR system", value: "others_outside_ddr_system" },
];
