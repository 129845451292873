import React, { Fragment } from "react";

const DDRReportTable = ({ tableData, isRequestSent, isRequestReceived, isRequestReceivedFormat, isRequestSentFormat }) => {
  return (
    <table className="table table-bordered table-striped">
      <thead className="summayTable">
        {isRequestReceived == true && isRequestReceivedFormat == true && (
          <tr>
            <th>SI.No</th>
            <th>Requested By (Institute Name)</th>
            <th>Delayed</th>
            <th>Delivered</th>
            <th>Discarded</th>
            <th>Duplicate</th>
            <th>Pending</th>
            <th>Not Available</th>
            <th className="ddr_ddr-rept">Total Count</th>
          </tr>
        )}

        {isRequestSent == true && isRequestReceivedFormat == true && (
          <tr>
            <th>SI.No</th>
            <th>Request Sent (Institute Name)</th>
            <th>Delayed</th>
            <th>Delivered</th>
            <th>Discarded</th>
            <th>Duplicate</th>
            <th>Pending</th>
            <th>Not Available</th>
            <th className="ddr_ddr-rept">Total Count</th>
          </tr>
        )}

{isRequestReceived == true && isRequestSentFormat == true && (
          <tr>
            <th>SI.No</th>
            <th>Requested By (Institute Name)</th>
            <th>Fulfilled</th>
            <th>Not Fulfilled</th>
            <th className="ddr_ddr-rept">Total Count</th>
          </tr>
        )}

        {isRequestSent == true && isRequestSentFormat == true && (
          <tr>
            <th>SI.No</th>
            <th>Request Sent (Institute Name)</th>
            <th>Fulfilled</th>
            <th>Not Fulfilled</th>
            <th className="ddr_ddr-rept">Total Count</th>
          </tr>
        )}


      </thead>
      <tbody>
        {!!tableData &&
          tableData.map((data, index) => (
            <Fragment>
              {!isRequestSentFormat?(
              <tr key={index} className="contactPersondata">
                {/* {isIndexed && <td className="ddrtable"> {data.id} </td>} */}
                <td className="ddrtable"> {data.id} </td>
                <td>{data.request_by_or_to_client}</td>
                <td className="ddrtable">{data.delayed}</td>
                <td className="ddrtable">{data.delivered}</td>
                <td className="ddrtable">{data.discarded}</td>
                <td className="ddrtable">{data.duplicate}</td>
                <td className="ddrtable">{data.pending}</td>
                <td className="ddrtable">{data.unfulfilled}</td>
                <td className="ddrtable">{data.total_count}</td>
              </tr>
              ):(<tr key={index} className="contactPersondata">
              <td className="ddrtable"> {data.id} </td>
              <td>{data.request_by_or_to_client}</td>
              <td className="ddrtable">{data.delivered}</td>
              <td className="ddrtable">{data.un_delivered}</td>
              <td className="ddrtable">{data.total_count}</td>
            </tr>)}
              {data.total_count === 0 ? (
                <tr>
                  <td colSpan={9} className="ddrtable contactPersondata">
                    <strong>
                      No DDR Sent/Received Data for the selected date
                    </strong>
                  </td>
                </tr>
              ) : (
                ""
              )}
            </Fragment>
          ))}
      </tbody>
    </table>
  );
};

export default DDRReportTable;
