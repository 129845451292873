import React from "react";
import { Button, Modal } from "react-bootstrap";
import "./modals.css";

export const IdleTimerContainerDialog = ({
  show,
  modalClose,
  heading,
  content,
  // full = false,
  size = "md",
  successCallback,
  dialogClassName = "dialog",
  sessionTimeOutRef,
}) => {
  const handleButton = () => {
    modalClose();
    clearTimeout(sessionTimeOutRef.current);
  };
  return (
    <Modal
      show={show}
      size={size}
      onHide={modalClose}
      backdrop={false}
      dialogClassName={dialogClassName}
    >
      <Modal.Header closeButton>
        <Modal.Title>{heading}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>{content}</div>
        <br />
        <div className="text-center">
          <Button
            type="submit"
            className="btn action-btn"
            onClick={handleButton}
          >
            Yes, Keep me signed in
          </Button>
          &nbsp;&nbsp;
          <Button
            type="submit"
            variant="btn btn-secondary"
            onClick={() => successCallback()}
          >
            No, Sign me out
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};
