export const accessType = [
  { label: "Print", value: "PRINT" },
  { label: "Online/Electronic", value: "ELECTRONIC" },
  // { label: "Online/Electronic", value: "ONLINE" },
];

export const subscriptionType = [
  { label: "ALL", value: "0" },
  { label: "print", value: "1" },
  { label: "online", value: "2" },
  { label: "package", value: "3" },
  { label: "open access", value: "4" },
];

export const vhSubscriptionType = [
  // { label: "ALL", value: "0" },
  { label: "Print", value: "1" },
  { label: "Online", value: "2" },
  { label: "Package", value: "3" },
  { label: "Open Access", value: "4" },
];

export const VCLRPackageTableProperty = [
  // {key: "aggregator_id", value: "#Aggregator"},
  { key: "aggregator_name", value: "Aggregator Name" },
  // {key: "package_id", value: "#Package"},
  { key: "package_name", value: "Package Name" },
  { key: "package_type", value: "Package Type" },
  // {key: "unique_resource_count", value: "Unique Resource Count"},
  // {key: "package_belongs_consortia_name", value: "Package Belongs Consortia Name"},
  // {key: "package_belongs_consortia_id", value: "#package Belongs Consortia"},
  // {key: "extraction_url", value: "Extraction Url"},
  { key: "package_status", value: "Package Status" },
  // {key: "last_modified_date", value: "Last Modified Date"},
];

export const ConsrtiaSubscriptionProperty = [
  // {key: "aggregator_id", value: "#Aggregator"},
  { key: "package_type", value: "Type of Package" },

  { key: "aggregator_name", value: "Aggregator Name" },
  // {key: "package_id", value: "#Package"},
  { key: "package_name", value: "Package Name" },
  // {key: "unique_resource_count", value: "Unique Resource Count"},
  // {key: "package_belongs_consortia_name", value: "Package Belongs Consortia Name"},
  // {key: "package_belongs_consortia_id", value: "#package Belongs Consortia"},
  // {key: "extraction_url", value: "Extraction Url"},
  // {key: "package_status", value: "Package Status"},
  // {key: "last_modified_date", value: "Last Modified Date"},
];

export const VCLRTableProperty = [
  { key: "access_type", value: "Access Type" },
  // {key: "end_date",value: "end_date"},
  // {key: "last_updated",value: "last_updated"},
  { key: "resource_id", value: "#Resource" },
  { key: "resource_name", value: "Resource Name" },
  { key: "resource_type", value: "Resource type" },
  // { key: "start_date",value: "start_date"},
];

export const testDropdown = [
  { label: "red", value: "red" },
  { label: "blue", value: "blue" },
  { label: "green", value: "green" },
];

export const tablePropertyVHS = [
  {
    key: "subject_name",
    value: "Subject Name",
  },
];
export const tableProperty = [
  {
    key: "SubjectName",
    value: "Subject Name",
  },
];

export const tablePropertyVCR = [
  { key: "type_of_package", value: "Type of Package" },
  { key: "publisher", value: "Aggregator/Publisher" },
  { key: "package_name", value: "Package Name" },
  { key: "count_of_itles", value: "Count of Titles" },
  { key: "last_updated_date", value: "Last Updated Date" },
];

export const tableData = [
  { SubjectName: "Basic Science" },
  { SubjectName: "Engineering & Technology" },
];

export const ViewConfigureOpenAccessResources =
  "View / Configure Open Access Resources";

export const ManageOASubscriptiom = "Manage OA Subscription";

export const ViewHoldingsSummary = "View Holdings Summary";

export const MANAGECONSORTIASUBSCRIPTION = "Manage consortia subscription";

export const viewConsortiaResources = "View Consortia Resources";
