import React from "react";
import SelectDropDown from "./SelectDropDown";
import InputGroup from "react-bootstrap/InputGroup";


const DropDownGroup = ({
  label,
  data,
  handleInputchange,
  name,
  defaultValue,
  classname,
  defaultSelectValue,
}) => {
  return (
    <div className="DropDownGroup">
      <InputGroup>
        <InputGroup.Prepend>
          <InputGroup.Text>{label}</InputGroup.Text>
        </InputGroup.Prepend>

        <SelectDropDown
          data={data}
          as={InputGroup.Append}
          handleInputchange={handleInputchange}
          name={name}
          defaultValue={defaultValue}
          classname={classname}
          defaultSelectValue={defaultSelectValue || ""}
        />
      </InputGroup>
    </div>
  );
};

export default DropDownGroup;
