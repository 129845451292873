import React, { useEffect, useRef, useState } from "react";
import Dummy_user from "../../assets/img/institution_logo.png";
import ReactTooltip from "react-tooltip";
import { baseUrl, getCustomerDetailsUrl } from "../../utilities/constants";
import { InfoDialogconsortia } from "../../atoms/Modals/InfoDialogconsortis";


const OrgImgSection = ({ setLogo, customerDetails, customerID, userID }) => {
  const inputFile = useRef(null);
  const [imgUrl, setImgUrl] = useState({});
  const [isImageLoad, setIsImageLoad] = useState(false);
  let inputFile123 = useRef();
  const [isSave, setIsSave] = useState(false);
  const [isRightFileFormat, setIsRightFileFormat] = useState(false);
  const [isValidFileFormat, setIsValidFileFormat] = useState(false);

  useEffect(() => {
    setImgUrl(customerDetails && customerDetails?.response?.data[0][0]?.logo);

  }, [imgUrl, isImageLoad]);

  useEffect(() => {
    fetch(getCustomerDetailsUrl(customerID), {})
      .then((val) => val.json())
      .then((resp) => setImgUrl(resp.data[0][0].logo));

  }, []);

  const onFileChange = (event) => {
    event.preventDefault();
    const url = `${baseUrl}/upload-file/`;
    const formData = new FormData();
    const orgalizationLogo = "orgalizationLogo";
    const ProImage = event.target.files[0];
    const ProImageType = ProImage.type;

    formData.append("blob", ProImage);
    formData.append("container_name", orgalizationLogo);
    formData.append("customer_id", customerID);
    formData.append("user_id", userID);
    if (ProImageType !== "video/mp4") {
      fetch(url, {
        method: "POST",
        body: formData,
      })
        .then((response) => response.json())
        .then((resp) => {
          if (resp && resp.access_url) {
            const image = resp.access_url;
            inputFile123 = resp.access_url;

            if (
              ProImageType === "image/png" ||
              ProImageType === "image/jpeg" ||
              ProImageType === "image/jpg"
            ) {
              setIsSave(true);
              setIsImageLoad(true);
              setLogo({ logo: image });
            } else {
              setIsRightFileFormat(true);
            }
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    } else {
      setIsValidFileFormat(true);
    }
  };

  const handleUpload = () => {
    inputFile.current.click();
  };

  return (
    <section className="OrgDetailsImgComponent org_mrg_frm_top">
      <InfoDialogconsortia
        show={isSave}
        modalClose={setIsSave}
        content={
          "Uploaded Institutional Logo will Update After Saving the Data"
        }
        successCallback={onFileChange}
        heading="Info"
        size="md"
      />
      <InfoDialogconsortia
        show={isRightFileFormat}
        modalClose={setIsRightFileFormat}
        content={"Please use a valid photo file format"}
        successCallback={onFileChange}
        heading="Info"
      />
      <InfoDialogconsortia
        show={isValidFileFormat}
        modalClose={setIsValidFileFormat}
        content={"Please use a valid photo file format"}
        successCallback={onFileChange}
        heading="Info"
      />
      <div className="OrgDetailsImg">
        <input
          type="file"
          alt="logo"
          accept="image/png,image/jpeg,image/jpg"
          id="file"
          onChange={onFileChange}
          ref={inputFile}
          style={{ display: "none" }}
        />
        {imgUrl === null ? (
          <div>
            {" "}
            <img className="containerImg" alt="User pic" src={Dummy_user} />
          </div>
        ) : (
          <div>
            {(imgUrl === `\\x`) || (imgUrl === "") || (imgUrl ==null)? (
              <img className="containerImg" alt="User pic" src={Dummy_user} />
            ) : (
              <img
                className="containerImg"
                alt="User pic"
                src={imgUrl ? imgUrl : inputFile123}
              />
            )}
          </div>
        )}
        {customerDetails.response.data[0][0].logo === "\\x" ||
          customerDetails.response.data[0][0].logo == null ||
          customerDetails.response.data[0][0].logo === "" ? (
          <span
            onClick={() => handleUpload()}
            className="orglogo"
            data-tip="System will accept .png, .jpg, .jpeg"
          >
            Upload Logo
            <ReactTooltip />
          </span>
        ) : (
          <span
            onClick={() => handleUpload()}
            className="orglogo"
            data-tip="System will accept .png, .jpg, .jpeg"
          >
            Update Logo
            <ReactTooltip />
          </span>
        )}
      </div>
    </section>
  );
};

export default OrgImgSection;
