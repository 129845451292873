import React from "react";
import Widget from "./Widget";
import _ from "lodash";

/**
 * @author
 * @function WidgetResources
 **/

const WidgetResources = ({ resourceData, openAccess, heading }) => {
  const _resourceData = _.cloneDeep(resourceData);

  const combineResourceCounts = (data) => {
    let combinedCount = 0;
    const combinedResourceTypes = [
      "Magazines",
      "Books Series",
      "Pre-print",
      "Journals",
    ];

    data?.forEach((item) => {
      if (combinedResourceTypes?.includes(item?.resource_type)) {
        combinedCount += item?.resource_count;
      }
    });

    const filteredData = data?.filter(
      (item) => !combinedResourceTypes?.includes(item.resource_type)
    );

    // Create the new array with combined count and filtered data
    const result = [
      {
        resource_type: "Journals",
        resource_count: combinedCount,
      },
    ];

    // Append the filtered data to the result
    filteredData?.forEach((item) => result.push(item));
    return result;
  };

  const combineResourceCountsForOpenAccess = (data) => {
    let combinedCount = 0;
    const combinedResourceTypes = [
      "Journal Articles",
      "Pre-Print",
      "Magazine Articles",
      "Books"
    ];

    data?.forEach((item) => {
      if (combinedResourceTypes?.includes(item?.val)) {
        combinedCount += item?.count;
      }
    });

    const filteredData = data?.filter(
      (item) => !combinedResourceTypes?.includes(item.val)
    );

    // Create the new array with combined count and filtered data
    const result = [
      {
        resource_type: "Journal Articles",
        resource_count: combinedCount,
      },
    ];

    // Append the filtered data to the result
    filteredData?.forEach((item) => result.push(item));

    return result;
  };

  // let data = openAccess
  //   ? _resourceData?.data_type?.buckets
  //   : _resourceData?.data[0][0];

  let data = openAccess
    ? combineResourceCountsForOpenAccess(_resourceData?.data_type?.buckets)
    : combineResourceCounts(_resourceData?.data[0][0]);

  return (
    <div className="widgetContainer">
      <div className="widgetMain">
        {data &&
          data?.map((item, index) => (
            <Widget
              value={item.count ? item.count : item.resource_count}
              item={item.val ? item.val : item.resource_type}
              text={item.val ? item.val : item.resource_type}
              key={index}
            />
          ))}
        {/* */}
      </div>
      <div className="widget_footer text-center"> {heading} </div>
    </div>
  );
};

export default WidgetResources;
