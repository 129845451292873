export const convertToInternationalCurrencySystem = (labelValue) => {
  // Nine Zeroes for Billions
  return Math.abs(Number(labelValue)) >= 1.0e9
    ? (Math.abs(Number(labelValue)) / 1.0e9).toFixed(2) + " B"
    : // Six Zeroes for Millions
    Math.abs(Number(labelValue)) >= 1.0e6
    ? (Math.abs(Number(labelValue)) / 1.0e6).toFixed(2) + " M"
    : // Three Zeroes for Thousands
      // : Math.abs(Number(labelValue)) >= 1.0e+3

      // ? (Math.abs(Number(labelValue)) / 1.0e+3).toFixed(2) + " K"

      Math.abs(Number(labelValue));
};

// alert( convertToInternationalCurrencySystem (6800000) ); // this outputs 6.8M
