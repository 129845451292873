import React from 'react'

/**
* @author
* @function OrgSectionHeading
**/

const OrgSectionHeading = ({heading}) => {
  return(
    <h6>{heading}</h6>
   )
  }


export default OrgSectionHeading