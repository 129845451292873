import React, { useEffect, useState } from "react";
import _ from "lodash";
import OrgSectionHeading from "./OrgSectionHeading";
import OrgTableWithView from "./OrgTableWithView";



const OrgViewSubscription = ({ customerDetails, customerID }) => {
  const _heading = "View Subscription Details";
  const [orgTableDatas, setOrgTableDatas] = useState([]);

  const tableData = [
    { key: "product", value: "Product" },
    { key: "Subscription_level", value: "Subscription Level" },
    { key: "viewDetails", value: "View Details" },
  ];

  useEffect(() => {
    getValuesFromDatas();

  }, []);

  const getValuesFromDatas = async () => {
    const _customerDetails = _.cloneDeep(customerDetails.data[0][0]);
    const subscription_summary = _customerDetails.subscription_summary
      ? _customerDetails.subscription_summary
      : [];
    setOrgTableDatas(subscription_summary);
  };

  return (
    <section className="OrgViewSubscription">
      <OrgSectionHeading heading={_heading} />

      <OrgTableWithView
        orgTableDatas={orgTableDatas}
        tableData={tableData}
        customerID={customerID}
      />
    </section>
  );
};

export default OrgViewSubscription;
