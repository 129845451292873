import React, { useState, useEffect } from "react";
import _ from "lodash";
import OrgSectionHeading from "./OrgSectionHeading";
import Loading from "../Loading/Loading";
import IpTableWithDelete from "./IpTable";
import Ip6TableWithDelete from "./Ip6TableWithDelete";

/**
 * @author
 * @function OrgIpRange
 **/

const OrgIpRange = ({
  setIps,
  customerDetails,
  customerID,
  setIsIpUpdated,
}) => {
  const [ip4, setIp4] = useState([]);
  const [ip6, setIp6] = useState([]);
  const [loading, setLoading] = useState(true);

  const ip4Key = { fromip4: "", toip4: "" };
  const ip6Key = { fromip6: "", toip6: "" };

  const tableDataIp4 = [
    { key: "fromip4", value: "From" },
    { key: "toip4", value: "To" },
  ];
  const tableDataIp6 = [
    { key: "fromip6", value: "From" },
    { key: "toip6", value: "To" },
  ];

  useEffect(() => {
    getValuesFromDatas();

  }, []);

  const getValuesFromDatas = async () => {
    const _customerDetails = _.cloneDeep(customerDetails.data[0][0]);
    const _ip4address = _customerDetails.ipaddress4;
    const _ip6address = _customerDetails.ipaddress6;
    setIp4(_ip4address);
    setIp6(_ip6address);
    setLoading(false);
  };

  useEffect(() => {
    setIps({ ipaddress4: ip4, ipaddress6: ip6 });

  }, [ip4, ip6]);


  return (
    <section className="OrgIpRange">
      <OrgSectionHeading heading="IP Range Update" />

      <div className="OrgIpRangeTables">
        <div className="col-md-5">
          <OrgSectionHeading heading="IP4" />
          {loading && <Loading />}
          {!loading && (
            <IpTableWithDelete
              tableData={tableDataIp4}
              init={ip4Key}
              setData={setIp4}
              loadedData={ip4}
              ipaddress4={true}
              customerDetails={
                customerDetails &&
                customerDetails?.data &&
                customerDetails?.data[0] &&
                customerDetails?.data[0][0]
              }
              customerID={customerID}
              setIsIpUpdated={setIsIpUpdated}
            />
          )}
        </div>
        <div className="col-md-6">
          <OrgSectionHeading heading="IP6" />
          {loading && <Loading />}
          {!loading && (
            <Ip6TableWithDelete
              tableData={tableDataIp6}
              init={ip6Key}
              setData={setIp6}
              ipaddress6={true}
              loadedData={ip6}
              customerDetails={
                customerDetails &&
                customerDetails?.data &&
                customerDetails?.data[0] &&
                customerDetails?.data[0][0]
              }
              customerID={customerID}
            />
          )}
        </div>
      </div>
    </section>
  );
};

export default OrgIpRange;
