import React from "react";

import { ResponsivePie } from "@nivo/pie";

export default function PiChartComponent({ componentData, keys }) {
  const data =
    componentData &&
    componentData?.data &&
    componentData?.data[0][0]?.map((item) => {
      let rObj = {};
      rObj["id"] = item[keys["name"]];
      rObj["label"] = item[keys["label"]];
      rObj["value"] = item[keys["count"]];
      rObj["color"] = "hsl(140, 70%, 50%)";

      return rObj;
    });

  return (
    <>
      <div className="chartDiv">
        {!!data && (
          <ResponsivePie
            data={data}
            margin={{ top: 20, right: 40, bottom: 20, left: -270 }}
            innerRadius={0.4}
            padAngle={0.9}
            cornerRadius={1}
            // colors={{ scheme: "nivo" }}
            colors={{ scheme: "set3" }}
            enableArcLinkLabels={false}
            borderWidth={2}
            borderColor={{ from: "color", modifiers: [["darker", 0.2]] }}
            radialLabelsSkipAngle={10}
            radialLabelsTextColor="#333333"
            radialLabelsLinkColor={{ from: "color" }}
            sliceLabelsSkipAngle={10}
            sliceLabelsTextColor="#333333"
            // arcLabelsRadiusOffset={0.5}
            // arcLabelsSkipAngle={8}
            legends={[
              {
                anchor: "top-right",
                direction: "column",
                justify: false,
                translateX: -170,
                translateY: 0,
                itemsSpacing: 0,
                itemWidth: 100,
                itemHeight: 18,
                itemTextColor: "#999",
                itemDirection: "left-to-right",
                itemOpacity: 1,
                symbolSize: 10,
                symbolShape: "circle",
                effects: [
                  {
                    on: "hover",
                    style: {
                      itemTextColor: "#000",
                    },
                  },
                ],
              },
            ]}
          />
        )}
      </div>
    </>
  );
}
