import React from "react";
import HeaderDropDownMenu from "../../atoms/DropDown/HeaderDropDownMenu";
import { useFetch } from "../../utilities/apiFunctions/useFetch";
import { diskoverSearchLoginUrl, getCustomerDetailsUrl } from "../../utilities/constants";
import { IdleTimerContainer } from "../OrgComponents/IdelTimerContainer";
import AdminHeading from "./AdminHeading";
import "./Header.css";
import LogoJgate from "./LogoJgate";
import { fetchUrl } from "../../utilities/apiFunctions/fatchUrl";



const Header = ({ user, customer }) => {
  const { customername } = customer;
  const { admin_type } = user;
  const userID = user.userID;
  const customerID = customer.informaticscustomer_id;
  const customerDetails = useFetch(getCustomerDetailsUrl(customerID), {});
  const data_customer = customerDetails?.response?.data[0][0]?.contactdetails;

  let fName = [];
  let lName = [];
  const primaryContactFirstName = data_customer && data_customer.map((val) => {
    if (val.access_type === "1") {
      fName.push(val.firstname)
      lName.push(val.lastname)
    }
  });
  const userFirstName = fName && fName[0];
  const userLastName = lName && lName[0];

  const handleDiskoverSearch =async ()=>{
    const formData = new FormData();
    formData.append("customer_id", customerID);
    const options = { method: "POST", body: formData };
    const data = await fetchUrl(diskoverSearchLoginUrl, options);
    if(data && data.product_url){
      window.open(data && data.product_url)
    }
  }

  return (
    <header>
      <div className="HeaderLeft">
        <LogoJgate />
        <div className="HeaderAdminHeading">
          <AdminHeading admin_type={admin_type} />
        </div>
        <div className="HeaderLogoInformatics">
          <span><strong>{customername}</strong></span>
        </div>
      </div>
      <div className="HeaderRight">
        <span onClick={handleDiskoverSearch} className="search_portal" target="blank">Click to Diskover Search</span>   
        {/* <span>
           <a
            href="http://20.204.168.47:8100/"
            className="search_portal"
            target="blank"
            onClick={handleDiskoverSearch}
          >
            Click to Diskover Search
          </a> 
        </span> */}
        {/* <span className="welcome_user">
          {data_customer &&
            data_customer.map((val, index) => {
              return val.access_type === "1" ? (
                <p key={index}>
                  Welcome {val.firstname} {val.lastname}
                </p>
              ) : (
                ""
              );
            })}
        </span> */}
        <span className="welcome_user"><p> Welcome {userFirstName} {userLastName}</p></span>
        <HeaderDropDownMenu customerID={customerID} userID={userID} />
        <IdleTimerContainer />
      </div>
    </header>
  );
};

export default Header;
