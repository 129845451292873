import React from "react";
import WidgetHeading from "../../atoms/Heading/WidgetHeading";

/**
 * @author
 * @function UsageSummaryTable
 **/

const UsageSummaryTable = ({ summaryTableData }) => {
  const data = summaryTableData.data[0][0];

  return (
    <>
      <WidgetHeading heading="Usage Summary (Current Year)" />

      <div className="tableWidget dashbrd_mrg_frm_btm">
        <table>
          <thead>
            <tr className="tableheader">
              <th className="tableheader">User Activity</th>
              <th className="tableheader">Total</th>
              <th className="tableheader">Jan</th>
              <th className="tableheader">Feb</th>
              <th className="tableheader">Mar</th>
              <th className="tableheader">Apr</th>
              <th className="tableheader">May</th>
              <th className="tableheader">Jun</th>
              <th className="tableheader">Jul</th>
              <th className="tableheader">Aug</th>
              <th className="tableheader">Sep</th>
              <th className="tableheader">Oct</th>
              <th className="tableheader">Nov</th>
              <th className="tableheader">Dec</th>
            </tr>
          </thead>
          <tbody>
            {data && data?.map((item, index) => (
              <tr key={index} className="tablebdy">
                <td className="tablebdy">{item.useractivity}</td>
                <td className="tablebdy">{item.total}</td>
                <td className="tablebdy">{item.jan}</td>
                <td className="tablebdy">{item.feb}</td>
                <td className="tablebdy">{item.mar}</td>
                <td className="tablebdy">{item.apr}</td>
                <td className="tablebdy">{item.may}</td>
                <td className="tablebdy">{item.jun}</td>
                <td className="tablebdy">{item.jul}</td>
                <td className="tablebdy">{item.aug}</td>
                <td className="tablebdy">{item.sep}</td>
                <td className="tablebdy">{item.oct}</td>
                <td className="tablebdy">{item.nov}</td>
                <td className="tablebdy">{item.dec}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default UsageSummaryTable;
