import React, { useEffect, useState, Fragment } from "react";
import { Button } from "react-bootstrap";
import _ from "lodash";
import DropDownGroup from "../../atoms/DropDown/DropDownGroup";
import Radio from "../../atoms/Radio/Radio";
import {
  RequestedFromLabel,
  Year,
  ILL,
  countReportKeys,
  ContuReport,
  getContuReport,
  exportContuReport,
  illSelectDropdown,
  formatedObject,
} from "../../containers/Reports/constants";
import { getConsortiaMemberList } from "../../containers/DDR/constants";
import CommonHeading from "../../atoms/Heading/CommonHeading";
import { useFetch } from "../../utilities/apiFunctions/useFetch";
import { fetchUrl } from "../../utilities/apiFunctions/fatchUrl";
import Loading from "../../components/Loading/Loading";
import { baseUrl } from "../../utilities/constants";
import ContuLendingDataTable from "../Table/ContuDataTable";
import ContuBrrrowDataTable from "../Table/ContuBrrrowDataTable";
import { Pagination } from "../ReportsComponents/Pagination";
import DropDownGroupCoontuReport from "../../atoms/DropDown/DropDownGroupContuReport";
import DropDownGroupCoontuReportILL from "../../atoms/DropDown/DropDownGroupCoontuReportILL";

const CountuReport = ({ customerID, consortiamaster_id }) => {
  const [data, setData] = useState({
    report_type: "0",
    selected_year: "",
    requesting_customer_id: "0",
  });

  const [downloadType, setDownloadType] = useState({
    export_type: "excel",
  });

  const [tableDataReport, setTableDataReport] = useState([]);
  const [isTableLoad, setIsTableLoad] = useState(false);
  const [fromDatedata, setFromDatedata] = useState("");
  const [isFromdateLoad, setIsFromdateLoad] = useState(false);
  const [isBorrowedData, setIsBorrowedData] = useState(false);

  tableDataReport &&
    tableDataReport?.forEach((item, ind) => {
      item.id = ind + 1;
    });


  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(15);
  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords =
    tableDataReport &&
    tableDataReport?.slice(indexOfFirstRecord, indexOfLastRecord);
  const nPages = Math.ceil(
    tableDataReport && tableDataReport?.length / recordsPerPage
  );

  const consortiaMemberList = useFetch(
    getConsortiaMemberList(consortiamaster_id),
    {}
  );

  const fromDateResponse = async () => {
    if (data["requesting_customer_id"] === "") {
      data["requesting_customer_id"] = "0";
    }

    if (data["report_type"] === "0") {
      await fetch(
        `${baseUrl}/api/get_client_wise_ddr_from_date/get_client_wise_ddr_from_date?customer_id=${customerID}&member_id=${data.requesting_customer_id}`
      )
        .then((response) => response.json())
        .then((val) => {
          setFromDatedata(val?.data[0][0]?.from_date);
        });
    } else {
      await fetch(
        `${baseUrl}/api/get_client_wise_sent_ddr_from_date/get_client_wise_sent_ddr_from_date?customer_id=${customerID}&member_id=${data.requesting_customer_id}`
      )
        .then((response) => response.json())
        .then((val) => {
          setFromDatedata(val?.data[0][0]?.from_date);
        });
    }
  };

  const dat = JSON.stringify(fromDatedata);
  const [year] = dat.split("-");
  const yearFormate = year.replaceAll('"', "");

  useEffect(() => {
    fromDateResponse();

  }, [isFromdateLoad]);

  useEffect(() => {
    setData({
      report_type: data.report_type,
      selected_year: yearFormate,
      requesting_customer_id: data.requesting_customer_id,
    });

  }, [fromDatedata, isFromdateLoad]);

  useEffect(() => {
    handleLoad();

  }, []);

  const handleLoad = async () => {
    setIsTableLoad(true);
    const _dat = _.cloneDeep(data);
    _dat["customer_id"] = customerID;

    if (_dat["requesting_customer_id"] === "0") {
      if (_dat["selected_year"] === "") {
        _dat["selected_year"] = '0';
      }
    }

    if (_dat["requesting_customer_id"] !== "0") {
      if (_dat["selected_year"] === "") {
        _dat["selected_year"] = yearFormate;
      }
    }

    let str = Object.entries(_dat)
      .map(([key, val]) => `${key}${val ? "=" : ""}${val}`)
      .join("&");

    const response = await fetchUrl(getContuReport(str));
    setTableDataReport(response.data[0][0]);
    setIsTableLoad(false);

    if (_dat["report_type"] !== "0") {
      setIsBorrowedData(true);
    } else {
      setIsBorrowedData(false);
    }
  };

  const handleContuExport = () => {
    const _dat = _.cloneDeep(data);
    _dat["customer_id"] = customerID;
    _dat["export_type"] = downloadType.export_type;

    if (_dat["requesting_customer_id"] === "0") {
      if (_dat["selected_year"] === "") {
        _dat["selected_year"] = '0';
      }
    }

    if (_dat["requesting_customer_id"] !== "0") {
      if (_dat["selected_year"] === "") {
        _dat["selected_year"] = yearFormate;
      }
    }

    let str = Object.entries(_dat)
      .map(([key, val]) => `${key}${val ? "=" : ""}${val}`)
      .join("&");

    window.open(exportContuReport(str));
  };

  const handleYearInputchange = (value, name) => {
    const _dat = _.cloneDeep(data);
    _dat[name] = value;
    if (data["selected_year"] == "") {
      data["selected_year"] = yearFormate;
    }
    setData(_dat);
  };

  var result = [];
  let _year = new Date();
  let endYear = _year.getFullYear();
  var startYear = parseFloat(yearFormate);
  for (var i = startYear; i <= endYear; i++) {
    result.push(i);
  }
  let yearRange = [];

  for (let i in result) {
    let object = {};
    object.label = result[i];
    object.value = result[i];
    yearRange.push(object);
  }

  const handleRadioButton = (value, name) => {
    const _dat = _.cloneDeep(downloadType);
    _dat[name] = value;
    setDownloadType(_dat);
  };

  const handleInputchangeInstitute = (value, name) => {
    const _dat = _.cloneDeep(data);
    _dat[name] = value;
    setData(_dat);
    setIsFromdateLoad(!isFromdateLoad);
  };

  const handleInputchange = (value, name) => {
    const _dat = _.cloneDeep(data);
    _dat[name] = value;
    setData(_dat);
    setIsFromdateLoad(!isFromdateLoad);
  };

  return (
    <div className="CountuReport">
      <CommonHeading heading={ContuReport} />

      <div className="CountuReportDropdownRow">
        {consortiaMemberList.isLoading && <Loading />}
        {!consortiaMemberList.isLoading &&
          consortiaMemberList.response &&
          !!consortiaMemberList.response.data && (
            <DropDownGroupCoontuReport
              classname="w-200"
              label={RequestedFromLabel}
              data={formatedObject(consortiaMemberList.response.data)}
              name="requesting_customer_id"
              handleInputchange={handleInputchangeInstitute}
            />
          )}

        <DropDownGroupCoontuReportILL
          handleInputchange={handleInputchange}
          name="report_type"
          data={illSelectDropdown}
          label={ILL}
        />

        <DropDownGroup
          label={Year}
          handleInputchange={handleYearInputchange}
          name="selected_year"
          data={yearRange}
        />

        <Button onClick={handleLoad} className="contactPersondata">
          Load
        </Button>
      </div>

      <div className="CountuReportRowExport">
        <Radio
          label="Excel"
          name="export_type"
          value={"excel"}
          checkedValue={downloadType["export_type"]}
          handleInputchange={handleRadioButton}
        />
        <Radio
          label="CSV"
          name="export_type"
          value={"csv"}
          checkedValue={downloadType["export_type"]}
          handleInputchange={handleRadioButton}
        />

        <Button onClick={handleContuExport} className="contactPersondata">
          Export
        </Button>
      </div>

      <div className="CountuReportRow">
        {isTableLoad && <Loading />}
        {!isBorrowedData ? (
          <Fragment>
            <ContuLendingDataTable
              tableProperty={countReportKeys}
              tableData={currentRecords}
              isIndexed="true"
            />
            <div>
              <Pagination
                nPages={nPages && nPages}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                setRecordsPerPage={setRecordsPerPage}
                tableDataReport={tableDataReport}
                recordsPerPage={recordsPerPage}
              />
            </div>
          </Fragment>
        ) : (
          <Fragment>
            <ContuBrrrowDataTable
              tableProperty={countReportKeys}
              tableData={currentRecords}
              isIndexed="true"
            />
            <div>
              <Pagination
                nPages={nPages && nPages}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                setRecordsPerPage={setRecordsPerPage}
                tableDataReport={tableDataReport}
                recordsPerPage={recordsPerPage}
              />
            </div>
          </Fragment>
        )}
      </div>
    </div>
  );
};

export default CountuReport;
